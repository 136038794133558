import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Transaction, User } from '@whiskybazar/core';
import { AddressesService } from './addresses.service';
import { DatabaseApiService } from './database-api.service';
import { OwnersService } from './owners.service';

export interface TransactionExpansionConfig {
  seller?: boolean;
  buyer?: boolean;
}

@Injectable()
export class TransactionsService {
  constructor(
    public addressesService: AddressesService,
    public dbApi: DatabaseApiService,
    private ownersService: OwnersService
  ) {}

  expand(transaction: Transaction, expansionConfig: TransactionExpansionConfig): Observable<Transaction> {
    let result: Observable<Transaction> = of(transaction);

    if (expansionConfig.seller) {
      result = result.pipe(
        switchMap((t: Transaction) =>
          this.ownersService.fetchById(t.sellerId).pipe(map((seller: User) => ({ ...t, seller })))
        )
      );
    }

    if (expansionConfig.buyer) {
      result = result.pipe(
        switchMap((t: Transaction) =>
          this.ownersService.fetchById(t.buyerId).pipe(map((buyer: User) => ({ ...t, buyer })))
        )
      );
    }

    return result;
  }
}
