import { Injectable, Injector, inject } from '@angular/core';
import { Database, equalTo, limitToLast, orderByChild } from '@angular/fire/database';
import { Observable, of, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AbstractFirebaseList } from './abstract-firebase-list';
import {
  Mapper,
  auctionMapper,
  Auction,
  AuctionData,
  Dealer,
  Bid,
  PlaceBidResult,
  AuctionTransaction,
} from '@whiskybazar/core';
import { BidsService, BidExpansionConfig } from './bids.service';
import { DealersService } from './dealers.service';
import { AuctionTransactionsService, AuctionTransactionExpansionConfig } from './auction-transactions.service';
import { DatabaseApiService } from './database-api.service';

export interface AuctionExpansionConfig {
  bids?: boolean | BidExpansionConfig;
  bestBid?: boolean | BidExpansionConfig;
  auctioneer?: boolean;
  transaction?: AuctionTransactionExpansionConfig;
}

@Injectable()
export class AuctionsService extends AbstractFirebaseList<Auction, AuctionData> {
  private readonly path = 'auctions';

  private dealersService: DealersService;

  private auctionTransactionsService: AuctionTransactionsService;

  private afDb: Database = inject(Database);

  constructor(private di: Injector, private bidsService: BidsService, private dbApi: DatabaseApiService) {
    super();

    // take care of circular dependency injection
    setTimeout(() => this.injectServices());
  }

  getPath(): string {
    return this.path;
  }

  getAngularFireDatabase(): Database {
    return this.afDb;
  }

  getMapper(): Mapper<AuctionData, Auction> {
    return auctionMapper;
  }

  getDatabaseApiService(): DatabaseApiService {
    return this.dbApi;
  }

  fetchById(id: string, expansionConfig?: AuctionExpansionConfig): Observable<Auction> {
    if (!expansionConfig) {
      return super.fetchById(id);
    }

    let result = super.fetchById(id);

    if (expansionConfig) {
      result = result.pipe(
        switchMap((auction: Auction | null) =>
          auction === null ? of(auction) : this.expandAuction(auction, expansionConfig)
        )
      );
    }

    return result;
  }

  fetchByAuctioneer(auctioneerId: string, limit = 20, expansionConfig?: AuctionExpansionConfig): Observable<Auction[]> {
    let result = this.query(orderByChild('auctioneer'), equalTo(auctioneerId), limitToLast(limit));

    if (expansionConfig) {
      result = result.pipe(switchMap((auctions: Auction[]) => this.expandAuctions(auctions, expansionConfig)));
    }

    return result;
  }

  placeBid(bid: Bid): Observable<PlaceBidResult> {
    return this.bidsService.placeBid(bid);
  }

  fetchByBids(bids: Bid[], expansionConfig?: AuctionExpansionConfig): Observable<Auction[]> {
    return combineLatest(bids.map((b: Bid) => this.fetchById(b.auctionId, expansionConfig)));
  }

  protected expandAuctions(auctions: Auction[], expansionConfig: AuctionExpansionConfig): Observable<Auction[]> {
    if (auctions.length === 0) {
      return of([]);
    }

    return combineLatest(auctions.map((auction: Auction) => this.expandAuction(auction, expansionConfig)));
  }

  protected expandAuction(auction: Auction, expansionConfig: AuctionExpansionConfig): Observable<Auction> {
    let result: Observable<Auction> = of(auction);

    if (expansionConfig.auctioneer) {
      result = result.pipe(switchMap((a: Auction) => this.fetchAuctioneer(a)));
    }

    if (expansionConfig.bids === true) {
      result = result.pipe(switchMap((a: Auction) => this.fetchBids(a)));
    } else if (expansionConfig.bids) {
      result = result.pipe(switchMap((a: Auction) => this.fetchBids(a, expansionConfig.bids as BidExpansionConfig)));
    }

    if (expansionConfig.bestBid === true) {
      result = result.pipe(switchMap((a: Auction) => this.fetchBestBid(a)));
    } else if (expansionConfig.bestBid) {
      result = result.pipe(
        switchMap((a: Auction) => this.fetchBestBid(a, expansionConfig.bestBid as BidExpansionConfig))
      );
    }

    if (expansionConfig.transaction) {
      result = result.pipe(switchMap((a: Auction) => this.fetchTransaction(a, expansionConfig.transaction)));
    }

    return result;
  }

  protected fetchBids(auction: Auction, expansionConfig?: BidExpansionConfig): Observable<Auction> {
    if (!auction.bidIds || auction.bidIds.length === 0) {
      return of(auction);
    }

    return combineLatest(auction.bidIds.map((bidId: string) => this.fetchBid(bidId, auction, expansionConfig))).pipe(
      map((result: Auction[]) =>
        result.reduce((prev: Auction, next: Auction) => ({
          ...auction,
          bids: [...prev.bids, ...next.bids],
        }))
      )
    );
  }

  protected fetchBid(bidId: string, auction: Auction, expansionConfig?: BidExpansionConfig): Observable<Auction> {
    return this.bidsService
      .fetchById(bidId, expansionConfig)
      .pipe(map((bid: Bid) => ({ ...auction, bids: [...auction.bids, bid] })));
  }

  protected fetchBestBid(auction: Auction, expansionConfig?: BidExpansionConfig): Observable<Auction> {
    if (!auction || !auction.bestBidId) {
      return of(auction);
    }

    return this.bidsService
      .fetchById(auction.bestBidId, expansionConfig)
      .pipe(map((bestBid: Bid) => ({ ...auction, bestBid })));
  }

  protected fetchAuctioneer(auction: Auction): Observable<Auction> {
    return this.dealersService.fetchById(auction.auctioneerId).pipe(
      map((auctioneer: Dealer) => {
        return { ...auction, auctioneer };
      })
    );
  }

  protected fetchTransaction(
    auction: Auction,
    expansionConfig: AuctionTransactionExpansionConfig
  ): Observable<Auction> {
    if (!auction.transactionId) {
      return of(auction);
    }

    return this.auctionTransactionsService
      .fetchById(auction.transactionId, expansionConfig)
      .pipe(map((transaction: AuctionTransaction) => ({ ...auction, transaction })));
  }

  private injectServices() {
    this.dealersService = this.di.get(DealersService);
    this.auctionTransactionsService = this.di.get(AuctionTransactionsService);
  }
}
