import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
  @Input() placeholder = 'Search..';

  @Input() searching: boolean;

  @Input()
  set value(value: string) {
    this.ctrl.setValue(value);
  }

  @Output() search = new EventEmitter<string>();

  readonly ctrl = new FormControl<string>('');

  changes$: Observable<void>;

  constructor() {
    this.changes$ = this.ctrl.valueChanges.pipe(
      tap((value: string) => this.search.emit(value)),
      map(() => null)
    );
  }
}
