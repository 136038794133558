import { STORAGE } from '../constants';

export class StorageUtils {
  static generateFileId(file: File): string {
    return (file.name.replace(/[^A-Za-z0-9_]/gi, '') + '_' + Math.round(Math.random() * Date.now())).slice(-31);
  }

  static buildShipmentLabelPath(referenceId: string, shippingProductId: string, file: File | string): string {
    const fileId = typeof file === 'string' ? file : StorageUtils.generateFileId(file);
    return [STORAGE.SHIPPING_FOLDER, referenceId, shippingProductId, fileId].join('/');
  }
}
