import { Component, OnInit, Input } from '@angular/core';

import { Bottle } from '@whiskybazar/core';

@Component({
  selector: 'app-bottle-dealer',
  templateUrl: './bottle-dealer.component.html',
  styleUrls: ['./bottle-dealer.component.scss'],
})
export class BottleDealerComponent implements OnInit {
  @Input() bottle: Bottle;

  @Input() country: 'code' | 'name';

  @Input() layout: 'name_country' | 'country_name';

  constructor() {
    this.country = 'code';
    this.layout = 'name_country';
  }

  ngOnInit() {}

  getDealerId(): string | null {
    if (!this.bottle) {
      return null;
    }

    const dealer = this.bottle.auction
      ? this.bottle.auction.auctioneer
      : this.bottle.sale
      ? this.bottle.sale.dealer
      : null;
    if (!dealer) {
      return null;
    }

    return dealer.id;
  }
}
