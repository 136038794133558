import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  Auth,
  RecaptchaVerifier,
  User,
  authState,
  ConfirmationResult,
  signInWithEmailAndPassword,
  UserCredential,
  createUserWithEmailAndPassword,
  user,
  sendPasswordResetEmail,
  linkWithPhoneNumber,
} from '@angular/fire/auth';
import { forkJoin, from as fromPromise, Observable, of, throwError } from 'rxjs';
import { catchError, map, mapTo, switchMap, take, tap } from 'rxjs/operators';

import { Admin, AuthUser, Collector, Dealer } from '@whiskybazar/core';
import { environment } from '@whiskybazar/pwa/environment';
import { AdminsService } from './admins.service';
import { CollectorsService } from './collectors.service';
import { DealersService } from './dealers.service';

export const DEFAULT_RECAPTCHA_PARAMS: Object = {
  size: 'invisible',
  callback: () => {},
};

export interface AuthCustomClaims {
  result: 'ADDED_NEW' | 'CLAIMS_DEFINED';
}

@Injectable()
export class AuthService {
  set redirect(url: string) {
    this._url = url;
  }
  get redirect(): string {
    return this._url;
  }
  private _url: string;

  get stateChange(): Observable<User | null> {
    return authState(this.afAuth);
  }

  private recaptchaVerifier: RecaptchaVerifier;

  private confirmationResult: ConfirmationResult;

  constructor(
    private afAuth: Auth = inject(Auth),
    private adminsService: AdminsService,
    private collectorsService: CollectorsService,
    private dealersService: DealersService,
    private http: HttpClient
  ) {}

  authenticate(): Observable<AuthUser> {
    return this.stateChange.pipe(switchMap((user: User) => this.load(user)));
  }

  login(email: string, password: string): Observable<AuthUser> {
    return fromPromise(signInWithEmailAndPassword(this.afAuth, email, password)).pipe(
      switchMap((result: UserCredential) => this.load(result.user))
    );
  }

  logout() {
    this.afAuth.signOut();
    this.redirect = null;
  }

  signupCollector(collector: Collector): Observable<Collector> {
    return fromPromise(createUserWithEmailAndPassword(this.afAuth, collector.email, collector.password)).pipe(
      switchMap((result: UserCredential) => this.collectorsService.create({ ...collector, id: result.user.uid }))
    );
  }

  delete(): Observable<boolean> {
    if (!this.afAuth.currentUser) {
      return of(false);
    }

    return user(this.afAuth).pipe(
      switchMap((user: User) => fromPromise(user.delete())),
      mapTo(true)
    );
  }

  getIdToken(forceRefresh = false): Observable<string> {
    return user(this.afAuth).pipe(switchMap((user: User) => fromPromise(user.getIdToken(forceRefresh))));
  }

  sendPasswordResetEmail(email: string): Observable<void> {
    return fromPromise(sendPasswordResetEmail(this.afAuth, email));
  }

  setCustomUserClaims(uid: string): Observable<boolean> {
    const endpoint = `${environment.cloudFunctions}/v1/auth/${uid}/custom_claims`;

    return this.http.post<AuthCustomClaims>(endpoint, null).pipe(
      map((res: AuthCustomClaims) => res.result === 'ADDED_NEW'),
      catchError(() => of(false)),
      tap((result: boolean) => this.getIdToken(result))
    );
  }

  createRecaptchaVerifier(container: string, params = DEFAULT_RECAPTCHA_PARAMS): RecaptchaVerifier {
    this.recaptchaVerifier = new RecaptchaVerifier(container, params, this.afAuth);

    return this.recaptchaVerifier;
  }

  linkPhoneNumber(phoneNumber: string): Observable<boolean> {
    if (!this.recaptchaVerifier) {
      return throwError('Invalid call! Ensure reCAPTCHA verifier is created.');
    }

    return user(this.afAuth).pipe(
      switchMap((user: User) => fromPromise(linkWithPhoneNumber(user, phoneNumber, this.recaptchaVerifier))),
      tap((confirmationResult: ConfirmationResult) => (this.confirmationResult = confirmationResult)),
      mapTo(true)
    );
  }

  confirmVerificationCode(code: string): Observable<UserCredential> {
    if (!this.confirmationResult) {
      return throwError('Invalid call! Ensure confirmation result is set.');
    }

    return fromPromise(this.confirmationResult.confirm(code));
  }

  addCollectorPhoneNumber(collector: Collector, phoneNumber: string): Observable<Collector> {
    return this.collectorsService.update(collector.id, { ...collector, phone: phoneNumber, phoneVerified: true });
  }

  protected load(user: User | null): Observable<AuthUser> {
    if (!user) {
      return of(null);
    }

    return forkJoin([
      this.adminsService.fetchByAuth(user),
      this.collectorsService.fetchById(user.uid).pipe(take(1)),
      this.dealersService.fetchById(user.uid).pipe(take(1)),
    ]).pipe(
      map((result: [Admin | null, Collector | null, Dealer | null]) => {
        if (result[0] !== null) {
          return result[0];
        } else if (result[1] !== null) {
          return result[1];
        } else if (result[2] !== null) {
          return result[2];
        }

        return null;
      })
    );
  }
}
