import { trigger, transition, query, stagger, animateChild, style, animate } from '@angular/animations';

/**
 * Animating addition and removal of elements of DOM
 *
 * Add the `@items` trigger to the element with `ngFor`.
 *
 * **Example**:
 *
 * ```html
 *   <div @items ngFor="let item of items">...</div>
 * ```
 */
export const items = trigger('items', [
  transition(':enter', [
    style({ transform: 'scale(0.5)', opacity: 0 }),
    animate('1s cubic-bezier(.8,-0.6,0.2,1.5)', style({ transform: 'scale(1)', opacity: 1 })),
  ]),
  transition(':leave', [
    style({ transform: 'scale(1)', opacity: 1, height: '*' }),
    animate(
      '1s cubic-bezier(.8,-0.6,0.2,1.5)',
      style({ transform: 'scale(0.5)', opacity: 0, height: '0px', margin: '0px' })
    ),
  ]),
]);

/**
 * Animating the initial display of a list
 *
 * Add the `@list` trigger to the parent element of the element with `ngFor`.
 * This trigger is dependent on the `@items` trigger.
 *
 * **Example**
 *
 * ```html
 * <div @list>
 *   <div @items ngFor="let item of items">...</div>
 * </div>
 * ```
 */
export const list = trigger('list', [
  transition(':enter', [
    query('@items', stagger(300, animateChild()), {
      optional: true,
    }),
  ]),
]);
