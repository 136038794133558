import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { exhaustMap, map, take, filter, switchMap } from 'rxjs/operators';

import { Article, Category } from '@whiskybazar/pwa/categories/models';
import { CategoriesService } from '@whiskybazar/pwa/categories/services';
import * as ArticlePageActions from '../actions';
import * as fromStore from '../reducers';

@Injectable()
export class ArticlePageEffects {
  loadArticles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticlePageActions.loadArticles),
      exhaustMap(() => this.load().pipe(map((articles: Article[]) => ArticlePageActions.addArticles({ articles }))))
    )
  );

  loadArticleContent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticlePageActions.loadArticleContent),
      map((action) => action.article),
      exhaustMap((article: Article) =>
        this.categoriesService
          .fetchArticleContent(article)
          .pipe(
            map((result: Article) => ArticlePageActions.updateArticle({ article: { id: result.id, changes: result } }))
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store$: Store<fromStore.State>,
    private categoriesService: CategoriesService
  ) {}

  private load(): Observable<Article[]> {
    const articles$ = this.store$.pipe(
      select(fromStore.selectCategoryPageAll),
      take(1),
      // Extract the articles from each category
      map((categories: Category[]) =>
        categories
          .map((category: Category) => category.articles)
          .reduce((all: Article[], curr: Article[]) => all.concat(curr), [])
      )
    );

    return this.store$.pipe(
      select(fromStore.selectCategoryPageLoaded),
      filter((loaded: boolean) => loaded),
      take(1),
      switchMap(() => articles$)
    );
  }
}
