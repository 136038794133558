<mat-accordion displayMode="flat" multi="true">
  <mat-expansion-panel *ngFor="let category of categories" expanded="true">
    <mat-expansion-panel-header collapsedHeight="48px" expandedHeight="48px">
      {{ category.name }}
    </mat-expansion-panel-header>

    <mat-nav-list>
      <a
        *ngFor="let subcategory of category.children"
        mat-list-item
        [routerLink]="subcategory.path"
        routerLinkActive="active"
      >
        {{ subcategory.name }}
      </a>
    </mat-nav-list>
  </mat-expansion-panel>
</mat-accordion>
