import { Component, OnInit, ChangeDetectionStrategy, ElementRef, AfterViewInit, ViewChild } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BehaviorSubject, Observable, merge, fromEvent } from 'rxjs';
import { filter, mapTo, map, tap, distinctUntilChanged } from 'rxjs/operators';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { WindowRefService, PlatformService } from '@whiskybazar/pwa/core/services';
import { Slide, slides } from './slides';
import { Router } from '@angular/router';

export interface RuntimeSlide extends Slide {
  safeCaption: SafeHtml;
  safeText?: SafeHtml;
  style?: { [prop: string]: string };
}

export interface Dimensions {
  width: number;
  height: number;
}

export interface Breakpoint extends Dimensions {
  mediaQuery: string;
}

@Component({
  selector: 'app-hero-slider',
  templateUrl: './hero-slider.component.html',
  styleUrls: ['./hero-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeroSliderComponent implements OnInit, AfterViewInit {
  @ViewChild('slider', { static: true }) private slider: ElementRef;

  /**
   * Path to the folder containing images for the slides
   */
  readonly images = '/assets/images/slides';

  readonly breakpoints: Breakpoint[] = [
    {
      width: 640,
      height: 360,
      mediaQuery: '(max-width: 640px)',
    },
    {
      width: 640,
      height: 360,
      mediaQuery: '(min-width: 640px) and (max-width: 1024px)',
    },
    {
      width: 1024,
      height: 576,
      mediaQuery: '(min-width: 1024px) and (max-width: 1920px)',
    },
    {
      width: 1920,
      height: 1080,
      mediaQuery: '(min-width: 1920px)',
    },
  ];

  width$ = new BehaviorSubject<string>('1024px');

  auto$ = new BehaviorSubject<boolean>(false);

  slides$: Observable<RuntimeSlide[]>;

  resizing$: Observable<number>;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private domSanitizer: DomSanitizer,
    private winRef: WindowRefService,
    private platform: PlatformService,
    private router: Router
  ) {}

  ngOnInit() {
    this.slides$ = merge(...this.breakpoints.map((target: Breakpoint) => this.queryBreakpoint(target))).pipe(
      map((dimension: Dimensions) => this.generateSlides(dimension))
    );

    if (this.platform.onBrowser()) {
      this.resizing$ = fromEvent(this.winRef.nativeWindow, 'resize').pipe(
        map(() => this.getSliderWidth()),
        distinctUntilChanged(),
        tap((width: number) => this.updateWidth(width))
      );

      this.auto$.next(true);
    }
  }

  ngAfterViewInit() {
    if (this.platform.onBrowser()) {
      // Defer the update of the width to next VM cycle in order to avoid
      // ExpressionChangedAfterItHasBeenCheckedError
      setTimeout(() => this.updateWidth(this.getSliderWidth()));
    }
  }

  handleNavigation(slide: RuntimeSlide) {
    if (!slide.url) {
      return;
    }

    const [route, fragment] = slide.url.split('#');
    if (fragment) {
      this.router.navigate([route], { fragment });
    } else {
      this.router.navigate([route]);
    }
  }

  private getSliderWidth(): number {
    if (!this.slider || !this.slider.nativeElement || !this.slider.nativeElement.getBoundingClientRect) {
      return 1024;
    }

    return this.slider.nativeElement.getBoundingClientRect().width;
  }

  private updateWidth(width: number) {
    const w = Math.floor(width || 0);
    this.width$.next(`${w}px`);
  }

  private generateSlides(dimensions: Dimensions): RuntimeSlide[] {
    return slides.map(
      (slide: Slide) =>
        ({
          ...slide,
          image: this.buildSlideImageUrl(slide.image, dimensions),
          safeCaption: this.domSanitizer.bypassSecurityTrustHtml(slide.caption),
          safeText: slide.text ? this.domSanitizer.bypassSecurityTrustHtml(slide.text) : slide.text,
          style: this.buildSlideStyles(slide, dimensions),
        } as RuntimeSlide)
    );
  }

  private buildSlideStyles(slide: Slide, dimensions: Dimensions): { [prop: string]: string } {
    return {
      'background-image': `url('${this.buildSlideImageUrl(slide.image, dimensions)}')`,
      height: `${dimensions.height}px`,
    };
  }

  private buildSlideImageUrl(image: string, dimensions: Dimensions): string {
    return this.images + image.replace('{w}', '' + dimensions.width).replace('{h}', '' + dimensions.height);
  }

  private queryBreakpoint(target: Breakpoint): Observable<Dimensions> {
    return this.breakpointObserver.observe(target.mediaQuery).pipe(
      filter((result: BreakpointState) => result.matches === true),
      mapTo({
        width: target.width,
        height: target.height,
      } as Dimensions)
    );
  }
}
