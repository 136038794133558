import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CategoriesPageComponent } from './containers';
import { CategoriesPageGuard } from './guards';

const routes: Routes = [
  {
    path: 'categories',
    component: CategoriesPageComponent,
    canActivate: [CategoriesPageGuard],
    children: [
      {
        path: '**',
        children: [],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CategoriesRoutingModule {}
