export const DEALER_STATES = {
  PENDING_APPROVAL: {
    id: 'PENDING_APPROVAL',
    name: 'Pending Approval',
  },
  APPROVED: {
    id: 'APPROVED',
    name: 'Approved',
  },
};
