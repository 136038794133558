<div
  fxLayout="row"
  fxLayoutAlign="center center"
  class="button"
  [class.hero]="size === 'hero'"
  [class.mat-elevation-z8]="size === 'hero'"
  [class.positive]="color === 'positive'"
  [class.negative]="color === 'negative'"
>
  <div fxFlex="none" fxLayoutAlign="center center" class="label" [class.with-arrow]="arrow">
    <ng-content></ng-content>
  </div>
  <div fxFlex="none" fxLayoutAlign="center center" class="icon" *ngIf="arrow">
    <mat-icon>keyboard_arrow_right</mat-icon>
  </div>
</div>
