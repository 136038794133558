import { Pipe, PipeTransform } from '@angular/core';

import { AuthContextService } from '@whiskybazar/pwa/core/services';
import { Bottle } from '@whiskybazar/core';

type Operation = 'canBid' | 'hasWon' | 'hasLost';

/**
 * @deprecated Use the generic `authContext` pipe
 */
@Pipe({
  name: 'auction',
})
export class AuctionPipe implements PipeTransform {
  constructor(protected ctx: AuthContextService) {}

  transform(auction: Bottle, operation?: Operation): number | boolean {
    if (!auction) {
      return false;
    }

    switch (operation) {
      case 'canBid': {
        return this.ctx.canBid(auction);
      }

      case 'hasWon': {
        return this.ctx.hasBestBid(auction);
      }

      case 'hasLost': {
        return this.ctx.hasLostAuction(auction);
      }
    }

    return false;
  }
}
