export interface GoogleTagManagerConfig {
  containerID: string;
  auth?: string;
  preview?: string;
  cookiesWin?: string;
}

/**
 * See also "apps/pwa/src/index.html" for installing GTM with the noscript tag.
 *
 * @param config GTM configuration, i.e. container ID and custom environment parameters
 */
export function installGoogleTagManager({ containerID, auth, preview, cookiesWin }: GoogleTagManagerConfig) {
  // Preparing GTM API to be usable even before the async script is loaded
  window['dataLayer'] = window['dataLayer'] || [];
  window['dataLayer'].push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  });

  const f = document.getElementsByTagName('script')[0];

  const queryParams = [
    'id=' + containerID,
    auth ? 'gtm_auth=' + auth : '',
    preview ? 'gtm_preview=' + preview : '',
    cookiesWin ? 'gtm_cookies_win=' + cookiesWin : '',
  ]
    .filter(Boolean)
    .join('&');

  // Inject GTM main script asynchronously
  const script = document.createElement('script');
  script.src = `https://www.googletagmanager.com/gtm.js?${queryParams}`;
  script.async = true;
  f.parentNode.insertBefore(script, f);
}
