import { inject, Injectable } from '@angular/core';
import { Database, equalTo, orderByChild, QueryConstraint } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AbstractFirebaseList } from './abstract-firebase-list';
import { Mapper, dealerMapper, Dealer, DealerData } from '@whiskybazar/core';
import { DEALER_STATES } from '@whiskybazar/core';

@Injectable()
export class DealersService extends AbstractFirebaseList<Dealer, DealerData> {
  private readonly path = 'dealers';

  private afDb: Database = inject(Database);

  constructor() {
    super();
  }

  getPath(): string {
    return this.path;
  }

  getAngularFireDatabase(): Database {
    return this.afDb;
  }

  getMapper(): Mapper<DealerData, Dealer> {
    return dealerMapper;
  }

  fetchById(id: string): Observable<Dealer> {
    return super.fetchById(id).pipe(map((dealer: Dealer) => (this.approved(dealer) ? dealer : null)));
  }

  query(...q: QueryConstraint[]): Observable<Dealer[]> {
    return super.query(...q).pipe(map((dealers: Dealer[]) => this.filter(dealers)));
  }

  fetchByCountry(country: string): Observable<Dealer[]> {
    return this.query(orderByChild('country'), equalTo(country));
  }

  protected filter(dealers: Dealer[]): Dealer[] {
    return dealers.filter((d: Dealer) => this.approved(d));
  }

  protected approved(dealer: Dealer): boolean {
    if (!dealer) {
      return false;
    }

    return dealer.state === DEALER_STATES.APPROVED.id;
  }
}
