import { BuyerAuctionInvoice } from '../models';

/**
 * Calculates and returns the total amount based on the given invoice
 *
 * @param invoice Buyer auction invoice from which to calculate the total
 */
export function calcBuyerInvoiceTotal(invoice: BuyerAuctionInvoice): number {
  const details = invoice.details || {};

  return [
    details.hammerPrice ? details.hammerPrice : 0.0,
    details.auctionFee ? details.auctionFee : 0.0,
    details.packagingFee ? details.packagingFee : 0.0,
    details.shippingCost ? details.shippingCost : 0.0,
    details.shippingInsuranceCost ? details.shippingInsuranceCost : 0.0,
  ].reduce((sum: number, a: number) => sum + a);
}
