import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BottleTileComponent, BottleTileHeaderComponent, BottleTileActionComponent } from './bottle-tile.component';
import { ImageThumbnailModule } from '../../directives';
import {
  BottlePromotedImageModule,
  BottleAttrPrecedenceModule,
  AuthContextModule,
  BottleLotModule,
  BottleModule,
} from '../../pipes';
import { BottleStateModule } from '../bottle-state/bottle-state.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BottleDealerModule } from '../bottle-dealer/bottle-dealer.module';

@NgModule({
  declarations: [BottleTileComponent, BottleTileHeaderComponent, BottleTileActionComponent],
  imports: [
    CommonModule,
    ImageThumbnailModule,
    BottlePromotedImageModule,
    BottleAttrPrecedenceModule,
    BottleStateModule,
    AuthContextModule,
    BottleLotModule,
    BottleDealerModule,
    FlexLayoutModule,
    BottleModule,
  ],
  exports: [BottleTileComponent, BottleTileHeaderComponent, BottleTileActionComponent],
})
export class BottleTileModule {}
