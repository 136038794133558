import { Injectable, inject } from '@angular/core';
import { Database, list, query, orderByChild, equalTo, limitToLast, ref } from '@angular/fire/database';
import { BottlesService } from './bottles.service';
import { Observable, combineLatest, of } from 'rxjs';
import {
  Auction,
  AuctionData,
  AuctionTransactionData,
  BOTTLE_STATES,
  BidData,
  Bottle,
  auctionMapper,
  auctionTransactionMapper,
  bidMapper,
} from '@whiskybazar/core';
import { switchMap, map } from 'rxjs/operators';
import { OwnersService } from './owners.service';

@Injectable()
export class ArchiveService {
  private afDb: Database = inject(Database);

  private readonly path = '/archive';

  private get auctionTransactionsPath(): string {
    return `${this.path}/auction_transactions`;
  }

  constructor(private bottlesService: BottlesService, private ownersService: OwnersService) {}

  fetchDealerHistory(dealerId: string, limit = 20): Observable<Bottle[]> {
    const q = query(
      ref(this.afDb, this.auctionTransactionsPath),
      orderByChild('auctioneer'),
      equalTo(dealerId),
      limitToLast(limit)
    );

    return list(q).pipe(
      map((changes) => changes.map((change) => change.snapshot.val())),
      switchMap((data: AuctionTransactionData[]) => {
        if (data.length === 0) {
          return of([]);
        }

        const auctions: Auction[] = data.map(({ auction: auctionData, ...rest }: AuctionTransactionData) => {
          const aData = auctionData as AuctionData;

          const auction: Auction = auctionMapper.fromDb(aData);
          auction.bestBid = bidMapper.fromDb(aData.bids[aData.best_bid] as BidData);
          auction.bids = auction.bidIds.map((bidId) => bidMapper.fromDb(aData.bids[bidId] as BidData));
          auction.transactionId = rest.id;
          auction.transaction = auctionTransactionMapper.fromDb({ ...rest, auction: auction.id });

          return auction;
        });

        return combineLatest(
          auctions.map((auction) => {
            const bottle$ = this.bottlesService.fetchById(auction.bottleId);
            const seller$ = this.ownersService.fetchById(auction.transaction.sellerId);
            const buyer$ = this.ownersService.fetchById(auction.transaction.buyerId);

            return combineLatest([bottle$, seller$, buyer$]).pipe(
              map(([bottle, seller, buyer]) => {
                auction.transaction.seller = seller;
                auction.transaction.buyer = buyer;

                return {
                  ...bottle,
                  owner: seller,
                  // TODO(if): this is a hack, we should have a better way to handle this
                  state: BOTTLE_STATES.PENDING_SETTLEMENT.id,
                  auction,
                };
              })
            );
          })
        );
      })
    );
  }
}
