import { MetaBottle, MetaBottleData } from '../models';
import { Mapper } from './mapper';
import { BottleImagesUtils } from '../utils';

export class MetaBottleMapper implements Mapper<MetaBottleData, MetaBottle> {
  fromDb(data: MetaBottleData | null): MetaBottle | null {
    if (!data) {
      return null;
    }

    // TODO complete by adding remaining data fields
    return {
      age: <number>data.age,
      alcoholPercentage: <number>data.alcohol_percentage,
      approved: data.approved,
      barcode: <string>data.barcode,
      bottler: <string>data.bottler,
      bottlingYear: <number>data.bottling_year,
      createdAt: data.created_at ? new Date(data.created_at) : null,
      description: <string>data.description,
      distillationYear: <number>data.distillation_year,
      distillery: <string>data.distillery,
      hasAdditives: <boolean>data.has_additives,
      id: <string>data.id,
      image: <string>data.image,
      images: data.images ? BottleImagesUtils.fromDbImages(data) : [],
      numberOfBottles: <number>data.number_of_bottles,
      origin: <string>data.origin,
      region: <string>data.region,
      seoText: <string>data.seo_text,
      title: <string>data.title,
      type: <string>data.type,
      updatedAt: data.updated_at ? new Date(data.updated_at) : null,
      volume: <number>data.volume,
    };
  }

  toDb(model: MetaBottle | null): MetaBottleData | null {
    if (!model) {
      return null;
    }

    // TODO complete by adding remaining fields
    return {
      // cask_type: model.caskType ? model.caskType : undefined,
      // serie_brand: model.serieBrand ? model.serieBrand : undefined,
      age: model.age ? model.age : undefined,
      alcohol_percentage: model.alcoholPercentage ? model.alcoholPercentage : undefined,
      barcode: model.barcode ? model.barcode : undefined,
      bottler: model.bottler ? model.bottler : undefined,
      bottling_date: model.distillationDate ? model.distillationDate : undefined,
      bottling_month: model.bottlingMonth ? model.bottlingMonth : undefined,
      bottling_year: model.bottlingYear ? model.bottlingYear : undefined,
      cold_filtered: model.coldFiltered ? model.coldFiltered : undefined,
      description: model.description ? model.description : (model.description || '').length === 0 ? '' : undefined,
      distillation_date: model.distillationDate ? model.distillationDate : undefined,
      distillation_month: model.distillationMonth ? model.distillationMonth : undefined,
      distillation_year: model.distillationYear ? model.distillationYear : undefined,
      distillery: model.distillery ? model.distillery : undefined,
      has_additives: model.hasAdditives ? model.hasAdditives : undefined,
      id: model.id ? model.id : undefined,
      image: model.image ? model.image : undefined,
      images: model.images ? BottleImagesUtils.toDbImages(model) : undefined,
      number_of_bottles: model.numberOfBottles ? model.numberOfBottles : undefined,
      origin: model.origin ? model.origin : undefined,
      region: model.region ? model.region : undefined,
      seo_text: model.seoText ? model.seoText : undefined,
      title: model.title ? model.title : undefined,
      type: model.type ? model.type : undefined,
      volume: model.volume >= 0 ? +model.volume : undefined,
    };
  }
}

export const metaBottleMapper = new MetaBottleMapper();
