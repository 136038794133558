import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-forgot-pass-form',
  templateUrl: './forgot-pass-form.component.html',
  styleUrls: ['./forgot-pass-form.component.scss'],
})
export class ForgotPassFormComponent implements OnInit {
  @Input()
  set pending(val: boolean) {
    if (val) {
      this.form.disable();
    } else {
      this.form.enable();
    }

    this.isPending = val;
  }

  @Input() errorMessage: string;

  @Output() readonly submitted = new EventEmitter<string>();

  isPending = false;

  form: UntypedFormGroup;

  constructor() {
    const email = new UntypedFormControl(null, [Validators.required]);
    this.form = new UntypedFormGroup({ email });
  }

  ngOnInit() {}

  submit() {
    if (this.form.valid) {
      this.submitted.emit(this.form.value.email);
    }
  }
}
