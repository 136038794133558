<div class="flex flex-row items-center">
  <div class="flex-1">
    <form>
      <div class="search-field">
        <input [placeholder]="placeholder" [formControl]="ctrl" [attr.aria-label]="placeholder" />
      </div>
    </form>
  </div>
  <div class="flex flex-row justify-center items-center">
    <mat-icon *ngIf="!searching">search</mat-icon>
    <mat-spinner diameter="26" strokeWidth="3" color="primary" *ngIf="searching"></mat-spinner>
  </div>
</div>

<!-- NOTE: this is only to safely unsubscribe the observable -->
<ng-template *ngIf="changes$ | async"></ng-template>
