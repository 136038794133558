import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { AUCTION_VARIABLE_FEE, Bottle, BuyerAuctionInvoice, calcBuyerInvoiceTotal } from '@whiskybazar/core';

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentDetailsComponent implements OnInit {
  @Input()
  set bottle(val: Bottle) {
    if (!val) {
      return;
    }

    this.invoice = val.auction.transaction.buyerInvoice;
  }

  invoice: BuyerAuctionInvoice;

  auctionVariableFee: number;

  constructor() {
    this.auctionVariableFee = AUCTION_VARIABLE_FEE;
  }

  ngOnInit() {}

  calcTotal(): number {
    if (!this.invoice) {
      return 0.0;
    }

    return calcBuyerInvoiceTotal(this.invoice);
  }
}
