<div class="flex flex-col px-2">
  <div class="toolbar flex flex-row items-center p-2 space-x-4">
    <div class="flex-1 flex flex-row items-center space-x-2">
      <!-- Filters toggler -->
      <button mat-icon-button color="primary" (click)="drawer.toggle()">
        <mat-icon aria-label="Open filters">filter_list</mat-icon>
      </button>

      <button *ngIf="hasFilters" mat-icon-button color="accent" (click)="onClearFilters()">
        <mat-icon aria-label="Clear filters">filter_list_off</mat-icon>
      </button>

      <!-- Search Input -->
      <app-search
        placeholder="Search by title/description/LOT"
        [value]="query"
        [searching]="searching"
        (search)="onSearch($event)"
      ></app-search>

      <button *ngIf="hasQuery" mat-icon-button color="accent" (click)="onClearQuery()">
        <mat-icon aria-label="Clear search">search_off</mat-icon>
      </button>
    </div>

    <div>
      <!-- List View -->
      <button mat-icon-button color="primary" (click)="setViewMode('list')" [fxHide]="isList()">
        <mat-icon aria-label="View list">view_list</mat-icon>
      </button>
      <!-- Grid View -->
      <button mat-icon-button color="primary" (click)="setViewMode('grid')" [fxHide]="isGrid()">
        <mat-icon aria-label="View grid">view_module</mat-icon>
      </button>
    </div>
  </div>

  <mat-drawer-container class="sm:flex-1" [style.min-height]="matDrawerContentHeight + 'px'">
    <!-- Filtering and Sorting -->
    <mat-drawer #drawer [mode]="drawerMode$ | async" [opened]="drawerOpened$ | async" class="py-6 px-4 w-80">
      <!-- ### Sorting ### -->
      <mat-form-field appearance="fill" class="w-full">
        <mat-label>Sort by</mat-label>
        <mat-select [value]="sorting.selection" (selectionChange)="onSortingChange($event)">
          <mat-option *ngFor="let option of sorting.options" [value]="option.value">{{ option.name }}</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- ### Filters ### -->
      <div class="mt-4 mb-6 w-full border-b border-b-gray-200 relative flex justify-center">
        <div class="text-muted absolute -bottom-2.5 bg-white px-3">Filter by</div>
      </div>

      <!-- Distilleries -->
      <app-chips-input
        label="Distilleries"
        placeholder="Sellect distilleries"
        [options]="distilleriesFilter.options"
        [selection]="distilleriesFilter.selection"
        [displayFn]="displayFn"
        [filterFn]="filterFn"
        [equalFn]="equalFn"
        (changes)="onDistilleriesChange($event)"
      ></app-chips-input>

      <!-- Bottlers -->
      <app-chips-input
        label="Bottlers"
        placeholder="Sellect bottlers"
        [options]="bottlersFilter.options"
        [selection]="bottlersFilter.selection"
        [displayFn]="displayFn"
        [filterFn]="filterFn"
        [equalFn]="equalFn"
        (changes)="onBottlersChange($event)"
      ></app-chips-input>

      <!-- Regions -->
      <app-chips-input
        label="Regions"
        placeholder="Sellect regions"
        [options]="regionsFilter.options"
        [selection]="regionsFilter.selection"
        [displayFn]="displayFn"
        [filterFn]="filterFn"
        [equalFn]="equalFn"
        (changes)="onRegionsChange($event)"
      ></app-chips-input>

      <!-- Types -->
      <app-chips-input
        label="Types"
        placeholder="Sellect types"
        [options]="typesFilter.options"
        [selection]="typesFilter.selection"
        [displayFn]="displayFn"
        [filterFn]="filterFn"
        [equalFn]="equalFn"
        (changes)="onTypesChange($event)"
      ></app-chips-input>

      <!-- Price -->
      <div class="range-filter flex flex-col pt-3 pb-2 px-3 rounded-tl-md rounded-tr-md">
        <span class="text-muted font-medium">Price</span>
        <div class="flex justify-center">
          <mat-slider [min]="priceFilter.min" [max]="priceFilter.max" [step]="priceFilter.step" discrete>
            <input
              [value]="priceFilter.selection[0] ?? priceFilter.min"
              matSliderStartThumb
              (valueChange)="onPriceMinChange($event)"
            />
            <input
              [value]="priceFilter.selection[1] ?? priceFilter.max"
              matSliderEndThumb
              (valueChange)="onPriceMaxChange($event)"
            />
          </mat-slider>
        </div>
      </div>
    </mat-drawer>

    <mat-drawer-content class="py-4 sm:min-h-[600px]">
      <p *ngIf="!loading && total > 0" class="search-results">
        <span class="text-muted">Showing</span>
        <span class="font-medium"> {{ bottles.length }} </span>
        <span class="text-muted"> of </span>
        <span class="font-medium"> {{ total }} </span>
        <span class="text-muted">auctions</span>
      </p>

      <p *ngIf="!loading && total === 0" class="search-results">
        <span class="text-muted">No auctions found</span>
      </p>

      <div class="-mt-4" *ngIf="isGrid()">
        <app-bottles-grid [bottles]="bottles"></app-bottles-grid>
      </div>

      <div class="mt-4" *ngIf="isList()">
        <app-bottles-list [bottles]="bottles"></app-bottles-list>
      </div>

      <div class="loader flex justify-center items-center" *ngIf="loading && bottles.length === 0">
        <mat-spinner class="flex-none"></mat-spinner>
      </div>

      <ng-content></ng-content>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
