<div class="page-container" fxLayout="column" fxLayoutAlign="center center">
  <div
    class="host"
    fxFlex="none"
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutGap="32px"
    [innerHTML]="content$ | async"
    #host
  ></div>
</div>

<!-- NONE VISIBLE STREAMS -->
<ng-container *ngIf="navigation$ | async"></ng-container>
<ng-container *ngIf="navigateToFragment$ | async"></ng-container>
<ng-container *ngIf="activatedRouteFragment$ | async"></ng-container>
