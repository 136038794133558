/* eslint-disable no-bitwise */
import { Observable, fromEvent, race, Subject, throwError, merge } from 'rxjs';
import { map, take, switchMap } from 'rxjs/operators';

/**
 * Directions of image rotations
 */
export enum ImageRotation {
  Left = 1,
  Right = 2,
}

/**
 * Definition of all possible image orientation based on EXIF
 * and the misc ones defined in this implementation
 *
 * See this [visualization]{@link https://bit.ly/2q46lhy}
 */
export enum ImageOrientation {
  // EXIF Orientations
  HorizontalNormal = 1,
  HorizontalMirror = 2,
  Rotate180 = 3,
  VerticalMirror = 4,
  HorizontalMirrorRotate270CW = 5,
  Rotate90CW = 6,
  HorizontalMirrorRotate90CW = 7,
  Rotate270CW = 8,
  // Misc Orientations
  NotDefined = -1,
  NotJPEG = -2,
}

/**
 * A collection of tools to manipulate digital image
 *
 * @todo Convert to a service and remove the unused methods
 */
export class ImageTools {
  /**
   * Returns the orientation of the image in the provided file
   *
   * Orientation is defined as per `ImageOrientation` enum
   *
   * Courtesy of:
   *   - [Bart Kalisz]{@link https://jsfiddle.net/wunderbart/dtwkfjpg/}
   *
   * See also this [SO answer]{@link http://stackoverflow.com/a/32490603}
   *
   * @param {File} file The file containing the image
   * @returns {Observable<number>} An observable that will emit the orientation and will complete
   */
  public static getOrientation(file: File): Observable<number> {
    const extractOrientation = (buffer: ArrayBuffer): number => {
      const view = new DataView(buffer);

      if (view.getUint16(0, false) !== 0xffd8) {
        return ImageOrientation.NotJPEG;
      }

      const length = view.byteLength;
      let offset = 2;

      while (offset < length) {
        const marker = view.getUint16(offset, false);
        offset += 2;

        if (marker === 0xffe1) {
          if (view.getUint32((offset += 2), false) !== 0x45786966) {
            return ImageOrientation.NotDefined;
          }
          const little = view.getUint16((offset += 6), false) === 0x4949;
          offset += view.getUint32(offset + 4, little);
          const tags = view.getUint16(offset, little);
          offset += 2;

          for (let i = 0; i < tags; i++) {
            if (view.getUint16(offset + i * 12, little) === 0x0112) {
              return view.getUint16(offset + i * 12 + 8, little);
            }
          }
        } else if ((marker & 0xff00) !== 0xff00) {
          break;
        } else {
          offset += view.getUint16(offset, false);
        }
      }

      return ImageOrientation.NotDefined;
    };

    const subject = new Subject<number>();

    const reader = new FileReader();

    reader.onload = () => {
      const orientation = extractOrientation(reader.result as ArrayBuffer);
      subject.next(orientation);
      subject.complete();
    };

    reader.onerror = (error: any) => {
      subject.error(new Error(error));
      console.error('FileReader Error:', error);
    };

    reader.readAsArrayBuffer(file.slice(0, 64 * 1024));

    return subject.asObservable();
  }

  /**
   * Resets the orientation of the provided image to `HorizontalNormal` {@link ImageOrientation}
   *
   * Courtesy of:
   *   - [Bart Kalisz]{@link https://jsfiddle.net/wunderbart/w1hw5kv1/}
   *
   * @param {string} srcBase64 Base64 representation of the image to reset orientation of
   * @param {Observable<number>} srcOrientation The orientation of the provided image
   */
  public static resetOrientation(srcBase64: string, srcOrientation: number): Observable<string> {
    const img = new Image();
    const onLoad = (): string => {
      const width = img.width,
        height = img.height,
        canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d');

      // set proper canvas dimensions before transform & export
      if (4 < srcOrientation && srcOrientation < 9) {
        canvas.width = height;
        canvas.height = width;
      } else {
        canvas.width = width;
        canvas.height = height;
      }

      // transform context before drawing image
      switch (srcOrientation) {
        case ImageOrientation.HorizontalMirror:
          ctx.transform(-1, 0, 0, 1, width, 0);
          break;
        case ImageOrientation.Rotate180:
          ctx.transform(-1, 0, 0, -1, width, height);
          break;
        case ImageOrientation.VerticalMirror:
          ctx.transform(1, 0, 0, -1, 0, height);
          break;
        case ImageOrientation.HorizontalMirrorRotate270CW:
          ctx.transform(0, 1, 1, 0, 0, 0);
          break;
        case ImageOrientation.Rotate90CW:
          ctx.transform(0, 1, -1, 0, height, 0);
          break;
        case ImageOrientation.HorizontalMirrorRotate90CW:
          ctx.transform(0, -1, -1, 0, height, width);
          break;
        case ImageOrientation.Rotate270CW:
          ctx.transform(0, -1, 1, 0, 0, width);
          break;
      }

      // draw image
      ctx.drawImage(img, 0, 0);

      // export base64
      return canvas.toDataURL();
    };

    img.src = srcBase64;

    return fromEvent(img, 'load').pipe(
      map(() => onLoad()),
      take(1)
    );
  }

  /**
   * Returns Base64 representation of the image in the provided file
   *
   * Courtesy of:
   *   - [Mathew Holden]{@link https://bit.ly/2q46lhy}
   *
   * @param {File} file The file containing the image
   * @returns {Observable<string>} The Base64 representation of the image
   */
  public static getBase64(file: File): Observable<string> {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    const load$ = fromEvent(reader, 'load').pipe(map(() => reader.result as string));
    const error$ = fromEvent(reader, 'error').pipe(
      map((error: any) => {
        throw new Error(error);
      })
    );

    return race(load$, error$).pipe(take(1));
  }

  /**
   * Rotates the provide image 90 degrees based on the provided rotation direction (LEFT/RIGHT)
   * and returns the result
   *
   * @param {string} url URL of the image to rotate
   * @param {ImageRotation} direction Rotation direction, {@link ImageRotation}, defaults to Right
   * @throws {Error} If an unsuported angle is provided
   * @return {Observable<string>} The result of the rotation
   */
  public static rotate(url: string, direction = ImageRotation.Right): Observable<string> {
    const img = new Image();
    const onLoad = (): string => {
      const width = img.width,
        height = img.height,
        canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d');

      canvas.width = height;
      canvas.height = width;

      switch (direction) {
        case ImageRotation.Right:
          ctx.transform(0, 1, -1, 0, height, 0);
          break;

        case ImageRotation.Left:
          ctx.transform(0, -1, 1, 0, 0, width);
          break;

        default:
          throw new Error(`Angle '${direction}' is not supported!`);
      }

      // draw image
      ctx.drawImage(img, 0, 0);

      // export base64
      return canvas.toDataURL();
    };

    img.crossOrigin = 'Anonymous';
    img.src = url;

    const load$ = fromEvent(img, 'load').pipe(
      map(() => onLoad()),
      take(1)
    );

    const error$ = fromEvent(img, 'error').pipe(
      switchMap(() => throwError('Unknown error with image rotation!')),
      take(1)
    );

    return merge(load$, error$);
  }
}
