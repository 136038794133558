import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';

import { services } from './services';
import { httpInterceptorProviders } from './interceptors';
import { guards } from './guards';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    ...services,
    ...httpInterceptorProviders,
    ...guards,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
})
export class CoreModule {}
