<div @list fxLayout="row wrap" fxLayoutAlign="center start">
  <div
    @items
    fxFlex="nogrow"
    *ngFor="let bottle of bottles; trackBy: trackByBottles"
    class="tile"
    [routerLink]="bottle | authContext : 'getDetailsPath'"
    [queryParams]="{ referer: referer$ | async }"
  >
    <app-bottle-tile [bottle]="bottle">
      <app-bottle-tile-action>
        <app-call-to-purchase [bottle]="bottle">
          <ng-container *ngIf="bottle.state === bottleStates.IN_AUCTION; else currentBid">Starting Bid</ng-container>
          <ng-template #currentBid>Current Bid</ng-template>
        </app-call-to-purchase>
      </app-bottle-tile-action>
    </app-bottle-tile>
  </div>
</div>
