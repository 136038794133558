import { Injectable } from '@angular/core';
import { NavigationExtras } from '@angular/router';

interface NavItem {
  link: string;
  label: string;
  icon?: string;
  extras?: NavigationExtras;
}

@Injectable()
export class NavigationService {
  defaultNav: NavItem[] = [
    {
      link: '/',
      label: 'Live Auctions',
      extras: {
        fragment: 'auctions',
      },
    },
    { link: '/categories', label: 'Categories' },
    { link: '/buying', label: 'Buying' },
    { link: '/selling', label: 'Selling' },
    { link: '/delivery', label: 'Delivery' },
    // TODO add more links
  ];

  adminNav: NavItem[] = [
    { link: 'admin', icon: 'dashboard', label: 'Dashboard' },
    { link: 'admin/bottles', icon: 'table_chart', label: 'Bottles' },
    // TODO: add more links
  ];

  collectorNav: NavItem[] = [
    { link: 'collector/collection', icon: 'local_bar', label: 'My Collection' },
    { link: 'collector/bids', icon: 'casino', label: 'My Bids' },
    // TODO: { link: 'collector/favorites', icon: 'favorite', label: 'My Favorites' },
    { link: 'collector/profile', icon: 'person', label: 'Profile' },
  ];

  dealerNav: NavItem[] = [
    { link: 'dealer/auctions', icon: 'gavel', label: 'Auctions' },
    { link: 'dealer/history', icon: 'history', label: 'History' },
    { link: 'dealer/invoices', icon: 'receipt', label: 'Invoices' },
    // TODO: { link: 'dealer/profile', icon: 'person', label: 'Profile' },
  ];
}
