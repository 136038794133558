import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';
import { Observable, of } from 'rxjs';
import { tap, switchMap, catchError, filter, take, mapTo } from 'rxjs/operators';

import { Article } from '@whiskybazar/pwa/categories/models';
import { selectArticle, loadArticles, loadArticleContent } from '../store/actions';
import { State, selectArticlePageLoaded, selectArticlePageEntities } from '../store/reducers';

@Injectable()
export class ArticlePageGuard {
  constructor(private store$: Store<State>) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const articleId = next.data['articleId'];
    if (articleId) {
      this.store$.dispatch(selectArticle({ articleId }));
    }

    return this.checkStore(articleId).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkStore(articleId: string): Observable<boolean> {
    return this.store$.pipe(
      select(selectArticlePageLoaded),
      tap((loaded: boolean) => this.doLoad(loaded)),
      filter((loaded: boolean) => loaded),
      take(1),
      switchMap(() => this.checkArticle(articleId))
    );
  }

  private doLoad(loaded: boolean) {
    if (loaded) {
      return;
    }

    this.store$.dispatch(loadArticles());
  }

  private checkArticle(articleId: string): Observable<boolean> {
    return this.store$.pipe(
      select(selectArticlePageEntities),
      tap((entities: Dictionary<Article>) => this.doLoadContent(entities, articleId)),
      filter((entities: Dictionary<Article>) => this.hasArticleContent(entities, articleId)),
      take(1),
      mapTo(true)
    );
  }

  private doLoadContent(entities: Dictionary<Article>, articleId: string) {
    if (this.hasArticleContent(entities, articleId)) {
      return;
    }

    const article: Article = entities[articleId];
    this.store$.dispatch(loadArticleContent({ article }));
  }

  private hasArticleContent(entities: Dictionary<Article>, articleId: string): boolean {
    const article: Article = entities[articleId];
    return !!article && !!article.content;
  }
}
