import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { exhaustMap } from 'rxjs/operators';

import { AuthService } from '@whiskybazar/pwa/core/services';
import { environment } from '@whiskybazar/pwa/environment';

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
  constructor(private di: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.authRequired(req)) {
      return next.handle(req);
    }

    return this.di
      .get(AuthService)
      .getIdToken()
      .pipe(exhaustMap((token: string) => next.handle(this.authorize(req, token))));
  }

  protected authorize(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({
      setHeaders: { Authorization: `Bearer ${token}` },
    });
  }

  protected authRequired(req: HttpRequest<any>): boolean {
    return req.url.startsWith(environment.cloudFunctions);
  }
}
