<div class="container">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div fxLayout="column" fxLayoutGap="0px">
      <mat-form-field fxFill>
        <input
          matInput
          #amount
          type="number"
          formControlName="amount"
          i18n-placeholder
          placeholder="Enter your Max bid (in EUR)"
          required
        />
        <mat-hint align="start">
          {{ (amount.value | bottlePrice : true | currency : 'EUR') + ' incl. fees, excl. shipping' }}
        </mat-hint>
        <mat-hint align="end">
          &asymp; {{ (amount.value || 0 | bottlePrice : true) / 0.13417 | currency : 'DKK' }}
        </mat-hint>
      </mat-form-field>

      <mat-error *ngIf="errorMessage !== null">{{ errorMessage }}</mat-error>

      <div fxFill class="mt-2">
        <button type="submit" mat-raised-button color="primary" [disabled]="form.invalid || isPending" i18n>
          PLACE BID
        </button>
      </div>
    </div>
  </form>
</div>
