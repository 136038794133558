import { createSelector } from '@ngrx/store';

import * as fromAuth from '../reducers';
import * as fromPhoneVerification from '../reducers/phone-verification.reducer';

export const selectPhoneVerificationState = createSelector(
  fromAuth.selectAuthState,
  (state: fromAuth.AuthState) => state.phoneVerification
);

export const selectPhoneVerificationError = createSelector(
  selectPhoneVerificationState,
  fromPhoneVerification.getError
);

export const selectPhoneVerificationPending = createSelector(
  selectPhoneVerificationState,
  fromPhoneVerification.getPending
);

export const selectPhoneVerificationPhoneNumber = createSelector(
  selectPhoneVerificationState,
  fromPhoneVerification.getPhoneNumber
);

export const selectPhoneVerificationStep = createSelector(selectPhoneVerificationState, fromPhoneVerification.getStep);

export const selectPhoneVerificationVerified = createSelector(
  selectPhoneVerificationState,
  fromPhoneVerification.getVerified
);
