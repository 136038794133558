import {
  AUCTION_VARIABLE_FEE,
  AUCTIONEER_VARIABLE_PROFIT,
  Bottle,
  BuyerAuctionInvoice,
  Dealer,
  SellerAuctionInvoice,
  Transaction,
  User,
} from '@whiskybazar/core';

export class InvoiceView {
  public readonly auctionVariableFee: number = AUCTION_VARIABLE_FEE;

  public readonly auctioneerVariableProfit: number = AUCTIONEER_VARIABLE_PROFIT;

  auctioneerInvoice: any;

  buyerInvoice: BuyerAuctionInvoice;

  sellerInvoice: SellerAuctionInvoice;

  auctioneer: Dealer;

  buyer: User;

  seller: User;

  transaction: Transaction;

  protected init(bottle: Bottle) {
    this.auctioneerInvoice = bottle ? bottle.auction.transaction.auctioneerInvoice : null;
    this.buyerInvoice = bottle ? bottle.auction.transaction.buyerInvoice : null;
    this.sellerInvoice = bottle ? bottle.auction.transaction.sellerInvoice : null;
    this.auctioneer = bottle ? (bottle.auction.auctioneer as Dealer) : null;
    this.buyer = bottle ? bottle.auction.transaction.buyer : null;
    this.seller = bottle ? bottle.auction.transaction.seller : null;
    this.transaction = bottle ? bottle.auction.transaction : null;
  }
}
