import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss'],
})
export class CurrencyComponent implements OnInit {
  readonly DEFAULT_CODE = 'EUR';

  @Input() amount: number;

  code: string;

  constructor() {
    this.code = this.DEFAULT_CODE;

    // TODO: parameterize the currency based on user selected value and locale
  }

  ngOnInit() {}
}
