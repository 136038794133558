<ng-template #inMyCollection>
  <ng-container *ngIf="bottle | authContext : 'isOwner'">This bottle is in My Collection</ng-container>
</ng-template>

<ng-template #fromAuctions>
  <ng-container *ngIf="bottle | authContext : 'isAuctioneer'">This bottle is from Auctions</ng-container>
</ng-template>

<ng-template #awaitingBid>
  <ng-container *ngIf="(bottle | authContext : 'isDealer') && !(bottle | authContext : 'isAuctioneer')"
    >Awaiting to receive bid</ng-container
  >
</ng-template>

<ng-template #inLiveAuctions>
  <ng-container *ngIf="(bottle | authContext : 'isDealer') && !(bottle | authContext : 'isAuctioneer')"
    >This bottle is in live auctions</ng-container
  >
</ng-template>

<ng-template #default let-bottle>
  {{ bottle.state | bottleState : bottle }}
  <div *ngIf="elaborate" class="mat-caption text-muted">
    <ng-container [ngSwitch]="bottle.state">
      <!-- IN_PRIVATE_COLLECTION -->
      <ng-container *ngSwitchCase="bottleStates.IN_PRIVATE_COLLECTION">
        <ng-container *ngTemplateOutlet="inMyCollection"></ng-container>
      </ng-container>

      <!-- PENDING_AUCTION_START -->
      <ng-container *ngSwitchCase="bottleStates.PENDING_AUCTION_START">
        <ng-container *ngIf="bottle | authContext : 'isOwner'"
          >Waiting for the auctioneer to activate the auction</ng-container
        >
      </ng-container>

      <!-- IN_AUCTION -->
      <ng-container *ngSwitchCase="bottleStates.IN_AUCTION">
        <ng-container *ngTemplateOutlet="inMyCollection"></ng-container>
        <ng-container *ngTemplateOutlet="fromAuctions"></ng-container>
        <ng-container *ngTemplateOutlet="awaitingBid"></ng-container>
      </ng-container>

      <!-- PENDING_SHIPPING -->
      <ng-container *ngSwitchCase="bottleStates.PENDING_SHIPPING">
        <ng-container *ngIf="bottle | authContext : 'isBuyer'"
          >Waiting for the auctioneer to ship your bottle</ng-container
        >
      </ng-container>

      <!-- SHIPPED -->
      <ng-container *ngSwitchCase="bottleStates.SHIPPED">
        <ng-container *ngIf="bottle | authContext : 'isBuyer'"> Your bottle has been shipped </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #pendingSettlement let-bottle>
  <div>Pending Settlement</div>
  <div class="mat-caption text-muted">
    <ng-container *ngIf="elaborate"> Waiting for delivery and buyer's withdrawal right to expire </ng-container>

    <ng-container *ngIf="!elaborate">
      Expected: {{ bottle | authContext : 'getExpectedSettlementDate' | dfnsFormat : 'dd-MM-yyyy' }}
    </ng-container>
  </div>
</ng-template>

<ng-template #pendingExpirationOfWithdrawalRight let-bottle>
  <div [class.positive]="elaborate">Delivered</div>
  <div class="mat-caption text-muted">
    <ng-container *ngIf="elaborate"> Waiting for your right of widthdrawal to expire </ng-container>

    <ng-container *ngIf="!elaborate">
      Your right of withdrawal will expire on
      {{ bottle | authContext : 'getBuyerRightOfWithdrawalExpirationDate' | dfnsFormat : 'dd-MM-yyyy' }}
    </ng-container>
  </div>
</ng-template>

<ng-template #pendingTransactionCompletion let-bottle>
  <div [class.positive]="elaborate">Delivered</div>
  <div class="mat-caption text-muted">Pending settlement with auctioneer</div>
</ng-template>

<ng-template #defaultTransaction let-bottle>
  {{ bottle?.auction?.transaction?.state | transactionState }}
</ng-template>

<ng-template #settled let-invoice>
  <ng-container *ngIf="elaborate">
    <span>Settled</span>
    <div class="mat-caption text-muted">Payment to your account is completed</div>
  </ng-container>

  <span *ngIf="!elaborate">Settled: {{ invoice.settledAt | dfnsFormat : 'dd-MM-yyyy' }}</span>
</ng-template>

<ng-template #increaseWinningChance>
  <div class="mat-caption text-muted">Place a new max-bid to increase your winning chance</div>
</ng-template>

<ng-template #enterBid>
  <span>Enter your bid below</span>
  <ng-container *ngTemplateOutlet="increaseWinningChance"></ng-container>
</ng-template>

<ng-template #loginToBid>
  <span>Login to bid</span>
  <div class="mat-caption text-muted">Only registered users are allowed to bid at auctions</div>
</ng-template>

<ng-template #winningLosingBid>
  <span *ngIf="bottle | authContext : 'hasBestBid'">You have the winning bid</span>
  <span *ngIf="!(bottle | authContext : 'hasBestBid')">Your bid has been exceeded</span>
  <ng-container *ngTemplateOutlet="increaseWinningChance"></ng-container>
</ng-template>

<!--
  ACTUAL TEMPLATE
 -->
<div [ngSwitch]="bottle.state">
  <!-- PENDING_AUCTIONEER_ACCEPTANCE state -->
  <ng-container *ngSwitchCase="bottleStates.PENDING_AUCTIONEER_ACCEPTANCE">
    <ng-container [ngSwitch]="bottle.auction.state">
      <!-- REJECTED state -->
      <ng-container *ngSwitchCase="auctionStates.REJECTED">
        <span class="negative">Auction Declined</span>

        <ng-container *ngIf="elaborate">
          <div class="mat-caption text-muted" *ngIf="bottle | authContext : 'isOwner'" i18n>
            Please refine your auction details if trying again
          </div>
          <div class="mat-caption text-muted" *ngIf="bottle | authContext : 'isAuctioneer'" i18n>
            You have declined to handle this auction
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <span [class.positive]="bottle | authContext : 'isAuctioneer'">
          {{ bottle.state | bottleState : bottle }}
        </span>
        <ng-container *ngIf="elaborate">
          <div class="mat-caption text-muted" *ngIf="bottle | authContext : 'isOwner'" i18n>
            Waiting for auctioneer to review your auction
          </div>
          <div class="mat-caption text-muted" *ngIf="bottle | authContext : 'isAuctioneer'" i18n>
            Waiting for you to respond to seller
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>

  <!-- PENDING_DELIVERY_TO_AUCTIONEER state -->
  <div class="positive" *ngSwitchCase="bottleStates.PENDING_DELIVERY_TO_AUCTIONEER">
    <ng-container
      *ngIf="(bottle | authContext : 'isAuctioneer') || (bottle | authContext : 'isOwner'); else notRelated"
    >
      <!-- AUCTIONEER -->
      <ng-container *ngIf="bottle | authContext : 'isAuctioneer'">
        <ng-container *ngIf="elaborate">
          <span>You Accepted this Auction</span>
          <div class="mat-caption text-muted">Waiting for seller to deliver the bottle</div>
        </ng-container>
        <span *ngIf="!elaborate">Pending Delivery</span>
      </ng-container>

      <!-- OWNER -->
      <ng-container *ngIf="bottle | authContext : 'isOwner'">
        <div>Auction Accepted</div>
        <div class="mat-caption text-muted">Pending Delivery to Auctioneer</div>
      </ng-container>
    </ng-container>
    <ng-template #notRelated>
      <ng-container *ngTemplateOutlet="default; context: { $implicit: bottle }"></ng-container>
    </ng-template>
  </div>

  <!-- PENDING_AUCTION_START state -->
  <ng-container *ngSwitchCase="bottleStates.PENDING_AUCTION_START">
    <div class="positive" *ngIf="bottle | authContext : 'isAuctioneer'; else notAuctioneer">
      <span *ngIf="!elaborate">Delivered</span>
      <ng-container *ngIf="elaborate">
        <span>Bottle is delivered</span>
        <div class="mat-caption text-muted">In case the bottle is not as expected, please contact the seller</div>
      </ng-container>
    </div>
    <ng-template #notAuctioneer>
      <ng-container *ngTemplateOutlet="default; context: { $implicit: bottle }"></ng-container>
    </ng-template>
  </ng-container>

  <!-- IN_AUCTION state -->
  <ng-container *ngSwitchCase="bottleStates.IN_AUCTION">
    <ng-container *ngIf="!elaborate">
      {{ (bottle?.auction?.runtime | auctionRuntime) || 'N/A' }}
    </ng-container>

    <ng-container *ngIf="elaborate">
      <ng-container *ngIf="bottle | authContext : 'noAuth'; else auth">
        <ng-container *ngTemplateOutlet="loginToBid"></ng-container>
      </ng-container>

      <ng-template #auth>
        <ng-container *ngIf="bottle | authContext : 'canBid'; else cannotBid">
          <ng-container *ngTemplateOutlet="enterBid"></ng-container>
        </ng-container>

        <ng-template #cannotBid>
          <ng-container *ngTemplateOutlet="default; context: { $implicit: bottle }"></ng-container>
        </ng-template>
      </ng-template>
    </ng-container>
  </ng-container>

  <!-- PENDING_AUCTION_END state -->
  <ng-container *ngSwitchCase="bottleStates.PENDING_AUCTION_END">
    <ng-container *ngIf="!elaborate">
      <app-auction-countdown [auction]="bottle.auction" [suffix]="''"></app-auction-countdown>
    </ng-container>

    <ng-container *ngIf="elaborate">
      <ng-container *ngIf="bottle | authContext : 'noAuth'; else auth">
        <ng-container *ngTemplateOutlet="loginToBid"></ng-container>
      </ng-container>

      <ng-template #auth>
        <ng-container *ngIf="bottle | authContext : 'canBid'; else cannotBid">
          <ng-container *ngIf="bottle | authContext : 'hasAnyBid'; else hasNoBids">
            <ng-container *ngTemplateOutlet="winningLosingBid"></ng-container>
          </ng-container>

          <ng-template #hasNoBids>
            <ng-container *ngTemplateOutlet="enterBid"></ng-container>
          </ng-template>
        </ng-container>

        <ng-template #cannotBid>
          <span>In Auction</span>
          <div class="mat-caption text-muted">
            <ng-container *ngTemplateOutlet="inMyCollection"></ng-container>
            <ng-container *ngTemplateOutlet="fromAuctions"></ng-container>
            <ng-container *ngTemplateOutlet="inLiveAuctions"></ng-container>
          </div>
        </ng-template>
      </ng-template>
    </ng-container>
  </ng-container>

  <!-- IN_TRANSACTION state -->
  <ng-container *ngSwitchCase="bottleStates.IN_TRANSACTION">
    <ng-container *ngIf="elaborate">
      <!-- Auctioneer/Seller -->
      <ng-container *ngIf="bottle | authContext : 'isAuctioneerOrSeller'">
        <span>Auction ended</span>
        <div class="mat-caption text-muted">Waiting for buyer to complete the payment</div>
      </ng-container>

      <!-- Buyer (Winner) -->
      <div class="positive" *ngIf="bottle | authContext : 'hasWonAuction'">
        <div>You won the auction</div>
        <div class="mat-caption text-muted">Please complete the payment before the due date below</div>
      </div>

      <!-- Buyer (Looser) -->
      <div class="negative" *ngIf="bottle | authContext : 'hasLostAuction'">
        <div>You lost this auction</div>
        <div class="mat-caption text-muted">Better luck next time</div>
      </div>

      <!-- Admin -->
      <ng-container *ngIf="bottle | authContext : 'isAdmin'">
        {{ bottle.auction?.transaction?.state | transactionState }}
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!elaborate">
      <!-- Seller -->
      <span *ngIf="bottle | authContext : 'isSeller'">
        Ended: {{ bottle?.auction?.transaction?.createdAt | dfnsFormat : 'dd-MM-yyyy' }}
      </span>

      <!-- Dealer -->
      <span *ngIf="bottle | authContext : 'isAuctioneer'">
        {{ bottle.auction?.transaction?.state | transactionState }}
      </span>

      <!-- Buyer (Winner) -->
      <div class="positive" *ngIf="bottle | authContext : 'hasWonAuction'">
        <div>You won: {{ bottle?.auction?.transaction?.createdAt | dfnsFormat : 'dd-MM-yyyy' }}</div>
        <div class="mat-caption text-muted">
          Payment due date: {{ bottle | authContext : 'getBuyerPaymentDueDate' | dfnsFormat : 'dd-MM-yyyy' }}
        </div>
      </div>

      <!-- Buyer (Looser) -->
      <span class="negative" *ngIf="bottle | authContext : 'hasLostAuction'">
        You lost: {{ bottle?.auction?.transaction?.createdAt | dfnsFormat : 'dd-MM-yyyy' }}
      </span>

      <!-- Admin -->
      <ng-container *ngIf="bottle | authContext : 'isAdmin'">
        {{ bottle.auction?.transaction?.state | transactionState }}
      </ng-container>
    </ng-container>
  </ng-container>

  <!-- PENDING_PICKUP_AT_AUCTIONEER -->
  <ng-container *ngSwitchCase="bottleStates.PENDING_PICKUP_AT_AUCTIONEER">
    <!-- Seller -->
    <div *ngIf="bottle | authContext : 'isSeller'">
      <ng-container *ngTemplateOutlet="pendingSettlement; context: { $implicit: bottle }"></ng-container>
    </div>

    <!-- Dealer -->
    <div class="positive" *ngIf="bottle | authContext : 'isAuctioneer'">
      <div>{{ bottle?.state | bottleState }}</div>
      <div class="mat-caption text-muted">
        <ng-container *ngIf="elaborate"> Waiting for buyer to pickup </ng-container>

        <ng-container *ngIf="!elaborate">
          Pickup due date: {{ bottle | authContext : 'getPickupDueDate' | dfnsFormat : 'dd-MM-yyyy' }}
        </ng-container>
      </div>
    </div>

    <!-- Buyer -->
    <div class="positive" *ngIf="bottle | authContext : 'isBuyer'">
      <div>{{ bottle?.state | bottleState }}</div>
      <div class="mat-caption text-muted">
        <ng-container *ngIf="elaborate"> Waiting for you to pickup at auctioneer </ng-container>

        <ng-container *ngIf="!elaborate">
          Pickup deadline: {{ bottle | authContext : 'getPickupDueDate' | dfnsFormat : 'dd-MM-yyyy' }}
        </ng-container>
      </div>
    </div>

    <!-- Admin -->
    <div *ngIf="bottle | authContext : 'isAdmin'">
      <div>{{ bottle?.state | bottleState }}</div>
    </div>
  </ng-container>

  <!-- PENDING_SHIPPING -->
  <ng-container *ngSwitchCase="bottleStates.PENDING_SHIPPING">
    <!-- Seller -->
    <div *ngIf="bottle | authContext : 'isSeller'">
      <ng-container *ngTemplateOutlet="pendingSettlement; context: { $implicit: bottle }"></ng-container>
    </div>

    <!-- Dealer -->
    <div class="positive" *ngIf="bottle | authContext : 'isAuctioneer'">
      <div>{{ bottle?.state | bottleState }}</div>
      <div class="mat-caption text-muted">
        <ng-container *ngIf="elaborate"> Waiting for you to pack and ship the bottle </ng-container>

        <ng-container *ngIf="!elaborate">
          Shipping due date: {{ bottle | authContext : 'getShippingDueDate' | dfnsFormat : 'dd-MM-yyyy' }}
        </ng-container>
      </div>
    </div>

    <!-- Buyer and anyone else -->
    <span *ngIf="!(bottle | authContext : 'isAuctioneerOrSeller')">
      <ng-container *ngTemplateOutlet="default; context: { $implicit: bottle }"></ng-container>
    </span>
  </ng-container>

  <!-- SHIPPED -->
  <ng-container *ngSwitchCase="bottleStates.SHIPPED">
    <!-- Seller and Dealer -->
    <ng-container *ngIf="bottle | authContext : 'isAuctioneerOrSeller'">
      <ng-container *ngTemplateOutlet="pendingSettlement; context: { $implicit: bottle }"></ng-container>
    </ng-container>

    <!-- Buyer and anyone else -->
    <span [class.positive]="bottle | authContext : 'isBuyer'" *ngIf="!(bottle | authContext : 'isAuctioneerOrSeller')">
      <ng-container *ngTemplateOutlet="default; context: { $implicit: bottle }"></ng-container>
    </span>
  </ng-container>

  <!-- PENDING_SETTLEMENT -->
  <ng-container *ngSwitchCase="bottleStates.PENDING_SETTLEMENT">
    <ng-container [ngSwitch]="bottle.auction.transaction.state">
      <!-- PENDING_EXPIRATION_OF_BUYERS_RIGHT_OF_WITHDRAWAL -->
      <ng-container *ngSwitchCase="transactionStates.PENDING_EXPIRATION_OF_BUYERS_RIGHT_OF_WITHDRAWAL">
        <!-- Seller and Dealer -->
        <ng-container *ngIf="bottle | authContext : 'isAuctioneerOrSeller'">
          <ng-container *ngTemplateOutlet="pendingSettlement; context: { $implicit: bottle }"></ng-container>
        </ng-container>

        <!-- Buyer -->
        <span *ngIf="bottle | authContext : 'isBuyer'">
          <ng-container
            *ngTemplateOutlet="pendingExpirationOfWithdrawalRight; context: { $implicit: bottle }"
          ></ng-container>
        </span>

        <!-- Admin -->
        <span *ngIf="bottle | authContext : 'isAdmin'">
          <ng-container *ngTemplateOutlet="defaultTransaction; context: { $implicit: bottle }"></ng-container>
        </span>
      </ng-container>

      <!-- PENDING_SETTLEMENT_WITH_SELLER -->
      <ng-container *ngSwitchCase="transactionStates.PENDING_SETTLEMENT_WITH_SELLER">
        <!-- Seller and Dealer -->
        <ng-container *ngIf="bottle | authContext : 'isAuctioneerOrSeller'">
          <ng-container *ngTemplateOutlet="pendingSettlement; context: { $implicit: bottle }"></ng-container>
        </ng-container>

        <!-- Buyer -->
        <span *ngIf="bottle | authContext : 'isBuyer'">
          <ng-container *ngTemplateOutlet="pendingTransactionCompletion; context: { $implicit: bottle }"></ng-container>
        </span>

        <!-- Admin -->
        <span *ngIf="bottle | authContext : 'isAdmin'">
          <ng-container *ngTemplateOutlet="defaultTransaction; context: { $implicit: bottle }"></ng-container>
        </span>
      </ng-container>

      <!-- PENDING_SETTLEMENT_WITH_AUCTIONEER -->
      <ng-container *ngSwitchCase="transactionStates.PENDING_SETTLEMENT_WITH_AUCTIONEER">
        <!-- Seller -->
        <ng-container *ngIf="bottle | authContext : 'isSeller'">
          <ng-container
            *ngTemplateOutlet="settled; context: { $implicit: bottle.auction.transaction.sellerInvoice }"
          ></ng-container>
        </ng-container>

        <!-- Dealer -->
        <ng-container *ngIf="bottle | authContext : 'isAuctioneer'">
          <ng-container *ngTemplateOutlet="pendingSettlement; context: { $implicit: bottle }"></ng-container>
        </ng-container>

        <!-- Buyer -->
        <span *ngIf="bottle | authContext : 'isBuyer'">
          <ng-container *ngTemplateOutlet="pendingTransactionCompletion; context: { $implicit: bottle }"></ng-container>
        </span>

        <!-- Admin -->
        <span *ngIf="bottle | authContext : 'isAdmin'">
          <ng-container *ngTemplateOutlet="defaultTransaction; context: { $implicit: bottle }"></ng-container>
        </span>
      </ng-container>

      <!-- COMPLETED -->
      <ng-container *ngSwitchCase="transactionStates.COMPLETED">
        <!-- Seller -->
        <ng-container *ngIf="bottle | authContext : 'isSeller'">
          <ng-container
            *ngTemplateOutlet="settled; context: { $implicit: bottle.auction.transaction.sellerInvoice }"
          ></ng-container>
        </ng-container>

        <!-- Dealer -->
        <ng-container *ngIf="bottle | authContext : 'isAuctioneer'">
          <ng-container
            *ngTemplateOutlet="settled; context: { $implicit: bottle.auction.transaction.auctioneerInvoice }"
          ></ng-container>
        </ng-container>

        <!-- Buyer -->
        <span *ngIf="bottle | authContext : 'isBuyer'">
          <ng-container *ngTemplateOutlet="pendingTransactionCompletion; context: { $implicit: bottle }"></ng-container>
        </span>

        <!-- Admin -->
        <span *ngIf="bottle | authContext : 'isAdmin'">
          <ng-container *ngTemplateOutlet="defaultTransaction; context: { $implicit: bottle }"></ng-container>
        </span>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <ng-container *ngTemplateOutlet="defaultTransaction; context: { $implicit: bottle }"></ng-container>
      </ng-container>
    </ng-container>
  </ng-container>

  <!-- ARCHIVED state -->
  <ng-container *ngSwitchCase="bottleStates.ARCHIVED">
    <ng-container *ngIf="bottle | authContext : 'isOwner'; else archived">Deleting...</ng-container>
    <ng-template #archived>Archived</ng-template>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <ng-container *ngTemplateOutlet="default; context: { $implicit: bottle }"></ng-container>
  </ng-container>
</div>
