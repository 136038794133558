import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { DialogService } from '@whiskybazar/pwa/core/services';
import { Bottle } from '@whiskybazar/core';

@Component({
  selector: 'app-bottle-delete-button',
  templateUrl: './bottle-delete-button.component.html',
  styleUrls: ['./bottle-delete-button.component.scss'],
})
export class BottleDeleteButtonComponent implements OnInit {
  @Input() bottle: Bottle;

  @Output() readonly deleted = new EventEmitter<Bottle>();

  constructor(private dialogService: DialogService) {}

  ngOnInit() {}

  delete() {
    this.dialogService.openConfirm('Are you sure about deleting this bottle?').subscribe((confirmed) => {
      if (!confirmed) {
        return;
      }

      this.deleted.emit(this.bottle);
    });
  }
}
