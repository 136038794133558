import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity/src/models';
import { Observable, of } from 'rxjs';
import { map, take, filter, tap, switchMap, catchError } from 'rxjs/operators';

import * as fromStore from '../store';
import { StaticPage } from '@whiskybazar/core';

@Injectable()
export class PageGuard {
  constructor(private store: Store<fromStore.State>) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const pageId = next.data['pageId'];
    if (!pageId) {
      return false;
    }

    this.store.dispatch(new fromStore.SelectPage(pageId));

    return this.checkStore(pageId).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkStore(pageId: string): Observable<boolean> {
    return this.store.select(fromStore.selectEntities).pipe(
      tap((entities) => this.doLoad(pageId, entities)),
      filter((entities) => entities[pageId] !== null),
      map((entities) => entities[pageId] !== null),
      take(1)
    );
  }

  doLoad(pageId: string, entities: Dictionary<StaticPage>) {
    if (entities[pageId]) {
      return;
    }

    this.store.dispatch(new fromStore.LoadPage(pageId));
  }
}
