import { Pipe, PipeTransform } from '@angular/core';

import { COUNTRIES_DICT } from '@whiskybazar/core';
import { User } from '@whiskybazar/core';

@Pipe({
  name: 'bottleCountry',
})
export class BottleCountryPipe implements PipeTransform {
  transform(bottle: any, args?: any): any {
    if (typeof bottle !== 'object') {
      return null;
    }

    const format = args ? args : 'name';

    if (bottle.auction) {
      return this.getDealerCountry(bottle.auction.auctioneer, format);
    } else if (bottle.sale) {
      return this.getDealerCountry(bottle.sale.dealer, format);
    }

    return null;
  }

  protected getDealerCountry(dealer: User | null, format: 'code' | 'name'): string | null {
    return dealer ? (format === 'name' ? dealer.country : COUNTRIES_DICT[dealer.country]) : null;
  }
}
