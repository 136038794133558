import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { mapTo, switchMap, catchError, tap, take, map } from 'rxjs/operators';

// eslint-disable-next-line @nx/enforce-module-boundaries
import * as fromAuthStore from '@whiskybazar/pwa/auth/store';
import * as fromStore from '../store';
import { AuthUser } from '@whiskybazar/core';

@Injectable()
export class LandingPageGuard {
  constructor(private store$: Store<fromStore.State>, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.checkAuth().pipe(
      switchMap((result: boolean) => (result === true ? this.checkStore() : of(false))),
      catchError(() => of(false))
    );
  }

  checkAuth(): Observable<boolean> {
    return this.store$.select(fromAuthStore.getUser).pipe(
      take(1),
      tap((auth: AuthUser) => this.doRedirect(auth)),
      // Only allow none-authenticated and collectors
      map((auth: AuthUser) => !auth || auth.type === 'collector')
    );
  }

  checkStore(): Observable<boolean> {
    return this.store$.select(fromStore.getLoaded).pipe(
      tap((loaded: boolean) => this.doLoad(loaded)),
      take(1),
      mapTo(true) // whether data is loaded or not, return true
    );
  }

  protected doLoad(loaded: boolean) {
    if (loaded) {
      return;
    }

    this.store$.dispatch(fromStore.loadBottles());
  }

  protected doRedirect(auth: AuthUser) {
    if (!auth) {
      return;
    }

    switch (auth.type) {
      case 'dealer':
        return this.router.navigate(['/dealer', 'auctions']);

      case 'admin':
        return this.router.navigate(['/admin']);
    }
  }
}
