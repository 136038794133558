export enum SortBy {
  PriceAsc = 'price:asc',
  PriceDesc = 'price:desc',
  AgeAsc = 'age:asc',
  AgeDesc = 'age:desc',
}

export interface SortingOption {
  name: string;
  value: SortBy;
}

export interface Sorting {
  options: SortingOption[];
  selection: SortBy;
}
