import { Injectable, inject } from '@angular/core';
import { Database, equalTo, object, orderByChild, ref } from '@angular/fire/database';
import { Observable, of, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AbstractFirebaseList } from './abstract-firebase-list';
import { DatabaseApiService } from './database-api.service';
import {
  Mapper,
  bidMapper,
  maxBidMapper,
  Bid,
  BidData,
  User,
  PlaceBidResult,
  MaxBid,
  MaxBidData,
} from '@whiskybazar/core';

export interface BidExpansionConfig {
  maxBid?: {
    ownerId?: string;
  };
}

@Injectable()
export class BidsService extends AbstractFirebaseList<Bid, BidData> {
  private readonly path = 'bids';

  private afDb: Database = inject(Database);

  constructor(private dbApi: DatabaseApiService) {
    super();
  }

  getPath(): string {
    return this.path;
  }

  getAngularFireDatabase(): Database {
    return this.afDb;
  }

  getMapper(): Mapper<BidData, Bid> {
    return bidMapper;
  }

  getDatabaseApiService(): DatabaseApiService {
    return this.dbApi;
  }

  fetchById(id: string, expansionConfig?: BidExpansionConfig): Observable<Bid> {
    if (!expansionConfig) {
      return super.fetchById(id);
    }

    let result = super.fetchById(id);

    if (expansionConfig) {
      result = result.pipe(switchMap((bid: Bid) => this.expandBid(bid, expansionConfig)));
    }

    return result;
  }

  fetchByBidder(bidder: User, expansionConfig?: BidExpansionConfig): Observable<Bid[]> {
    let result = this.query(orderByChild('bidder'), equalTo(bidder.id));

    if (expansionConfig) {
      result = result.pipe(switchMap((bids: Bid[]) => this.expandBids(bids, expansionConfig)));
    }
    return result;
  }

  placeBid(bid: Bid): Observable<PlaceBidResult> {
    return this.getDatabaseApiService().create<BidData, PlaceBidResult>(this.getPath(), this.getMapper().toDb(bid));
  }

  protected expandBids(bids: Bid[], expansionConfig): Observable<Bid[]> {
    if (bids.length === 0) {
      return of([]);
    }

    return combineLatest(bids.map((bid: Bid) => this.expandBid(bid, expansionConfig)));
  }

  protected expandBid(bid: Bid, expansionConfig: BidExpansionConfig): Observable<Bid> {
    if (expansionConfig.maxBid.ownerId === bid.bidderId) {
      return this.loadMaxBid(bid);
    }

    return of(bid);
  }

  protected loadMaxBid(bid: Bid): Observable<Bid> {
    if (!bid.maxBidId) {
      return of(bid);
    }

    return object(ref(this.getAngularFireDatabase(), `/max_bids/${bid.maxBidId}`)).pipe(
      map((changes) => changes.snapshot.val()),
      map((data: MaxBidData) => maxBidMapper.fromDb(data)),
      map((maxBid: MaxBid) => ({ ...bid, maxBid }))
    );
  }
}
