import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BottlePromotedImagePipe } from './bottle-promoted-image.pipe';

@NgModule({
  declarations: [BottlePromotedImagePipe],
  imports: [CommonModule],
  exports: [BottlePromotedImagePipe],
})
export class BottlePromotedImageModule {}
