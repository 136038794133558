import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BarcodeTitleFormComponent } from './barcode-title-form.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ImageThumbnailModule } from '../../directives';
import { BottleAttrModule, BottlePromotedImageModule } from '../../pipes';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [BarcodeTitleFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatCardModule,
    MatAutocompleteModule,
    MatOptionModule,
    ImageThumbnailModule,
    BottleAttrModule,
    BottlePromotedImageModule,
    MatInputModule,
    MatButtonModule,
    FlexLayoutModule,
  ],
  exports: [BarcodeTitleFormComponent],
})
export class BarcodeTitleFormModule {}
