<ng-template #callToPurchase let-bottle>
  <app-call-to-purchase
    *ngIf="!(bottle | authContext : 'isAuctioneerOrOwner')"
    [bottle]="bottle"
    [routerLink]="bottle | authContext : 'getDetailsPath'"
    [queryParams]="{ referer: referer$ | async }"
  >
    <ng-container *ngIf="bottle.state === bottleStates.IN_AUCTION; else currentBid">Starting Bid</ng-container>
    <ng-template #currentBid>Current Bid</ng-template>
  </app-call-to-purchase>
</ng-template>

<ng-template #viewDetails let-bottle>
  <button
    mat-button
    [routerLink]="bottle | authContext : 'getDetailsPath'"
    [queryParams]="{ referer: referer$ | async }"
  >
    VIEW DETAILS
  </button>
</ng-template>

<ng-template #auctioneerAction let-bottle let-action="action">
  <ng-container *ngIf="bottle | authContext : 'isAuctioneer'; else notAuctioneer">
    <button
      mat-button
      [routerLink]="bottle | authContext : 'getDetailsPath'"
      [queryParams]="{ referer: referer$ | async }"
    >
      {{ action }}
    </button>
  </ng-container>
  <ng-template #notAuctioneer>
    <ng-container *ngTemplateOutlet="viewDetails; context: { $implicit: bottle }"></ng-container>
  </ng-template>
</ng-template>

<!--
  ACTUAL TEMPLATE
 -->
<div @list fxLayout="column">
  <div @items fxFlex="none" class="bottle-row" *ngFor="let bottle of bottles; trackBy: trackByBottles">
    <app-bottle-preview [bottle]="bottle">
      <!-- Footer actions -->
      <app-bottle-preview-footer>
        <app-bottle-edit-button [bottle]="bottle"></app-bottle-edit-button>
        <app-bottle-delete-button [bottle]="bottle" (deleted)="delete($event)"></app-bottle-delete-button>
        <ng-container [ngSwitch]="bottle.state">
          <!-- DRAFT -->
          <ng-container *ngSwitchCase="bottleStates.DRAFT">
            <button mat-button routerLink="/collector/collection/add">RESUME EDITING</button>
          </ng-container>

          <!-- PENDING_AUCTIONEER_ACCEPTANCE -->
          <ng-container *ngSwitchCase="bottleStates.PENDING_AUCTIONEER_ACCEPTANCE">
            <ng-container [ngSwitch]="bottle.auction.state">
              <!-- PENDING_ACCEPTANCE -->
              <ng-container *ngSwitchCase="auctionStates.PENDING_ACCEPTANCE">
                <ng-container
                  *ngTemplateOutlet="auctioneerAction; context: { $implicit: bottle, action: 'RESPOND TO SELLER' }"
                ></ng-container>
              </ng-container>

              <ng-container *ngSwitchDefault>
                <ng-container *ngTemplateOutlet="viewDetails; context: { $implicit: bottle }"></ng-container>
              </ng-container>
            </ng-container>
          </ng-container>

          <!-- PENDING_DELIVERY_TO_AUCTIONEER -->
          <ng-container *ngSwitchCase="bottleStates.PENDING_DELIVERY_TO_AUCTIONEER">
            <ng-container
              *ngTemplateOutlet="auctioneerAction; context: { $implicit: bottle, action: 'CONFIRM DELIVERY' }"
            ></ng-container>
          </ng-container>

          <!-- PENDING_AUCTION_START -->
          <ng-container *ngSwitchCase="bottleStates.PENDING_AUCTION_START">
            <ng-container
              *ngTemplateOutlet="auctioneerAction; context: { $implicit: bottle, action: 'START AUCTION' }"
            ></ng-container>
          </ng-container>

          <!-- IN_AUCTION -->
          <ng-container *ngSwitchCase="bottleStates.IN_AUCTION">
            <ng-container *ngIf="bottle | authContext : 'isAuctioneerOrOwner'">
              <ng-container *ngTemplateOutlet="viewDetails; context: { $implicit: bottle }"></ng-container>
            </ng-container>
          </ng-container>

          <!-- PENDING_AUCTION_END -->
          <ng-container *ngSwitchCase="bottleStates.PENDING_AUCTION_END">
            <ng-container *ngIf="bottle | authContext : 'isAuctioneerOrOwner'">
              <ng-container *ngTemplateOutlet="viewDetails; context: { $implicit: bottle }"></ng-container>
            </ng-container>
          </ng-container>

          <!-- IN_TRANSACTION -->
          <ng-container *ngSwitchCase="bottleStates.IN_TRANSACTION">
            <ng-container *ngIf="bottle | authContext : 'isAuctioneerOrOwner'; else winnerOrLosser">
              <ng-container *ngTemplateOutlet="viewDetails; context: { $implicit: bottle }"></ng-container>
            </ng-container>
            <!-- Winner or Losser -->
            <ng-template #winnerOrLosser>
              <!-- Winner -->
              <ng-container *ngIf="bottle | authContext : 'isBuyer'">
                <button
                  mat-button
                  [routerLink]="['/', 'bottle', 'checkout', bottle.id]"
                  [queryParams]="{ referer: referer$ | async }"
                >
                  PROCEED TO PAYMENT
                </button>
              </ng-container>
              <!-- Losser -->
              <ng-container *ngIf="bottle | authContext : 'hasLostAuction'">
                <ng-container *ngTemplateOutlet="viewDetails; context: { $implicit: bottle }"></ng-container>
              </ng-container>
            </ng-template>
          </ng-container>

          <!-- PENDING_PICKUP_AT_AUCTIONEER -->
          <ng-container *ngSwitchCase="bottleStates.PENDING_PICKUP_AT_AUCTIONEER">
            <ng-container
              *ngTemplateOutlet="auctioneerAction; context: { $implicit: bottle, action: 'CONFIRM PICKUP' }"
            ></ng-container>
          </ng-container>

          <!-- PENDING_SHIPPING -->
          <ng-container *ngSwitchCase="bottleStates.PENDING_SHIPPING">
            <ng-container
              *ngTemplateOutlet="auctioneerAction; context: { $implicit: bottle, action: 'SHIP ITEM' }"
            ></ng-container>
          </ng-container>

          <!-- SHIPPED -->
          <ng-container *ngSwitchCase="bottleStates.SHIPPED">
            <ng-container *ngIf="bottle | authContext : 'isAuctioneerOrSeller'; else notAuctioneerOrSeller">
              <ng-container *ngTemplateOutlet="viewDetails; context: { $implicit: bottle }"></ng-container>
            </ng-container>
            <ng-template #notAuctioneerOrSeller>
              <button
                mat-button
                [routerLink]="bottle | authContext : 'getDetailsPath'"
                [queryParams]="{ referer: referer$ | async }"
              >
                CONFIRM DELIVERY
              </button>
            </ng-template>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <ng-container *ngTemplateOutlet="viewDetails; context: { $implicit: bottle }"></ng-container>
          </ng-container>
        </ng-container>
      </app-bottle-preview-footer>

      <!--
        Call-to-action (for anyone that is not auctioneer nore owner)
      -->
      <app-bottle-preview-action>
        <ng-container [ngSwitch]="bottle.state">
          <!-- IN_AUCTION -->
          <ng-container *ngSwitchCase="bottleStates.IN_AUCTION">
            <ng-container *ngTemplateOutlet="callToPurchase; context: { $implicit: bottle }"></ng-container>
          </ng-container>

          <!-- PENDING_AUCTION_END -->
          <ng-container *ngSwitchCase="bottleStates.PENDING_AUCTION_END">
            <ng-container *ngTemplateOutlet="callToPurchase; context: { $implicit: bottle }"></ng-container>
          </ng-container>
        </ng-container>
      </app-bottle-preview-action>
    </app-bottle-preview>
  </div>
</div>
