import { Payment, PaymentData } from '../models';

export function isPaymentPending(payment: Payment): boolean {
  return payment.status === 'PENDING';
}

export function hasPaymentFailed(payment: Payment): boolean {
  return payment.status === 'FAILED';
}

export function hasPaymentSucceeded(payment: Payment): boolean {
  return payment.status === 'AUTHORIZED' || payment.status === 'COMPLETED';
}

export function createPaymentData(
  amount: number,
  state: 'COMPLETED' | 'FAILED' | 'PENDING' | 'AUTHORIZED' | 'CAPTURED' = 'COMPLETED',
  method = 'BANK'
): PaymentData {
  return {
    amount,
    created_at: Date.now(),
    authorized_at: Date.now(),
    captured_at: Date.now(),
    state,
    method,
  };
}
