import { Pipe, PipeTransform } from '@angular/core';

import { AuthContextService } from '@whiskybazar/pwa/core/services';
import { Bottle } from '@whiskybazar/core';

type Operation = Exclude<keyof AuthContextService, 'getAuth' | 'setAuth'>;

@Pipe({
  name: 'authContext',
})
export class AuthContextPipe implements PipeTransform {
  constructor(protected ctx: AuthContextService) {}

  transform(value: Bottle, operation: Operation): ReturnType<AuthContextService[Operation]> {
    if (!value) {
      return false;
    }

    return this.ctx[operation](value);
  }
}
