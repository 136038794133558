import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, switchMap, catchError, tap, take } from 'rxjs/operators';

import * as fromStore from '../store';

@Injectable()
export class CategoriesPageGuard {
  constructor(private store$: Store<fromStore.State>) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.checkStore().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkStore(): Observable<boolean> {
    return this.store$.pipe(
      select(fromStore.selectCategoriesPageLoaded),
      tap((loaded: boolean) => this.doLoad(loaded)),
      filter((loaded: boolean) => loaded),
      take(1)
    );
  }

  doLoad(loaded: boolean) {
    if (loaded) {
      return;
    }

    this.store$.dispatch(fromStore.loadCategories());
  }
}
