import { Injectable } from '@angular/core';
import { Route, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { take, switchMap, catchError } from 'rxjs/operators';

import * as fromAuthStore from '@whiskybazar/pwa/auth/store';
import { AuthUser } from '@whiskybazar/core';
import { AuthGuard } from './auth.guard';

@Injectable()
export class SignupGuard {
  constructor(private authGuard: AuthGuard) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.check();
  }

  canLoad(route: Route): Observable<boolean> {
    return this.check();
  }

  protected check(): Observable<boolean> {
    return this.authGuard.store$.select(fromAuthStore.getLoggedIn).pipe(
      take(1),
      switchMap((loggedIn: boolean) => of(loggedIn === false)),
      catchError(() => of(false))
    );
  }
}
