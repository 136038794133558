import { Component, OnInit, Input } from '@angular/core';

import { AuthContextService } from '@whiskybazar/pwa/core/services';
import { Bottle } from '@whiskybazar/core';

@Component({
  selector: 'app-bottle-price',
  templateUrl: './bottle-price.component.html',
  styleUrls: ['./bottle-price.component.scss'],
})
export class BottlePriceComponent implements OnInit {
  @Input() bottle: Bottle;

  @Input() inline: boolean;

  constructor(public ctx: AuthContextService) {
    this.inline = false;
  }

  ngOnInit() {}
}
