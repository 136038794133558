import {
  Invoice,
  InvoiceData,
  OpeningHours,
  PackageShop,
  PackageShopData,
  Payment,
  PaymentData,
  Shipment,
  ShipmentData,
  ShipmentRoute,
  ShipmentRouteData,
  ShipmentRoutePoint,
  ShipmentRoutePointData,
  Transaction,
  TransactionData,
} from '../models';
import { Mapper } from './mapper';

export abstract class TransactionMapper implements Mapper<TransactionData, Transaction> {
  fromDb(data: TransactionData | null): Transaction | null {
    if (!data) {
      return null;
    }

    return {
      id: <string>data.id,
      createdAt: data.created_at ? new Date(data.created_at) : new Date(),
      updatedAt: data.updated_at ? new Date(data.updated_at) : new Date(data.created_at),
      state: <string>data.state,
      sellerId: <string>data.seller,
      buyerId: <string>data.buyer,
      transactionId: <string>data.transaction_id,
    };
  }

  toDb(model: Transaction | null): TransactionData | null {
    if (!model) {
      return null;
    }

    return {
      id: model.id,
      seller: model.sellerId,
      buyer: model.buyerId,
      state: model.state,
    };
  }

  protected fromDbPackageShop(data: PackageShopData | null): PackageShop | null {
    if (!data) {
      return null;
    }

    const openingHours = Object.keys(data.opening_hours).map((day: string) => ({
      day,
      from: data.opening_hours[day].from,
      to: data.opening_hours[day].to,
    }));

    return {
      id: data.id,
      name: data.name,
      address: {
        id: null,
        address: data.address,
        zipCode: data.zip_code,
        city: data.city,
        country: data.country,
      },
      distance: data.distance,
      openingHours,
    };
  }

  protected toDbPackageShop(model: PackageShop | null): PackageShopData | null {
    if (!model) {
      return undefined;
    }

    const opening_hours = {};
    model.openingHours.forEach((i: OpeningHours) => (opening_hours[i.day] = { from: i.from, to: i.to }));

    return {
      id: model.id,
      name: model.name,
      address: model.address.address,
      zip_code: model.address.zipCode,
      city: model.address.city,
      country: 'Denmark',
      distance: model.distance,
      opening_hours,
    };
  }

  protected fromDbShipment(shipment: ShipmentData): Shipment {
    if (!shipment) {
      return null;
    }

    return {
      id: shipment.id,
      orderId: shipment.order_id,
      packageNumber: shipment.package_number,
      labelUrl: shipment.label_url,
      price: shipment.price,
      createdAt: shipment.created_at ? new Date(shipment.created_at) : null,
    };
  }

  protected fromDbPayment(data: PaymentData | null): Payment | null {
    if (!data) {
      return null;
    }

    return {
      id: data.id,
      link: data.link,
      amount: data.amount,
      createdAt: data.created_at ? new Date(data.created_at) : null,
      authorizedAt: data.authorized_at ? new Date(data.authorized_at) : null,
      capturedAt: data.captured_at ? new Date(data.captured_at) : null,
      status: data.state ? data.state : 'PENDING',
      method: data.method,
    };
  }

  protected fromDbShipmentRoute(data: ShipmentRouteData | null): ShipmentRoute | null {
    if (!data) {
      return null;
    }

    const points = Object.keys(data)
      .map((order) => this.fromDbShipmentPoint(data[order]))
      .sort((a, b) => a.order - b.order);

    return { points };
  }

  fromDbShipmentPoint(data: ShipmentRoutePointData | null): ShipmentRoutePoint | null {
    if (!data) {
      return null;
    }

    return {
      order: data.order,
      from: data.from,
      to: data.to,
      shipment: this.fromDbShipment(data.shipment),
    };
  }

  protected fromDbInvoice(data: InvoiceData | null): Invoice | null {
    if (!data) {
      return null;
    }

    return {
      invoiceId: <string>data.invoice_id,
      vatRate: data.vat_rate ? data.vat_rate : 0.0,
      vatAmount: data.vat_amount ? data.vat_amount : 0.0,
    };
  }
}
