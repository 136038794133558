import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthContextModule } from '../../pipes';
import { BottleTileModule } from '../bottle-tile/bottle-tile.module';
import { CallToPurchaseModule } from '../call-to-purchase/call-to-purchase.module';
import { BottlesGridComponent } from './bottles-grid.component';
import { RouterModule } from '@angular/router';
import { BottlePreviewModule } from '../bottle-preview/bottle-preview.module';
import { BottlePreviewFooterComponent } from '../bottle-preview/bottle-preview.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [BottlesGridComponent],
  imports: [
    CommonModule,
    AuthContextModule,
    BottleTileModule,
    CallToPurchaseModule,
    RouterModule,
    BottlePreviewModule,
    FlexLayoutModule,
  ],
  exports: [BottlesGridComponent],
})
export class BottlesGridModule {}
