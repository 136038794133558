import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BottleDealerComponent } from './bottle-dealer.component';
import { AuthContextModule, BottleCountryModule, BottleDealerPipe, BottleDealerPipeModule } from '../../pipes';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [BottleDealerComponent],
  imports: [CommonModule, BottleCountryModule, AuthContextModule, RouterModule, BottleDealerPipeModule],
  exports: [BottleDealerComponent],
})
export class BottleDealerModule {}
