import { Injectable } from '@angular/core';
import { Route, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { take, switchMap, catchError } from 'rxjs/operators';

import * as fromAuthStore from '@whiskybazar/pwa/auth/store';
import { AuthUser } from '@whiskybazar/core';
import { AuthGuard } from './auth.guard';

@Injectable()
export class DealerGuard {
  constructor(private authGuard: AuthGuard) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authGuard
      .canActivate(next, state)
      .pipe(switchMap((loggedIn: boolean) => (loggedIn === false ? of(false) : this.isDealer())));
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authGuard
      .canActivateChild(next, state)
      .pipe(switchMap((loggedIn: boolean) => (loggedIn === false ? of(false) : this.isDealer())));
  }

  canLoad(route: Route): Observable<boolean> {
    return this.authGuard
      .canLoad(route)
      .pipe(switchMap((loggedIn: boolean) => (loggedIn === false ? of(false) : this.isDealer())));
  }

  protected isDealer(): Observable<boolean> {
    return this.authGuard.store$.select(fromAuthStore.getUser).pipe(
      take(1),
      switchMap((user: AuthUser) => of(user.type === 'dealer')),
      catchError(() => of(false))
    );
  }
}
