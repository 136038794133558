import { Pipe, PipeTransform } from '@angular/core';

import { AUCTION_VARIABLE_FEE, AUCTION_FIXED_FEE } from '@whiskybazar/core';
import { Bottle } from '@whiskybazar/core';

@Pipe({
  name: 'bottlePrice',
})
export class BottlePricePipe implements PipeTransform {
  transform(value: Bottle | number, total?: boolean): number {
    let price = 0.0;
    if (this.isBottle(value)) {
      const bottle = value as Bottle;
      price = bottle.auction.bestBid ? bottle.auction.bestBid.amount : bottle.auction.minPrice;
    } else {
      price = +(value as number);
    }

    if (total === true) {
      price = this.calcTotal(price);
    }

    return price;
  }

  // TODO: move this to a core service
  protected calcTotal(price: number): number {
    return [price, price * AUCTION_VARIABLE_FEE, AUCTION_FIXED_FEE].reduce((sum: number, a: number) => sum + a, 0.0);
  }

  private isBottle(value: any): boolean {
    return Object.keys(value).indexOf('auction') !== -1;
  }
}
