<div fxLayout="column" [class.dealer-theme]="(user$ | async)?.type === 'dealer'">
  <div fxFlex="none">
    <mat-toolbar color="primary" data-cy="header">
      <a class="logo" fxFlex="none" fxLayout="row" fxLayoutAlign="start center" routerLink="/">
        <img src="/assets/images/logo.svg" class="logo-short" />
        <img src="/assets/images/whiskybazar.svg" class="logo-long" fxHide.lt-md />
      </a>

      <div fxFlex="grow" fxShow fxHide.lt-sm>
        <app-horizontal-nav
          [auth]="user$ | async"
          [loggedIn]="loggedIn$ | async"
          (logout)="logout()"
          (search)="toggleSearchBar()"
        ></app-horizontal-nav>
      </div>

      <div fxFlex="none" fxFlex.lt-sm="grow" fxLayout="row" fxLayoutAlign="end center" fxHide fxShow.lt-md>
        <app-search-button class="sm:hidden"></app-search-button>
        <button fxFlex="none" mat-icon-button (click)="snav.toggle()" fxHide fxShow.lt-sm>
          <mat-icon>menu</mat-icon>
        </button>
      </div>
    </mat-toolbar>
  </div>

  <div fxFlex="none" fxLayout="row" fxLayoutAlign="center center" class="search-bar" *ngIf="searchBar">
    <div fxFlex="none" ngClass.gt-sm="large" ngClass.lt-md="small"><app-search></app-search></div>
  </div>

  <div fxFlex="none">
    <mat-progress-bar mode="indeterminate" *ngIf="navigating$ | async"></mat-progress-bar>
  </div>

  <div class="mat-app-background" fxFlex="noshrink" [hidden]="navigating$ | async">
    <mat-sidenav-container>
      <mat-sidenav #snav [mode]="'over'" position="end">
        <app-vertical-nav
          [drawer]="snav"
          [auth]="user$ | async"
          [loggedIn]="loggedIn$ | async"
          (logout)="logout()"
        ></app-vertical-nav>
      </mat-sidenav>
      <mat-sidenav-content ngClass.lt-sm="small"> <router-outlet></router-outlet> </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>

<footer
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap="32px"
  [class.dealer-theme]="(user$ | async)?.type === 'dealer'"
>
  <div fxFlex="none"><app-footer-nav></app-footer-nav></div>
  <div class="footer" fxFlex="none">
    <div
      fxLayout.lt-sm="column"
      fxLayout="row"
      fxLayoutAlign="center start"
      fxLayoutAlign.lt-sm="center center"
      fxLayoutGap="32px"
    >
      <div fxFlex="none">
        <span>PAYMENT METHODS</span>
        <app-payment-methods></app-payment-methods>
      </div>
      <div fxFlex="none">
        <p>
          Whisky Bazar is provided by<br />
          Jutlander Group ApS, Messingvej 54, 8940 Randers SØ<br />
          Registered in Denmark - CRN 38536990
        </p>
      </div>
    </div>
    <p>
      Contact@WhiskyBazar.com<br />
      &copy; Copyright 2018 - All Rights Reserved<br />
      <span class="app-version">Version {{ appVersion }}+{{ appRevisionId }}</span>
    </p>
  </div>
</footer>

<ng-container *ngIf="closing$ | async">Closing...</ng-container>
