import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { MatDrawerMode } from '@angular/material/sidenav';

import { Bottle, Facet, FacetOption, Sorting, Range, SortBy } from '@whiskybazar/core';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { MatSelectChange } from '@angular/material/select';

// A bit of a hack to get the drawer height to be dynamic in order to avoid
// vertical and horizontal scrollbars when the filters are applied.
// NOTE: the default height should be increased when adding more filters to
//       this component.
const MAT_DRAWER_DEFAULT_HEIGHT = 600;
const MAT_DRAWER_HEIGHT_INCREMENT_PER_FILTER = 50;

type ViewMode = 'grid' | 'list';

@Component({
  selector: 'app-bottles',
  templateUrl: './bottles.component.html',
  styleUrls: ['./bottles.component.scss'],
})
export class BottlesComponent {
  @Input() bottles: Bottle[];

  @Input() loading: boolean;

  @Input() searching: boolean;

  @Input() query: string;

  @Input() distilleriesFilter: Facet;

  @Input() bottlersFilter: Facet;

  @Input() regionsFilter: Facet;

  @Input() typesFilter: Facet;

  @Input() priceFilter: Range;

  @Input() sorting: Sorting;

  @Input() total: number;

  @Output() queryChange = new EventEmitter<string>();

  @Output() distilleriesChange = new EventEmitter<FacetOption[]>();

  @Output() bottlersChange = new EventEmitter<FacetOption[]>();

  @Output() regionsChange = new EventEmitter<FacetOption[]>();

  @Output() typesChange = new EventEmitter<FacetOption[]>();

  @Output() priceChange = new EventEmitter<number[]>();

  @Output() sortingChange = new EventEmitter<SortBy>();

  @Output() clearFilters = new EventEmitter<void>();

  hasQuery = false;

  viewMode: ViewMode;

  drawerOpened$: Observable<boolean>;

  drawerMode$: Observable<MatDrawerMode>;

  get hasFilters(): boolean {
    return (
      this.distilleriesFilter.selection.length > 0 ||
      this.bottlersFilter.selection.length > 0 ||
      this.regionsFilter.selection.length > 0 ||
      this.typesFilter.selection.length > 0 ||
      this.priceFilter.selection.length > 0
    );
  }

  get matDrawerContentHeight(): number {
    return (
      MAT_DRAWER_DEFAULT_HEIGHT +
      (this.distilleriesFilter.selection.length +
        this.bottlersFilter.selection.length +
        this.regionsFilter.selection.length +
        this.typesFilter.selection.length) *
        MAT_DRAWER_HEIGHT_INCREMENT_PER_FILTER
    );
  }

  constructor(private breakpointObserver: BreakpointObserver) {
    this.viewMode = 'grid';

    const isLarge$ = this.breakpointObserver
      .observe('(min-width: 768px)')
      .pipe(map((state: BreakpointState) => state.matches));

    this.drawerOpened$ = isLarge$.pipe(filter((matches: boolean) => matches));

    this.drawerMode$ = isLarge$.pipe(
      map((isLarge: boolean) => (isLarge ? ('side' as MatDrawerMode) : ('push' as MatDrawerMode))),
      startWith('side' as MatDrawerMode)
    );
  }

  setViewMode(mode: ViewMode) {
    this.viewMode = mode;
  }

  isGrid(): boolean {
    return this.viewMode === 'grid';
  }

  isList(): boolean {
    return this.viewMode === 'list';
  }

  displayFn(option: FacetOption): string {
    return `${option.name} (${option.count})`;
  }

  filterFn(item: string | FacetOption, list: FacetOption[]): FacetOption[] {
    if (typeof item === 'string') {
      return list.filter(({ name }) => name.toLocaleLowerCase().includes(item.trim().toLocaleLowerCase()));
    }

    return list.filter(({ name }) => name === item.name);
  }

  equalFn(a: FacetOption, b: FacetOption): boolean {
    return a && b ? a.name === b.name : a === b;
  }

  onSearch(value: string) {
    this.hasQuery = (value ?? '').length > 0;

    this.queryChange.emit(value);
  }

  onDistilleriesChange(selection: FacetOption[]) {
    this.distilleriesChange.emit(selection);
  }

  onBottlersChange(selection: FacetOption[]) {
    this.bottlersChange.emit(selection);
  }

  onRegionsChange(selection: FacetOption[]) {
    this.regionsChange.emit(selection);
  }

  onTypesChange(selection: FacetOption[]) {
    this.typesChange.emit(selection);
  }

  onPriceMinChange(selection: number) {
    this.priceChange.emit([selection, this.priceFilter.selection[1] ?? this.priceFilter.max]);
  }

  onPriceMaxChange(selection: number) {
    this.priceChange.emit([this.priceFilter.selection[0] ?? this.priceFilter.min, selection]);
  }

  onSortingChange(selection: MatSelectChange) {
    this.sortingChange.emit(selection.value as SortBy);
  }

  onClearFilters() {
    this.clearFilters.emit();
  }

  onClearQuery() {
    this.hasQuery = false;
    this.queryChange.emit('');
  }
}
