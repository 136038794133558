import { Component, OnInit, Input } from '@angular/core';

import { Article } from '@whiskybazar/pwa/categories/models';

@Component({
  selector: 'app-article-summary',
  templateUrl: './article-summary.component.html',
  styleUrls: ['./article-summary.component.scss'],
})
export class ArticleSummaryComponent implements OnInit {
  @Input() article: Article;

  constructor() {}

  ngOnInit() {}
}
