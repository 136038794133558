import { inject, Injectable } from '@angular/core';
import { Database, equalTo, limitToFirst, limitToLast, orderByChild } from '@angular/fire/database';
import { Observable, of, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AbstractFirebaseList } from './abstract-firebase-list';
import { MetaBottlesService } from './meta-bottles.service';
import { AuctionsService, AuctionExpansionConfig } from './auctions.service';
import { SalesService, SaleExpansionConfig } from './sales.service';
import { OwnersService } from './owners.service';
import { DatabaseApiService } from './database-api.service';
import { Mapper, bottleMapper, MetaBottle, Auction, Sale, Bottle, BottleData, User } from '@whiskybazar/core';
import { BOTTLE_STATES } from '@whiskybazar/core';

export interface BottleExpansionConfig {
  auction?: AuctionExpansionConfig;
  sale?: SaleExpansionConfig;
  owner?: boolean;
}

@Injectable()
export class BottlesService extends AbstractFirebaseList<Bottle, BottleData> {
  private readonly path = 'bottles';

  private afDb: Database = inject(Database);

  constructor(
    private metaBottlesService: MetaBottlesService,
    private auctionsService: AuctionsService,
    private salesService: SalesService,
    private ownersService: OwnersService,
    private dbApi: DatabaseApiService
  ) {
    super();
  }

  getPath(): string {
    return this.path;
  }

  getAngularFireDatabase(): Database {
    return this.afDb;
  }

  getMapper(): Mapper<BottleData, Bottle> {
    return bottleMapper;
  }

  getDatabaseApiService(): DatabaseApiService {
    return this.dbApi;
  }

  fetchBiddable(limit = 10, expansionConfig?: BottleExpansionConfig): Observable<Bottle[]> {
    const bottlesInAuctionQuery$ = this.query(
      orderByChild('state'),
      equalTo(BOTTLE_STATES.IN_AUCTION.id),
      limitToFirst(limit)
    );

    const bottlesPendingAuctionEndQuery$ = this.query(
      orderByChild('state'),
      equalTo(BOTTLE_STATES.PENDING_AUCTION_END.id),
      limitToFirst(limit)
    );

    let biddables$ = combineLatest([bottlesInAuctionQuery$, bottlesPendingAuctionEndQuery$]).pipe(
      map((result: [Bottle[], Bottle[]]) => [...result[0], ...result[1]]),
      switchMap((bottles: Bottle[]) => this.fetchMetaBottles(bottles))
    );

    if (expansionConfig) {
      biddables$ = biddables$.pipe(switchMap((bottles: Bottle[]) => this.expandBottles(bottles, expansionConfig)));
    }

    return biddables$;
  }

  fetchPurchasable(limit = 10, expansionConfig?: BottleExpansionConfig): Observable<Bottle[]> {
    const bottlesForSaleQuery$ = this.query(
      orderByChild('state'),
      equalTo(BOTTLE_STATES.FOR_SALE.id),
      limitToFirst(limit)
    );
    const bottlesOnSaleQuery$ = this.query(
      orderByChild('state'),
      equalTo(BOTTLE_STATES.ON_SALE.id),
      limitToFirst(limit)
    );

    let purchasables = combineLatest([bottlesForSaleQuery$, bottlesOnSaleQuery$]).pipe(
      map((results: [Bottle[], Bottle[]]) => [...results[0], ...results[1]]),
      switchMap((bottles: Bottle[]) => this.fetchMetaBottles(bottles))
    );

    if (expansionConfig) {
      purchasables = purchasables.pipe(switchMap((bottles: Bottle[]) => this.expandBottles(bottles, expansionConfig)));
    }

    return purchasables;
  }

  fetchById(id: string, expansionConfig: BottleExpansionConfig = {}): Observable<Bottle> {
    let result = super.fetchById(id).pipe(switchMap((bottle: Bottle) => this.fetchMetaBottle(bottle)));

    if (expansionConfig.auction) {
      result = result.pipe(
        switchMap((b: Bottle) => (this.hasAuction(b) ? this.fetchAuction(b, expansionConfig.auction) : of(b)))
      );
    }

    if (expansionConfig.sale) {
      result = result.pipe(
        switchMap((b: Bottle) => (this.hasSale(b) ? this.fetchSale(b, expansionConfig.sale) : of(b)))
      );
    }

    if (expansionConfig.owner) {
      result = result.pipe(switchMap((b: Bottle) => this.fetchOwner(b)));
    }

    return result;
  }

  fetchDraftByOwner(ownerId: string): Observable<Bottle | null> {
    return this.query(orderByChild('owner'), equalTo(ownerId)).pipe(
      map((bottles: Bottle[]) => bottles.filter((b) => b.state === BOTTLE_STATES.DRAFT.id)),
      map((drafts: Bottle[]) => (drafts.length > 0 ? drafts[0] : null))
    );
  }

  fetchByOwner(owner: User, limit = 10, expansionConfig?: BottleExpansionConfig): Observable<Bottle[]> {
    let result = this.query(orderByChild('owner'), equalTo(owner.id), limitToLast(limit)).pipe(
      map((bottles: Bottle[]) => this.filterAll(bottles)),
      switchMap((bottles: Bottle[]) => this.fetchMetaBottles(bottles))
    );

    if (expansionConfig) {
      result = result.pipe(switchMap((bottles: Bottle[]) => this.expandBottles(bottles, expansionConfig)));
    }

    return result;
  }

  hasAuction(bottle: Bottle): boolean {
    return !!bottle.auctionId;
  }

  hasSale(bottle: Bottle): boolean {
    // TODO below is not a valid strategy, instead check for `saleId` on `bottle`
    return bottle.state === BOTTLE_STATES.ON_SALE.id || bottle.state === BOTTLE_STATES.FOR_SALE.id;
  }

  create(bottle: Bottle): Observable<Bottle> {
    if (bottle.id) {
      return of(bottle);
    }

    return super.create(bottle);
  }

  createDraf(ownerId: string): Observable<Bottle> {
    const draft: Bottle = {
      id: null,
      ownerId,
      state: BOTTLE_STATES.DRAFT.id,
    };

    return this.create(draft);
  }

  fetchByAuctions(auctions: Auction[]): Observable<Bottle[]> {
    return combineLatest(
      auctions.map((a: Auction) =>
        this.fetchById(a.bottleId).pipe(map((b: Bottle) => ({ ...b, auctionId: a.id, auction: a } as Bottle)))
      )
    );
  }

  fetchByMetaBottle(metaBottleId: string, expansionConfig?: BottleExpansionConfig): Observable<Bottle[]> {
    let result = this.query(orderByChild('meta_bottle'), equalTo(metaBottleId)).pipe(
      switchMap((bottles: Bottle[]) => this.fetchMetaBottles(bottles))
    );

    if (expansionConfig) {
      result = result.pipe(switchMap((bottles: Bottle[]) => this.expandBottles(bottles, expansionConfig)));
    }

    return result;
  }

  fetchRecentlyUpdated(limit = 20, expansionConfig?: BottleExpansionConfig): Observable<Bottle[]> {
    let result = this.query(orderByChild('updated_at'), limitToLast(limit)).pipe(
      switchMap((bottles: Bottle[]) => this.fetchMetaBottles(bottles))
    );

    if (expansionConfig) {
      result = result.pipe(switchMap((bottles: Bottle[]) => this.expandBottles(bottles, expansionConfig)));
    }

    return result;
  }

  private fetchMetaBottles(bottles: Bottle[]): Observable<Bottle[]> {
    return bottles.length === 0 ? of([]) : combineLatest(bottles.map((bottle) => this.fetchMetaBottle(bottle)));
  }

  private fetchMetaBottle(bottle: Bottle): Observable<Bottle> {
    return this.metaBottlesService.fetchById(bottle.metaBottleId).pipe(
      map((metaBottle: MetaBottle) => {
        return { ...bottle, metaBottle };
      })
    );
  }

  private expandBottles(bottles: Bottle[], expansionConfig: BottleExpansionConfig): Observable<Bottle[]> {
    if (bottles.length === 0) {
      return of([]);
    }

    if (expansionConfig.auction) {
      return combineLatest(bottles.map((bottle) => this.fetchAuction(bottle, expansionConfig.auction)));
    } else if (expansionConfig.sale) {
      return combineLatest(bottles.map((bottle) => this.fetchSale(bottle, expansionConfig.sale)));
    }

    if (expansionConfig.owner) {
      return combineLatest(bottles.map((b: Bottle) => this.fetchOwner(b)));
    }

    return of(bottles);
  }

  private fetchAuction(bottle: Bottle, expansionConfig: AuctionExpansionConfig): Observable<Bottle> {
    return this.auctionsService.fetchById(bottle.auctionId, expansionConfig).pipe(
      map((auction: Auction) => {
        return { ...bottle, auction };
      })
    );
  }

  private fetchSale(bottle: Bottle, expansionConfig: SaleExpansionConfig): Observable<Bottle> {
    return this.salesService.fetchById(bottle.id, expansionConfig).pipe(
      map((sale: Sale) => {
        return { ...bottle, sale };
      })
    );
  }

  private fetchOwner(bottle: Bottle): Observable<Bottle> {
    return this.ownersService.fetchById(bottle.ownerId).pipe(map((owner: User) => ({ ...bottle, owner })));
  }

  private filterAll(bottles: Bottle[]): Bottle[] {
    return bottles.filter((b: Bottle) => this.filterOne(b));
  }

  private filterOne(bottle: Bottle): boolean {
    if (bottle.state === BOTTLE_STATES.ARCHIVED.id) {
      return false;
    }

    return true;
  }
}
