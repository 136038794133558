import {
  AuctionData,
  AuctioneerAuctionInvoice,
  AuctioneerAuctionInvoiceData,
  AuctioneerAuctionInvoiceDetails,
  AuctioneerAuctionInvoiceDetailsData,
  AuctionTransaction,
  AuctionTransactionData,
  BuyerAuctionInvoice,
  BuyerAuctionInvoiceData,
  BuyerAuctionInvoiceDetails,
  BuyerAuctionInvoiceDetailsData,
  SellerAuctionInvoice,
  SellerAuctionInvoiceData,
  SellerAuctionInvoiceDetails,
  SellerAuctionInvoiceDetailsData,
} from '../models';
import { Mapper } from './mapper';
import { TransactionMapper } from './transaction.mapper';

export class AuctionTransactionMapper
  extends TransactionMapper
  implements Mapper<AuctionTransactionData, AuctionTransaction>
{
  fromDb(data: AuctionTransactionData | null): AuctionTransaction | null {
    const result = super.fromDb(data);
    if (!result) {
      return result;
    }

    return {
      ...result,
      auctioneerId: data.auctioneer,
      auctioneerInvoice: this.fromDbAuctioneerInvoice(data.auctioneer_invoice),
      auctionId: typeof data.auction === 'string' ? data.auction : (data.auction as AuctionData)?.id,
      buyerInvoice: this.fromDbBuyerInvoice(data.buyer_invoice),
      sellerInvoice: this.fromDbSellerInvoice(data.seller_invoice),
      shippingDueAt: data.shipping_due_at ? new Date(data.shipping_due_at) : undefined,
      pickupDueAt: data.pickup_due_at ? new Date(data.pickup_due_at) : undefined,
    };
  }

  toDb(model: AuctionTransaction | null): AuctionTransactionData | null {
    const result = super.toDb(model);
    if (!result) {
      return null;
    }

    return {
      ...result,
      auction: model.auctionId,
      auctioneer: model.auctioneerId,
      buyer_invoice: this.toDbBuyerInvoice(model.buyerInvoice),
    };
  }

  fromDbAuctioneerInvoice(data: AuctioneerAuctionInvoiceData | null): AuctioneerAuctionInvoice | null {
    const base = this.fromDbInvoice(data);
    if (!base) {
      return null;
    }

    return {
      ...base,
      details: this.fromDbAuctioneerAuctionInvoiceDetails(data.details),
      expectedSettlementAt: new Date(data.expected_settlement_at),
      settledAt: data.settled_at ? new Date(data.settled_at) : null,
      payment: this.fromDbPayment(data.payment),
    } as AuctioneerAuctionInvoice;
  }

  fromDbBuyerInvoice(data: BuyerAuctionInvoiceData | null): BuyerAuctionInvoice | null {
    const base = this.fromDbInvoice(data);
    if (!base) {
      return null;
    }

    return {
      ...base,
      details: this.fromDbBuyerInvoiceDetails(data.details),
      billingAddressId: <string>data.billing_address,
      shippingAddressId: <string>data.shipping_address,
      shippingProductId: <string>data.shipping_product_id,
      shippingInsuranceIncluded: <boolean>data.shipping_insurance_included,
      pickupLocation: this.fromDbPackageShop(data.pickup_location),
      payment: this.fromDbPayment(data.payment),
      shipmentRoute: this.fromDbShipmentRoute(data.shipment_route),
      paymentDueAt: data.payment_due_at ? new Date(data.payment_due_at) : null,
      rightOfWithdrawalExpiresAt: data.right_of_withdrawal_expires_at
        ? new Date(data.right_of_withdrawal_expires_at)
        : null,
    };
  }

  fromDbSellerInvoice(data: SellerAuctionInvoiceData | null): SellerAuctionInvoice | null {
    const base = this.fromDbInvoice(data);
    if (!base) {
      return null;
    }

    return {
      ...base,
      details: this.fromDbSellerInvoiceDetails(data.details),
      expectedSettlementAt: data.expected_settlement_at ? new Date(data.expected_settlement_at) : null,
      settledAt: data.settled_at ? new Date(data.settled_at) : null,
      payment: this.fromDbPayment(data.payment),
    } as SellerAuctionInvoice;
  }

  protected toDbBuyerInvoice(model: BuyerAuctionInvoice | null): BuyerAuctionInvoiceData | null {
    if (!model) {
      return null;
    }

    return {
      details: this.toDbBuyerAuctionInvoiceDetails(model.details),
      pickup_location: this.toDbPackageShop(model.pickupLocation),
      shipping_product_id: model.shippingProductId,
      shipping_insurance_included: model.shippingInsuranceIncluded,
    };
  }

  protected fromDbAuctioneerAuctionInvoiceDetails(
    data: AuctioneerAuctionInvoiceDetailsData | null
  ): AuctioneerAuctionInvoiceDetails {
    if (!data) {
      return null;
    }

    return {
      hammerPrice: <number>data.hammer_price,
      variableProfit: <number>data.variable_profit,
      fixedProfit: <number>data.fixed_profit,
    } as AuctioneerAuctionInvoiceDetails;
  }

  protected fromDbBuyerInvoiceDetails(data: BuyerAuctionInvoiceDetailsData | null): BuyerAuctionInvoiceDetails | null {
    if (!data) {
      return null;
    }

    return {
      hammerPrice: <number>data.hammer_price,
      auctionFee: <number>data.auction_fee,
      packagingFee: <number>data.packaging_fee,
      shippingCost: <number>data.shipping_cost,
      shippingInsuranceCost: <number>data.shipping_insurance_cost,
    };
  }

  protected toDbBuyerAuctionInvoiceDetails(
    model: BuyerAuctionInvoiceDetails | null
  ): BuyerAuctionInvoiceDetailsData | null {
    if (!model) {
      return null;
    }

    return {
      shipping_cost: <number>model.shippingCost,
      shipping_insurance_cost: <number>model.shippingInsuranceCost,
    };
  }

  protected fromDbSellerInvoiceDetails(
    data: SellerAuctionInvoiceDetailsData | null
  ): SellerAuctionInvoiceDetails | null {
    if (!data) {
      return null;
    }

    return {
      hammerPrice: <number>data.hammer_price,
      commissionFee: <number>data.commission_fee,
      handlingFee: <number>data.handling_fee,
    } as SellerAuctionInvoiceDetails;
  }
}

export const auctionTransactionMapper = new AuctionTransactionMapper();
