import { Pipe, PipeTransform } from '@angular/core';

import { Bottle, BottleImagesUtils, Image, MetaBottle } from '@whiskybazar/core';

@Pipe({
  name: 'bottlePromotedImage',
})
export class BottlePromotedImagePipe implements PipeTransform {
  readonly placeholder = 'assets/images/bottle.png';

  transform(bottle: Bottle | MetaBottle | null): string {
    if (!!bottle && !!bottle['metaBottle']) {
      return this.findPromotedImage((bottle as Bottle).metaBottle);
    } else if (!!bottle && (!!bottle['image'] || !!bottle['images'])) {
      return this.findPromotedImage(bottle as MetaBottle);
    }

    return this.placeholder;
  }

  protected findPromotedImage(metaBottle: MetaBottle): string {
    const legacyPromotedImageUrl: string = metaBottle.image;
    const promotedImage: Image = BottleImagesUtils.getPromotedImage(metaBottle.images);

    return !!promotedImage
      ? promotedImage.servingUrl
      : !!legacyPromotedImageUrl
      ? legacyPromotedImageUrl
      : this.placeholder;
  }
}
