<form [formGroup]="form">
  <div fxLayout="column">
    <!-- Name -->
    <mat-form-field fxFlex>
      <mat-label>Name</mat-label>
      <input matInput type="text" i18n-placeholder placeholder="Name" formControlName="name" required />
    </mat-form-field>
    <!-- Address (aka Street) -->
    <mat-form-field fxFlex>
      <mat-label>Address</mat-label>
      <input matInput type="text" i18n-placeholder placeholder="Street" formControlName="address" required />
    </mat-form-field>
    <!-- Zip Code -->
    <mat-form-field fxFlex>
      <mat-label>Postal Code</mat-label>
      <input matInput type="text" i18n-placeholder placeholder="Postal Code" formControlName="zipCode" required />
    </mat-form-field>
    <!-- City -->
    <mat-form-field fxFlex>
      <mat-label>City</mat-label>
      <input matInput type="text" i18n-placeholder placeholder="City" formControlName="city" required />
    </mat-form-field>
    <!-- State -->
    <mat-form-field fxFlex>
      <mat-label>Region/State</mat-label>
      <input matInput type="text" i18n-placeholder placeholder="Region/State" formControlName="state" />
    </mat-form-field>
    <!-- Country -->
    <mat-form-field fxFlex>
      <mat-label>Country</mat-label>
      <mat-select i18n-placeholder placeholder="Country" formControlName="country" required>
        <mat-option *ngFor="let country of countries" [value]="country">
          {{ country }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</form>
<ng-container *ngIf="changes$ | async"></ng-container>
