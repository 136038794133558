import { inject, Injectable } from '@angular/core';
import { Database, equalTo, limitToFirst, orderByChild, startAt } from '@angular/fire/database';
import { Observable, of } from 'rxjs';

import { AbstractFirebaseList } from './abstract-firebase-list';
import { DatabaseApiService } from './database-api.service';
import { Mapper, metaBottleMapper, MetaBottle, MetaBottleData } from '@whiskybazar/core';

@Injectable()
export class MetaBottlesService extends AbstractFirebaseList<MetaBottle, MetaBottleData> {
  private readonly path = 'meta_bottles';

  private afDb: Database = inject(Database);

  constructor(private dbApi: DatabaseApiService) {
    super();
  }

  getPath(): string {
    return this.path;
  }

  getAngularFireDatabase(): Database {
    return this.afDb;
  }

  getMapper(): Mapper<MetaBottleData, MetaBottle> {
    return metaBottleMapper;
  }

  getDatabaseApiService(): DatabaseApiService {
    return this.dbApi;
  }

  findByBarcode(query: string, exact = true, limit = 10): Observable<MetaBottle[]> {
    return exact
      ? this.query(orderByChild('barcode'), equalTo(query), limitToFirst(limit))
      : this.query(orderByChild('barcode'), startAt(query), limitToFirst(limit));
  }

  findByTitle(query: string, exact = true, limit = 10): Observable<MetaBottle[]> {
    return exact
      ? this.query(orderByChild('title'), equalTo(query), limitToFirst(limit))
      : this.query(orderByChild('title'), startAt(query), limitToFirst(limit));
  }

  create(metaBottle: MetaBottle): Observable<MetaBottle> {
    // do not recreate the data, if it is already created!
    if (metaBottle.id) {
      return of(metaBottle);
    }

    return super.create(metaBottle);
  }
}
