import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuctionFormComponent } from './auction-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CurrencyModule } from '../currency/currency.module';
import { AuctionProfitModule } from '../../pipes';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [AuctionFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    CurrencyModule,
    AuctionProfitModule,
    MatSelectModule,
    MatOptionModule,
    FlexLayoutModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatButtonModule,
  ],
  exports: [AuctionFormComponent],
})
export class AuctionFormModule {}
