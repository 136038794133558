import { AuthUser } from '@whiskybazar/core';
import { AuthAction, AuthActionType } from './../actions';

export interface State {
  loggedIn: boolean;
  user: AuthUser | null;
}

export const initialState: State = {
  loggedIn: false,
  user: null,
};

export function reducer(state = initialState, action: AuthAction): State {
  switch (action.type) {
    case AuthActionType.AuthenticateSuccess:
    case AuthActionType.LoginSuccess:
      return {
        ...state,
        loggedIn: true,
        user: action.user,
      };

    case AuthActionType.Logout: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const getLoggedIn = (state: State) => state.loggedIn;
export const getUser = (state: State) => state.user;
