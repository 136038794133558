import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PackageShopComponent } from './package-shop.component';
import { AddressModule } from '../address/address.module';

@NgModule({
  declarations: [PackageShopComponent],
  imports: [CommonModule, AddressModule],
  exports: [PackageShopComponent],
})
export class PackageShopModule {}
