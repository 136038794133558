import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { BottleEditButtonComponent } from './bottle-edit-button.component';
import { AuthContextModule } from '../../pipes';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [BottleEditButtonComponent],
  imports: [CommonModule, MatIconModule, MatButtonModule, AuthContextModule],
  exports: [BottleEditButtonComponent],
})
export class BottleEditButtonModule {}
