import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CallToPurchaseComponent } from './call-to-purchase.component';
import { CurrencyModule } from '../currency/currency.module';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [CallToPurchaseComponent],
  imports: [CommonModule, CurrencyModule, MatIconModule, FlexLayoutModule],
  exports: [CallToPurchaseComponent],
})
export class CallToPurchaseModule {}
