<div fxLayout="column" fxLayoutAlign="center stretch">
  <div fxFlex="nogrow" class="primary" [fxShow]="image?.promoted">Primary</div>
  <div fxFlex="grow" fxLayoutAlign="center center" class="image">
    <img *ngIf="image$ | async as imageData; else original" [src]="imageData" [class.faded]="pending" />
    <ng-template #original>
      <img *ngIf="!image.base64Data" [appImageThumbnail]="image.servingUrl" [class.faded]="pending" />
      <img *ngIf="image.base64Data" [src]="image.base64Data" [class.faded]="pending" />
    </ng-template>
  </div>
  <div fxFlex="nogrow" fxLayout="row wrap" fxLayoutAlign="end start" fxLayoutGap="4px" *ngIf="!pending">
    <!-- Promote -->
    <div fxFlex="none" *ngIf="admin || permissions?.promote" i18n-matTooltip matTooltip="Promote">
      <button mat-icon-button color="primary" (click)="promote()" [disabled]="image?.promoted">
        <mat-icon>assistant_photo</mat-icon>
      </button>
    </div>
    <!-- Rotate Left -->
    <div fxFlex="none" *ngIf="permissions?.rotate" i18n-matTooltip matTooltip="Rotate left">
      <button mat-icon-button (click)="rotateLeft()"><mat-icon>rotate_left</mat-icon></button>
    </div>
    <!-- Rotate Right -->
    <div fxFlex="none" *ngIf="permissions?.rotate" i18n-matTooltip matTooltip="Rotate right">
      <button mat-icon-button (click)="rotateRight()"><mat-icon>rotate_right</mat-icon></button>
    </div>
    <!-- Delete -->
    <div fxFlex="none" *ngIf="permissions?.delete" i18n-matTooltip matTooltip="Delete">
      <button mat-icon-button color="warn" (click)="delete()"><mat-icon>delete_forever</mat-icon></button>
    </div>
  </div>
  <div class="spinner" fxFlex="nogrow" fxLayout="row" fxLayoutAlign="center center" *ngIf="pending">
    <mat-spinner diameter="26" strokeWidth="2" color="primary"></mat-spinner>
  </div>
</div>
