import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuctionRuntimeModule, BottleStatePipeModule, TransactionStateModule, AuthContextModule } from '../../pipes';
import { BottleStateComponent } from './bottle-state.component';
import { DateFnsModule } from 'ngx-date-fns';
import { AuctionCountdownModule } from '../auction-countdown/auction-countdown.module';

@NgModule({
  declarations: [BottleStateComponent],
  imports: [
    CommonModule,
    TransactionStateModule,
    BottleStatePipeModule,
    AuctionRuntimeModule,
    AuctionCountdownModule,
    AuthContextModule,
    DateFnsModule,
  ],
  exports: [BottleStateComponent],
})
export class BottleStateModule {}
