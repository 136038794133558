<form>
  <mat-form-field class="w-full" appearance="fill">
    <mat-label>{{ label }}</mat-label>
    <mat-chip-grid #chipGrid [attr.aria-label]="label + ' selection'">
      <mat-chip-row *ngFor="let s of selection" (removed)="remove(s)">
        {{ displayFn(s) }}
        <button matChipRemove [attr.aria-label]="'remove ' + s">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
    </mat-chip-grid>
    <input
      [placeholder]="placeholder"
      #input
      [formControl]="ctrl"
      [matChipInputFor]="chipGrid"
      [matAutocomplete]="auto"
      [matChipInputSeparatorKeyCodes]="SEPARATOR_KEYS_CODES"
      (matChipInputTokenEnd)="add($event)"
    />
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let option of options$ | async" [value]="option">
        {{ displayFn(option) }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>

<!-- NOTE: this is only to safely unsubscribe -->
<ng-template *ngIf="selection$ | async"></ng-template>
