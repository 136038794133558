import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, mapTo, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

import {
  Bottle,
  OpenAuctionDocument,
  buildMultiValueFilters,
  buildRangeFilters,
  SearchResult,
} from '@whiskybazar/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SearchService } from '@whiskybazar/pwa/core/services';
import {
  loadBottles,
  loadBottlesSuccess,
  loadMoreBottles,
  loadMoreBottlesSuccess,
  reloadBottles,
  reloadBottlesSuccess,
  searchBottles,
  searchBottlesSuccess,
  resetFilters,
  searchBottlesError,
} from '../actions';
import * as fromStore from '../reducers';

const BATCH_SIZE = 20;

@Injectable()
export class LandingPageEffects {
  loadBottles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadBottles),
      switchMap(() => this.load(BATCH_SIZE).pipe(map((result) => loadBottlesSuccess({ result }))))
    )
  );

  reloadBottles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reloadBottles),
      switchMap(() => this.load(BATCH_SIZE).pipe(map((result) => reloadBottlesSuccess({ result }))))
    )
  );

  loadMoreBottles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMoreBottles),
      withLatestFrom(this.store$.select(fromStore.getBottlesCount), (_, count: number) => count),
      switchMap((count: number) =>
        this.load(count + BATCH_SIZE).pipe(map((result) => loadMoreBottlesSuccess({ result })))
      )
    )
  );

  searchBottles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchBottles, resetFilters),
      debounceTime(300),
      withLatestFrom(this.store$.select(fromStore.selectLandingPageState), (_, state) => ({ state })),
      switchMap(({ state: { query, facets, sorting } }) =>
        this.searchService
          .searchInOpenAuctions({
            q: query,
            filters: [
              ...buildMultiValueFilters<OpenAuctionDocument>({
                distillery: facets.distilleries.selection ?? [],
                bottler: facets.bottlers.selection ?? [],
                region: facets.regions.selection ?? [],
                type: facets.types.selection ?? [],
              }),
              ...buildRangeFilters<OpenAuctionDocument>({
                price: facets.price.selection ?? [],
              }),
            ],
            sorting: [sorting],
          })
          .pipe(
            map((result) => searchBottlesSuccess({ result })),
            catchError((error) => {
              console.error(error);

              return of(
                searchBottlesError({ error: (error.message as string) ?? 'An unknown error occurred while searching!' })
              );
            })
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store$: Store<fromStore.State>,
    private searchService: SearchService
  ) {}

  private load(count: number): Observable<SearchResult<Bottle>> {
    return this.searchService.searchInOpenAuctions({ limit: count }).pipe(takeUntil(this.loadMore()));
  }

  private loadMore(): Observable<boolean> {
    return this.actions$.pipe(ofType(loadMoreBottles), mapTo(true));
  }
}
