import { Injectable } from '@angular/core';

import {
  AUCTION_STATES,
  AuthUser,
  Bid,
  Bottle,
  BOTTLE_STATES,
  Dealer,
  EditImagePermissions,
  isShipmentBookingManual,
  TRANSACTION_STATES,
} from '@whiskybazar/core';

@Injectable()
export class AuthContextService {
  private _auth: AuthUser = null;

  getAuth(): AuthUser | null {
    return this._auth;
  }

  setAuth(auth: AuthUser | null) {
    this._auth = auth;
  }

  auth(): boolean {
    return !!this._auth;
  }

  noAuth(): boolean {
    return !this._auth;
  }

  hasVerifiedPhoneNumber(): boolean {
    return this._auth ? this._auth.phoneVerified : false;
  }

  hasAnyBid(bottle: Bottle): boolean {
    if (!bottle || !bottle.auction || !bottle.auction.bids || !this._auth) {
      return false;
    }

    return !!bottle.auction.bids.find((bid) => bid.bidderId === this._auth.id);
  }

  hasBestBid(bottle: Bottle): boolean {
    if (!bottle.auction || !bottle.auction.bestBid || !this._auth) {
      return false;
    }

    return bottle.auction.bestBid.bidderId === this._auth.id;
  }

  getMaxBid(bottle: Bottle): number {
    return this.hasBestBid(bottle)
      ? bottle.auction.bestBid.maxBid
        ? bottle.auction.bestBid.maxBid.amount
        : bottle.auction.bestBid.amount
      : this.findMaxBid(bottle);
  }

  canBid(bottle: Bottle): boolean {
    if (!bottle.auction || !this._auth || this.isAdmin()) {
      return false;
    }

    // TODO: remove below once dealers are allowed to buy in auctions
    if (this.isDealer()) {
      return false;
    }

    // auctioneer and owner of the bottle can not bid
    return !(bottle.auction.auctioneerId === this._auth.id || bottle.ownerId === this._auth.id);
  }

  isAuctioneer(bottle: Bottle): boolean {
    if (!this._auth) {
      return false;
    }

    return bottle?.auction?.auctioneerId === this._auth?.id;
  }

  hasWonAuction(bottle: Bottle): boolean {
    return this.canBid(bottle) && this.hasBestBid(bottle);
  }

  hasLostAuction(bottle: Bottle): boolean {
    if (this.hasBestBid(bottle) || !this.canBid(bottle)) {
      return false;
    }

    if (!bottle.auction || !bottle.auction.bids) {
      return false;
    }

    return bottle.auction.bids.filter((b: Bid) => b.bidderId === this._auth.id).length > 0;
  }

  shipmentBooked(bottle: Bottle): boolean {
    if (!bottle.auction || !bottle.auction.transaction) {
      return false;
    }

    const invoice = bottle.auction.transaction.buyerInvoice;
    if (!invoice) {
      return false;
    }

    // shipment is booked, if the buyer invoice has a shipment route
    return !!invoice.shipmentRoute;
  }

  transactionCompleted(bottle: Bottle): boolean {
    if (!bottle.auction || !bottle.auction.transaction) {
      return true;
    }

    return bottle.auction.transaction.state === TRANSACTION_STATES.COMPLETED.id;
  }

  isCollector(): boolean {
    return this._auth ? this._auth.type === 'collector' : false;
  }

  isDealer(): boolean {
    return this._auth ? this._auth.type === 'dealer' : false;
  }

  isOwner(bottle: Bottle): boolean {
    if (!this._auth) {
      return false;
    }

    return this._auth.id === bottle.ownerId;
  }

  canView(bottle: Bottle): boolean {
    return this.isOwner(bottle) || this.isAuctioneer(bottle);
  }

  isPurchasable(bottle: Bottle): boolean {
    return (
      [
        BOTTLE_STATES.IN_AUCTION.id,
        BOTTLE_STATES.PENDING_AUCTION_END.id,
        BOTTLE_STATES.FOR_SALE.id,
        BOTTLE_STATES.ON_SALE.id,
      ].includes(bottle.state) &&
      !this.isOwner(bottle) &&
      !this.isAuctioneer(bottle)
    );
  }

  needsAttention(bottle: Bottle): boolean {
    // Auctioneer must pay attention to bottles that are pending acceptence and
    // pending shipping
    let state = [BOTTLE_STATES.PENDING_AUCTIONEER_ACCEPTANCE.id, BOTTLE_STATES.PENDING_SHIPPING.id].find(
      (s: string) => s === bottle.state
    );
    if (state && this.isAuctioneer(bottle)) {
      return true;
    }

    // Seller must pay attention to bottles that are pending delivery to auctioneer
    state = [BOTTLE_STATES.PENDING_DELIVERY_TO_AUCTIONEER.id].find((s: string) => s === bottle.state);
    if (state && this.isOwner(bottle)) {
      return true;
    }

    // Buyer must pay attention to bottles that are in transaction
    state = [BOTTLE_STATES.IN_TRANSACTION.id].find((s: string) => s === bottle.state);
    if (state && this.isBuyer(bottle)) {
      return true;
    }

    return false;
  }

  isBuyer(bottle: Bottle): boolean {
    if (!this._auth) {
      return false;
    }

    if (!bottle || !bottle.auction || !bottle.auction.transaction) {
      return false;
    }

    return bottle.auction.transaction.buyerId === this._auth.id;
  }

  isSeller(bottle: Bottle): boolean {
    if (!this._auth) {
      return false;
    }

    if (!bottle) {
      return false;
    }

    return (
      (!!bottle.auction && !!bottle.auction.transaction && bottle.auction.transaction.sellerId === this._auth.id) ||
      this.isOwner(bottle)
    );
  }

  isAdmin(): boolean {
    return this._auth ? this._auth.type === 'admin' : false;
  }

  canEdit(bottle: Bottle): boolean {
    if (!bottle) {
      return false;
    }

    const isInEditableState = [
      BOTTLE_STATES.IN_PRIVATE_COLLECTION.id,
      BOTTLE_STATES.IN_PUBLIC_COLLECTION.id,
      BOTTLE_STATES.PENDING_AUCTIONEER_ACCEPTANCE.id,
      BOTTLE_STATES.PENDING_DELIVERY_TO_AUCTIONEER.id,
      BOTTLE_STATES.PENDING_AUCTION_START.id,
    ].some((state) => state === bottle.state);

    return (
      (isInEditableState && (this.isOwner(bottle) || this.isAuctioneer(bottle))) ||
      (this.isAuctioneer(bottle) && bottle.state === BOTTLE_STATES.IN_AUCTION.id)
    );
  }

  canDelete(bottle: Bottle): boolean {
    if (!bottle) {
      return false;
    }

    const isInDeletableState = [
      BOTTLE_STATES.DRAFT.id,
      BOTTLE_STATES.IN_PRIVATE_COLLECTION.id,
      BOTTLE_STATES.IN_PUBLIC_COLLECTION.id,
    ].some((state) => state === bottle.state);

    return isInDeletableState && this.isOwner(bottle);
  }

  canEditMetaBottle(bottle: Bottle): boolean {
    return [
      // The bottle has to be valid
      !!bottle,
      // There must be an authenticated user
      !!this._auth,
      // The meta-bottle must be defined and not approved
      bottle.metaBottle && !bottle.metaBottle.approved,
      // The authenticated user must be the owner or the auctioneer
      this.isOwner(bottle) || this.isAuctioneer(bottle),
    ].every((value: boolean) => value === true);
  }

  calcEditImagePermissions(bottle: Bottle): EditImagePermissions {
    return {
      delete: true,
      rotate: true,
      promote: this.isAdmin() || this.isOwner(bottle) || this.canEditMetaBottle(bottle),
    };
  }

  getDealer(bottle: Bottle): Dealer | null {
    if (!bottle) {
      return null;
    }

    const dealer = bottle.auction ? bottle.auction.auctioneer : bottle.sale ? bottle.sale.dealer : null;

    return dealer as Dealer;
  }

  getDealerId(bottle: Bottle): string | null {
    const dealer = this.getDealer(bottle);

    return dealer ? dealer.id : null;
  }

  hasNumbering(bottle: Bottle): boolean {
    if (!bottle || !bottle.metaBottle) {
      return false;
    }

    return bottle.metaBottle.numberOfBottles > 0 && (bottle.itemNumber > 0 || bottle.state === BOTTLE_STATES.DRAFT.id);
  }

  isAuctionRejected(bottle: Bottle): boolean {
    if (!bottle || !bottle.auction) {
      return false;
    }

    return bottle.auction.state === AUCTION_STATES.REJECTED.id;
  }

  getTotalBidsCount(bottle: Bottle): number {
    if (!bottle || !bottle.auction) {
      return 0;
    }

    return bottle.auction.bidIds ? bottle.auction.bidIds.length : 0;
  }

  getHammerPrice(bottle: Bottle): number {
    if (!bottle || !bottle.auction) {
      return 0;
    }

    return bottle.auction.bestBid ? bottle.auction.bestBid.amount : 0;
  }

  isAuctioneerOrOwner(bottle: Bottle): boolean {
    return this.isAuctioneer(bottle) || this.isOwner(bottle);
  }

  hasWonOrLost(bottle: Bottle): boolean {
    return this.hasWonAuction(bottle) || this.hasLostAuction(bottle);
  }

  isAuctioneerOrSeller(bottle: Bottle): boolean {
    return this.isAuctioneer(bottle) || this.isSeller(bottle);
  }

  hasTransaction(bottle: Bottle): boolean {
    return !!bottle && !!bottle.auction && !!bottle.auction.transaction;
  }

  getBuyerPaymentDueDate(bottle: Bottle): Date | null {
    if (!this.isBuyer(bottle)) {
      return null;
    }

    const buyerInvoice = bottle.auction.transaction.buyerInvoice;
    if (!buyerInvoice || !buyerInvoice.paymentDueAt) {
      return null;
    }

    return buyerInvoice.paymentDueAt;
  }

  getShippingDueDate(bottle: Bottle): Date | null {
    if (!this.hasTransaction(bottle)) {
      return null;
    }

    const transaction = bottle.auction.transaction;
    if (!transaction || !transaction.shippingDueAt) {
      return null;
    }

    return transaction.shippingDueAt;
  }

  getPickupDueDate(bottle: Bottle): Date | null {
    if (!this.hasTransaction(bottle)) {
      return null;
    }

    const transaction = bottle.auction.transaction;
    if (!transaction || !transaction.pickupDueAt) {
      return null;
    }

    return transaction.pickupDueAt;
  }

  getBuyerRightOfWithdrawalExpirationDate(bottle: Bottle): Date | null {
    if (!this.isBuyer(bottle)) {
      return null;
    }

    const buyerInvoice = bottle.auction.transaction.buyerInvoice;
    if (!buyerInvoice || !buyerInvoice.rightOfWithdrawalExpiresAt) {
      return null;
    }

    return buyerInvoice.rightOfWithdrawalExpiresAt;
  }

  getExpectedSettlementDate(bottle: Bottle): Date | null {
    if (this.isSeller(bottle)) {
      return bottle.auction.transaction.sellerInvoice.expectedSettlementAt;
    } else if (this.isAuctioneer(bottle)) {
      return bottle.auction.transaction.auctioneerInvoice.expectedSettlementAt;
    }

    return null;
  }

  getDetailsPath(bottle: Bottle): string {
    if (this.isOwner(bottle)) {
      return `/collector/collection/${bottle.id}`;
    } else if (this.isAuctioneer(bottle)) {
      return `/dealer/auctions/${bottle.id}`;
    } else if (this.isAdmin()) {
      return `/admin/bottles/${bottle.id}`;
    }

    return `/bottle/${bottle.id}`;
  }

  getExpectedDeliveryToBuyerDate(bottle: Bottle): Date | null {
    if (!bottle || !bottle.auction || !bottle.auction.transaction || !bottle.auction.transaction.shippingDueAt) {
      return null;
    }

    // TODO: should be refactored to constants and moved to backend logic
    const shippingDueDate = bottle.auction.transaction.shippingDueAt;
    return new Date(shippingDueDate.setDate(shippingDueDate.getDate() + 7));
  }

  getSettlementDate(bottle: Bottle): Date | null {
    if (this.isSeller(bottle)) {
      return bottle.auction.transaction.sellerInvoice.settledAt;
    } else if (this.isAuctioneer(bottle)) {
      return bottle.auction.transaction.auctioneerInvoice.settledAt;
    }

    return null;
  }

  isShipmentBookingManual(bottle: Bottle): boolean {
    if (!bottle.auction || !bottle.auction.transaction) {
      return false;
    }

    const invoice = bottle.auction.transaction.buyerInvoice;
    if (!invoice) {
      return false;
    }

    if (this.shipmentBooked(bottle)) {
      return false;
    }

    return isShipmentBookingManual(invoice.shippingProductId);
  }

  protected findMaxBid(bottle: Bottle): number {
    const bids = bottle.auction.bids.filter((b: Bid) => b.bidderId === this._auth.id);

    // Find the highest max bid, i.e. max max bid, among the bids
    const highestMaxBid = bids
      .filter((b: Bid) => !!b.maxBid)
      .reduce((p: Bid, c: Bid) => (p.maxBid.amount >= c.maxBid.amount ? p : c), {
        id: null,
        maxBid: { id: null, amount: 0 },
      });

    return bids.reduce((p: Bid, c: Bid) => (p.amount >= c.amount ? p : c), {
      id: null,
      amount: highestMaxBid.maxBid.amount,
    }).amount;
  }
}
