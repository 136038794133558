import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { Payment } from '@whiskybazar/core';
import { environment } from '@whiskybazar/pwa/environment';

interface PaymentLinkResponse {
  id?: string;
  url?: string;
  code?: string;
}

interface PaymentStatusResponse extends Payment {
  code?: string;
}

@Injectable()
export class PaymentService {
  private readonly base = '/v1/payments';
  private readonly endpoints = {
    link: '/link/:transactionId',
    status: '/:id/status',
  };

  constructor(private http: HttpClient) {}

  getPaymentLink(transactionId: string): Observable<Payment> {
    const url = this.buildUrl(this.endpoints.link, { ':transactionId': transactionId }) + '?framed=1';

    return this.http
      .get<PaymentLinkResponse>(url)
      .pipe(
        switchMap((res: PaymentLinkResponse) =>
          res.code ? throwError(res.code) : of({ id: res.id, link: res.url, status: 'PENDING' } as Payment)
        )
      );
  }

  getPaymentStatus(paymentId: string): Observable<Payment> {
    const url = this.buildUrl(this.endpoints.status, { ':id': paymentId });

    return this.http
      .get<PaymentStatusResponse>(url)
      .pipe(switchMap((res: PaymentStatusResponse) => (res.code ? throwError(res.code) : of(res as Payment))));
  }

  protected buildUrl(endpoint: string, params?: { [key: string]: string }): string {
    let url = `${environment.cloudFunctions}${this.base}${endpoint}`;
    if (params) {
      Object.keys(params).forEach((k: string) => (url = url.replace(k, params[k])));
    }

    return url;
  }
}
