<div fxLayout="row" fxLayoutAlign="center center">
  <div fxFlex="grow" fxLayout="column" fxLayoutAlign="center center">
    <div fxFlex="none" class="mat-small">
      <ng-content></ng-content>
    </div>
    <div fxFlex="none" class="mat-h1">
      <app-currency [amount]="getPrice()"></app-currency>
    </div>
    <div class="icon" fxLayout="column" fxLayoutAlign="center center">
      <mat-icon fxFlex="none">keyboard_arrow_right</mat-icon>
    </div>
  </div>
</div>
