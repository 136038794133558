<mat-nav-list>
  <ng-container *ngIf="!loggedIn">
    <a mat-list-item routerLink="login" i18n>
      <mat-icon matListIcon>lock_open</mat-icon>
      Login
    </a>
    <a mat-list-item routerLink="signup" i18n>
      <mat-icon matListIcon>person_add</mat-icon>
      Signup
    </a>
  </ng-container>

  <ng-container *ngIf="loggedIn">
    <ng-container [ngSwitch]="auth.type">
      <!-- Admin Actions -->
      <ng-container *ngSwitchCase="'admin'">
        <ng-container *ngFor="let nav of navService.adminNav">
          <a mat-list-item [routerLink]="nav.link">
            <mat-icon matListIcon>{{ nav.icon }}</mat-icon>
            <span>{{ nav.label }}</span>
          </a>
        </ng-container>
      </ng-container>

      <!-- Collector Actions -->
      <ng-container *ngSwitchCase="'collector'">
        <ng-container *ngFor="let nav of navService.collectorNav">
          <a mat-list-item [routerLink]="nav.link">
            <mat-icon matListIcon>{{ nav.icon }}</mat-icon>
            <span>{{ nav.label }}</span>
          </a>
        </ng-container>
      </ng-container>

      <!-- Dealer Actions -->
      <ng-container *ngSwitchCase="'dealer'">
        <ng-container *ngFor="let nav of navService.dealerNav">
          <a mat-list-item [routerLink]="nav.link">
            <mat-icon matListIcon>{{ nav.icon }}</mat-icon>
            <span>{{ nav.label }}</span>
          </a>
        </ng-container>
      </ng-container>
    </ng-container>

    <mat-divider></mat-divider>

    <a mat-list-item (click)="_logout()">
      <mat-icon matListIcon>keyboard_backspace</mat-icon>
      <span i18n>Logout</span>
    </a>
  </ng-container>

  <mat-divider></mat-divider>

  <ng-container *ngFor="let nav of navService.defaultNav">
    <a
      mat-list-item
      [routerLink]="nav.link"
      [fragment]="nav?.extras?.fragment"
      *ngIf="!auth || auth.type === 'collector'"
    >
      {{ nav.label }}
    </a>
  </ng-container>
</mat-nav-list>
