import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PageGuard } from './guards';
import { StaticPageComponent } from './containers';

const routes: Routes = [
  {
    path: 'terms',
    component: StaticPageComponent,
    canActivate: [PageGuard],
    data: {
      pageId: 'terms',
      title: 'Terms & Conditions',
    },
  },

  {
    path: 'privacy',
    component: StaticPageComponent,
    canActivate: [PageGuard],
    data: {
      pageId: 'privacy',
      title: 'Privacy Policy',
    },
  },

  {
    path: 'about',
    component: StaticPageComponent,
    canActivate: [PageGuard],
    data: {
      pageId: 'about',
      title: 'About',
    },
  },

  {
    path: 'contact',
    component: StaticPageComponent,
    canActivate: [PageGuard],
    data: {
      pageId: 'contact',
      title: 'Contact',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class StaticsRoutingModule {}
