<div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="32px">
  <div fxFlex="none" *ngIf="full">
    <h1 class="text-center">Auctioneer Invoice</h1>
  </div>

  <div fxFlex="none" fxLayout="row" fxLayoutGap="32px" *ngIf="full">
    <!-- WhiskyBazar.com -->
    <div fxFlex="50%" fxLayout="row" fxLayoutAlign="start start">
      <div fxFlex="none">
        <h2>WhiskyBazar.com</h2>
        <app-address [address]="whiskyBazar" [full]="true" [crn]="whiskyBazar.crn"></app-address>
      </div>
    </div>
    <!-- Auctioneer -->
    <div fxFlex="50%" fxLayout="row" fxLayoutAlign="end start">
      <div fxFlex="none">
        <h2>Auctioneer</h2>
        <app-address [address]="billing" [full]="true" [crn]="auctioneer.companyNumber"></app-address>
      </div>
    </div>
  </div>

  <div fxFlex="none" fxLayout="column" fxLayoutGap="16px">
    <div fxFlex="none" fxLayout="column" fxLayoutAlign="start end">
      <!-- Invoice ID -->
      <div fxFlex="none"><strong>Invoice ID:</strong> {{ invoice?.invoiceId }}</div>
      <!-- Invoice Date -->
      <div fxFlex="none"><strong>Invoice Date:</strong> {{ transaction?.createdAt | date }}</div>
      <!-- Payment Date -->
      <div fxFlex="none" *ngIf="invoice.payment">
        <strong>Payment Date:</strong> {{ invoice?.payment?.authorizedAt | date }}
      </div>
      <!-- Payment Method -->
      <div fxFlex="none" *ngIf="invoice.payment"><strong>Payment Method:</strong> {{ invoice?.payment?.method }}</div>
    </div>

    <!-- Details -->
    <div fxFlex="none" fxLayout="row" fxLayoutGap="32px">
      <table border="0" cellpadding="0" cellspacing="0">
        <thead>
          <tr>
            <th>Item</th>
            <th style="min-width: 64px">Amount</th>
          </tr>
        </thead>
        <tbody>
          <!-- Variable Profit -->
          <tr>
            <!-- Details -->
            <td>
              <strong>{{ auctioneerVariableProfit | percent }} profit in auction on WhiskyBazar.com</strong>
              <br />
              <small>LOT:&nbsp;{{ bottle?.auction?.auctionId }}</small>
              <br />
              <small>Winning bid:&nbsp;<app-currency [amount]="invoice?.details?.hammerPrice"></app-currency></small>
            </td>
            <!-- Amount -->
            <td>
              <app-currency [amount]="invoice?.details?.variableProfit"></app-currency>
            </td>
          </tr>
          <!-- Fixed Profit -->
          <tr>
            <!-- Details -->
            <td class="bb">
              <strong>Fixed profit in auction on WhiskyBazar.com</strong>
              <br />
              <small>LOT:&nbsp;{{ bottle?.auction?.auctionId }}</small>
            </td>
            <!-- Amount -->
            <td class="bb">
              <app-currency [amount]="invoice?.details?.fixedProfit"></app-currency>
            </td>
          </tr>
          <!-- Subtotal -->
          <tr>
            <td class="text-right text-bold">Subtotal:</td>
            <td>
              <app-currency [amount]="calcSubtotal()"></app-currency>
            </td>
          </tr>
          <!-- VAT -->
          <tr>
            <td class="text-right">
              <span class="text-bold">VAT:&nbsp;</span>
              <small>({{ invoice?.vatRate | percent }})</small>
            </td>
            <td>
              <app-currency [amount]="invoice.vatAmount"></app-currency>
            </td>
          </tr>
          <!-- TOTAL -->
          <tr>
            <td class="text-right text-bold" style="border-top: 2px solid #000">TOTAL:</td>
            <td style="border-top: 2px solid #000">
              <app-currency [amount]="calcTotal()"></app-currency>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
