import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxPicaResizeOptionsInterface, NgxPicaService } from '@digitalascetic/ngx-pica';
import { forkJoin, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

import { Image } from '@whiskybazar/core';
import { ImageTools } from '@whiskybazar/pwa/utils';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
})
export class ImageUploaderComponent implements OnInit {
  @Input() uploads: Image[];

  @Input() uploading: boolean;

  @Output() readonly uploaded: EventEmitter<Image[]>;

  @Output() readonly errored: EventEmitter<string>;

  pending: boolean;

  constructor(private pica: NgxPicaService) {
    this.uploads = [];
    this.uploading = false;
    this.uploaded = new EventEmitter<Image[]>();
    this.errored = new EventEmitter<string>();
    this.pending = false;
  }

  ngOnInit() {}

  uploadFiles(files: FileList): void {
    if (!files || files.length === 0) {
      return;
    }

    this.pending = true;

    const files$: Observable<Image>[] = [];
    for (let i = 0; i < files.length; i++) {
      const file: File = files.item(i);
      files$.push(this.readFile(file));
    }

    forkJoin(files$)
      .pipe(
        take(1),
        tap(() => (this.pending = false))
      )
      .subscribe(
        (result: Image[]) => this.uploaded.emit(result),
        (err) => this.errored.emit(err)
      );
  }

  onDragDropError(err: string) {
    this.errored.emit(err);
  }

  protected readFile(file: File): Observable<Image> {
    const options: NgxPicaResizeOptionsInterface = {
      exifOptions: {
        forceExifOrientation: true,
      },
      aspectRatio: {
        keepAspectRatio: true,
      },
    };

    return this.pica.resizeImage(file, 1200, 1200, options).pipe(
      take(1),
      switchMap((resized: File) =>
        ImageTools.getBase64(resized).pipe(map((result: string) => this.createUploadImage(result, resized)))
      )
    );
  }

  private createUploadImage(base64Data: string, file: File): Image {
    return {
      id: null,
      servingUrl: base64Data,
      base64Data,
      file,
      uploadPercent: 0,
    } as Image;
  }
}
