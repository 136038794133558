import { Component, OnInit, Input } from '@angular/core';

import { AuthContextService } from '@whiskybazar/pwa/core/services';
import { Bottle, States, StatesObject } from '@whiskybazar/core';
import { BOTTLE_STATES } from '@whiskybazar/core';
import { toStates } from '@whiskybazar/pwa/utils';

@Component({
  selector: 'app-bottle-preview',
  templateUrl: './bottle-preview.component.html',
  styleUrls: ['./bottle-preview.component.scss'],
})
export class BottlePreviewComponent implements OnInit {
  @Input() bottle: Bottle;

  readonly bottleStates: States = toStates(BOTTLE_STATES as StatesObject);

  constructor(public ctx: AuthContextService) {}

  ngOnInit() {}
}

@Component({
  selector: 'app-bottle-preview-header',
  template: ` <ng-content></ng-content> `,
})
export class BottlePreviewHeaderComponent {}

@Component({
  selector: 'app-bottle-preview-footer',
  template: ` <ng-content></ng-content> `,
})
export class BottlePreviewFooterComponent {}

@Component({
  selector: 'app-bottle-preview-action',
  template: ` <ng-content></ng-content> `,
})
export class BottlePreviewActionComponent {}
