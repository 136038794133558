import { ActionReducer } from '@ngrx/store';

import * as AuthActions from '../actions';
import * as fromAuth from './auth.reducers';
import * as fromForgotPass from './forgot-pass.reducers';
import * as fromLoginPage from './login-page.reducers';
import * as fromPhoneVerification from './phone-verification.reducer';

// Reset auth states on logout
export function resetAuth(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state: any, action: any) {
    if (action.type === AuthActions.AuthActionType.Logout) {
      return {
        status: fromAuth.initialState,
        loginPage: fromLoginPage.initialState,
        forgotPass: fromForgotPass.initialState,
        phoneVerification: fromPhoneVerification.initialState,
      };
    }

    return reducer(state, action);
  };
}

// Cross-states updates on post-auth
export function postAuth(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state: any, action: any) {
    const isAuthenticated =
      action.type === AuthActions.AuthActionType.LoginSuccess ||
      action.type === AuthActions.AuthActionType.AuthenticateSuccess;
    let _state = state;
    if (isAuthenticated) {
      _state = {
        ...state,
        phoneVerification: {
          ...state.phoneVerification,
          verified: action.user.type === 'collector' && action.user.phoneVerified,
        },
      };
    }

    return reducer(_state, action);
  };
}

export const metaReducersList = [resetAuth, postAuth];
