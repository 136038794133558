import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { BottleAttrModule } from '../../pipes';
import { AddressModule } from '../address/address.module';
import { CurrencyModule } from '../currency/currency.module';
import { BuyerInvoiceComponent } from './buyer-invoice.component';

@NgModule({
  declarations: [BuyerInvoiceComponent],
  imports: [CommonModule, AddressModule, BottleAttrModule, CurrencyModule, FlexLayoutModule],
  exports: [BuyerInvoiceComponent],
})
export class BuyerInvoiceModule {}
