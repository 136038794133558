import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import * as fromAuthStore from '@whiskybazar/pwa/auth/store';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  pending$: Observable<boolean>;
  error$: Observable<string>;

  constructor(private store: Store<fromAuthStore.AuthState>) {}

  ngOnInit() {
    this.pending$ = this.store.select(fromAuthStore.getLoginPagePending);
    this.error$ = this.store
      .select(fromAuthStore.getLoginPageError)
      .pipe(map((error: any) => (error ? 'Invalid credentials!' : null)));
  }

  onSubmit($event: { email: string; password: string }) {
    this.store.dispatch(new fromAuthStore.Login($event.email, $event.password));
  }
}
