import { AuthGuard } from './auth.guard';
import { AdminGuard } from './admin.guard';
import { CollectorGuard } from './collector.guard';
import { DealerGuard } from './dealer.guard';
import { SignupGuard } from './signup.guard';
import { LoginGuard } from './login.guard';

export const guards = [AuthGuard, AdminGuard, CollectorGuard, DealerGuard, SignupGuard, LoginGuard];

export * from './auth.guard';
export * from './admin.guard';
export * from './collector.guard';
export * from './dealer.guard';
export * from './signup.guard';
export * from './login.guard';
