import { Component } from '@angular/core';

import { BottlePreviewComponent } from '../bottle-preview/bottle-preview.component';

@Component({
  selector: 'app-bottle-tile',
  templateUrl: './bottle-tile.component.html',
  styleUrls: ['./bottle-tile.component.scss'],
})
export class BottleTileComponent extends BottlePreviewComponent {}

@Component({
  selector: 'app-bottle-tile-header',
  template: `<ng-content></ng-content>`,
})
export class BottleTileHeaderComponent {}

@Component({
  selector: 'app-bottle-tile-action',
  template: `<ng-content></ng-content>`,
})
export class BottleTileActionComponent {}
