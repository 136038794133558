<div *ngIf="ended$ | async as ended; else countdown">Ended</div>
<ng-template #countdown>
  <div *ngIf="countdowns$ | async as countdowns">
    <span *ngFor="let countdown of countdowns; trackBy: trackByFn">
      <ng-container [ngSwitch]="countdown.unit.name">
        <ng-container *ngSwitchCase="unitNames.days">
          {{ countdown.time | dfnsDifferenceInDays : now }}{{ countdown.unit.suffix }}
        </ng-container>
        <ng-container *ngSwitchCase="unitNames.hours">
          {{ countdown.time | dfnsDifferenceInHours : now }}{{ countdown.unit.suffix }}
        </ng-container>
        <ng-container *ngSwitchCase="unitNames.minutes">
          {{ countdown.time | dfnsDifferenceInMinutes : now }}{{ countdown.unit.suffix }}
        </ng-container>
        <ng-container *ngSwitchCase="unitNames.seconds">
          {{ countdown.time | dfnsDifferenceInSeconds : now }}{{ countdown.unit.suffix }}
        </ng-container>
      </ng-container>
    </span>
    {{ suffix }}
  </div>
</ng-template>
