import { createAction, props } from '@ngrx/store';

import { Category } from '@whiskybazar/pwa/categories/models';

export const fetchCategories = createAction('[Categories/Category Page] Fetch Categories');

export const selectCategory = createAction('[Categories/Category Page] Select Category', props<{ id: string }>());

export const addCategories = createAction(
  '[Categories/Category Page] Add Categories',
  props<{ categories: Category[] }>()
);

export const clearCategories = createAction('[Categories/Category Page] Clear Categories');
