import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AuctionRuntimeModule,
  BottleDealerPipeModule,
  BottlePromotedImageModule,
  AuthContextModule,
  BottleConditionModule,
  BottleLotModule,
} from '../../pipes';
import { BottleDealerModule } from '../bottle-dealer/bottle-dealer.module';
import {
  BottlePreviewComponent,
  BottlePreviewHeaderComponent,
  BottlePreviewFooterComponent,
  BottlePreviewActionComponent,
} from './bottle-preview.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CurrencyModule } from '../currency/currency.module';
import { MatCardModule } from '@angular/material/card';
import { BottleStateModule } from '../bottle-state/bottle-state.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BottleDeleteButtonModule } from '../bottle-delete-button/bottle-delete-button.module';
import { ImageThumbnailModule } from '../../directives/image-thumbnail/image-thumbnail.module';

@NgModule({
  declarations: [
    BottlePreviewComponent,
    BottlePreviewHeaderComponent,
    BottlePreviewFooterComponent,
    BottlePreviewActionComponent,
  ],
  imports: [
    CommonModule,
    AuctionRuntimeModule,
    BottlePromotedImageModule,
    BottleDealerModule,
    BottleDealerPipeModule,
    FlexLayoutModule,
    AuthContextModule,
    BottleConditionModule,
    CurrencyModule,
    MatCardModule,
    ImageThumbnailModule,
    BottleLotModule,
    BottleStateModule,
    MatButtonModule,
    MatIconModule,
    BottleDeleteButtonModule,
  ],
  exports: [
    BottlePreviewComponent,
    BottlePreviewHeaderComponent,
    BottlePreviewFooterComponent,
    BottlePreviewActionComponent,
  ],
})
export class BottlePreviewModule {}
