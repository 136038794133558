import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { StaticsRoutingModule } from './statics-routing.module';
import { components } from './components';
import { containers } from './containers';
import { guards } from './guards';
import { reducers } from './store/reducers';
import { effects } from './store/effects';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    StaticsRoutingModule,
    StoreModule.forFeature('statics', reducers),
    EffectsModule.forFeature(effects),
  ],
  declarations: [...components, ...containers],
  providers: [...guards],
})
export class StaticsModule {}
