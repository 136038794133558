import { inject, Injectable, Injector } from '@angular/core';
import { Database } from '@angular/fire/database';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AbstractFirebaseList } from './abstract-firebase-list';
import { Mapper, saleMapper, Sale, SaleData, Dealer } from '@whiskybazar/core';
import { DealersService } from './dealers.service';

export interface SaleExpansionConfig {
  dealer?: boolean;
  transaction?: boolean;
}

@Injectable()
export class SalesService extends AbstractFirebaseList<Sale, SaleData> {
  private readonly path = 'sales';

  private dealersService: DealersService;

  private afDb: Database = inject(Database);

  constructor(private di: Injector) {
    super();

    // take care of circular dependency injection
    setTimeout(() => this.injectServices());
  }

  getPath(): string {
    return this.path;
  }

  getAngularFireDatabase(): Database {
    return this.afDb;
  }

  getMapper(): Mapper<SaleData, Sale> {
    return saleMapper;
  }

  fetchById(id: string, expansionConfig?: SaleExpansionConfig): Observable<Sale> {
    if (!expansionConfig) {
      return super.fetchById(id);
    }

    return super.fetchById(id).pipe(switchMap((sale: Sale) => this.expandSale(sale, expansionConfig)));
  }

  protected expandSale(sale: Sale, expansionConfig: SaleExpansionConfig): Observable<Sale> {
    let result: Observable<Sale> = of(sale);

    if (expansionConfig.dealer) {
      result = result.pipe(switchMap((s: Sale) => this.fetchDealer(s)));
    }

    // TODO implement rest

    return result;
  }

  protected fetchDealer(sale: Sale): Observable<Sale> {
    return this.dealersService.fetchById(sale.dealerId).pipe(
      map((dealer: Dealer) => {
        return { ...sale, dealer };
      })
    );
  }

  private injectServices() {
    this.dealersService = this.di.get(DealersService);
  }
}
