import { MaxBid, MaxBidData } from '../models';
import { Mapper } from './mapper';

export class MaxBidMapper implements Mapper<MaxBidData, MaxBid> {
  fromDb(data: MaxBidData | null): MaxBid | null {
    if (!data) {
      return null;
    }

    return {
      id: <string>data.id,
      bidId: <string>data.bid,
      ownerId: <string>data.owner,
      createdAt: data.created_at ? new Date(data.created_at) : new Date(),
      updatedAt: data.updated_at ? new Date(data.updated_at) : new Date(data.created_at),
      amount: <number>data.amount,
    };
  }

  toDb(model: MaxBid | null): MaxBidData | null {
    return null;
  }
}

export const maxBidMapper = new MaxBidMapper();
