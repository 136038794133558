import { Action, createReducer, on } from '@ngrx/store';

import { Category } from '@whiskybazar/pwa/categories/models';
import * as CategoriesPageActions from '../actions/categories-page.actions';

export interface State {
  categories: Category[];
  loaded: boolean;
  loading: boolean;
  error: any;
}

export const initialState: State = {
  categories: [],
  loaded: false,
  loading: false,
  error: null,
};

const categoriesPageReducer = createReducer(
  initialState,

  on(CategoriesPageActions.loadCategories, (state) => ({ ...state, loading: true })),

  on(CategoriesPageActions.loadCategoriesSuccess, (state, action) => ({
    ...state,
    loading: false,
    loaded: true,
    categories: action.categories,
  })),

  on(CategoriesPageActions.loadCategoriesFailure, (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return categoriesPageReducer(state, action);
}

export const getCategories = (state: State) => state.categories;
export const getLoading = (state: State) => state.loading;
export const getLoaded = (state: State) => state.loaded;
export const getError = (state: State) => state.error;
