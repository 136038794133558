import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Bottle, BottleImagesUtils, Image } from '@whiskybazar/core';

@Component({
  selector: 'app-bottle-images',
  templateUrl: './bottle-images.component.html',
  styleUrls: ['./bottle-images.component.scss'],
})
export class BottleImagesComponent implements OnInit {
  @Input() bottle: Bottle;

  enlarged$: BehaviorSubject<Image>;

  loading: boolean;

  constructor() {
    this.loading = false;
  }

  ngOnInit() {
    this.loading = true;

    const hasImages = this.bottle && this.bottle.images && this.bottle.images.length;
    const promotedImage = hasImages ? BottleImagesUtils.getPromotedImage(this.bottle.images) : null;
    const defaultImg = !!promotedImage ? promotedImage : hasImages ? this.bottle.images[0] : null;
    this.enlarged$ = new BehaviorSubject<Image>(defaultImg);
  }

  enlarge(img: Image) {
    if (this.enlarged$.getValue().servingUrl === img.servingUrl) {
      return;
    }

    this.loading = true;
    this.enlarged$.next(img);
  }

  onLoaded() {
    // Jest tests will fail if `setTimeout` is not used due
    // to `ExpressionChangedAfterItHasBeenCheckedError`
    setTimeout(() => {
      this.loading = false;
    });
  }
}
