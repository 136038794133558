import { Action } from '@ngrx/store';

export enum ForgotPassActionType {
  SendPasswordResetEmail = '[Auth] Send Password Reset Email',
  SendPasswordResetEmailSuccess = '[Auth] Send Password Reset Email Success',
  SendPasswordResetEmailFail = '[Auth] Send Password Reset Email Fail',
}

export class SendPasswordResetEmail implements Action {
  readonly type = ForgotPassActionType.SendPasswordResetEmail;

  constructor(public email: string) {}
}

export class SendPasswordResetEmailSuccess implements Action {
  readonly type = ForgotPassActionType.SendPasswordResetEmailSuccess;
}

export class SendPasswordResetEmailFail implements Action {
  readonly type = ForgotPassActionType.SendPasswordResetEmailFail;

  constructor(public error: any) {}
}

export type ForgotPassAction = SendPasswordResetEmail | SendPasswordResetEmailSuccess | SendPasswordResetEmailFail;
