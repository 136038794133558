<div class="mat-h3">
  {{ user.name }}
</div>
<div class="mb-2 mat-caption text-muted" *ngIf="withAddress">
  {{ user.address }}, {{ user.zipCode }} {{ user.city }}, {{ user.country }}
</div>
<div class="user-detail">
  <span>Email:</span>&nbsp;<a href="mailto:{{ user.email }}">{{ user.email }}</a>
</div>
<div class="user-detail"><span>Phone:</span>&nbsp;{{ user.phone || 'N/A' }}</div>
