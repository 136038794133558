import { Pipe, PipeTransform } from '@angular/core';

import { User } from '@whiskybazar/core';

@Pipe({
  name: 'bottleDealer',
})
export class BottleDealerPipe implements PipeTransform {
  transform(bottle: any, args?: any): any {
    if (typeof bottle !== 'object') {
      return null;
    }

    if (bottle.auction) {
      return this.getDealerName(bottle.auction.auctioneer);
    } else if (bottle.sale) {
      return this.getDealerName(bottle.sale.dealer);
    }

    return null;
  }

  protected getDealerName(dealer: User | null): string | null {
    return dealer ? dealer.name : null;
  }
}
