import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SellerInvoiceComponent } from './seller-invoice.component';
import { AddressModule } from '../address/address.module';
import { CurrencyModule } from '../currency/currency.module';
import { BottleAttrModule } from '../../pipes';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [SellerInvoiceComponent],
  imports: [CommonModule, AddressModule, CurrencyModule, BottleAttrModule, FlexLayoutModule],
  exports: [SellerInvoiceComponent],
})
export class SellerInvoiceModule {}
