import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuctionResultsComponent } from './auction-results.component';
import { MatIconModule } from '@angular/material/icon';
import { DateFnsModule } from 'ngx-date-fns';
import { CurrencyModule } from '../currency/currency.module';
import { UserModule } from '../user/user.module';
import { AuctionProfitModule, AuctionRuntimeModule, AuthContextModule, BottlePricePipeModule } from '../../pipes';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [AuctionResultsComponent],
  imports: [
    CommonModule,
    MatIconModule,
    DateFnsModule,
    CurrencyModule,
    UserModule,
    AuctionRuntimeModule,
    AuthContextModule,
    AuctionProfitModule,
    MatSelectModule,
    MatOptionModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    BottlePricePipeModule,
  ],
  exports: [AuctionResultsComponent],
})
export class AuctionResultsModule {}
