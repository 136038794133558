export interface Slide {
  image: string;
  caption: string;
  text?: string;
  action: string;
  url?: string;
}

export const slides: Slide[] = [
  // Slide 1
  {
    image: '/S1_{w}x{h}.jpg',
    caption: 'Fastest auction online<br />WhiskyBazar.com',
    text: 'Live auctions with limited runtime',
    action: 'LIVE AUCTIONS',
    url: '/#auctions',
  },
  // Slide 2
  {
    image: '/S2_{w}x{h}.jpg',
    caption: 'We offer a completely<br />Free account',
    text: 'Full access to a wide range of whisky from our many sellers',
    action: 'FREE ACCOUNT',
    url: '/signup',
  },
  // Slide 3
  {
    image: '/S3_{w}x{h}.jpg',
    caption: 'Bid on your<br />favorite bottles',
    text: 'Get free Account and find your favorites',
    action: 'GO TO AUCTIONS',
    url: '/#auctions',
  },
  // Slide 4
  {
    image: '/S4_{w}x{h}.jpg',
    caption: 'International Auctions<br />with worldwide shipping',
    text: 'We offer a 100% shipping insurance for buyers',
    action: 'SIGNUP HERE',
    url: '/signup',
  },
];
