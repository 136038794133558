import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, exhaustMap, catchError, tap } from 'rxjs/operators';

import { StaticPage } from '@whiskybazar/core';
import { StaticPageActionType, LoadPage, LoadPageSuccess, LoadPageFail } from '../actions';

@Injectable()
export class PagesEffects {
  loadPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StaticPageActionType.LoadPage),
      exhaustMap((action: LoadPage) => this.load(action.pageId)),
      map((page: StaticPage) => new LoadPageSuccess(page)),
      catchError((error: any) => of(new LoadPageFail(error)))
    )
  );

  constructor(private actions$: Actions, private http: HttpClient) {}

  protected load(pageId: string): Observable<StaticPage> {
    return this.http
      .get(`/statics/${pageId}.html`, {
        responseType: 'text',
      })
      .pipe(map((content: string) => ({ id: pageId, content } as StaticPage)));
  }
}
