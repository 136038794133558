import { AuthActionType, AuthAction } from './../actions';

export interface State {
  error: string | null;
  pending: boolean;
}

export const initialState: State = {
  error: null,
  pending: false,
};

export function reducer(state = initialState, action: AuthAction): State {
  switch (action.type) {
    case AuthActionType.Login: {
      return {
        ...state,
        error: null,
        pending: true,
      };
    }

    case AuthActionType.LoginSuccess: {
      return {
        ...state,
        error: null,
        pending: false,
      };
    }

    case AuthActionType.LoginFailure: {
      return {
        ...state,
        error: action.error,
        pending: false,
      };
    }

    default: {
      return state;
    }
  }
}

export const getError = (state: State) => state.error;
export const getPending = (state: State) => state.pending;
