import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AuthRoutingModule } from './auth-routing.module';
import { components } from './components';
import { containers } from './containers';
import { effects } from './store/effects';
import { metaReducers, reducers } from './store/reducers';
import { MatInputModule } from '@angular/material/input';

const MATERIAL_MODULES = [
  MatButtonModule,
  MatCardModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  MatToolbarModule,
  MatSidenavModule,
  FormsModule,
  MatFormFieldModule,
  ReactiveFormsModule,
  MatInputModule,
];

@NgModule({
  imports: [
    ...MATERIAL_MODULES,
    CommonModule,
    FlexLayoutModule,
    AuthRoutingModule,
    StoreModule.forFeature('auth', reducers, { metaReducers }),
    EffectsModule.forFeature(effects),
  ],
  declarations: [...components, ...containers],
})
export class AuthModule {}
