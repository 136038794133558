import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Event, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

import { environment } from '@whiskybazar/pwa/environment';
import { WindowRefService } from './window-ref.service';

@Injectable({
  providedIn: 'root',
})
export class DataLayerService {
  get gtmContainerId(): string {
    return environment.googleTagManager?.containerID;
  }

  constructor(private title: Title, private router: Router, private winRef: WindowRefService) {}

  startTracking() {
    // Ensure that we do the tracking on the production environment only
    if (!this.isEnabled()) {
      return;
    }

    this.router.events
      .pipe(filter((event: Event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.pushEvent('page_view', {
          page_title: this.title.getTitle(),
          page_path: event.urlAfterRedirects,
          page_url: this.winRef.nativeWindow.location.href,
        });
      });
  }

  setUserId(userId: string) {
    if (!this.isEnabled()) {
      return;
    }

    this.pushEvent('user_data', { user_id: userId });
  }

  pushEvent(eventName: string, eventParams: Record<string, string> = {}) {
    if (!this.isEnabled()) {
      return;
    }

    const dataLayer = this.winRef.nativeWindow['dataLayer'];
    if (!dataLayer) {
      return;
    }

    dataLayer.push({
      event: eventName,
      ...eventParams,
    });
  }

  protected isEnabled(): boolean {
    return !!environment.googleTagManager;
  }
}
