import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import * as fromAuthStore from '@whiskybazar/pwa/auth/store';

@Component({
  selector: 'app-forgot-pass-page',
  templateUrl: './forgot-pass-page.component.html',
  styleUrls: ['./forgot-pass-page.component.scss'],
})
export class ForgotPassPageComponent implements OnInit {
  sent$: Observable<boolean>;
  pending$: Observable<boolean>;
  error$: Observable<string>;

  constructor(private store$: Store<fromAuthStore.State>) {
    this.sent$ = this.store$.select(fromAuthStore.selectForgotPassSent);
    this.pending$ = this.store$.select(fromAuthStore.selectForgotPassPending);
    this.error$ = this.store$
      .select(fromAuthStore.selectForgotPassError)
      .pipe(map((error: any) => this.parseError(error)));
  }

  ngOnInit() {}

  onSubmit(email: string) {
    this.store$.dispatch(new fromAuthStore.SendPasswordResetEmail(email));
  }

  protected parseError(error: any): string | null {
    if (!error) {
      return null;
    }

    if ((error.code || '').startsWith('auth/')) {
      return error.message;
    }

    return 'Due to an unexpected error it was not possible to send the email! Please try again.';
  }
}
