import { Component, OnInit, Input } from '@angular/core';

import { Bottle } from '@whiskybazar/core';

@Component({
  selector: 'app-bottle-details',
  templateUrl: './bottle-details.component.html',
  styleUrls: ['./bottle-details.component.scss'],
})
export class BottleDetailsComponent implements OnInit {
  @Input() bottle: Bottle;

  constructor() {}

  ngOnInit() {}
}

@Component({
  selector: 'app-bottle-details-body',
  template: ` <ng-content></ng-content> `,
})
export class BottleDetailsBodyComponent {}
