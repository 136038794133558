/**
 * Converts the given value to a Date object and returns it.
 *
 * The given value must be a valid value which is accepted by the Date
 * constructor, otherwise this function will throw and error.
 *
 * @param val The value to be converted to Date
 * @throws Error if given value cannot be converted to Date
 */
export function toDate(val: any): Date {
  if (val instanceof Date) {
    return val;
  } else if (typeof val === 'string') {
    return new Date(val);
  } else if (typeof val === 'number') {
    return new Date(val);
  }

  throw new Error(`Cannot convert '${val}' to Date!`);
}
