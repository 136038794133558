import {
  Component,
  Directive,
  OnInit,
  TemplateRef,
  ContentChildren,
  QueryList,
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { AnimationPlayer, AnimationFactory, AnimationBuilder, animate, style } from '@angular/animations';
import { interval, Observable, of } from 'rxjs';
import { tap, filter } from 'rxjs/operators';

@Directive({
  selector: '[appCarouselItem]',
})
export class CarouselItemDirective {
  constructor(public tpl: TemplateRef<any>) {}
}

@Component({
  selector: 'app-carousel',
  exportAs: 'carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent implements OnInit {
  @Input() auto = true;

  @Input() delay = 3;

  @Input() loop = true;

  /**
   * Define width of the carousel, i.e. width of an item. Defaults to `640px`.
   *
   * Accepted values are: `number[px|em]`, e.g. `'420px'`, `'420'`.
   * Default unit is `px` if none is provided.
   */
  @Input()
  set width(value: string) {
    const [, val, unit] = (value || '').match(/^(\d+)(px|em)$/i);
    if (val) {
      this._width = +val;
    }

    this.wrapperStyles = {
      width: `${this._width}${unit ? unit : 'px'}`,
    };
  }
  private _width = 640;

  @ContentChildren(CarouselItemDirective) items: QueryList<CarouselItemDirective>;

  @ViewChild('carousel', { static: true })
  private carousel: ElementRef;

  wrapperStyles: { [prop: string]: string } = {};

  auto$: Observable<number>;

  private current = 0;

  constructor(private animationBuilder: AnimationBuilder) {
    this.wrapperStyles = { width: `${this._width}px` };
  }

  ngOnInit() {
    this.auto$ = this.auto
      ? interval(this.delay * 1000).pipe(
          filter(() => this.items && this.items.length > 0),
          tap((n) => (this.loop && n > 0 && n % this.items.length === 0 ? this.reset() : this.next()))
        )
      : of(0);
  }

  reset() {
    this.animate(0, `${250 * this.items.length}ms ease`);
    this.current = 0;
  }

  next() {
    if (this.current + 1 === this.items.length) {
      return;
    }

    this.current = (this.current + 1) % this.items.length;
    const offset = this.current * this._width;

    this.animate(offset);
  }

  prev() {
    if (this.current === 0) {
      return;
    }

    this.current = (this.current - 1 + this.items.length) % this.items.length;
    const offset = this.current * this._width;

    this.animate(offset);
  }

  private animate(offset: number, timings = '400ms ease-in') {
    const animationFactory: AnimationFactory = this.animationBuilder.build([
      animate(timings, style({ transform: `translateX(-${offset}px)` })),
    ]);
    const player: AnimationPlayer = animationFactory.create(this.carousel.nativeElement);
    player.play();
  }
}
