import { Component, OnInit, Input } from '@angular/core';

import { Bottle } from '@whiskybazar/core';

@Component({
  selector: 'app-call-to-purchase',
  templateUrl: './call-to-purchase.component.html',
  styleUrls: ['./call-to-purchase.component.scss'],
})
export class CallToPurchaseComponent implements OnInit {
  @Input() bottle: Bottle;

  constructor() {}

  ngOnInit() {}

  getPrice(): number {
    if (this.bottle && this.bottle.auction) {
      return this.bottle.auction.bestBid ? this.bottle.auction.bestBid.amount : this.bottle.auction.minPrice;
    }

    return 0.0;
  }
}
