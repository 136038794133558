import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromRoot from '@whiskybazar/pwa/store';
import * as fromPages from './pages.reducers';

export interface StaticsState {
  pages: fromPages.State;
}

export interface State extends fromRoot.State {
  statics: StaticsState;
}

export const reducers = {
  pages: fromPages.reducer,
};

export const selectStaticsState = createFeatureSelector<StaticsState>('statics');

export const selectPagesState = createSelector(selectStaticsState, (state: StaticsState) => state.pages);

export const selectCurrentPageId = createSelector(selectPagesState, fromPages.getSelectedPageId);

export const selectError = createSelector(selectPagesState, fromPages.getError);

export const selectPending = createSelector(selectPagesState, fromPages.getPending);

export const selectEntities = createSelector(selectPagesState, fromPages.getEntities);

export const selectCurrentPage = createSelector(
  selectEntities,
  selectCurrentPageId,
  (pageEntities, pageId) => pageEntities[pageId]
);
