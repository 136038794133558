import { createAction, props } from '@ngrx/store';

export const initPhoneVerification = createAction(
  '[Auth] Init PhoneVerification', // prettier-ignore
  props<{ phoneNumber: string }>() // prettier-ignore
);

export const initPhoneVerificationSuccess = createAction(
  '[Auth/API] Init PhoneVerification Success' // prettier-ignore
);

export const initPhoneVerificationFailure = createAction(
  '[Auth/API] Init PhoneVerification Failure',
  props<{ error: any }>()
);

export const confirmVerificationCode = createAction(
  '[Auth] Confirm Verification Code', // prettier-ignore
  props<{ code: string }>() // prettier-ignore
);

export const confirmVerificationCodeSuccess = createAction(
  '[Auth/API] Confirm Verification Code Success' // prettier-ignore
);

export const confirmVerificationCodeFailure = createAction(
  '[Auth/API] Confirm Verification Code Failure',
  props<{ error: any }>()
);

export const resetPhoneNumberVerification = createAction(
  '[Auth] Reset Phone Number Verification' // prettier-ignore
);
