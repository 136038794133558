<ng-template #startingBid>
  <span>Starting bid:</span>&nbsp;
  <app-currency [amount]="bottle?.auction?.minPrice"></app-currency>
</ng-template>

<ng-template #runtime> <span>Runtime:</span>&nbsp;{{ bottle.auction.runtime | auctionRuntime }} </ng-template>

<ng-template #auctionEnded>
  <span>Auction ended:</span>&nbsp; {{ bottle?.auction?.transaction?.createdAt | dfnsFormat : 'dd-MM-yyyy' }}
</ng-template>

<ng-template #hammerPrice>
  <span>Winning bid:</span>&nbsp;<app-currency [amount]="bottle | authContext : 'getHammerPrice'"></app-currency>
</ng-template>

<ng-template #hammerPriceWithBids>
  <ng-container *ngTemplateOutlet="hammerPrice"></ng-container>
  <span class="mat-caption text-muted" matTooltip="Total bids received" matTooltipPosition="above"
    >({{ bottle?.auction?.bidIds?.length }})</span
  >
</ng-template>

<ng-template #hammerPriceWithBidsAndAuctionEnded>
  <ng-container *ngTemplateOutlet="hammerPriceWithBids"></ng-container>
  <div class="mat-caption text-muted">
    <ng-container *ngTemplateOutlet="auctionEnded"></ng-container>
  </div>
</ng-template>

<ng-template #myProfit>
  <span>My profit:</span>&nbsp;<app-currency
    [amount]="bottle?.auction?.bestBid?.amount | auctionProfit : bottle"
  ></app-currency>
  <mat-icon matTooltip="Exchange and transfer fees may occur" matTooltipPosition="above">info_outline</mat-icon>
</ng-template>

<ng-template #bidsCount> <span>Bids:</span>&nbsp; {{ bottle?.auction?.bidIds?.length }} </ng-template>

<ng-template #paymentDueWithAuctionEnded>
  <span>Payment due date:</span>&nbsp;{{ bottle | authContext : 'getBuyerPaymentDueDate' | dfnsFormat : 'dd-MM-yyyy' }}
  <div class="mat-caption text-muted">
    <ng-container *ngTemplateOutlet="auctionEnded"></ng-container>
  </div>
</ng-template>

<ng-template #maxBid>
  <span>Max bid:</span>&nbsp;<app-currency [amount]="bottle | authContext : 'getMaxBid'"></app-currency>
</ng-template>

<ng-template #hammerPriceWinner>
  <ng-container *ngTemplateOutlet="hammerPrice"></ng-container>
  <span class="mat-caption">( <ng-container *ngTemplateOutlet="maxBid"></ng-container>)</span>
  <div class="mat-caption text-muted">
    <app-currency [amount]="bottle?.auction?.bestBid?.amount | bottlePrice : true"></app-currency> incl. fees, excl.
    shipping
  </div>
</ng-template>

<ng-template #hammerPriceLooser>
  <ng-container *ngTemplateOutlet="hammerPriceWithBids"></ng-container>
  <div class="mat-caption text-muted">
    <ng-container *ngTemplateOutlet="maxBid"></ng-container>
  </div>
</ng-template>

<ng-template #expectedSettlementDate>
  <span>Expected settlement date:</span>
  {{ bottle | authContext : 'getExpectedSettlementDate' | dfnsFormat : 'dd-MM-yyyy' }}
</ng-template>

<ng-template #expectedSettlementDateElaborated>
  <ng-container *ngTemplateOutlet="expectedSettlementDate"></ng-container>
  <div class="mat-caption text-muted">Your payment is expected to be completed on this date</div>
</ng-template>

<ng-template #shippingDue>
  <span>Shipping due date:</span>
  {{ bottle | authContext : 'getShippingDueDate' | dfnsFormat : 'dd-MM-yyyy' }}
</ng-template>

<ng-template #expectedShipping>
  <span>Expected shipping:</span>
  {{ bottle | authContext : 'getShippingDueDate' | dfnsFormat : 'dd-MM-yyyy' }}
</ng-template>

<ng-template #pickupDeadline>
  <span>Pickup deadline:</span>
  {{ bottle | authContext : 'getPickupDueDate' | dfnsFormat : 'dd-MM-yyyy' }}
</ng-template>

<ng-template #expectedShippingWithAuctionEnded>
  <ng-container *ngTemplateOutlet="expectedShipping"></ng-container>
  <div class="mat-caption text-muted">
    <ng-container *ngTemplateOutlet="auctionEnded"></ng-container>
  </div>
</ng-template>

<ng-template #pickupDeadlineWithAuctionEnded>
  <ng-container *ngTemplateOutlet="pickupDeadline"></ng-container>
  <div class="mat-caption text-muted">
    <ng-container *ngTemplateOutlet="auctionEnded"></ng-container>
  </div>
</ng-template>

<ng-template #hammerPricePaid>
  <ng-container *ngTemplateOutlet="hammerPrice"></ng-container>
  <span class="mat-caption">( <ng-container *ngTemplateOutlet="maxBid"></ng-container>)</span>
  <div class="mat-caption text-muted">
    <app-currency [amount]="bottle?.auction?.transaction?.buyerInvoice?.payment?.amount"></app-currency> incl. fees and
    shipping (PAID)
  </div>
</ng-template>

<ng-template #expectedDeliveryToBuyer>
  <span>Expected delivery:</span>&nbsp;
  {{ bottle | authContext : 'getExpectedDeliveryToBuyerDate' | dfnsFormat : 'dd-MM-yyyy' }}
</ng-template>

<ng-template #expectedDeliveryToBuyerWithAuctionEnded>
  <ng-container *ngTemplateOutlet="expectedDeliveryToBuyer"></ng-container>
  <span class="mat-caption">(latest)</span>
  <div class="mat-caption text-muted">
    <ng-container *ngTemplateOutlet="auctionEnded"></ng-container>
  </div>
</ng-template>

<ng-template #withdrawalRightExpires>
  <span>Withdrawal rights expires:</span>&nbsp;{{
    bottle | authContext : 'getBuyerRightOfWithdrawalExpirationDate' | dfnsFormat : 'dd-MM-yyyy'
  }}
</ng-template>

<ng-template #bottleDelivered>
  <span>Bottle delivered:</span>&nbsp;{{
    bottle | authContext : 'getExpectedDeliveryToBuyerDate' | dfnsFormat : 'dd-MM-yyyy'
  }}
</ng-template>

<ng-template #withdrawalRightExpiresWithBottleDelivered>
  <ng-container *ngTemplateOutlet="withdrawalRightExpires"></ng-container>
  <div class="mat-caption text-muted">
    <ng-container *ngTemplateOutlet="bottleDelivered"></ng-container>
  </div>
</ng-template>

<ng-template #pendingSettlement>
  <div class="auction-result mat-h3">
    <ng-container *ngTemplateOutlet="hammerPriceWithBidsAndAuctionEnded"></ng-container>
  </div>

  <div class="auction-result mat-h3 mt-4">
    <ng-container *ngTemplateOutlet="myProfit"></ng-container>
  </div>

  <div class="auction-result mat-h3 mt-4">
    <ng-container *ngTemplateOutlet="expectedSettlementDateElaborated"></ng-container>
  </div>

  <div class="auction-result mt-4">
    <ng-container *ngTemplateOutlet="startingBid"></ng-container>
  </div>

  <div class="auction-result">
    <ng-container *ngTemplateOutlet="runtime"></ng-container>
  </div>
</ng-template>

<ng-template #withdrawalRightExpired>
  <span>Withdrawal rights expired:</span>&nbsp;{{
    bottle | authContext : 'getBuyerRightOfWithdrawalExpirationDate' | dfnsFormat : 'dd-MM-yyyy'
  }}
</ng-template>

<ng-template #withdrawalRightExpiredWithBottleDelivered>
  <ng-container *ngTemplateOutlet="withdrawalRightExpired"></ng-container>
  <div class="mat-caption text-muted">
    <ng-container *ngTemplateOutlet="bottleDelivered"></ng-container>
  </div>
</ng-template>

<ng-template #delivered>
  <div class="auction-result mat-h3">
    <ng-container *ngTemplateOutlet="hammerPricePaid"></ng-container>
  </div>

  <div class="auction-result mat-h3 mt-4">
    <ng-container *ngTemplateOutlet="withdrawalRightExpiredWithBottleDelivered"></ng-container>
  </div>
</ng-template>

<ng-template #paymentDate>
  <span>Payment date:</span>
  {{ bottle | authContext : 'getSettlementDate' | dfnsFormat : 'dd-MM-yyyy' }}
</ng-template>

<ng-template #paymentDateElaborated>
  <ng-container *ngTemplateOutlet="paymentDate"></ng-container>
  <div class="mat-caption text-muted">Payment to your account is completed</div>
</ng-template>

<ng-template #settled>
  <div class="auction-result mat-h3">
    <ng-container *ngTemplateOutlet="hammerPriceWithBidsAndAuctionEnded"></ng-container>
  </div>

  <div class="auction-result mat-h3 mt-4">
    <ng-container *ngTemplateOutlet="myProfit"></ng-container>
  </div>

  <div class="auction-result mat-h3 mt-4">
    <ng-container *ngTemplateOutlet="paymentDateElaborated"></ng-container>
  </div>

  <div class="auction-result mt-4">
    <ng-container *ngTemplateOutlet="startingBid"></ng-container>
  </div>

  <div class="auction-result">
    <ng-container *ngTemplateOutlet="runtime"></ng-container>
  </div>
</ng-template>

<ng-template #sellerInfo>
  <div class="mat-caption text-muted">Seller's contact information:</div>
  <div>
    <app-user [user]="bottle.owner"></app-user>
  </div>
</ng-template>

<!--
  ACTUAL TEMPLATE
 -->
<div fxLayout="column" fxLayoutGap="32px">
  <div fxFlex="none" [ngSwitch]="bottle.state">
    <!-- IN_TRANSACTION -->
    <ng-container *ngSwitchCase="bottleStates.IN_TRANSACTION">
      <!-- AUCTIONEER/SELLER -->
      <ng-container *ngIf="bottle | authContext : 'isAuctioneerOrSeller'">
        <div class="auction-result mat-h3">
          <ng-container *ngTemplateOutlet="hammerPriceWithBidsAndAuctionEnded"></ng-container>
        </div>

        <div class="auction-result mat-h3 mt-4">
          <ng-container *ngTemplateOutlet="myProfit"></ng-container>
        </div>

        <div class="auction-result mat-h3 mt-4">
          <ng-container *ngTemplateOutlet="expectedSettlementDateElaborated"></ng-container>
        </div>

        <div class="auction-result mt-4">
          <ng-container *ngTemplateOutlet="startingBid"></ng-container>
        </div>

        <div class="auction-result">
          <ng-container *ngTemplateOutlet="runtime"></ng-container>
        </div>

        <div class="auction-result mt-4" *ngIf="bottle | authContext : 'isAuctioneer'">
          <ng-container *ngTemplateOutlet="sellerInfo"></ng-container>
        </div>
      </ng-container>

      <!-- BUYER (Winner) -->
      <ng-container *ngIf="bottle | authContext : 'hasWonAuction'">
        <div class="auction-result mat-h3">
          <ng-container *ngTemplateOutlet="hammerPriceWinner"></ng-container>
        </div>

        <div class="auction-result mat-h3 mt-4">
          <ng-container *ngTemplateOutlet="paymentDueWithAuctionEnded"></ng-container>
        </div>
      </ng-container>

      <!-- BUYER (Looser) -->
      <ng-container *ngIf="bottle | authContext : 'hasLostAuction'">
        <div class="auction-result mat-h3">
          <ng-container *ngTemplateOutlet="hammerPriceLooser"></ng-container>
        </div>

        <div class="auction-result mat-h3 mt-4">
          <ng-container *ngTemplateOutlet="auctionEnded"></ng-container>
        </div>
      </ng-container>

      <!-- ADMIN -->
      <ng-container *ngIf="bottle | authContext : 'isAdmin'">
        <div class="auction-result">
          <ng-container *ngTemplateOutlet="hammerPrice"></ng-container>
        </div>

        <div class="auction-result">
          <span>Payment due date:</span>&nbsp;{{
            bottle.auction.transaction.buyerInvoice.paymentDueAt | dfnsFormat : 'dd-MM-yyyy'
          }}
        </div>
      </ng-container>
    </ng-container>

    <!-- PENDING_PICKUP_AT_AUCTIONEER -->
    <ng-container *ngSwitchCase="bottleStates.PENDING_PICKUP_AT_AUCTIONEER">
      <!-- SELLER -->
      <ng-container *ngIf="bottle | authContext : 'isSeller'">
        <ng-container *ngTemplateOutlet="pendingSettlement"></ng-container>
      </ng-container>

      <!-- AUCTIONEER -->
      <ng-container *ngIf="bottle | authContext : 'isAuctioneer'">
        <div class="auction-result">
          <ng-container *ngTemplateOutlet="hammerPrice"></ng-container>
        </div>

        <div class="auction-result">
          <ng-container *ngTemplateOutlet="pickupDeadline"></ng-container>
        </div>

        <div class="auction-result">
          <ng-container *ngTemplateOutlet="expectedSettlementDate"></ng-container>
        </div>

        <div class="auction-result mt-4">
          <ng-container *ngTemplateOutlet="sellerInfo"></ng-container>
        </div>
      </ng-container>

      <!-- BUYER -->
      <ng-container *ngIf="bottle | authContext : 'isBuyer'">
        <div class="auction-result mat-h3">
          <ng-container *ngTemplateOutlet="hammerPricePaid"></ng-container>
        </div>

        <div class="auction-result mat-h3 mt-4">
          <ng-container *ngTemplateOutlet="pickupDeadlineWithAuctionEnded"></ng-container>
        </div>
      </ng-container>

      <!-- ADMIN -->
      <ng-container *ngIf="bottle | authContext : 'isAdmin'">
        <div class="auction-result">
          <ng-container *ngTemplateOutlet="hammerPrice"></ng-container>
        </div>

        <div class="auction-result">
          <ng-container *ngTemplateOutlet="pickupDeadline"></ng-container>
        </div>
      </ng-container>
    </ng-container>

    <!-- PENDING_SHIPPING -->
    <ng-container *ngSwitchCase="bottleStates.PENDING_SHIPPING">
      <!-- SELLER -->
      <ng-container *ngIf="bottle | authContext : 'isSeller'">
        <ng-container *ngTemplateOutlet="pendingSettlement"></ng-container>
      </ng-container>

      <!-- AUCTIONEER -->
      <ng-container *ngIf="bottle | authContext : 'isAuctioneer'">
        <div class="auction-result">
          <ng-container *ngTemplateOutlet="hammerPrice"></ng-container>
        </div>

        <div class="auction-result">
          <ng-container *ngTemplateOutlet="shippingDue"></ng-container>
        </div>

        <div class="auction-result">
          <ng-container *ngTemplateOutlet="expectedSettlementDate"></ng-container>
        </div>

        <div class="auction-result mt-4">
          <ng-container *ngTemplateOutlet="sellerInfo"></ng-container>
        </div>
      </ng-container>

      <!-- BUYER -->
      <ng-container *ngIf="bottle | authContext : 'isBuyer'">
        <div class="auction-result mat-h3">
          <ng-container *ngTemplateOutlet="hammerPricePaid"></ng-container>
        </div>

        <div class="auction-result mat-h3 mt-4">
          <ng-container *ngTemplateOutlet="expectedShippingWithAuctionEnded"></ng-container>
        </div>
      </ng-container>

      <!-- ADMIN -->
      <ng-container *ngIf="bottle | authContext : 'isAdmin'">
        <div class="auction-result">
          <ng-container *ngTemplateOutlet="hammerPrice"></ng-container>
        </div>

        <div class="auction-result">
          <ng-container *ngTemplateOutlet="shippingDue"></ng-container>
        </div>
      </ng-container>
    </ng-container>

    <!-- SHIPPED -->
    <ng-container *ngSwitchCase="bottleStates.SHIPPED">
      <!-- AUCTIONEER/SELLER -->
      <ng-container *ngIf="bottle | authContext : 'isAuctioneerOrSeller'">
        <ng-container *ngTemplateOutlet="pendingSettlement"></ng-container>

        <div class="auction-result mt-4" *ngIf="bottle | authContext : 'isAuctioneer'">
          <ng-container *ngTemplateOutlet="sellerInfo"></ng-container>
        </div>
      </ng-container>

      <!-- BUYER -->
      <ng-container *ngIf="bottle | authContext : 'hasWonAuction'">
        <div class="auction-result mat-h3">
          <ng-container *ngTemplateOutlet="hammerPricePaid"></ng-container>
        </div>

        <div class="auction-result mat-h3 mt-4">
          <ng-container *ngTemplateOutlet="expectedDeliveryToBuyerWithAuctionEnded"></ng-container>
        </div>
      </ng-container>

      <!-- ADMIN -->
      <ng-container *ngIf="bottle | authContext : 'isAdmin'">
        <div class="auction-result">
          <ng-container *ngTemplateOutlet="hammerPrice"></ng-container>
        </div>

        <div class="auction-result">
          <ng-container *ngTemplateOutlet="expectedDeliveryToBuyer"></ng-container>
        </div>
      </ng-container>
    </ng-container>

    <!-- PENDING_SETTLEMENT -->
    <ng-container *ngSwitchCase="bottleStates.PENDING_SETTLEMENT">
      <!-- ADMIN -->
      <ng-container *ngIf="bottle | authContext : 'isAdmin'">
        <div class="auction-result">
          <ng-container *ngTemplateOutlet="hammerPrice"></ng-container>
        </div>
      </ng-container>
      <!-- END: ADMIN -->

      <!-- Transaction States -->
      <ng-container [ngSwitch]="bottle.auction.transaction.state">
        <!-- PENDING_EXPIRATION_OF_BUYERS_RIGHT_OF_WITHDRAWAL -->
        <ng-container *ngSwitchCase="transactionStates.PENDING_EXPIRATION_OF_BUYERS_RIGHT_OF_WITHDRAWAL">
          <!-- AUCTIONEER/SELLER -->
          <ng-container *ngIf="bottle | authContext : 'isAuctioneerOrSeller'">
            <ng-container *ngTemplateOutlet="pendingSettlement"></ng-container>
          </ng-container>

          <!-- BUYER -->
          <ng-container *ngIf="bottle | authContext : 'hasWonAuction'">
            <div class="auction-result mat-h3">
              <ng-container *ngTemplateOutlet="hammerPricePaid"></ng-container>
            </div>

            <div class="auction-result mat-h3 mt-4">
              <ng-container *ngTemplateOutlet="withdrawalRightExpiresWithBottleDelivered"></ng-container>
            </div>
          </ng-container>
        </ng-container>

        <!-- PENDING_SETTLEMENT_WITH_SELLER -->
        <ng-container *ngSwitchCase="transactionStates.PENDING_SETTLEMENT_WITH_SELLER">
          <!-- AUCTIONEER/SELLER -->
          <ng-container *ngIf="bottle | authContext : 'isAuctioneerOrSeller'">
            <ng-container *ngTemplateOutlet="pendingSettlement"></ng-container>
          </ng-container>

          <!-- BUYER -->
          <ng-container *ngIf="bottle | authContext : 'hasWonAuction'">
            <ng-container *ngTemplateOutlet="delivered"></ng-container>
          </ng-container>
        </ng-container>

        <!-- PENDING_SETTLEMENT_WITH_AUCTIONEER -->
        <ng-container *ngSwitchCase="transactionStates.PENDING_SETTLEMENT_WITH_AUCTIONEER">
          <!-- SELLER -->
          <ng-container *ngIf="bottle | authContext : 'isSeller'">
            <ng-container *ngTemplateOutlet="settled"></ng-container>
          </ng-container>

          <!-- AUCTIONEER -->
          <ng-container *ngIf="bottle | authContext : 'isAuctioneer'">
            <ng-container *ngTemplateOutlet="pendingSettlement"></ng-container>
          </ng-container>

          <!-- BUYER -->
          <ng-container *ngIf="bottle | authContext : 'hasWonAuction'">
            <ng-container *ngTemplateOutlet="delivered"></ng-container>
          </ng-container>
        </ng-container>

        <!-- COMPLETED -->
        <ng-container *ngSwitchCase="transactionStates.COMPLETED">
          <!-- AUCTIONEER/SELLER -->
          <ng-container *ngIf="bottle | authContext : 'isAuctioneerOrSeller'">
            <ng-container *ngTemplateOutlet="settled"></ng-container>
          </ng-container>

          <!-- BUYER -->
          <ng-container *ngIf="bottle | authContext : 'hasWonAuction'">
            <ng-container *ngTemplateOutlet="delivered"></ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
      <!-- END: Transaction States -->
    </ng-container>
  </div>
</div>
