import { ActionReducerMap, ActionReducer, MetaReducer } from '@ngrx/store';
import { RouterReducerState, routerReducer } from '@ngrx/router-store';

import { environment } from '@whiskybazar/pwa/environment';
import { RouterStateUrl } from '@whiskybazar/pwa/utils';

export interface State {
  routerReducer: RouterReducerState<RouterStateUrl>;
}

export const reducers: ActionReducerMap<State> = {
  routerReducer: routerReducer,
};

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return function (state: State, action: any): State {
    console.log('ACTION:', action);
    console.log('STATE:', state);
    console.log('----------');

    return reducer(state, action);
  };
}

// HMR related meta-reducer to reset state on HMR
export function hmr(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state: any, action: any) {
    if (action.type === 'SET_ROOT_STATE') {
      return action.payload;
    }

    return reducer(state, action);
  };
}

// SSR (Server-side Rendering, i.e. Angular Universal) related meta-reducer to rehydrate state on browser
export function ssr(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state: any, action: any) {
    if (action.type === 'REHYDRATE') {
      console.log('Rehydrating NgRx Store', action.payload);
      return action.payload;
    }

    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<State>[] = environment.production ? [ssr, logger] : [ssr, hmr, logger];
