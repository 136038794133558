import { Component, OnInit, Input } from '@angular/core';

import { Bottle, States, StatesObject } from '@whiskybazar/core';
import { toStates } from '@whiskybazar/pwa/utils';
import { BOTTLE_STATES, AUCTION_STATES } from '@whiskybazar/core';

@Component({
  selector: 'app-auction-details',
  templateUrl: './auction-details.component.html',
  styleUrls: ['./auction-details.component.scss'],
})
export class AuctionDetailsComponent implements OnInit {
  @Input() bottle: Bottle;

  readonly bottleStates: States = toStates(BOTTLE_STATES as StatesObject);

  readonly auctionStates: States = toStates(AUCTION_STATES as StatesObject);

  constructor() {}

  ngOnInit() {}
}
