import { AuctionTransaction } from '../models';
import { calcAuctionTotalRevenue } from './auction-total-revenue-calculator';

/**
 * Calculates and returns the total profit amount based on the given auction transaction
 *
 * @param transaction Auction transaction from which to calculate the total profit amount
 */
export function calcAuctionTotalProfit(transaction: AuctionTransaction, vatRate: number): number {
  const revenue = calcAuctionTotalRevenue(transaction);

  return revenue > 0 ? revenue * (1 - vatRate) : revenue;
}
