import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { list, items } from '@whiskybazar/pwa/shared/animations';
import { BOTTLE_STATES, AUCTION_STATES } from '@whiskybazar/core';
import { Bottle, States, StatesObject } from '@whiskybazar/core';
import { toStates } from '@whiskybazar/pwa/utils';

export type PageType = 'live_auctions' | 'my_collection' | 'my_bids' | 'auctions';

@Component({
  animations: [list, items],
  selector: 'app-bottles-list',
  templateUrl: './bottles-list.component.html',
  styleUrls: ['./bottles-list.component.scss'],
})
export class BottlesListComponent implements OnInit {
  @Input() bottles: Bottle[];

  @Input() set page(val: PageType) {
    if (val) {
      this._page.next(val);
    }
  }
  private _page = new BehaviorSubject<PageType>('live_auctions');

  @Output() readonly deleted = new EventEmitter<Bottle>();

  readonly bottleStates: States = toStates(BOTTLE_STATES as StatesObject);

  readonly auctionStates: States = toStates(AUCTION_STATES as StatesObject);

  referer$: Observable<string>;

  constructor() {}

  ngOnInit() {
    this.referer$ = this._page.asObservable().pipe(
      map((page: PageType) => {
        switch (page) {
          case 'my_collection': {
            return encodeURIComponent('/collector/collection');
          }

          case 'my_bids': {
            return encodeURIComponent('/collector/bids');
          }

          case 'auctions': {
            return encodeURIComponent('/dealer/auctions');
          }

          default: {
            return encodeURIComponent('/#auctions');
          }
        }
      })
    );
  }

  delete(bottle: Bottle) {
    this.deleted.emit(bottle);
  }

  trackByBottles(index: number, bottle: Bottle) {
    return bottle.id;
  }
}
