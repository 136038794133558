import { Auction, AUCTION_STATES, Bottle } from '@whiskybazar/core';

function bottleUpdatedAtComparetor(a: Bottle, b: Bottle, direction = 1): number {
  return !a.updatedAt || !b.updatedAt ? 0 : (b.updatedAt.getTime() - a.updatedAt.getTime()) * direction;
}

function auctionStateComparetor(a: Auction, b: Auction, direction = 1): number {
  if (a.state === AUCTION_STATES.RUNNING.id && b.state === AUCTION_STATES.RUNNING.id) {
    return !a.updatedAt || !b.updatedAt ? 0 : (b.updatedAt.getTime() - a.updatedAt.getTime()) * direction;
  } else if (a.state === AUCTION_STATES.COUNTING_DOWN.id && b.state === AUCTION_STATES.COUNTING_DOWN.id) {
    return !a.endingAt || !b.endingAt ? 0 : (a.endingAt.getTime() - b.endingAt.getTime()) * direction;
  }

  const STATES_SORTING = {
    COUNTING_DOWN: 1,
    RUNNING: 2,
  };

  const aState = STATES_SORTING[a.state] || 0;
  const bState = STATES_SORTING[b.state] || 0;

  return (aState - bState) * direction;
}

/**
 * Sorts the given list of bottles by the update time according to the `direction`.
 *
 * @param bottles list of bottles to sort
 * @param direction 1 = recent first, -1 = oldest first, Default: 1
 */
export function sortBottlesByUpdatedAt(bottles: Bottle[], direction = 1): Bottle[] {
  return bottles.sort((a: Bottle, b: Bottle) => bottleUpdatedAtComparetor(a, b, direction));
}

/**
 * Sorts the given list of bottles by auction according to auction state and runtime
 *
 * @param bottles list of bottles to sort
 * @param direction 1 = forward, -1 = backward, Default: 1
 */
export function sortBottlesByAuction(bottles: Bottle[], direction = 1): Bottle[] {
  return bottles.sort((a: Bottle, b: Bottle) =>
    a.auction && b.auction
      ? auctionStateComparetor(a.auction, b.auction, direction)
      : bottleUpdatedAtComparetor(a, b, direction)
  );
}
