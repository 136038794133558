<form [formGroup]="form" (ngSubmit)="submit()">
  <div fxLayout="column" formGroupName="metaBottle" *ngIf="isDefaultState">
    <!-- Barcode -->
    <mat-form-field>
      <mat-label>Barcode</mat-label>
      <input matInput type="text" placeholder="Barcode" i18n-placeholer formControlName="barcode" />
    </mat-form-field>
    <!-- Title -->
    <mat-form-field class="with-hints">
      <mat-label>Title</mat-label>
      <input
        matInput
        type="text"
        placeholder="Title (Distillery, Bottler, Batch/Cask No, Item X of Y)"
        i18n-placeholer
        formControlName="title"
        required
      />
      <mat-hint>e.g. Glenlivet Signatory Vintage The Un-chillfiltred Collection</mat-hint>
    </mat-form-field>
    <!-- Distillery and Bottler -->
    <div fxLayout="row" fxLayoutGap="16px" class="mt-4">
      <!-- Distillery -->
      <mat-form-field fxFlex="50">
        <mat-label>Distillery</mat-label>
        <input matInput type="text" placeholder="Distillery" i18n-placeholder formControlName="distillery" />
      </mat-form-field>
      <!-- Bottler -->
      <mat-form-field fxFlex="50">
        <mat-label>Bottler</mat-label>
        <input matInput type="text" placeholder="Bottler" i18n-placeholder formControlName="bottler" />
      </mat-form-field>
    </div>
    <!-- Type -->
    <mat-form-field>
      <mat-label>Type</mat-label>
      <mat-select placeholder="Type" i18n-placeholer formControlName="type">
        <mat-option value="Single Malt Whisky">Single Malt Whisky</mat-option>
        <mat-option value="Blended Malt Whisky">Blended Malt Whisky</mat-option>
        <mat-option value="Blended Whisky">Blended Whisky</mat-option>
        <mat-option value="Grain Whisky">Grain Whisky</mat-option>
        <mat-option value="Rye Whisky">Rye Whisky</mat-option>
        <mat-option value="Bourbon Whiskey">Bourbon Whiskey</mat-option>
        <mat-option value="New Make Spirit">New Make Spirit</mat-option>
        <mat-option value="Other">Other</mat-option>
      </mat-select>
    </mat-form-field>
    <!-- Origin -->
    <mat-form-field>
      <mat-label>Origin</mat-label>
      <input matInput type="text" placeholder="Origin" i18n-placeholer formControlName="origin" />
    </mat-form-field>
    <!-- Region -->
    <mat-form-field>
      <mat-label>Region</mat-label>
      <input matInput type="text" placeholder="Region" i18n-placeholer formControlName="region" />
    </mat-form-field>
    <!-- Age -->
    <mat-form-field>
      <mat-label>Age</mat-label>
      <input matInput type="number" placeholder="Age" i18n-placeholer formControlName="age" />
    </mat-form-field>
    <!-- Alcohol Percentage -->
    <mat-form-field>
      <mat-label>Alcohol (%)</mat-label>
      <input matInput type="number" placeholder="Alcohol (%)" i18n-placeholer formControlName="alcoholPercentage" />
    </mat-form-field>
    <!-- Volume -->
    <mat-form-field>
      <mat-label>Volume (ml)</mat-label>
      <input matInput type="number" placeholder="Volume (ml)" i18n-placeholer formControlName="volume" />
    </mat-form-field>
    <!-- Distillation Year -->
    <mat-form-field>
      <mat-label>Distillation Year</mat-label>
      <input
        matInput
        type="number"
        placeholder="Distillation Year"
        i18n-placeholer
        formControlName="distillationYear"
      />
    </mat-form-field>
    <!-- Bottling Year -->
    <mat-form-field>
      <mat-label>Bottling Year</mat-label>
      <input matInput type="number" placeholder="Bottling Year" i18n-placeholer formControlName="bottlingYear" />
    </mat-form-field>
  </div>
  <!-- Item Number and Number of Items -->
  <ng-container
    *ngIf="!bottle?.metaBottle?.id || !bottle?.metaBottle?.approved || (bottle | authContext : 'hasNumbering')"
  >
    <div fxLayout="row" fxLayoutGap="16px">
      <!-- Item Number -->
      <mat-form-field [fxFlex]="isDefaultState ? 50 : 100">
        <mat-label>Item Number</mat-label>
        <input matInput type="number" placeholder="Bottle number" i18n-placeholer formControlName="itemNumber" />
        <span matSuffix *ngIf="!isDefaultState">of {{ bottle?.metaBottle?.numberOfBottles }}</span>
      </mat-form-field>
      <!-- Number of Items -->
      <mat-form-field [fxFlex]="50" formGroupName="metaBottle" *ngIf="isDefaultState">
        <mat-label>Number of Items</mat-label>
        <input
          matInput
          type="number"
          placeholder="Total number of bottles"
          i18n-placeholer
          formControlName="numberOfBottles"
        />
      </mat-form-field>
    </div>
  </ng-container>
  <div fxLayout="column" fxLayoutGap="16px">
    <!-- Batch Number -->
    <mat-form-field>
      <mat-label>Batch number</mat-label>
      <input matInput type="number" placeholder="Batch number" i18n-placeholder formControlName="batchNumber" />
    </mat-form-field>
    <!-- Cask Number -->
    <mat-form-field>
      <mat-label>Cask number</mat-label>
      <input matInput type="number" placeholder="Cask number" i18n-placeholder formControlName="caskNumber" />
    </mat-form-field>
  </div>
  <!-- Meta-Bottle Description -->
  <div fxLayout="column" formGroupName="metaBottle" *ngIf="isDefaultState">
    <mat-form-field class="with-hints">
      <mat-label>Description</mat-label>
      <textarea
        matInput
        formControlName="description"
        i18n-placeholder
        placeholder="Description"
        #description
        maxlength="2075"
      ></textarea>
      <mat-hint align="end">{{ description.value.length }}/2075</mat-hint>
    </mat-form-field>
  </div>
  <div fxLayout="column" class="mb-4">
    <!-- Condition -->
    <mat-form-field>
      <mat-label>Bottle Condition</mat-label>
      <mat-select placeholder="Bottle Condition" i18n-placeholder formControlName="condition" required>
        <mat-option value="condition_1">New</mat-option>
        <mat-option value="condition_2">Excellent</mat-option>
        <mat-option value="condition_3">Good</mat-option>
        <mat-option value="condition_4">Fair</mat-option>
        <mat-option value="condition_5">Damaged</mat-option>
        <mat-option value="condition_6">Other</mat-option>
      </mat-select>
    </mat-form-field>
    <!-- Original Packaging Included? -->
    <div class="mb-2">
      <mat-checkbox matInput formControlName="originalPackagingIncluded" i18n>Original packaging included</mat-checkbox>
    </div>
    <!-- Bottle Description (aka Comments) -->
    <mat-form-field class="with-hints">
      <mat-label>Comments</mat-label>
      <textarea
        matInput
        formControlName="description"
        i18n-placeholder
        placeholder="Comments"
        #comments
        maxlength="1000"
      ></textarea>
      <mat-hint align="start">Any additional info, issues and defects must be mentioned here!</mat-hint>
      <mat-hint align="end">{{ comments.value.length }}/1000</mat-hint>
    </mat-form-field>
  </div>
  <!-- Actions -->
  <div
    fxLayout.gt-xs="row"
    fxLayout.xs="column"
    fxLayoutAlign.gt-xs="end center"
    fxLayoutAlign.xs="start stretch"
    fxLayoutGap="8px"
  >
    <ng-content></ng-content>
  </div>
</form>
