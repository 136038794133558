import { Pipe, PipeTransform } from '@angular/core';

import { AuthContextService } from '@whiskybazar/pwa/core/services';
import { Bottle } from '@whiskybazar/core';

type Operation = 'canEdit' | 'canDelete';

/**
 * @deprecated Use the generic `authContext` pipe
 */
@Pipe({
  name: 'bottle',
})
export class BottlePipe implements PipeTransform {
  constructor(protected ctx: AuthContextService) {}

  transform(bottle: Bottle, operation?: Operation): boolean {
    if (!bottle) {
      return false;
    }

    switch (operation) {
      case 'canDelete': {
        return this.ctx.canDelete(bottle);
      }

      default: {
        return false;
      }
    }
  }
}
