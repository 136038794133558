<mat-card appearance="outlined" fxFlex ngClass.lt-sm="small">
  <div fxFlexFill fxLayout="row" fxLayoutGap="24px" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="0px">
    <!-- Image Thumbnail -->
    <div fxFlex="none" fxLayoutAlign="center center" class="thumbnail" ngClass.lt-sm="small">
      <img [appImageThumbnail]="metaBottle | bottlePromotedImage" thumbnailSize="240x240" />
    </div>

    <!-- Main Content Container -->
    <div fxFlex="grow" fxLayout="column" fxLayoutAlign="start stretch">
      <!-- Header -->
      <div class="header" fxFlex="nogrow" fxLayout="row" fxLayoutAlign="start start">
        <div fxFlex="grow">
          <h2 ngClass.gt-md="mat-headline-5">{{ metaBottle?.title }}</h2>

          <div class="subtitle mat-small">Barcode: {{ metaBottle?.barcode || 'N/A' }}</div>
        </div>
      </div>

      <!-- Body -->
      <div
        class="body"
        fxFlex="noshrink"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayout.lt-sm="column"
        fxLayoutAlign.lt-sm="start start"
      >
        <!-- LEFT COLUMN (top 3 rows on small viewports) -->
        <div fxFlex="50%" fxFlex.lt-sm="none">
          <!-- Type -->
          <div class="attribute"><span>Type:</span> {{ metaBottle?.type || 'N/A' }}</div>

          <!-- Age -->
          <div class="attribute"><span>Age:</span> {{ metaBottle?.age + ' years' || 'N/A' }}</div>

          <!-- Item No -->
          <div class="attribute">
            <span>Item No:</span> {{ metaBottle?.numberOfBottles ? '-/' + metaBottle?.numberOfBottles : 'N/A' }}
          </div>
        </div>

        <!-- RIGHT COLUMN (bottom 3 rows on small viewports) -->
        <div fxFlex="50%" fxFlex.lt-sm="none">
          <!-- Distillation Year -->
          <div class="attribute"><span>Distilled:</span> {{ metaBottle?.distillationYear || 'N/A' }}</div>

          <!-- Bottling Year -->
          <div class="attribute"><span>Bottled:</span> {{ metaBottle?.bottlingYear || 'N/A' }}</div>

          <!-- Alcohol Percentage -->
          <div class="attribute">
            <span>Alcohol:</span> {{ metaBottle?.alcoholPercentage ? metaBottle?.alcoholPercentage + '%' : 'N/A' }}
          </div>
        </div>
      </div>

      <!-- Bottom -->
      <div class="bottom" fxFlex="none" fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="none">
          <h4><ng-content></ng-content></h4>
        </div>
      </div>

      <!-- Footer -->
      <div class="footer" fxFlex="none">
        <mat-card-footer>
          <div fxLayout="column" fxLayoutAlign="start stretch">
            <div class="multi-footer" fxFlex="none" fxLayout="row" fxLayoutAlign="end end">
              <ng-content select="app-meta-bottle-preview-action"></ng-content>
            </div>
          </div>
        </mat-card-footer>
      </div>
    </div>
  </div>
</mat-card>
