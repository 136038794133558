<div fxLayout="column" class="main">
  <!-- Action Bar -->
  <div fxFlex="none" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" class="action-bar">
    <ng-content select="app-bottle-tile-header"></ng-content>
  </div>

  <!-- Image -->
  <div fxFlex="none" fxLayoutAlign="center center" class="thumbnail">
    <img [appImageThumbnail]="bottle | bottlePromotedImage" thumbnailSize="168x168" />
  </div>

  <!-- Details -->
  <div fxFlex="grow">
    <!-- Title -->
    <h2>{{ bottle?.metaBottle.title }}</h2>

    <!-- Two Attributes (based on availability and precedence) -->
    <h4>{{ bottle | bottleAttrPrecedence }}</h4>
  </div>

  <!-- State -->
  <div fxFlex="none">
    <h3><app-bottle-state [bottle]="bottle"></app-bottle-state></h3>
  </div>

  <!-- Action -->
  <div fxFlex="none"><ng-content select="app-bottle-tile-action"></ng-content></div>
</div>
<div fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="16px" class="footer">
  <div fxFlex="grow" *ngIf="!(bottle | authContext : 'isAuctioneer')" class="dealer">
    <app-bottle-dealer [bottle]="bottle" [layout]="'country_name'"></app-bottle-dealer>
  </div>
  <div fxFlex="none">LOT: {{ bottle | bottleLot }}</div>
</div>
