import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ImageThumbnailModule } from '../../directives';
import { BottleImagesComponent } from './bottle-images.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [BottleImagesComponent],
  imports: [
    CommonModule,
    ImageThumbnailModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
  ],
  exports: [BottleImagesComponent],
})
export class BottleImagesModule {}
