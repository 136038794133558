import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthContextPipe } from './auth-context.pipe';

@NgModule({
  declarations: [AuthContextPipe],
  imports: [CommonModule],
  exports: [AuthContextPipe],
})
export class AuthContextModule {}
