import { Injectable, inject } from '@angular/core';
import { Database } from '@angular/fire/database';
import { User } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { Admin, AdminMapper, Mapper } from '@whiskybazar/core';
import { AbstractFirebaseList } from './abstract-firebase-list';

@Injectable()
export class AdminsService extends AbstractFirebaseList<boolean, boolean> {
  private readonly path = 'admins';

  private afDb: Database = inject(Database);

  constructor() {
    super();
  }

  getPath(): string {
    return this.path;
  }

  getAngularFireDatabase(): Database {
    return this.afDb;
  }

  getMapper(): Mapper<boolean, boolean> {
    return new AdminMapper();
  }

  fetchByAuth(auth: User): Observable<Admin | null> {
    return this.fetchById(auth.uid).pipe(
      take(1),
      map((val: boolean) => (val === true ? this.createAdmin(auth) : null))
    );
  }

  protected createAdmin(user: User): Admin {
    return {
      id: user.uid,
      type: 'admin',
      email: user.email,
      name: user.displayName,
    };
  }
}
