import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

interface SeoImage {
  url: string;
  width: number;
  height: number;
}

interface SeoTags {
  title: string;
  description: string;
  image: SeoImage;
  url?: string;
}

@Injectable()
export class SeoService {
  readonly site = 'Whisky Bazar';

  readonly domain = 'https://whiskybazar.com';

  readonly logo = '/assets/images/logo.png';

  private readonly defaults: SeoTags = {
    title: 'Whisky Bazar - buy and sell whisky online',
    description:
      'The best site on the internet for whisky collectors, ' +
      'sellers and buyers as well as local shops that are in the field of whisky',
    image: {
      url: this.logo,
      width: 263,
      height: 147,
    },
    url: this.domain,
  };

  constructor(private meta: Meta, private title: Title) {}

  generateTags(val: Partial<SeoTags>, type = 'website') {
    const tags: SeoTags = { ...this.defaults, ...val };

    this.title.setTitle(tags.title);

    this.meta.updateTag({ name: 'description', content: tags.description });
    this.meta.updateTag({ name: 'image', content: this.getAbsoluteUrl(tags.image.url) });

    // Open Graph markup
    this.meta.updateTag({ property: 'og:type', content: type });
    this.meta.updateTag({ property: 'og:title', content: tags.title });
    this.meta.updateTag({ property: 'og:description', content: tags.description });
    this.meta.updateTag({ property: 'og:image', content: this.getAbsoluteUrl(tags.image.url) });
    this.meta.updateTag({ property: 'og:image:width', content: `${tags.image.width}` });
    this.meta.updateTag({ property: 'og:image:height', content: `${tags.image.height}` });
    this.meta.updateTag({ property: 'og:url', content: this.getAbsoluteUrl(tags.url) });
    this.meta.updateTag({ property: 'og:site_name', content: this.site });

    // Twitter markup
    this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
  }

  protected getAbsoluteUrl(url: string): string {
    return url.startsWith('http') ? url : url.startsWith('/') ? this.domain + url : `${this.domain}/${url}`;
  }
}
