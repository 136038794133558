import { Bottle, BottleData, Image } from '../models';
import { Mapper } from './mapper';
import { BottleImagesUtils } from '../utils';

export class BottleMapper implements Mapper<BottleData, Bottle> {
  fromDb(data: BottleData | null): Bottle | null {
    if (!data) {
      return null;
    }

    return {
      auctionId: <string>data.auction,
      barcode: <string>data.barcode,
      batchNumber: <number>data.batch_number,
      caskNumber: <number>data.cask_number,
      condition: <string>data.condition,
      createdAt: data.created_at ? new Date(data.created_at) : new Date(),
      description: <string>data.description,
      id: <string>data.id,
      images: data.images ? BottleImagesUtils.fromDbImages(data) : [],
      itemNumber: <number>data.item_number,
      metaBottleId: <string>data.meta_bottle,
      originalPackagingIncluded: <boolean>data.original_packaging_included,
      ownerId: <string>data.owner,
      state: <string>data.state,
      updatedAt: data.updated_at ? new Date(data.updated_at) : new Date(data.created_at),
      views: <number>data.views,
      transactionHistory: data.transaction_history
        ? Object.keys(data.transaction_history).map((key) => key)
        : undefined,
    };
  }

  toDb(model: Bottle | null): BottleData | null {
    if (!model) {
      return null;
    }

    return {
      barcode: model.barcode
        ? model.barcode
        : model.metaBottle && model.metaBottle.barcode
        ? model.metaBottle.barcode
        : undefined,
      batch_number: model.batchNumber ? model.batchNumber : undefined,
      cask_number: model.caskNumber ? model.caskNumber : undefined,
      condition: model.condition ? model.condition : undefined,
      description: model.description ? model.description : undefined,
      id: model.id ? model.id : undefined,
      images: model.images ? BottleImagesUtils.toDbImages(model) : undefined,
      item_number: model.itemNumber ? model.itemNumber : undefined,
      meta_bottle: model.metaBottleId ? model.metaBottleId : model.metaBottle ? model.metaBottle.id : undefined,
      original_packaging_included: model.originalPackagingIncluded ? model.originalPackagingIncluded : undefined,
      owner: model.ownerId ? model.ownerId : undefined,
      state: model.state,
    };
  }
}

export const bottleMapper = new BottleMapper();
