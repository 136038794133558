import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentMethodsComponent } from './payment-methods.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [PaymentMethodsComponent],
  imports: [CommonModule, FlexLayoutModule],
  exports: [PaymentMethodsComponent],
})
export class PaymentMethodsModule {}
