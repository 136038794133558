import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShippingOptionComponent } from './shipping-option.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CurrencyModule } from '../currency/currency.module';

@NgModule({
  declarations: [ShippingOptionComponent],
  imports: [CommonModule, FlexLayoutModule, CurrencyModule],
  exports: [ShippingOptionComponent],
})
export class ShippingOptionModule {}
