import { Component, Input, ViewChild, ElementRef } from '@angular/core';

import { WindowRefService } from '@whiskybazar/pwa/core/services';

@Component({
  selector: 'app-printer',
  templateUrl: './printer.component.html',
  styleUrls: ['./printer.component.scss'],
})
export class PrinterComponent {
  @Input() stylesUrls = ['/assets/css/print.css'];

  @Input() title: string;

  @ViewChild('content', { static: true }) content: ElementRef;

  constructor(private winRef: WindowRefService) {}

  print() {
    const win: Window = this.winRef.nativeWindow;
    if (!win) {
      throw new Error('Window is undefined!');
    }

    const printWin = win.open();
    if (!printWin) {
      throw new Error('Failed to create print window!');
    }

    const content = this.render(this.title, this.content.nativeElement.innerHTML, this.stylesUrls);
    printWin.document.write(content);
    printWin.document.close();
    printWin.focus();
  }

  private render(title: string, content: string, stylesUrls: string[]): string {
    return `
    <html>
      <head>
        <title>WhiskyBazar.com - ${title}</title>
        <link
          rel="preload"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500"
          as="style"
          onload="this.onload=null;this.rel='stylesheet'"
        />
        ${stylesUrls.map((url) => `<link href="${url}" rel="stylesheet" type="text/css">`).join('\n')}
      </head>
      <body onload="window.print()" style="font-family: Roboto, sans-serif">
        ${content}
      </body>
    </html>
    `;
  }
}
