import { Injectable, Injector, inject } from '@angular/core';
import { Database, object, ref } from '@angular/fire/database';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { CollectorsService } from './collectors.service';
import { DealersService } from './dealers.service';
import { User, OwnerData } from '@whiskybazar/core';

@Injectable()
export class OwnersService {
  private readonly path = 'owners';

  private collectorsService: CollectorsService;

  private dealersService: DealersService;

  private afDb: Database = inject(Database);

  constructor(private di: Injector) {
    // take care of circular dependency injection
    setTimeout(() => this.injectServices());
  }

  fetchById(id: string): Observable<User | null> {
    return object(ref(this.afDb, `${this.path}/${id}`)).pipe(
      map((changes) => changes.snapshot.val()),
      switchMap((data: OwnerData | null) => (data === null ? of(null) : this.load(data)))
    );
  }

  protected load(data: OwnerData): Observable<User | null> {
    if (data.collector) {
      return this.collectorsService.fetchById(data.collector);
    } else if (data.dealer) {
      return this.dealersService.fetchById(data.dealer);
    }

    return of(null);
  }

  private injectServices(): void {
    this.collectorsService = this.di.get(CollectorsService);
    this.dealersService = this.di.get(DealersService);
  }
}
