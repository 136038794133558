import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil, scan, filter } from 'rxjs/operators';

import { Bottle } from '@whiskybazar/core';

@Component({
  selector: 'app-bottle-form',
  templateUrl: './bottle-form.component.html',
  styleUrls: ['./bottle-form.component.scss'],
})
export class BottleFormComponent implements OnInit, OnDestroy {
  @Input()
  set value(val: Bottle | null) {
    if (!val) {
      return;
    }

    if (!this.form.dirty) {
      // Ensure that meta-bottle has a valid value, otherwise remove it
      const value = { ...val };
      if (value.metaBottle === undefined || value.metaBottle === null) {
        delete value.metaBottle;
      }

      this.form.patchValue(value);
    } else if (val.metaBottle && val.metaBottle.id) {
      this.form.get('metaBottle').patchValue(val.metaBottle);
    }

    this.bottle = val;
  }
  bottle: Bottle = null;

  @Input()
  set pending(isPending: boolean) {
    if (isPending) {
      this.form.disable();
    } else {
      this.form.enable();

      // Ensure that a disabled meta-bottle form stays disabled
      const metaBottleIdCtrl = this.form.get('metaBottle.id');
      this.toggleMetaBottleForm(metaBottleIdCtrl.value);
    }
  }

  @Input()
  set canEditMetaBottle(canEdit: boolean) {
    if (canEdit) {
      this.form.get('metaBottle').enable();
    } else {
      this.form.get('metaBottle').disable();
    }

    this._catEditMetaBottle = canEdit;
  }
  private _catEditMetaBottle = false;

  @Input() state: 'default' | 'only_bottle' = 'default';

  @Output() readonly submitted: EventEmitter<Bottle>;

  form: UntypedFormGroup;

  destroyed$ = new Subject<void>();

  get isDefaultState(): boolean {
    return this.state === 'default';
  }

  constructor() {
    this.submitted = new EventEmitter<Bottle>();

    this.form = new UntypedFormGroup({
      metaBottle: new UntypedFormGroup({
        id: new UntypedFormControl(null),
        age: new UntypedFormControl('', [Validators.maxLength(3)]),
        barcode: new UntypedFormControl('', Validators.maxLength(13)),
        title: new UntypedFormControl('', Validators.required),
        type: new UntypedFormControl('Other'),
        origin: new UntypedFormControl('', [Validators.maxLength(42)]),
        alcoholPercentage: new UntypedFormControl('', [Validators.max(100)]),
        volume: new UntypedFormControl('', [Validators.min(0), Validators.max(10000)]),
        distillationYear: new UntypedFormControl(),
        bottlingYear: new UntypedFormControl(),
        numberOfBottles: new UntypedFormControl(),
        description: new UntypedFormControl('', Validators.maxLength(2075)),
        distillery: new UntypedFormControl(),
        bottler: new UntypedFormControl(),
        region: new UntypedFormControl(),
      }),
      id: new UntypedFormControl(null),
      itemNumber: new UntypedFormControl(), // TODO validate against metaBottle.numberOfBottles
      condition: new UntypedFormControl('', Validators.required),
      originalPackagingIncluded: new UntypedFormControl(false),
      description: new UntypedFormControl('', Validators.maxLength(1000)),
      batchNumber: new UntypedFormControl(),
      caskNumber: new UntypedFormControl(),
    });
  }

  ngOnInit() {
    // Enable/disable meta-bottle form based on its value
    this.form
      .get('metaBottle.id')
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
      .subscribe((value: string | null) => this.toggleMetaBottleForm(value));

    // Rest meta-bottle form when an existing meta-bottle is removed
    this.form
      .get('metaBottle.id')
      .valueChanges.pipe(
        scan((acc, curr) => ({ ...acc, prev: acc.current, current: curr }), { prev: null, current: null }),
        filter(({ prev, current }) => !!prev && !current),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => this.form.get('metaBottle').reset());
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  submit() {
    if (this.form.invalid) {
      return;
    }

    const bottle: Bottle = this.form.value as Bottle;
    this.submitted.emit(bottle);
  }

  private toggleMetaBottleForm(id: string) {
    // Do not allow editing of exiting meta-bottle properties if not allowed
    if (id && !this._catEditMetaBottle) {
      this.form.get('metaBottle').disable();
    }

    // Enable and reset the meta-bottle form in order for the user to edit
    if (!id) {
      this.form.get('metaBottle').enable();
    }
  }
}
