import { Pipe, PipeTransform } from '@angular/core';

import { Bottle } from '@whiskybazar/core';
import { AuthContextService } from '@whiskybazar/pwa/core/services';
import { sellerProfit, auctioneerProfit } from '@whiskybazar/core';

@Pipe({
  name: 'auctionProfit',
})
export class AuctionProfitPipe implements PipeTransform {
  constructor(private ctx: AuthContextService) {}

  transform(amount: number, bottle?: Bottle): number {
    if (!amount) {
      return 0;
    }

    // Return seller profit when bottle is not given
    if (!bottle) {
      return this.calcSellerProfit(amount);
    }

    let result = 0;
    if (this.ctx.isSeller(bottle)) {
      result = this.calcSellerProfit(amount);
    } else if (this.ctx.isAuctioneer(bottle)) {
      result = this.calcAuctioneerProfit(amount);
    }

    return result;
  }

  protected calcSellerProfit(amount: number): number {
    return sellerProfit(amount);
  }

  protected calcAuctioneerProfit(amount: number): number {
    return auctioneerProfit(amount);
  }
}
