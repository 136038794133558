import { Address, AddressData } from '../models';
import { Mapper } from './mapper';

export class AddressMapper implements Mapper<AddressData, Address> {
  fromDb(data: AddressData | null): Address | null {
    if (!data) {
      return null;
    }

    return {
      id: <string>data.id,
      name: <string>data.name,
      address: <string>data.address,
      zipCode: <string>data.zip_code,
      city: <string>data.city,
      country: <string>data.country,
      email: <string>data.email,
      phone: <string>data.phone,
    };
  }

  toDb(model: Address | null): AddressData | null {
    if (!model) {
      return null;
    }

    return {
      id: model.id ? model.id : undefined,
      name: model.name,
      address: model.address,
      zip_code: model.zipCode,
      city: model.city,
      country: model.country,
      email: model.email ? model.email : undefined,
      phone: model.phone ? model.phone : undefined,
    };
  }
}

export const addressMapper = new AddressMapper();
