import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Route, Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ArticlePageComponent } from '@whiskybazar/pwa/categories/containers/article-page/article-page.component';
import { CategoryPageComponent } from '@whiskybazar/pwa/categories/containers/category-page/category-page.component';
import { ArticlePageGuard } from '@whiskybazar/pwa/categories/guards/article-page.guard';
import { CategoryPageGuard } from '@whiskybazar/pwa/categories/guards/category-page.guard';
import { Article, ArticlesResponse, Category, Hierarchy } from '@whiskybazar/pwa/categories/models';

@Injectable()
export class CategoriesService {
  private readonly rootPath = 'categories';

  private readonly endpoints = {
    hierarchy: '/statics/categories/hierarchy.json',
    articles: '/statics/categories/articles.json',
    article: '/statics/categories',
  };

  constructor(private http: HttpClient, private router: Router, private location: Location) {}

  fetchAll(): Observable<Category[]> {
    return forkJoin([this.fetchHierarchy(), this.fetchArticles()]).pipe(
      map(([hierarchy, articles]) => this.createCategories(hierarchy, articles)),
      map((categories: Category[]) => this.pruneCategories(categories)),
      tap((categories: Category[]) => this.createRoutes(categories))
    );
  }

  initNavigation(): void {
    const url = this.location.path(true);
    this.router.navigateByUrl(url);
  }

  fetchArticleContent(article: Article): Observable<Article> {
    const url = `${this.endpoints.article}/${article.filename}.html`;

    return this.http.get(url, { responseType: 'text' }).pipe(map((content: string) => ({ ...article, content })));
  }

  protected fetchHierarchy(): Observable<Hierarchy> {
    return this.http.get<Hierarchy>(this.endpoints.hierarchy);
  }

  protected fetchArticles(): Observable<ArticlesResponse> {
    return this.http.get<ArticlesResponse>(this.endpoints.articles);
  }

  protected createCategories(hierarchy: Hierarchy, articles: ArticlesResponse): Category[] {
    const findArticlesByCategory = (categoryName: string, categoryId: string): Article[] => {
      return Object.keys(articles)
        .filter((key: string) => articles[key].category === categoryName)
        .map((key: string) => {
          const id = this.createId(categoryName, key);
          const path = this.createPath(...categoryName.split('/'), key);

          return {
            ...articles[key],
            id,
            filename: key,
            path: key,
            route: {
              path,
              data: {
                categoryId,
                articleId: id,
              },
            },
          } as Article;
        });
    };

    return Object.keys(hierarchy).map((parent: string) => {
      const children: Category[] = Object.keys(hierarchy[parent]).map((child: string) => {
        const id = this.createId(parent, child);
        const path = this.createPath(parent, child);

        return {
          id,
          name: child,
          path,
          route: {
            path,
            data: {
              categoryId: id,
            },
          },
          articles: findArticlesByCategory(`${parent}/${child}`.toLowerCase(), id),
        } as Category;
      });

      return {
        id: parent,
        name: parent,
        children,
      };
    });
  }

  protected pruneCategories(categories: Category[]): Category[] {
    return categories
      .map((category: Category) => {
        const children = category.children.filter((child: Category) => child.articles && child.articles.length > 0);
        if (children.length > 0) {
          return {
            ...category,
            children,
          };
        }

        return null;
      })
      .filter((category: Category | null) => category !== null);
  }

  protected createId(...parts: string[]): string {
    const normalize = (str: string): string => str.toLowerCase().replace(/\s+/g, '-');

    return parts.map((part: string) => normalize(part)).join('/');
  }

  protected createPath(...parts: string[]): string {
    const slugify = (str: string): string =>
      str
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/[^\w\-]/g, '');

    return parts.map((part: string) => slugify(part)).join('/');
  }

  protected createRoutes(categories: Category[]) {
    const root = this.router.config.find((route: Route) => route.path === this.rootPath);
    if (!root) {
      throw new Error('Root route for "Categories" does not exist!');
    }

    const routes = root.children[0].children;
    // Do not set the routes if these are already set
    if (routes && routes.length > 0) {
      return;
    }

    const categoriesRoutes = categories
      .map((category: Category) => category.children)
      .reduce((all, children) => all.concat(children), [])
      .map(
        (child: Category) =>
          ({
            ...child.route,
            component: CategoryPageComponent,
            canActivate: [CategoryPageGuard],
          } as Route)
      );

    const articlesRoutes = categories
      .map((category: Category) => category.children)
      .reduce((all, children) => all.concat(children), [])
      .map((category: Category) =>
        category.articles
          ? category.articles.map(
              (article: Article) =>
                ({
                  ...article.route,
                  component: ArticlePageComponent,
                  canActivate: [ArticlePageGuard],
                } as Route)
            )
          : []
      )
      .reduce((all, current) => all.concat(current), []);

    // Add a "fall-through" route to the bottom of the routes,
    // such that "/categories" route show the first category
    categoriesRoutes.push({
      path: '',
      redirectTo: categoriesRoutes[0].path,
      pathMatch: 'full',
    } as Route);

    root.children = [
      {
        path: '',
        canActivateChild: [CategoryPageGuard],
        children: [...articlesRoutes, ...categoriesRoutes],
      },
    ];
  }
}
