import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, tap, take, switchMap, catchError } from 'rxjs/operators';

import { selectCategory, fetchCategories } from '../store/actions';
import { State, selectCategoryPageLoaded } from '../store/reducers';

@Injectable()
export class CategoryPageGuard {
  constructor(private store$: Store<State>) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const categoryId = next.data['categoryId'];
    if (categoryId) {
      this.store$.dispatch(selectCategory({ id: categoryId }));
    }

    return this.checkStore().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    // Only consider child routes that are articles, since `canActivate()`
    // takes care of categories
    if (childRoute.data && childRoute.data['articleId']) {
      return this.canActivate(childRoute);
    }

    return of(true);
  }

  protected checkStore(): Observable<boolean> {
    return this.store$.pipe(
      select(selectCategoryPageLoaded),
      tap((loaded: boolean) => this.doLoad(loaded)),
      filter((loaded: boolean) => loaded),
      take(1)
    );
  }

  private doLoad(loaded: boolean) {
    if (loaded) {
      return;
    }

    this.store$.dispatch(fetchCategories());
  }
}
