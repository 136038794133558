import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import { ENTER } from '@angular/cdk/keycodes';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable, Subject, merge } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-chips-input',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatInputModule,
    MatAutocompleteModule,
    MatIconModule,
  ],
  templateUrl: './chips-input.component.html',
  styleUrls: ['./chips-input.component.scss'],
})
export class ChipsInputComponent<T> {
  SEPARATOR_KEYS_CODES: number[] = [ENTER];

  ctrl = new FormControl('');

  options$: Observable<T[]>;

  selection$: Observable<void>;

  add$ = new Subject<T>();

  select$ = new Subject<T>();

  @Input() label: string;

  @Input() placeholder: string;

  @Input() options: T[];

  @Input() selection: T[] = [];

  @Input() displayFn: (option: T) => string;

  @Input() filterFn: (item: string | T, list: T[]) => T[];

  @Input() equalFn: (a: T, b: T) => boolean;

  @Output() changes = new EventEmitter<T[]>();

  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  constructor() {
    this.options$ = this.ctrl.valueChanges.pipe(
      startWith(''),
      map((q: string) => (q ? this._filter(q) : this.options.slice()))
    );

    this.selection$ = merge(this.add$, this.select$).pipe(
      distinctUntilChanged((a, b) => this.equalFn(a, b)),
      map((option) => {
        const selection = this.selection.find((item) => this.equalFn(item, option));
        if (selection) {
          this.remove(selection);

          return;
        }

        this.selection = [...this.selection, option];

        this.changes.emit(this.selection);
      })
    );
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim().toLocaleLowerCase();

    const options = this.filterFn(value, this.options);
    if (options.length !== 1) {
      return;
    }

    this.add$.next(options[0]);

    event.chipInput?.clear();

    this.ctrl.setValue(null);
  }

  remove(option: T): void {
    this.selection = this.selection.filter((s) => !this.equalFn(s, option));

    this.changes.emit(this.selection);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const value: T = event.option.value;

    this.select$.next(value);

    this.input.nativeElement.value = '';
    this.ctrl.setValue(null);
  }

  private _filter(value: string): T[] {
    return this.filterFn(value, this.options);
  }
}
