import { Bottle } from '@whiskybazar/core';

import { BottleMissingImagesException } from '../exceptions';

export class BottleValidator {
  validateNew(bottle: Bottle): void {
    if (!bottle.images || bottle.images.length === 0) {
      throw new BottleMissingImagesException();
    }

    // TODO add more validation rules here
  }
}

const bottleValidator = new BottleValidator();
export default bottleValidator;
