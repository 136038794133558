import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BottlePricePipe } from './bottle-price.pipe';

@NgModule({
  declarations: [BottlePricePipe],
  imports: [CommonModule],
  exports: [BottlePricePipe],
})
export class BottlePricePipeModule {}
