<h1 mat-dialog-title i18n>{{ data.title }}</h1>
<div mat-dialog-content>
  <div *ngIf="data.type === 'info'">
    <p>{{ data.message }}</p>
  </div>

  <div *ngIf="data.type === 'error'">
    <mat-error>{{ data.message }}</mat-error>
  </div>
</div>

<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
  <button fxFlex="none" mat-button mat-dialog-close="" i18n>{{ data.button }}</button>
</div>
