import { Pipe, PipeTransform } from '@angular/core';

import { Auction, Sale } from '@whiskybazar/core';

@Pipe({
  name: 'bottleLot',
})
export class BottleLotPipe implements PipeTransform {
  transform(bottle: any, args?: any): any {
    if (typeof bottle !== 'object') {
      return null;
    }

    if (bottle.auction) {
      return ((bottle.auction as Auction).auctionId || '').slice(-5);
    } else if (bottle.sale) {
      return ((bottle.sale as Sale).saleId || '').slice(-5);
    }

    return null;
  }
}
