import { createAction, props } from '@ngrx/store';

import { Category } from '@whiskybazar/pwa/categories/models';

export const loadCategories = createAction('[Categories] Load Categories');

export const loadCategoriesSuccess = createAction(
  '[Categories] Load Categories Success',
  props<{ categories: Category[] }>()
);

export const loadCategoriesFailure = createAction('[Categories] Load Categories Failure', props<{ error: any }>());
