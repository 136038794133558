import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { catchError, exhaustMap, map, withLatestFrom } from 'rxjs/operators';

import { Collector } from '@whiskybazar/core';
import { AuthService } from '@whiskybazar/pwa/core/services';
import * as PhoneVerificationActions from '../actions/phone-verification.actions';
import * as fromStore from '../reducers';
import * as PhoneVerificationSelectors from '../selectors/phone-verification.selectors';

@Injectable()
export class PhoneVerificationEffects {
  initPhoneVerification$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PhoneVerificationActions.initPhoneVerification),
      exhaustMap((action) =>
        this.authService.linkPhoneNumber(action.phoneNumber).pipe(
          map(() => PhoneVerificationActions.initPhoneVerificationSuccess()),
          catchError(() =>
            of(
              PhoneVerificationActions.initPhoneVerificationFailure({
                error: 'Failed to send the verification code! Please try again.',
              })
            )
          )
        )
      )
    );
  });

  confirmVerificationCode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PhoneVerificationActions.confirmVerificationCode),
      exhaustMap(({ code }) =>
        this.authService.confirmVerificationCode(code).pipe(
          map(() => PhoneVerificationActions.confirmVerificationCodeSuccess()),
          catchError(() =>
            of(
              PhoneVerificationActions.confirmVerificationCodeFailure({
                error: 'Verification failed! Please try again.',
              })
            )
          )
        )
      )
    );
  });

  addCollectorPhoneNumber$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PhoneVerificationActions.confirmVerificationCodeSuccess),
        withLatestFrom(combineLatest([this.user$, this.phoneNumber$]), (_, [collector, phoneNumber]) => ({
          collector,
          phoneNumber,
        })),
        exhaustMap(({ collector, phoneNumber }) =>
          this.authService.addCollectorPhoneNumber(collector as Collector, phoneNumber)
        )
      );
    },
    { dispatch: false }
  );

  protected get phoneNumber$() {
    return this.store.pipe(select(PhoneVerificationSelectors.selectPhoneVerificationPhoneNumber));
  }

  protected get user$() {
    return this.store.pipe(select(fromStore.getUser));
  }

  constructor(private actions$: Actions, private authService: AuthService, private store: Store<fromStore.State>) {}
}
