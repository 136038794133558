import { Action } from '@ngrx/store';

import { AuthUser } from '@whiskybazar/core';

export enum AuthActionType {
  Authenticate = '[Auth] Authenticate',
  AuthenticateSuccess = '[Auth] Authenticate Success',
  Login = '[Auth] Login',
  Logout = '[Auth] Logout',
  LoginSuccess = '[Auth] Login Success',
  LoginFailure = '[Auth] Login Failure',
  LoginRedirect = '[Auth] Login Redirect',
}

export class Authenticate implements Action {
  readonly type = AuthActionType.Authenticate;
}

export class AuthenticateSuccess implements Action {
  readonly type = AuthActionType.AuthenticateSuccess;

  constructor(public user: AuthUser) {}
}

export class Login implements Action {
  readonly type = AuthActionType.Login;

  constructor(public email: string, public password: string, public redirectUrl?: string) {}
}

export class LoginSuccess implements Action {
  readonly type = AuthActionType.LoginSuccess;

  constructor(public user: AuthUser) {}
}

export class LoginFailure implements Action {
  readonly type = AuthActionType.LoginFailure;

  constructor(public error: any) {}
}

export class LoginRedirect implements Action {
  readonly type = AuthActionType.LoginRedirect;
}

export class Logout implements Action {
  readonly type = AuthActionType.Logout;
}

export type AuthAction =
  | Authenticate
  | AuthenticateSuccess
  | Login
  | LoginSuccess
  | LoginFailure
  | LoginRedirect
  | Logout;
