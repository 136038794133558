import { inject, Injectable } from '@angular/core';
import { Database } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Mapper, collectorMapper, Collector, CollectorData } from '@whiskybazar/core';
import { AbstractFirebaseList } from './abstract-firebase-list';
import { DatabaseApiService } from './database-api.service';

@Injectable()
export class CollectorsService extends AbstractFirebaseList<Collector, CollectorData> {
  private readonly path = 'collectors';

  private afDb: Database = inject(Database);

  constructor(private dbApi: DatabaseApiService) {
    super();
  }

  getPath(): string {
    return this.path;
  }

  getAngularFireDatabase(): Database {
    return this.afDb;
  }

  getMapper(): Mapper<CollectorData, Collector> {
    return collectorMapper;
  }

  getDatabaseApiService(): DatabaseApiService {
    return this.dbApi;
  }

  create(model: Collector): Observable<Collector> {
    return this.getDatabaseApiService()
      .create<CollectorData, CollectorData>(this.buildPath(model.id), this.getMapper().toDb(model))
      .pipe(map((result: CollectorData) => this.getMapper().fromDb(result)));
  }
}
