import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { CallToActionModule } from '@whiskybazar/pwa-ui';
import { BottlesGridModule, BottlesListModule, CarouselModule, PaginatorModule, SearchModule } from '../shared';
import { HeroSliderModule, components, ChipsInputComponent } from './components';
import { containers } from './containers';
import { guards } from './guards';
import { PublicRoutingModule } from './public-routing.module';
import { effects } from './store/effects';
import { reducers } from './store/reducers';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';

const MATERIAL_MODULES = [
  MatButtonModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatCardModule,
  MatSidenavModule,
  MatSliderModule,
  MatSelectModule,
  MatSnackBarModule,
];

const SHARED = [
  BottlesGridModule,
  BottlesListModule,
  CarouselModule,
  CallToActionModule,
  PaginatorModule,
  SearchModule,
];

@NgModule({
  imports: [
    ...MATERIAL_MODULES,
    CommonModule,
    FlexLayoutModule,
    PublicRoutingModule,
    StoreModule.forFeature('public', reducers),
    EffectsModule.forFeature(effects),
    ...SHARED,
    HeroSliderModule,

    // Standalone components
    ChipsInputComponent,
  ],
  declarations: [...components, ...containers],
  providers: [...guards],
})
export class PublicModule {}
