import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@whiskybazar/pwa/environment';

@Injectable()
export class DatabaseApiService {
  readonly api = '/v1';

  constructor(private http: HttpClient) {}

  getEndpointUrl(prefix: string): string {
    return environment.cloudFunctions + this.api + (prefix.startsWith('/') ? prefix : `/${prefix}`);
  }

  create<T, O>(resource: string, data: T): Observable<O> {
    return this.http.post<O>(this.getEndpointUrl(resource), data);
  }

  update<T, O>(resource: string, data: T): Observable<O> {
    return this.http.put<O>(this.getEndpointUrl(resource), data);
  }

  delete<O>(resource: string): Observable<O> {
    return this.http.delete<O>(this.getEndpointUrl(resource));
  }
}
