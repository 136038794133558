import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  Address,
  auctionTransactionMapper,
  Bottle,
  OpeningHours,
  PackageShop,
  Shipment,
  ShipmentData,
  ShipmentRoute,
  ShipmentRoutePoint,
  ShipmentRoutePointData,
  ShippingOption,
} from '@whiskybazar/core';
import { environment } from '@whiskybazar/pwa/environment';

interface PickupLocation {
  id: string;
  name: string;
  distance: number;
  address: string;
  zip_code: string;
  city: string;
  country: string;
  opening_hours: {
    [day: string]: {
      from: string;
      to: string;
    };
  };
}

interface PickupLocationsResponse {
  locations: PickupLocation[];
}

interface ShippingCostResponse {
  options?: ShippingOption[];
  error?: string;
}

interface BookShippingResponse {
  points?: ShipmentRoutePointData[];
  code?: string;
}

@Injectable()
export class ShippingService {
  private readonly base = '/v1/shipping';
  private readonly endpoints = {
    pickupLocations: '/pickup_locations',
    cost: '/cost',
    shipment: '/shipment/:transactionId',
    cancel: '/shipment/:transactionId/cancel',
  };

  constructor(private http: HttpClient) {}

  fetchPickupLocations(address: Address): Observable<PackageShop[]> {
    const url = this.buildUrl(this.endpoints.pickupLocations);
    const body = { address: address.address, zip_code: address.zipCode };

    return this.http
      .post<PickupLocationsResponse>(url, body)
      .pipe(
        map((res: PickupLocationsResponse) =>
          res.locations
            .map((d: PickupLocation) => this.parsePickupLocation(d))
            .sort((a: PackageShop, b: PackageShop) => a.distance - b.distance)
        )
      );
  }

  fetchShippingCost(sender: Address, receiver: Address): Observable<ShippingOption[]> {
    const url = this.buildUrl(this.endpoints.cost);
    const body = {
      sender: {
        name: sender.name,
        address: sender.address,
        zip_code: sender.zipCode,
        city: sender.city,
        country: sender.country,
        email: sender.email,
        phone: sender.phone,
      },
      receiver: {
        name: receiver.name,
        address: receiver.address,
        zip_code: receiver.zipCode,
        city: receiver.city,
        country: receiver.country,
        email: receiver.email,
        phone: receiver.phone,
      },
    };

    return this.http
      .post<ShippingCostResponse>(url, body)
      .pipe(map((res: ShippingCostResponse) => this.parseShippingCost(res)));
  }

  bookShipping(bottle: Bottle): Observable<ShipmentRoute> {
    const url = this.buildUrl(this.endpoints.shipment, {
      ':transactionId': bottle.auction.transactionId,
    });

    return this.http.get<BookShippingResponse>(url).pipe(map((res: BookShippingResponse) => this.parseBooking(res)));
  }

  addShipment(shipment: Shipment, transactionId: string): Observable<void> {
    const url = this.buildUrl(this.endpoints.shipment, {
      ':transactionId': transactionId,
    });

    const body = {
      id: shipment.id,
      order_id: shipment.orderId,
      package_number: shipment.packageNumber,
      label_url: shipment.labelUrl,
      price: +shipment.price,
    } as ShipmentData;

    return this.http.post<void>(url, body);
  }

  cancelShipment(transactionId: string): Observable<void> {
    const url = this.buildUrl(this.endpoints.cancel, {
      ':transactionId': transactionId,
    });

    return this.http.post<void>(url, {});
  }

  protected parsePickupLocation(location: PickupLocation): PackageShop {
    return {
      id: location.id,
      name: location.name,
      address: {
        address: location.address,
        zipCode: location.zip_code,
        city: location.city,
        country: location.country,
      },
      distance: location.distance,
      openingHours: Object.keys(location.opening_hours).map(
        (day) =>
          ({
            day,
            from: location.opening_hours[day].from,
            to: location.opening_hours[day].to,
          } as OpeningHours)
      ),
    } as PackageShop;
  }

  protected parseShippingCost(res: ShippingCostResponse): ShippingOption[] {
    if (res.error) {
      throw new Error(res.error);
    }

    return res.options;
  }

  protected parseBooking(res: BookShippingResponse): ShipmentRoute {
    if (res.code) {
      throw new Error(res.code);
    }

    const points: ShipmentRoutePoint[] = res.points.map((point: ShipmentRoutePointData) =>
      auctionTransactionMapper.fromDbShipmentPoint(point)
    );
    return { points };
  }

  buildUrl(endpoint: string, params?: { [key: string]: string }): string {
    let url = `${environment.cloudFunctions}${this.base}${endpoint}`;
    if (params) {
      Object.keys(params).forEach((k: string) => (url = url.replace(k, params[k])));
    }

    return url;
  }
}
