import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Observable, EMPTY } from 'rxjs';
import { tap, catchError, finalize } from 'rxjs/operators';

import { Image, EditImagePermissions } from '@whiskybazar/core';
import { ImageTools, ImageRotation } from '@whiskybazar/pwa/utils';

@Component({
  selector: 'app-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.scss'],
})
export class ImageEditorComponent implements OnInit {
  @Input()
  set image(value: Image) {
    this._image = { ...value };
  }

  /**
   * @deprecated use `permissions` instead
   */
  @Input() admin: boolean;

  @Input() permissions: EditImagePermissions = {
    delete: true,
    rotate: true,
    promote: false,
  };

  @Output() readonly deleted = new EventEmitter<Image>();

  @Output() readonly rotated = new EventEmitter<Image>();

  @Output() readonly promoted = new EventEmitter<Image>();

  @Output() readonly errorMsg = new EventEmitter<string>();

  image$: Observable<string>;

  get pending(): boolean {
    return this.image.pending || this.rotating;
  }

  private rotating: boolean;

  get image(): Image {
    return this._image;
  }
  private _image: Image;

  constructor() {
    this.rotating = false;
    this.admin = false;
  }

  ngOnInit() {}

  delete() {
    this.image.pending = true;
    this.deleted.emit(this.image);
  }

  promote() {
    this.image.pending = true;
    this.promoted.emit(this.image);
  }

  rotateLeft() {
    this.rotate(ImageRotation.Left);
  }

  rotateRight() {
    this.rotate(ImageRotation.Right);
  }

  protected rotate(direction: ImageRotation) {
    this.image.pending = true;
    this.rotating = true;
    try {
      this.image$ = ImageTools.rotate(this.image.servingUrl, direction).pipe(
        tap((base64Data: string) => this.rotated.emit({ ...this.image, base64Data })),
        catchError((error) => {
          this.errorMsg.emit(error);
          return EMPTY;
        }),
        tap(() => (this.rotating = false)),
        finalize(() => {
          this.rotating = false;
          this.image.pending = false;
        })
      );
    } catch (error) {
      this.rotating = false;
      this.image.pending = false;
      this.errorMsg.emit(error);
    }
  }
}
