import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';

import { AuthContextModule } from '../../pipes';
import { BottleStatePipeModule } from '../../pipes/bottle-state/bottle-state-pipe.module';
import { BottleDeleteButtonModule } from '../bottle-delete-button/bottle-delete-button.module';
import { BottleEditButtonModule } from '../bottle-edit-button/bottle-edit-button.module';
import { BottlePreviewModule } from '../bottle-preview/bottle-preview.module';
import { CallToPurchaseModule } from '../call-to-purchase/call-to-purchase.module';
import { BottlesListComponent } from './bottles-list.component';

@NgModule({
  declarations: [BottlesListComponent],
  imports: [
    CommonModule,
    CallToPurchaseModule,
    BottlePreviewModule,
    BottleEditButtonModule,
    BottleDeleteButtonModule,
    BottleStatePipeModule,
    AuthContextModule,
    RouterModule,
    FlexLayoutModule,
    MatButtonModule,
  ],
  exports: [BottlesListComponent],
})
export class BottlesListModule {}
