import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { AUCTION_RUNTIMES, AUCTION_STATES } from '@whiskybazar/core';
import { Auction, Dealer } from '@whiskybazar/core';

@Component({
  selector: 'app-auction-form',
  templateUrl: './auction-form.component.html',
  styleUrls: ['./auction-form.component.scss'],
})
export class AuctionFormComponent implements OnInit {
  @Input()
  set value(val: Auction | null) {
    if (val) {
      this.updateForm(val);
    }

    this.auction = val;
  }

  @Input()
  set pending(val: boolean) {
    if (val) {
      this.form.disable();
    } else {
      this.form.enable();
    }

    this.isPending = val;
  }

  @Input() auctioneers: Dealer[];

  @Input() action = 'SEND';

  @Output() readonly submitted: EventEmitter<Auction>;

  form: UntypedFormGroup;

  runtimes: { id: string; title: string }[];

  isPending: boolean;

  auction: Auction;

  constructor() {
    this.submitted = new EventEmitter<Auction>();

    this.form = new UntypedFormGroup({
      id: new UntypedFormControl(null),
      minPrice: new UntypedFormControl(null, [Validators.required, Validators.min(1)]),
      runtime: new UntypedFormControl(AUCTION_RUNTIMES.runtime_4.id, [Validators.required]),
      state: new UntypedFormControl(AUCTION_STATES.DRAFT.id),
    });

    this.runtimes = [];
    Object.keys(AUCTION_RUNTIMES).forEach((k: string) =>
      this.runtimes.push({ id: k, title: AUCTION_RUNTIMES[k].title })
    );

    this.isPending = false;
  }

  ngOnInit() {
    if (this.auctioneers && this.auctioneers.length) {
      this.form.addControl(
        'auctioneer',
        new UntypedFormControl(this.auction ? this.auction.auctioneer : null, [Validators.required])
      );
    }
  }

  submit() {
    this.submitted.emit(this.form.value as Auction);
  }

  compare(opt: Dealer | null, selection: Dealer | null): boolean {
    if (!opt || !selection) {
      return false;
    }

    return opt.id === selection.id;
  }

  protected updateForm(val: Auction) {
    Object.keys(val).forEach((k: string) => {
      if (!this.form.contains(k) || !val[k]) {
        return;
      }

      const ctrl = this.form.get(k).setValue(val[k]);
    });
  }
}
