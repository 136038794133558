<mat-card appearance="outlined">
  <img mat-card-image [src]="'/assets/images/categories/' + article.image" [alt]="article.title" />
  <mat-card-content>
    <h2>{{ article.title }}</h2>
    <p>{{ article.summary }}</p>
  </mat-card-content>
  <mat-card-actions align="end">
    <a mat-button [routerLink]="article.path">Read More</a>
  </mat-card-actions>
</mat-card>
