import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BottleImagesEditorComponent } from './bottle-images-editor.component';
import { ImageEditorModule } from '../image-editor/image-editor.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [BottleImagesEditorComponent],
  imports: [CommonModule, ImageEditorModule, FlexLayoutModule, MatIconModule, MatButtonModule],
  exports: [BottleImagesEditorComponent],
})
export class BottleImagesEditorModule {}
