<app-hero-slider></app-hero-slider>

<div class="container flex justify-center m-auto">
  <div>
    <div>
      <a id="auctions">&nbsp;</a>
      <h1>LIVE AUCTIONS</h1>
      <p class="subheading">Here you find our latest auction items from all over the world.</p>
      <app-bottles
        [bottles]="bottles$ | async"
        [loading]="loading$ | async"
        [searching]="searching$ | async"
        [query]="query$ | async"
        [distilleriesFilter]="distilleriesFilter$ | async"
        [bottlersFilter]="bottlersFilter$ | async"
        [regionsFilter]="regionsFilter$ | async"
        [typesFilter]="typesFilter$ | async"
        [priceFilter]="priceFilter$ | async"
        [sorting]="sorting$ | async"
        [total]="total$ | async"
        (queryChange)="onQueryChange($event)"
        (distilleriesChange)="onDistilleriesChange($event)"
        (bottlersChange)="onBottlersChange($event)"
        (regionsChange)="onRegionsChange($event)"
        (typesChange)="onTypesChange($event)"
        (priceChange)="onPriceChange($event)"
        (sortingChange)="onSortingChange($event)"
        (clearFilters)="onClearFilters()"
      >
        <div class="mt-8">
          <app-paginator [hasMore]="hasMore$ | async" [loading]="loading$ | async" (more)="loadMore()"></app-paginator>
        </div>
      </app-bottles>
    </div>

    <div class="content">
      <h2>LIVE ON WHISKYBAZAR.COM</h2>
      <p class="subheading">Probably the Best Online Whisky Auction</p>
      <p>
        WhiskyBazar provides numerous of rare high-end auction items, as well as more widely known brands to serve your
        needs. Whether you are looking for a limited Scottish single malt or burbon you'll find just the bottle you need
        here.
      </p>
      <p>
        If you are looking for a specific bottle that is not yet to be found here, don't hesitate to contact us. We'll
        do our best to have it in no time.
      </p>
    </div>
  </div>
</div>

<!-- NOTE: this ensures cleanup of the subscription -->
<ng-container *ngIf="error$ | async"></ng-container>
