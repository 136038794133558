import { Component, OnInit, Input } from '@angular/core';

import { Bottle, States, StatesObject } from '@whiskybazar/core';
import { toStates } from '@whiskybazar/pwa/utils';
import { BOTTLE_STATES, AUCTION_STATES, TRANSACTION_STATES } from '@whiskybazar/core';

@Component({
  selector: 'app-bottle-state',
  templateUrl: './bottle-state.component.html',
  styleUrls: ['./bottle-state.component.scss'],
})
export class BottleStateComponent implements OnInit {
  @Input() bottle: Bottle;

  @Input() elaborate = false;

  readonly bottleStates: States = toStates(BOTTLE_STATES as StatesObject);

  readonly auctionStates: States = toStates(AUCTION_STATES as StatesObject);

  readonly transactionStates: States = toStates(TRANSACTION_STATES as StatesObject);

  constructor() {}

  ngOnInit() {}
}
