import { Component } from '@angular/core';

import { TRANSACTION_STATES } from '@whiskybazar/core';
import { StatesObject, States } from '@whiskybazar/core';
import { toStates } from '@whiskybazar/pwa/utils';
import { AuctionDetailsComponent } from '../auction-details/auction-details.component';

@Component({
  selector: 'app-auction-results',
  templateUrl: './auction-results.component.html',
  styleUrls: ['./auction-results.component.scss'],
})
export class AuctionResultsComponent extends AuctionDetailsComponent {
  readonly transactionStates: States = toStates(TRANSACTION_STATES as StatesObject);
}
