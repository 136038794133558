import { StatesObject, States, StateId } from '@whiskybazar/core';

/**
 * Reduces the given states object to an object of StateId => StateId
 *
 * **Example:**
 * ```ts
 * const bottleStates = toStates(BOTTLE_STATES);
 * bottleStates.DRAFT; // instead of BOTTLE_STATES.DRAFT.id
 * ```
 * @param {StatesObject} statesObj The states object to transform
 * @returns {States} The resulting states
 */
export function toStates(states: StatesObject): States {
  return Object.keys(states).reduce(
    (output: States, key: StateId) => ({ ...output, [key]: states[key].id } as States),
    {} as States
  );
}
