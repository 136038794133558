import { Pipe, PipeTransform } from '@angular/core';

import { TRANSACTION_STATES } from '@whiskybazar/core';

@Pipe({
  name: 'transactionState',
})
export class TransactionStatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    switch (value) {
      case TRANSACTION_STATES.PENDING_PAYMENT.id:
      case TRANSACTION_STATES.CANCELLED.id:
      case TRANSACTION_STATES.COMPLETED.id:
        return TRANSACTION_STATES[value].name;

      default: {
        // TODO return the actual state when auth is an admin

        return 'Pending Settlement';
      }
    }
  }
}
