<ng-template #countryName>
  <ng-container *ngIf="country">{{ bottle | bottleCountry : country }}, </ng-container
  ><a routerLink="/dealers/{{ bottle | authContext : 'getDealerId' }}">{{ bottle | bottleDealer }}</a>
</ng-template>

<ng-template #nameCountry>
  <a routerLink="/dealers/{{ bottle | authContext : 'getDealerId' }}">{{ bottle | bottleDealer }}</a
  ><ng-container *ngIf="country">, {{ bottle | bottleCountry : country }}</ng-container>
</ng-template>

<!--
  ACTUAL TEMPLATE
 -->
<ng-container [ngSwitch]="layout">
  <ng-container *ngSwitchCase="'country_name'">
    <ng-container *ngTemplateOutlet="countryName"></ng-container>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <ng-container *ngTemplateOutlet="nameCountry"></ng-container>
  </ng-container>
</ng-container>
