import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';

import { MetaBottle, BarcodeTitle } from '@whiskybazar/core';

@Component({
  selector: 'app-barcode-title-form',
  templateUrl: './barcode-title-form.component.html',
  styleUrls: ['./barcode-title-form.component.scss'],
})
export class BarcodeTitleFormComponent implements OnInit {
  @Input() set value(val: BarcodeTitle) {
    if (val) {
      this.form.patchValue(val);
    }
  }

  @Input() results: MetaBottle[];

  @Output() readonly searched = new EventEmitter<string>();

  @Output() readonly selected = new EventEmitter<MetaBottle>();

  @Output() readonly submitted = new EventEmitter<BarcodeTitle>();

  query$: Observable<string>;

  form: UntypedFormGroup;

  constructor() {
    this.form = new UntypedFormGroup({
      barcode: new UntypedFormControl('', Validators.maxLength(13)),
      title: new UntypedFormControl('', Validators.required),
    });
  }

  ngOnInit() {
    const barcodeCtrl = this.form.get('barcode') as UntypedFormControl;
    const titleCtrl = this.form.get('title') as UntypedFormControl;

    this.query$ = merge(barcodeCtrl.valueChanges, titleCtrl.valueChanges).pipe(
      debounceTime(150),
      distinctUntilChanged(),
      filter((v: any) => typeof v === 'string'),
      tap((q: string) => this.searched.emit(q))
    );
  }

  barcodeDisplayFn(value?: any): string | undefined {
    return typeof value === 'string' ? value : value && value.barcode ? value.barcode : undefined;
  }

  titleDisplayFn(value?: any): string | undefined {
    return typeof value === 'string' ? value : value && value.title ? value.title : undefined;
  }

  onSelected($event: MatAutocompleteSelectedEvent): void {
    const metaBottle: MetaBottle = $event.option.value as MetaBottle;

    this.selected.emit(metaBottle);
    this.form.patchValue(
      {
        barcode: metaBottle.barcode,
        title: metaBottle.title,
      } as BarcodeTitle,
      { emitEvent: false }
    );
  }

  submit() {
    if (this.form.invalid) {
      return;
    }

    const value: BarcodeTitle = this.form.value as BarcodeTitle;
    this.submitted.emit(value);
  }

  trackByMetaBottle(index: number, value: MetaBottle): string {
    return value.id;
  }
}
