import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoiceAddressComponent } from './invoice-address.component';

@NgModule({
  declarations: [InvoiceAddressComponent],
  imports: [CommonModule],
  exports: [InvoiceAddressComponent],
})
export class InvoiceAddressModule {}
