import { Component, Input, OnInit } from '@angular/core';

import { Address, Bottle, BuyerAuctionInvoice, calcBuyerInvoiceTotal } from '@whiskybazar/core';
import { InvoiceView } from '@whiskybazar/pwa/shared/common';

@Component({
  selector: 'app-buyer-invoice',
  templateUrl: './buyer-invoice.component.html',
  styleUrls: ['./buyer-invoice.component.scss'],
})
export class BuyerInvoiceComponent extends InvoiceView implements OnInit {
  @Input() set bottle(val: Bottle) {
    this._bottle = val;
    this.init(this.bottle);
  }

  @Input() full = true;

  invoice: BuyerAuctionInvoice;

  billing: Address;

  shipping: Address;

  get bottle(): Bottle {
    return this._bottle;
  }
  private _bottle: Bottle;

  constructor() {
    super();
  }

  ngOnInit() {}

  calcTotal(): number {
    return calcBuyerInvoiceTotal(this.invoice);
  }

  protected init(bottle: Bottle) {
    if (!bottle) {
      return;
    }

    super.init(bottle);

    this.invoice = this.buyerInvoice;
    this.billing = this.invoice ? this.invoice.billingAddress : null;
    this.shipping = this.invoice && this.invoice.shippingAddress ? this.invoice.shippingAddress : this.billing;
  }
}
