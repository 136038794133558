import {
  AuctioneerMonthlyInvoice,
  AuctioneerMonthlyInvoiceAuction,
  AuctioneerMonthlyInvoiceAuctionData,
  AuctioneerMonthlyInvoiceAuctions,
  AuctioneerMonthlyInvoiceData,
  AuctioneerMonthlyInvoicePaymentMethod,
  AuctioneerMonthlyInvoicePaymentMethods,
} from '../models';
import { Mapper } from './mapper';

export class AuctioneerMonthlyInvoiceMapper implements Mapper<AuctioneerMonthlyInvoiceData, AuctioneerMonthlyInvoice> {
  fromDb(data: AuctioneerMonthlyInvoiceData | null): AuctioneerMonthlyInvoice | null {
    if (!data) {
      return null;
    }

    return {
      id: data.id,
      auctioneerId: data.auctioneer,
      createdAt: data.created_at ? new Date(data.created_at) : null,
      updatedAt: data.updated_at ? new Date(data.updated_at) : null,
      invoiceId: data.invoice_id,
      invoiceDate: data.invoice_date ? new Date(data.invoice_date) : null,
      paymentDueAt: data.payment_due_at ? new Date(data.payment_due_at) : null,
      paymentMethods: this.fromDbPaymentMethods(data.payment_methods),
      auctions: this.fromDbAuctions(data.auctions),
      taxFreeAmount: data.tax_free_amount,
      taxableAmount: data.taxable_amount,
      vatRate: data.vat_rate,
      vatAmount: data.vat_amount,
      vatAmountDkk: data.vat_amount_dkk,
      exchangeRate: data.exchange_rate,
      totalExclVat: data.total_excl_vat,
      totalInclVat: data.total_incl_vat,
      totalInclVatDkk: data.total_incl_vat_dkk,
      totalExclVatDkk: data.total_excl_vat_dkk,
    };
  }

  toDb(data: AuctioneerMonthlyInvoice | null): AuctioneerMonthlyInvoiceData | null {
    return null;
  }

  protected fromDbPaymentMethods(
    data: AuctioneerMonthlyInvoicePaymentMethods | null
  ): AuctioneerMonthlyInvoicePaymentMethod[] {
    if (!data || !data.bank_transfer) {
      return null;
    }

    const {
      bank_transfer: { name, iban, bic },
    } = data;

    return [
      {
        name,
        iban,
        bic,
      },
    ];
  }

  fromDbAuctions(data: AuctioneerMonthlyInvoiceAuctions | null): AuctioneerMonthlyInvoiceAuction[] {
    if (!data) {
      return null;
    }

    return Object.keys(data).map((key) => this.fromDbAuction(data[key]));
  }

  fromDbAuction(data: AuctioneerMonthlyInvoiceAuctionData | null): AuctioneerMonthlyInvoiceAuction {
    if (!data) {
      return null;
    }

    return {
      id: data.id,
      auctionId: data.auction_id,
      title: data.title,
      hammerPrice: data.hammer_price,
      variableProfit: data.variable_profit,
      fixedProfit: data.fixed_profit,
      variableRate: data.variable_rate,
    };
  }
}

export const auctioneerMonthlyInvoiceMapper = new AuctioneerMonthlyInvoiceMapper();
