import { Pipe, PipeTransform } from '@angular/core';

import { AUCTION_RUNTIMES } from '@whiskybazar/core';

@Pipe({
  name: 'auctionRuntime',
})
export class AuctionRuntimePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (AUCTION_RUNTIMES[value]) {
      return AUCTION_RUNTIMES[value].title;
    }

    return 'Unknown';
  }
}
