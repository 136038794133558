import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { FooterNavComponent } from './footer-nav.component';

@NgModule({
  declarations: [FooterNavComponent],
  imports: [CommonModule, FlexLayoutModule, RouterModule],
  exports: [FooterNavComponent],
})
export class FooterNavModule {}
