import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { Address } from '@whiskybazar/core';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressComponent implements OnInit {
  @Input() address: Address;

  @Input() full = false;

  @Input() crn: string;

  constructor() {}

  ngOnInit() {}
}
