import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageUploaderComponent } from './image-uploader.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxPicaModule } from '@digitalascetic/ngx-pica';

@NgModule({
  declarations: [ImageUploaderComponent],
  imports: [CommonModule, MatProgressSpinnerModule, MatIconModule, FlexLayoutModule, NgxPicaModule],
  exports: [ImageUploaderComponent],
})
export class ImageUploaderModule {}
