import { Pipe, PipeTransform } from '@angular/core';

import { AuthContextService } from '@whiskybazar/pwa/core/services';
import { Bottle } from '@whiskybazar/core';
import { AUCTION_STATES, BOTTLE_STATES } from '@whiskybazar/core';

@Pipe({
  name: 'bottleState',
})
export class BottleStatePipe implements PipeTransform {
  constructor(private ctx: AuthContextService) {}

  transform(value: any, args?: any): any {
    const bottle: Bottle = args as Bottle;

    if (bottle && value === BOTTLE_STATES.PENDING_AUCTIONEER_ACCEPTANCE.id) {
      if (bottle.auction.state === AUCTION_STATES.REJECTED.id) {
        return AUCTION_STATES.REJECTED.name;
      }

      return this.ctx.isAuctioneer(bottle)
        ? 'Pending Your Acceptance'
        : BOTTLE_STATES.PENDING_AUCTIONEER_ACCEPTANCE.name;
    } else if (this.ctx.isAdmin() && value === BOTTLE_STATES.PENDING_PICKUP_AT_AUCTIONEER.id) {
      return `${BOTTLE_STATES[value].name} at Auctioneer`;
    } else if (BOTTLE_STATES[value]) {
      return BOTTLE_STATES[value].name;
    }

    return 'N/A';
  }
}
