import { AuctioneerAuctionInvoice } from '../models';

/**
 * Calculates and returns the subtotal amount based on the given invoice
 *
 * @param invoice Auctioneer auction invoice from which to calculate the subtotal
 */
export function calcAuctioneerInvoiceSubtotal(invoice: AuctioneerAuctionInvoice): number {
  const details = invoice.details || {};

  return [details.variableProfit, details.fixedProfit].reduce((sum: number, a: number) => sum + a);
}

/**
 * Calculates and returns the total amount based on the given invoice
 *
 * @param invoice Auctioneer auction invoice from which to calculate the total
 */
export function calcAuctioneerInvoiceTotal(invoice: AuctioneerAuctionInvoice): number {
  return [calcAuctioneerInvoiceSubtotal(invoice), invoice.vatAmount ? invoice.vatAmount : 0.0].reduce(
    (sum: number, a: number) => sum + a
  );
}
