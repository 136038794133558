import { Dealer, DealerData, DealerMonthlyInvoice, DealerMonthlyInvoices } from '../models';
import { Mapper } from './mapper';

export class DealerMapper implements Mapper<DealerData, Dealer> {
  fromDb(data: DealerData | null): Dealer | null {
    if (!data) {
      return null;
    }

    return {
      id: data.id,
      type: 'dealer',
      name: data.company_name,
      email: data.company_email,
      phone: data.company_phone,
      address: data.address,
      zipCode: data.zip_code,
      city: data.city,
      country: data.country,
      companyNumber: data.company_number,
      companyLogo: data.company_logo,
      generalDescription: data.general_description,
      state: data.state,
      createdAt: data.created_at ? new Date(data.created_at) : null,
      updatedAt: data.updated_at ? new Date(data.updated_at) : null,
      bankAccount: data.bank_account ? data.bank_account : null,
      monthlyInvoices: this.fromDbMonthlyInvoices(data.monthly_invoices),
    };
  }

  toDb(model: Dealer | null): DealerData | null {
    return null;
    // TODO impl.
  }

  protected fromDbMonthlyInvoices(data: DealerMonthlyInvoices | null): DealerMonthlyInvoice[] | null {
    if (!data) {
      return null;
    }

    return Object.keys(data).map((id) => {
      const { type, invoice_date, invoice_id } = data[id];

      return {
        id,
        type,
        invoiceDate: invoice_date ? new Date(invoice_date) : null,
        invoiceId: invoice_id,
      };
    });
  }
}

export const dealerMapper = new DealerMapper();
