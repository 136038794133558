import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit {
  @Input() hasMore: boolean;

  @Input() loading: boolean;

  @Output() readonly more = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {}

  loadMore() {
    this.more.emit();
  }
}
