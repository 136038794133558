import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { Auction, Bid } from '@whiskybazar/core';

@Component({
  selector: 'app-bid-form',
  templateUrl: './bid-form.component.html',
  styleUrls: ['./bid-form.component.scss'],
})
export class BidFormComponent implements OnInit {
  @Input()
  set auction(val: Auction | null) {
    if (val) {
      const amount = val.bestBid ? val.bestBid.amount + 1 : val.minPrice;
      const amountCtrl = this.form.get('amount');
      amountCtrl.setValidators(Validators.min(amount));
      amountCtrl.setValue(amount);

      const auctionIdCtrl = this.form.get('auctionId');
      auctionIdCtrl.setValue(val.id);
    }
  }

  @Input()
  set pending(val: boolean) {
    if (val) {
      this.form.disable();
    } else {
      this.form.enable();
    }

    this.isPending = val;
  }

  @Input() errorMessage: string | null;

  @Output() readonly submitted: EventEmitter<Bid>;

  form: UntypedFormGroup;

  isPending: boolean;

  constructor() {
    this.submitted = new EventEmitter<Bid>();

    this.form = new UntypedFormGroup({
      id: new UntypedFormControl(null),
      amount: new UntypedFormControl(null, [Validators.required]),
      maxBid: new UntypedFormControl(null),
      auctionId: new UntypedFormControl(null),
      bidder: new UntypedFormControl(null),
    });
  }

  ngOnInit() {}

  submit() {
    this.submitted.emit(this.form.value as Bid);
  }
}
