import { Database, list, object, query, Query, ref, QueryConstraint } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';

import { Mapper } from '@whiskybazar/core';
import { DatabaseApiService } from './database-api.service';

export abstract class AbstractFirebaseList<M, D> {
  abstract getPath(): string;

  abstract getAngularFireDatabase(): Database;

  abstract getMapper(): Mapper<D, M>;

  getDatabaseApiService(): DatabaseApiService {
    throw Error('Not implemented! This method must be implemented for classes specializing this class.');
  }

  buildPath(suffix?: string | null): string {
    return suffix ? `${this.getPath()}/${suffix}` : this.getPath();
  }

  fetchById(id: string): Observable<M | null> {
    return object(ref(this.getAngularFireDatabase(), this.buildPath(id))).pipe(
      map((change) => change.snapshot.val()),
      map((data: D | null) => this.getMapper().fromDb(data))
    );
  }

  query(...q: QueryConstraint[]): Observable<M[]> {
    const baseQuery = ref(this.getAngularFireDatabase(), this.getPath());
    return list(query(baseQuery, ...q)).pipe(
      map((changes) => changes.map((change) => change.snapshot.val())),
      map((result: D[]) => result.map((data: D) => this.getMapper().fromDb(data)))
    );
  }

  create(model: M): Observable<M> {
    return this.getDatabaseApiService()
      .create<D, D>(this.getPath(), this.getMapper().toDb(model))
      .pipe(map((result: D) => this.getMapper().fromDb(result)));
  }

  update(id: string, model: M): Observable<M> {
    return this.getDatabaseApiService()
      .update<D, D>(this.buildPath(id), this.getMapper().toDb(model))
      .pipe(map((result: D) => this.getMapper().fromDb(result)));
  }

  remove(id: string): Observable<boolean> {
    return this.getDatabaseApiService().delete<any>(this.buildPath(id)).pipe(mapTo(true));
  }
}
