<div #slider class="slider">
  <app-carousel [width]="width$ | async" [delay]="5" [auto]="auto$ | async">
    <ng-container *ngFor="let slide of slides$ | async">
      <ng-container *appCarouselItem>
        <div
          class="slide"
          [ngStyle]="slide.style"
          [style.width]="width$ | async"
          fxLayout="column"
          fxLayoutAlign="center stretch"
        >
          <div class="content" fxFlex="none">
            <div class="caption" [innerHTML]="slide.safeCaption"></div>
            <p class="text" *ngIf="slide.text" [innerHTML]="slide.safeText"></p>
          </div>
          <div class="action" fxFlex="none" fxLayout="column" fxLayoutAlign="center center">
            <app-call-to-action [size]="'hero'" [arrow]="false" (click)="handleNavigation(slide)">{{
              slide.action
            }}</app-call-to-action>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </app-carousel>
</div>
<ng-container *ngIf="resizing$ | async"></ng-container>
