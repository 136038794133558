import { createFeatureSelector, createSelector, MetaReducer } from '@ngrx/store';

import * as fromRoot from '@whiskybazar/pwa/store';
import * as fromAuth from './auth.reducers';
import * as fromForgotPass from './forgot-pass.reducers';
import * as fromLoginPage from './login-page.reducers';
import { metaReducersList } from './meta.reducers';
import * as fromPhoneVerification from './phone-verification.reducer';

export interface AuthState {
  status: fromAuth.State;
  loginPage: fromLoginPage.State;
  forgotPass: fromForgotPass.State;
  phoneVerification: fromPhoneVerification.State;
}

export interface State extends fromRoot.State {
  auth: AuthState;
}

export const metaReducers: MetaReducer<AuthState>[] = metaReducersList;

export const reducers = {
  status: fromAuth.reducer,
  loginPage: fromLoginPage.reducer,
  forgotPass: fromForgotPass.reducer,
  phoneVerification: fromPhoneVerification.reducer,
};

export const selectAuthState = createFeatureSelector<AuthState>('auth');

// Status selectors
export const selectAuthStatusState = createSelector(selectAuthState, (state: AuthState) => state.status);

export const getLoggedIn = createSelector(selectAuthStatusState, fromAuth.getLoggedIn);

export const getUser = createSelector(selectAuthStatusState, fromAuth.getUser);

// Login Page selectors
export const selectLoginPageState = createSelector(selectAuthState, (state: AuthState) => state.loginPage);

export const getLoginPageError = createSelector(selectLoginPageState, fromLoginPage.getError);

export const getLoginPagePending = createSelector(selectLoginPageState, fromLoginPage.getPending);

// Forgot Pass selectors
export const selectForgotPassState = createSelector(selectAuthState, (state: AuthState) => state.forgotPass);

export const selectForgotPassSent = createSelector(selectForgotPassState, fromForgotPass.getSent);

export const selectForgotPassError = createSelector(selectForgotPassState, fromForgotPass.getError);

export const selectForgotPassPending = createSelector(selectForgotPassState, fromForgotPass.getPending);
