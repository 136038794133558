import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, tap, shareReplay, filter, switchMap, delay } from 'rxjs/operators';

import { Category } from '@whiskybazar/pwa/categories/models';
import * as fromStore from '@whiskybazar/pwa/categories/store';

@Component({
  selector: 'app-categories-page',
  templateUrl: './categories-page.component.html',
  styleUrls: ['./categories-page.component.scss'],
})
export class CategoriesPageComponent implements OnInit {
  categories$: Observable<Category[]>;

  loaded$: Observable<boolean>;

  isMobile$: Observable<boolean>;

  backdrop$ = new Subject<boolean>();

  opened$ = new Subject<boolean>();

  mode$ = new Subject<'side' | 'over'>();

  private readonly breakpoint = '(max-width: 425px)';

  constructor(private store$: Store<fromStore.State>, private breakpointObserver: BreakpointObserver) {}

  ngOnInit() {
    this.categories$ = this.store$.pipe(select(fromStore.selectCategoriesPageCategories));

    this.loaded$ = this.store$.pipe(select(fromStore.selectCategoriesPageLoaded));

    this.isMobile$ = this.loaded$.pipe(
      filter((loaded: boolean) => loaded),
      switchMap(() =>
        this.breakpointObserver.observe(this.breakpoint).pipe(
          map((result: BreakpointState) => result.matches),
          delay(0),
          tap((result: boolean) => {
            if (result) {
              this.backdrop$.next(true);
              this.opened$.next(false);
              this.mode$.next('over');
            } else {
              this.backdrop$.next(false);
              this.opened$.next(true);
              this.mode$.next('side');
            }
          }),
          shareReplay(1)
        )
      )
    );
  }
}
