<!-- Display bottle condition -->
<ng-template #condition let-bottle> <span>Condition:</span> {{ bottle?.condition | bottleCondition }} </ng-template>

<!-- Display Runtime for the owner of the bottle and Condition for the auctioneer -->
<ng-template #conditionRuntime let-bottle>
  <ng-container *ngIf="bottle | authContext : 'isOwner'; else auctioneer">
    <span>Runtime:</span> {{ bottle?.auction?.runtime | auctionRuntime }}
  </ng-container>
  <ng-template #auctioneer>
    <ng-container *ngTemplateOutlet="condition; context: { $implicit: bottle }"></ng-container>
  </ng-template>
</ng-template>

<!-- Display min. price (aka starting bid) of bottle auction -->
<ng-template #minPrice let-bottle>
  <span>Starting Bid:</span>&nbsp;
  <app-currency [amount]="bottle.auction.minPrice"></app-currency>
</ng-template>

<!-- Display bids count of bottle auction -->
<ng-template #bids let-bottle> <span>Bids:</span> {{ bottle | authContext : 'getTotalBidsCount' }} </ng-template>

<!-- Display current bid (aka best bid) of bottle auction -->
<ng-template #currentBid let-bottle>
  <span>Current Bid:</span>&nbsp;
  <app-currency [amount]="bottle?.auction?.bestBid?.amount"></app-currency>
</ng-template>

<!-- Display max. bid of the bidder -->
<ng-template #maxBid let-bottle>
  <span>Max. Bid:</span>&nbsp;
  <app-currency [amount]="bottle | authContext : 'getMaxBid'"></app-currency>
</ng-template>

<!-- Display hammer price (aka winning bid) of the bottle auction -->
<ng-template #hammerPrice let-bottle>
  <span>Winning bid:</span>&nbsp;
  <app-currency [amount]="bottle | authContext : 'getHammerPrice'"></app-currency>
</ng-template>

<!-- Display Max. Bid for the buyer and Bids for anyone else -->
<ng-template #maxBidBids let-bottle>
  <ng-container *ngIf="bottle | authContext : 'hasWonOrLost'; else notWinnerLosser">
    <ng-container *ngTemplateOutlet="maxBid; context: { $implicit: bottle }"></ng-container>
  </ng-container>
  <ng-template #notWinnerLosser>
    <ng-container *ngTemplateOutlet="bids; context: { $implicit: bottle }"></ng-container>
  </ng-template>
</ng-template>

<!--
  ACTUAL CARD
 -->
<mat-card appearance="outlined" fxFlex ngClass.lt-sm="small">
  <div fxFlexFill fxLayout="row" fxLayoutGap="24px" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="0px">
    <!-- Bottle Image Thumbnail -->
    <div fxFlex="none" fxLayoutAlign="center center" class="thumbnail" ngClass.lt-sm="small">
      <img [appImageThumbnail]="bottle | bottlePromotedImage" thumbnailSize="240x240" />
    </div>

    <!-- Main Content Container -->
    <div fxFlex="grow" fxLayout="column" fxLayoutAlign="start stretch" style="padding-top: 0.5rem">
      <!-- Header -->
      <div class="header" fxFlex="nogrow" fxLayout="row" fxLayoutAlign="start start">
        <!-- Bottle Title -->
        <div fxFlex="nogrow">
          <h2 ngClass.gt-md="mat-headline-5">{{ bottle?.metaBottle?.title }}</h2>

          <div class="subtitle mat-small">
            <ng-container *ngIf="bottle.auction; else noAuction">
              LOT: {{ bottle | bottleLot
              }}<ng-container *ngIf="!(bottle | authContext : 'isAuctioneer')"
                >, <app-bottle-dealer [bottle]="bottle"></app-bottle-dealer
              ></ng-container>
            </ng-container>
            <ng-template #noAuction> Barcode: {{ bottle?.metaBottle?.barcode || 'N/A' }} </ng-template>
          </div>
        </div>
        <!-- Custom Header -->
        <div fxFlex="noshrink" fxLayout="row" fxLayoutAlign="end start">
          <ng-content select="app-bottle-preview-header"></ng-content>
        </div>
      </div>

      <!-- Body -->
      <div
        class="body"
        fxFlex="noshrink"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayout.lt-sm="column"
        fxLayoutAlign.lt-sm="start start"
      >
        <!-- LEFT COLUMN (top 2 rows on small viewports) -->
        <div fxFlex="50%" fxFlex.lt-sm="none">
          <!--
            TOP LEFT
          -->
          <div class="attribute"><span>Type:</span> {{ bottle?.metaBottle?.type }}</div>

          <!--
            BOTTOM LEFT
            Display Item No. if available, otherwise display Age
          -->
          <div class="attribute">
            <ng-container *ngIf="bottle | authContext : 'hasNumbering'; else age">
              <span>Item No:</span> {{ bottle.itemNumber }}/{{ bottle.metaBottle.numberOfBottles }}
            </ng-container>
            <ng-template #age> <span>Age:</span> {{ bottle?.metaBottle?.age }} </ng-template>
          </div>
        </div>
        <!-- END: LEFT COLUMN -->

        <!-- RIGHT COLUMN (bottom 2 rows on small viewports) -->
        <div fxFlex="50%" fxFlex.lt-sm="none">
          <!--
            TOP RIGHT
            Display Bottled, Distilled, Min. Price, Bids, Max Bid
            depending on context and availability
          -->
          <div class="attribute" [ngSwitch]="bottle.state">
            <!-- PENDING_AUCTIONEER_ACCEPTANCE state -->
            <ng-container *ngSwitchCase="bottleStates.PENDING_AUCTIONEER_ACCEPTANCE">
              <ng-container *ngTemplateOutlet="minPrice; context: { $implicit: bottle }"></ng-container>
            </ng-container>

            <!-- IN_AUCTION state -->
            <ng-container *ngSwitchCase="bottleStates.IN_AUCTION">
              <ng-container *ngIf="bottle | authContext : 'isAuctioneerOrOwner'; else canPurchase">
                <ng-container *ngTemplateOutlet="minPrice; context: { $implicit: bottle }"></ng-container>
              </ng-container>
              <ng-template #canPurchase>
                <ng-container *ngTemplateOutlet="bids; context: { $implicit: bottle }"></ng-container>
              </ng-template>
            </ng-container>

            <!-- PENDING_AUCTION_END state -->
            <ng-container *ngSwitchCase="bottleStates.PENDING_AUCTION_END">
              <ng-container *ngTemplateOutlet="bids; context: { $implicit: bottle }"></ng-container>
            </ng-container>

            <!-- IN_TRANSACTION state -->
            <ng-container *ngSwitchCase="bottleStates.IN_TRANSACTION">
              <ng-container *ngTemplateOutlet="maxBidBids; context: { $implicit: bottle }"></ng-container>
            </ng-container>

            <!-- PENDING_PICKUP_AT_AUCTIONEER state -->
            <ng-container *ngSwitchCase="bottleStates.PENDING_PICKUP_AT_AUCTIONEER">
              <ng-container *ngTemplateOutlet="maxBidBids; context: { $implicit: bottle }"></ng-container>
            </ng-container>

            <!-- PENDING_SHIPPING state -->
            <ng-container *ngSwitchCase="bottleStates.PENDING_SHIPPING">
              <ng-container *ngTemplateOutlet="maxBidBids; context: { $implicit: bottle }"></ng-container>
            </ng-container>

            <!-- SHIPPED state -->
            <ng-container *ngSwitchCase="bottleStates.SHIPPED">
              <ng-container *ngTemplateOutlet="maxBidBids; context: { $implicit: bottle }"></ng-container>
            </ng-container>

            <!-- PENDING_SETTLEMENT state -->
            <ng-container *ngSwitchCase="bottleStates.PENDING_SETTLEMENT">
              <ng-container *ngTemplateOutlet="maxBidBids; context: { $implicit: bottle }"></ng-container>
            </ng-container>

            <ng-container *ngSwitchDefault>
              <!-- Display Bottling year if available, otherwise display Distillation year -->
              <ng-container *ngIf="bottle?.metaBottle?.bottlingYear; else distilled">
                <span>Bottled:</span> {{ bottle?.metaBottle?.bottlingYear }}
              </ng-container>
              <ng-template #distilled> <span>Distilled:</span> {{ bottle?.metaBottle?.distillationYear }} </ng-template>
            </ng-container>
          </div>
          <!-- END: TOP RIGHT -->

          <!--
            BOTTOM RIGHT
            Display Condition, Runtime, Current Bid, Max. Bid, Hammer Price
            depending on the context
          -->
          <div class="attribute" [ngSwitch]="bottle.state">
            <!-- PENDING_AUCTIONEER_ACCEPTANCE state -->
            <ng-container *ngSwitchCase="bottleStates.PENDING_AUCTIONEER_ACCEPTANCE">
              <ng-container *ngTemplateOutlet="conditionRuntime; context: { $implicit: bottle }"></ng-container>
            </ng-container>

            <!-- PENDING_DELIVERY_TO_AUCTIONEER state -->
            <ng-container *ngSwitchCase="bottleStates.PENDING_DELIVERY_TO_AUCTIONEER">
              <ng-container *ngTemplateOutlet="conditionRuntime; context: { $implicit: bottle }"></ng-container>
            </ng-container>

            <!-- IN_AUCTION state -->
            <ng-container *ngSwitchCase="bottleStates.IN_AUCTION">
              <ng-container *ngTemplateOutlet="conditionRuntime; context: { $implicit: bottle }"></ng-container>
            </ng-container>

            <!-- PENDING_AUCTION_END state -->
            <ng-container *ngSwitchCase="bottleStates.PENDING_AUCTION_END">
              <ng-container *ngIf="bottle | authContext : 'isAuctioneerOrOwner'; else canPurchase">
                <ng-container *ngTemplateOutlet="currentBid; context: { $implicit: bottle }"></ng-container>
              </ng-container>
              <ng-template #canPurchase>
                <div
                  *ngIf="bottle | authContext : 'hasAnyBid'; else noBids"
                  [class.positive]="bottle | authContext : 'hasBestBid'"
                  [class.negative]="!(bottle | authContext : 'hasBestBid')"
                >
                  <ng-container *ngTemplateOutlet="maxBid; context: { $implicit: bottle }"></ng-container>
                </div>
                <ng-template #noBids>
                  <ng-container *ngTemplateOutlet="condition; context: { $implicit: bottle }"></ng-container>
                </ng-template>
              </ng-template>
            </ng-container>

            <!-- IN_TRANSACTION state -->
            <ng-container *ngSwitchCase="bottleStates.IN_TRANSACTION">
              <ng-container *ngTemplateOutlet="hammerPrice; context: { $implicit: bottle }"></ng-container>
            </ng-container>

            <!-- PENDING_PICKUP_AT_AUCTIONEER state -->
            <ng-container *ngSwitchCase="bottleStates.PENDING_PICKUP_AT_AUCTIONEER">
              <ng-container *ngTemplateOutlet="hammerPrice; context: { $implicit: bottle }"></ng-container>
            </ng-container>

            <!-- PENDING_SHIPPING state -->
            <ng-container *ngSwitchCase="bottleStates.PENDING_SHIPPING">
              <ng-container *ngTemplateOutlet="hammerPrice; context: { $implicit: bottle }"></ng-container>
            </ng-container>

            <!-- SHIPPED state -->
            <ng-container *ngSwitchCase="bottleStates.SHIPPED">
              <ng-container *ngTemplateOutlet="hammerPrice; context: { $implicit: bottle }"></ng-container>
            </ng-container>

            <!-- PENDING_SETTLEMENT state -->
            <ng-container *ngSwitchCase="bottleStates.PENDING_SETTLEMENT">
              <ng-container *ngTemplateOutlet="hammerPrice; context: { $implicit: bottle }"></ng-container>
            </ng-container>

            <ng-container *ngSwitchDefault>
              <ng-container *ngTemplateOutlet="condition; context: { $implicit: bottle }"></ng-container>
            </ng-container>
          </div>
          <!-- END: BOTTOM RIGHT -->
        </div>
        <!-- END: RIGHT COLUMN -->
      </div>

      <!-- Bottom -->
      <div class="bottom" fxFlex="none" fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="none">
          <h4><app-bottle-state [bottle]="bottle"></app-bottle-state></h4>
        </div>
      </div>

      <!-- Footer -->
      <div class="footer" fxFlex="none">
        <div fxLayout="column" fxLayoutAlign="start stretch">
          <div fxFlex="none" fxLayout="row" fxLayoutAlign="end center">
            <ng-content select="app-bottle-preview-footer"></ng-content>
          </div>
          <div class="single-footer" fxFlex="none">
            <ng-content select="app-bottle-preview-action"></ng-content>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>
