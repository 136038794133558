import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { BackButtonModule } from '@whiskybazar/pwa/shared';
import { CategoriesRoutingModule } from './categories-routing.module';
import { components } from './components';
import { containers } from './containers';
import { guards } from './guards';
import { services } from './services';
import { categoriesFeatureKey, effects, reducers } from './store';

const MATERIAL_MODULES = [
  MatIconModule,
  MatCardModule,
  MatMenuModule,
  MatExpansionModule,
  MatProgressSpinnerModule,
  MatSidenavModule,
  MatListModule,
  MatButtonModule,
];

@NgModule({
  declarations: [...components, ...containers],
  imports: [
    ...MATERIAL_MODULES,
    CommonModule,
    LayoutModule,
    FlexLayoutModule,
    StoreModule.forFeature(categoriesFeatureKey, reducers),
    EffectsModule.forFeature(effects),
    BackButtonModule,
    CategoriesRoutingModule,
  ],
  providers: [...guards, ...services],
})
export class CategoriesModule {}
