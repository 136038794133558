import { Bid, BidData } from '../models';
import { Mapper } from './mapper';

export class BidMapper implements Mapper<BidData, Bid> {
  fromDb(data: BidData | null): Bid | null {
    if (!data) {
      return null;
    }

    return {
      id: <string>data.id,
      auctionId: <string>data.auction,
      bidderId: <string>data.bidder,
      createdAt: data.created_at ? new Date(data.created_at) : new Date(),
      updatedAt: data.updated_at ? new Date(data.updated_at) : new Date(data.created_at),
      amount: <number>data.amount,
      maxBidId: typeof data.max_bid === 'string' ? data.max_bid : (data.max_bid as BidData)?.id,
    };
  }

  toDb(model: Bid | null): BidData | null {
    if (!model) {
      return null;
    }

    return {
      id: model.id ? model.id : undefined,
      auction: model.auctionId,
      bidder: model.bidderId ? model.bidderId : undefined,
      amount: model.amount,
    };
  }
}

export const bidMapper = new BidMapper();
