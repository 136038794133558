import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular-ivy';

import { environment } from '@whiskybazar/pwa/environment';
import { State } from '@whiskybazar/pwa/store';
import { AppModule } from './app/app.module';
import { exposeStore } from './e2e';
import { installGoogleTagManager } from './gtm';

if (environment.production) {
  Sentry.init({
    dsn: 'https://507c8e4bcd1f4492b1e93392426d6f28@o776633.ingest.sentry.io/5797503',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['localhost', 'https://whiskybazar.com'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    tracesSampleRate: 0.8,
  });

  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((moduleRef) => {
    const store$ = moduleRef.injector.get<Store<State>>(Store);
    exposeStore(store$);

    document.addEventListener('DOMContentLoaded', () => {
      if (environment.googleTagManager) {
        installGoogleTagManager(environment.googleTagManager);
      }
    });

    // Handle HMR (Hot Module Replacement)
    if (!environment.production && module['hot']) {
      const hot = module['hot'] as any;

      hot.accept();

      if (hot.data && moduleRef.instance['hmrOnInit']) {
        moduleRef.instance['hmrOnInit'](hot.data);
      }

      hot.dispose((data: any) => {
        if (moduleRef.instance['hmrOnDestroy']) {
          moduleRef.instance['hmrOnDestroy'](data);
        }
      });
    }
  })
  .catch((err) => console.log(err));
