import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuctioneerInvoiceComponent } from './auctioneer-invoice.component';
import { AddressModule } from '../address/address.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CurrencyModule } from '../currency/currency.module';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [AuctioneerInvoiceComponent],
  imports: [CommonModule, AddressModule, FormsModule, ReactiveFormsModule, CurrencyModule, FlexLayoutModule],
  exports: [AuctioneerInvoiceComponent],
})
export class AuctioneerInvoiceModule {}
