import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BottlePricePipeModule } from '../../pipes';
import { BottlePriceComponent } from './bottle-price.component';
import { CurrencyModule } from '../currency/currency.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [BottlePriceComponent],
  imports: [CommonModule, BottlePricePipeModule, CurrencyModule, MatIconModule, MatButtonModule, MatTooltipModule],
  exports: [BottlePriceComponent],
})
export class BottlePriceModule {}
