import { Directive, Input, HostBinding, OnInit, OnChanges } from '@angular/core';

import { ThumbnailGeneratorService } from '@whiskybazar/pwa/core/services';

@Directive({
  selector: '[appImageThumbnail]',
})
export class ImageThumbnailDirective implements OnInit, OnChanges {
  @Input('appImageThumbnail') uri: string;

  @Input() thumbnailSize = '128x128';

  @HostBinding('src') src: string;

  constructor(private thumbnailGenerator: ThumbnailGeneratorService) {}

  ngOnInit() {
    this.updateSrc();
  }

  ngOnChanges() {
    this.updateSrc();
  }

  protected updateSrc() {
    this.src = this.thumbnailGenerator.generate(this.uri, this.thumbnailSize);
  }
}
