import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { exhaustMap, filter, take, switchMap, map } from 'rxjs/operators';

import { Category } from '@whiskybazar/pwa/categories/models';
import * as fromStore from '../reducers';
import * as CategoryPageActions from '../actions/category-page.actions';

@Injectable()
export class CategoryPageEffects {
  fetchCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryPageActions.fetchCategories),
      exhaustMap(() =>
        this.fetch().pipe(map((categories: Category[]) => CategoryPageActions.addCategories({ categories })))
      )
    )
  );

  constructor(private actions$: Actions, private store$: Store<fromStore.State>) {}

  private fetch(): Observable<Category[]> {
    const categories$ = this.store$.pipe(
      select(fromStore.selectCategoriesPageCategories),
      take(1),
      // Flatten the categories hierarchy such that we only
      // have a list of leaf categories
      map((categories) =>
        categories
          .map((category: Category) => category.children)
          .reduce((all: Category[], subList: Category[]) => all.concat(subList), [])
      )
    );

    return this.store$.pipe(
      select(fromStore.selectCategoriesPageLoaded),
      filter((loaded: boolean) => loaded),
      take(1),
      switchMap(() => categories$)
    );
  }
}
