import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { COUNTRIES_CALLING_CODES, PhoneNumberVerificationStep, SUPPORTED_COUNTRIES } from '@whiskybazar/core';

@Component({
  selector: 'app-verify-phone-number',
  templateUrl: './verify-phone-number.component.html',
  styleUrls: ['./verify-phone-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerifyPhoneNumberComponent implements AfterViewInit, OnChanges {
  readonly RECAPTCHA_CONTAINER_ID = 'verify-phone-number-recaptcha';

  @Input()
  step: PhoneNumberVerificationStep = 'verify';

  @Input()
  set pending(val: boolean) {
    if (val) {
      this.phoneForm.disable();
      this.otpForm.disable();
    } else {
      this.phoneForm.enable();
      this.otpForm.enable();
    }

    this.isPending = val;
  }

  @Input() errorMessage: string | null;

  @Input() set country(val: string) {
    if (val && COUNTRIES_CALLING_CODES[val]) {
      this.phoneForm.get('code').setValue(COUNTRIES_CALLING_CODES[val]);
    }
  }

  @Output() readonly setup = new EventEmitter<string>();

  @Output() readonly verify = new EventEmitter<string>();

  @Output() readonly confirm = new EventEmitter<string>();

  @Output() readonly reseted = new EventEmitter<void>();

  phoneForm: UntypedFormGroup;

  otpForm: UntypedFormGroup;

  isPending: boolean;

  get verifying(): boolean {
    return this.step === 'verify';
  }

  get confirming(): boolean {
    return this.step === 'confirm';
  }

  get callingCodes(): string[] {
    return SUPPORTED_COUNTRIES.map((country) => country.calling_code);
  }

  private get phoneNumberWithCallingCode(): string {
    return `+${this.phoneForm.get('code').value}${this.phoneForm.get('phone').value}`;
  }

  constructor() {
    this.phoneForm = new UntypedFormGroup({
      code: new UntypedFormControl(null, [Validators.required]),
      phone: new UntypedFormControl(null, [Validators.required]),
    });

    this.otpForm = new UntypedFormGroup({
      otp: new UntypedFormControl(null, [Validators.required]),
    });
  }

  ngAfterViewInit() {
    this.setup.emit(this.RECAPTCHA_CONTAINER_ID);
  }

  ngOnChanges(changes) {
    console.log(JSON.stringify(changes ?? {}));
  }

  submit() {
    switch (this.step) {
      case 'verify':
        this.verify.emit(this.phoneNumberWithCallingCode);
        break;

      case 'confirm':
        this.confirm.emit(this.otpForm.get('otp').value as string);
        break;
    }
  }

  onReset() {
    this.reseted.emit();
  }
}
