import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Category } from '@whiskybazar/pwa/categories/models';
import { State, selectCategoryPageSelected } from '@whiskybazar/pwa/categories/store/reducers';

@Component({
  selector: 'app-category-page',
  templateUrl: './category-page.component.html',
  styleUrls: ['./category-page.component.scss'],
})
export class CategoryPageComponent implements OnInit {
  category$: Observable<Category>;

  constructor(private store$: Store<State>) {}

  ngOnInit() {
    this.category$ = this.store$.pipe(select(selectCategoryPageSelected));
  }
}
