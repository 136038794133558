import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetaBottlePreviewComponent, MetaBottlePreviewActionComponent } from './meta-bottle-preview.component';
import { MatCardModule } from '@angular/material/card';
import { ImageThumbnailModule } from '../../directives';
import { BottlePromotedImageModule } from '../../pipes';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [MetaBottlePreviewComponent, MetaBottlePreviewActionComponent],
  imports: [CommonModule, MatCardModule, ImageThumbnailModule, BottlePromotedImageModule, FlexLayoutModule],
  exports: [MetaBottlePreviewComponent, MetaBottlePreviewActionComponent],
})
export class MetaBottlePreviewModule {}
