export const environment = {
  production: true,
  version: '1.5.0',
  revisionId: 'gbecddf1',
  firebaseConfig: {
    apiKey: 'AIzaSyBGF2QUP_7ihYUcdJeK2vnlKvS5ehqikys',
    authDomain: 'whisky-bazar.firebaseapp.com',
    databaseURL: 'https://whisky-bazar.firebaseio.com',
    projectId: 'whisky-bazar',
    storageBucket: 'whisky-bazar.appspot.com',
    messagingSenderId: '275024405635',
    emulators: null,
  },
  cloudFunctions: 'https://us-central1-whisky-bazar.cloudfunctions.net',
  googleTagManager: {
    containerID: 'GTM-T97GHQV',
  },
  meilisearchConfig: {
    host: 'https://ms-cb8c75056f0d-3189.fra.meilisearch.io',
    apiKey: '630851bc9201c1f08540e611591355bcd5a28876c8b062b5fca874cdbfe2fcb1',
    indexes: {
      openAuctions: 'open-auctions-v1',
    },
  },
};
