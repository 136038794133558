<div class="container">
  <mat-error *ngIf="errorMessage !== null">{{ errorMessage }}</mat-error>

  <!-- Phone Number -->
  <form [formGroup]="phoneForm" (ngSubmit)="submit()">
    <div fxLayout="column" fxLayoutGap="0px">
      <div fxFill fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <div fxFlex="none" class="calling-code">
          <mat-form-field>
            <mat-select formControlName="code">
              <mat-option *ngFor="let code of callingCodes" [value]="code">+{{ code }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex="grow" fxLayout="column">
          <mat-form-field fxFill>
            <mat-label>Enter phone number</mat-label>
            <input matInput type="text" formControlName="phone" i18n-placeholder placeholder="Enter phone number" />
            <mat-icon matSuffix>phone</mat-icon>
          </mat-form-field>
        </div>
      </div>

      <div fxFill fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px" class="mt-2">
        <div fxFlex="none" *ngIf="verifying && isPending" data-chromatic="ignore">
          <mat-spinner diameter="36" strokeWidth="4" color="primary"></mat-spinner>
        </div>
        <button
          type="submit"
          mat-raised-button
          color="primary"
          [disabled]="phoneForm.invalid || isPending || confirming"
          i18n
        >
          Send Verification Code
        </button>
      </div>
    </div>
  </form>

  <div fxLayout="column" fxLayoutGap="0px" class="mt-2 mb-2">
    <div fxFill fxLayoutAlign="center center" [id]="RECAPTCHA_CONTAINER_ID"></div>
  </div>

  <!-- One Time Password (OTP) -->
  <ng-container *ngIf="confirming">
    <form [formGroup]="otpForm" (ngSubmit)="submit()">
      <div fxLayout="column" fxLayoutGap="0px">
        <mat-form-field fxFill>
          <mat-label>Enter verification code</mat-label>
          <input matInput type="text" formControlName="otp" i18n-placeholder placeholder="Enter verification code" />
          <mat-hint align="start" i18n>Check your phone for SMS</mat-hint>
        </mat-form-field>

        <div fxFill fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px" class="mt-2">
          <div fxFlex="none" *ngIf="confirming && isPending" data-chromatic="ignore">
            <mat-spinner diameter="36" strokeWidth="4" color="primary"></mat-spinner>
          </div>
          <button type="submit" mat-raised-button color="primary" [disabled]="otpForm.invalid || isPending" i18n>
            Confirm
          </button>
        </div>
      </div>
    </form>

    <div fxFill class="mt-4">
      <p class="text-muted mb-1">Have you not received any SMS?</p>

      <button type="button" mat-raised-button [disabled]="isPending" (click)="onReset()">Reset and Try Again</button>
    </div>
  </ng-container>
</div>
