import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentDetailsComponent } from './payment-details.component';
import { CurrencyModule } from '../currency/currency.module';
import { MatDividerModule } from '@angular/material/divider';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [PaymentDetailsComponent],
  imports: [CommonModule, CurrencyModule, MatDividerModule, FlexLayoutModule],
  exports: [PaymentDetailsComponent],
})
export class PaymentDetailsModule {}
