import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent implements OnInit {
  @Input() label = 'Back';

  @Input() icon = 'keyboard_backspace';

  @Input() color: 'primary' | 'warn' | 'accent' = 'primary';

  back$: Observable<any>;

  private backSubject = new Subject<void>();

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.back$ = this.backSubject.asObservable().pipe(
      switchMap(() =>
        this.activatedRoute.queryParamMap.pipe(
          map((params: ParamMap) => params.get('referer')),
          tap((referer: string) => {
            if (!!referer) {
              this.router.navigateByUrl(decodeURIComponent(referer));
            } else {
              this.router.navigate(['../'], { relativeTo: this.activatedRoute });
            }
          })
        )
      )
    );
  }

  goBack() {
    this.backSubject.next();
  }
}
