import { Injectable, inject } from '@angular/core';
import { Database } from '@angular/fire/database';

import { Mapper, addressMapper, Address, AddressData } from '@whiskybazar/core';
import { AbstractFirebaseList } from './abstract-firebase-list';

@Injectable()
export class AddressesService extends AbstractFirebaseList<Address, AddressData> {
  private readonly path = 'addresses';

  private afDb: Database = inject(Database);

  constructor() {
    super();
  }

  getPath(): string {
    return this.path;
  }

  getAngularFireDatabase(): Database {
    return this.afDb;
  }

  getMapper(): Mapper<AddressData, Address> {
    return addressMapper;
  }
}
