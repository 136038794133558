import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuctionCountdownComponent } from './auction-countdown.component';
import { DateFnsModule } from 'ngx-date-fns';

@NgModule({
  declarations: [AuctionCountdownComponent],
  imports: [CommonModule, DateFnsModule],
  exports: [AuctionCountdownComponent],
})
export class AuctionCountdownModule {}
