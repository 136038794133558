import { Component, OnInit, Input } from '@angular/core';

import { Category } from '@whiskybazar/pwa/categories/models';

@Component({
  selector: 'app-categories-navigation',
  templateUrl: './categories-navigation.component.html',
  styleUrls: ['./categories-navigation.component.scss'],
})
export class CategoriesNavigationComponent implements OnInit {
  @Input() categories: Category[];

  constructor() {}

  ngOnInit() {}
}
