import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuctionProfitPipe } from './auction-profit.pipe';

@NgModule({
  declarations: [AuctionProfitPipe],
  imports: [CommonModule],
  exports: [AuctionProfitPipe],
})
export class AuctionProfitModule {}
