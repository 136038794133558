import { Store } from '@ngrx/store';

/**
 * Exposes the store on `window` for E2E testing with Cypress
 *
 * @param store NgRx store to expose
 */
export function exposeStore(store: Store<any>): void {
  if (window['Cypress']) {
    window['__store__'] = store;
  }
}
