import { Injectable, inject } from '@angular/core';
import { Database } from '@angular/fire/database';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import {
  Mapper,
  auctionTransactionMapper,
  AuctionTransaction,
  AuctionTransactionData,
  Address,
} from '@whiskybazar/core';
import { AbstractFirebaseList } from './abstract-firebase-list';
import { TransactionsService, TransactionExpansionConfig } from './transactions.service';
import { DatabaseApiService } from './database-api.service';

export interface AuctionTransactionExpansionConfig extends TransactionExpansionConfig {
  buyerInvoice?: {
    billingAddress?: boolean;
    shippingAddress?: boolean;
  };
}

@Injectable()
export class AuctionTransactionsService extends AbstractFirebaseList<AuctionTransaction, AuctionTransactionData> {
  private readonly path = 'auction_transactions';

  private afDb: Database = inject(Database);

  constructor(private transactionsService: TransactionsService) {
    super();
  }

  getPath(): string {
    return this.path;
  }

  getAngularFireDatabase(): Database {
    return this.afDb;
  }

  getMapper(): Mapper<AuctionTransactionData, AuctionTransaction> {
    return auctionTransactionMapper;
  }

  getDatabaseApiService(): DatabaseApiService {
    return this.transactionsService.dbApi;
  }

  fetchById(id: string, expanstionConfig?: AuctionTransactionExpansionConfig): Observable<AuctionTransaction> {
    if (!expanstionConfig) {
      return super.fetchById(id);
    }

    let result = super
      .fetchById(id)
      .pipe(
        switchMap((t: AuctionTransaction) =>
          t === null ? of(t) : this.transactionsService.expand(t, expanstionConfig)
        )
      );

    // Expand with billing address of buyer (if requested)
    if (expanstionConfig.buyerInvoice && expanstionConfig.buyerInvoice.billingAddress) {
      result = result.pipe(
        switchMap((t: AuctionTransaction) =>
          t === null || !t.buyerInvoice || !t.buyerInvoice.billingAddressId
            ? of(t)
            : this.transactionsService.addressesService.fetchById(t.buyerInvoice.billingAddressId).pipe(
                map((billingAddress: Address) => ({
                  ...t,
                  buyerInvoice: { ...t.buyerInvoice, billingAddress },
                }))
              )
        )
      );
    }

    // Expand with shipping address of buyer (if requested)
    if (expanstionConfig.buyerInvoice && expanstionConfig.buyerInvoice.shippingAddress) {
      result = result.pipe(
        switchMap((t: AuctionTransaction) =>
          t === null || !t.buyerInvoice || !t.buyerInvoice.shippingAddressId
            ? of(t)
            : this.transactionsService.addressesService.fetchById(t.buyerInvoice.shippingAddressId).pipe(
                map((shippingAddress: Address) => ({
                  ...t,
                  buyerInvoice: { ...t.buyerInvoice, shippingAddress },
                }))
              )
        )
      );
    }

    return result;
  }

  updateBuyerInvoice(transaction: AuctionTransaction): Observable<AuctionTransaction> {
    const addressMapper = this.transactionsService.addressesService.getMapper();
    const invoice = transaction.buyerInvoice;
    const transactionData = this.getMapper().toDb(transaction);
    const invoiceData = transactionData.buyer_invoice;
    const data = {
      ...invoiceData,
      details: undefined, // remove details since it is not accepted by the API
      billing_address: addressMapper.toDb(invoice.billingAddress),
      shipping_address: invoice.shippingAddress ? addressMapper.toDb(invoice.shippingAddress) : undefined,
    };

    const path = `${this.buildPath(transaction.id)}/buyer_invoice`;
    return this.getDatabaseApiService()
      .update<any, any>(path, data)
      .pipe(map(() => transaction));
  }
}
