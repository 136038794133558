import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BottlePipe } from './bottle.pipe';

@NgModule({
  declarations: [BottlePipe],
  imports: [CommonModule],
  exports: [BottlePipe],
})
export class BottleModule {}
