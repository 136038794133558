import { Pipe, PipeTransform } from '@angular/core';

import { BOTTLE_CONDITIONS } from '@whiskybazar/core';

@Pipe({
  name: 'bottleCondition',
})
export class BottleConditionPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (BOTTLE_CONDITIONS[value] && BOTTLE_CONDITIONS.condition_6.id !== value) {
      return BOTTLE_CONDITIONS[value].title;
    }

    return 'Unknown';
  }
}
