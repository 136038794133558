<div fxLayout="column" fxLayoutGap="8px">
  <!-- Bottle Specific Images Section -->
  <div fxLayout="row wrap" fxLayoutAlign="center center" class="mt-4">
    <div fxFlex="none" *ngFor="let img of bottle?.images" class="mb-4 mr-4">
      <app-image-editor
        [image]="img"
        [permissions]="permissions"
        (deleted)="delete($event)"
        (rotated)="rotate($event)"
        (error)="error(img, $event)"
        (promoted)="promote($event)"
      ></app-image-editor>
    </div>

    <p fxFlex="none" *ngIf="!bottle?.images?.length" i18n>No images to display!</p>
  </div>
</div>
