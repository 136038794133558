import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, NgModule, NgZone, PLATFORM_ID, APP_ID, makeStateKey, TransferState } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EffectsModule } from '@ngrx/effects';
import { FullRouterStateSerializer, RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { take } from 'rxjs/operators';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { connectDatabaseEmulator, getDatabase, provideDatabase } from '@angular/fire/database';
import { connectAuthEmulator, getAuth, provideAuth } from '@angular/fire/auth';
import { connectStorageEmulator, getStorage, provideStorage } from '@angular/fire/storage';

import { SearchButtonComponent } from '@whiskybazar/pwa-ui';
import { AuthModule } from '@whiskybazar/pwa/auth/auth.module';
import { CategoriesModule } from '@whiskybazar/pwa/categories/categories.module';
import { CoreModule } from '@whiskybazar/pwa/core/core.module';
import { AuthContextService } from '@whiskybazar/pwa/core/services';
import { environment } from '@whiskybazar/pwa/environment';
import { PublicModule } from '@whiskybazar/pwa/public/public.module';
import { StaticsModule } from '@whiskybazar/pwa/statics/statics.module';
import { metaReducers, reducers, State } from '@whiskybazar/pwa/store';
import { CustomRouterStateSerializer } from '@whiskybazar/pwa/utils';
import { exposeStore } from '../e2e';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { FooterNavModule, HorizontalNavModule, PaymentMethodsModule, SearchModule, VerticalNavModule } from './shared';

const NGRX_STATE = makeStateKey('NGRX_STATE');

export const MATERIAL_MODULES = [
  MatButtonModule,
  MatCardModule,
  MatIconModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  MatToolbarModule,
  MatSidenavModule,
  MatProgressBarModule,
  MatDividerModule,
  MatSnackBarModule,
  MatDialogModule,
];

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent],
  imports: [
    BrowserAnimationsModule,
    ...MATERIAL_MODULES,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAuth(() => {
      if (environment.firebaseConfig.emulators) {
        const auth = getAuth();
        try {
          connectAuthEmulator(auth, environment.firebaseConfig.emulators.auth, { disableWarnings: true });
        } catch (error) {
          // ignore the error
        }
        return auth;
      }

      return getAuth();
    }),
    provideDatabase(() => {
      if (environment.firebaseConfig.emulators) {
        const database = getDatabase();
        const { host, port } = environment.firebaseConfig.emulators.database;
        try {
          connectDatabaseEmulator(database, host, port);
        } catch (error) {
          // ignore the error
        }

        return database;
      }

      return getDatabase();
    }),
    provideStorage(() => {
      if (environment.firebaseConfig.emulators) {
        const storage = getStorage();
        const { host, port } = environment.firebaseConfig.emulators.storage;
        try {
          connectStorageEmulator(storage, host, port);
        } catch (error) {
          // ignore the error
        }

        return storage;
      }

      return getStorage();
    }),
    CommonModule,
    FlexLayoutModule,
    LayoutModule,
    TransferHttpCacheModule,
    CoreModule,
    AuthModule,
    PublicModule,
    StaticsModule,
    CategoriesModule,
    AppRoutingModule,
    HorizontalNavModule,
    VerticalNavModule,
    SearchModule,
    FooterNavModule,
    PaymentMethodsModule,

    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: { strictStateImmutability: true, strictActionImmutability: true },
    }),

    environment.production ? [] : StoreDevtoolsModule.instrument({ maxAge: 42 }),

    EffectsModule.forRoot([]),

    StoreRouterConnectingModule.forRoot({ serializer: FullRouterStateSerializer }),

    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
    }),

    SearchButtonComponent,
  ],
  providers: [
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    {
      provide: APP_ID,
      useValue: 'whiskybazarPWA',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private store$: Store<State>,
    private state: TransferState,
    @Inject(PLATFORM_ID) private platformId: string,
    private router: Router,
    private ctx: AuthContextService,
    private ngZone: NgZone
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.onBrowser();
    }

    if (isPlatformServer(platformId)) {
      this.onServer();
    }
  }

  public hmrOnInit(store) {
    if (!store || !store.state) {
      return;
    }

    // restore state
    this.store$.dispatch({ type: 'SET_ROOT_STATE', payload: store.state });

    // restore navigation
    // NOTE: this is necessary due to the fact that the router will have
    // navigated to the /login route due to lazy-loading
    if (store.state.routerReducer && store.state.routerReducer.state) {
      const [route, fragment] = (store.state.routerReducer.state.url || '/').split('#');
      if (fragment) {
        this.ngZone.run(() => this.router.navigate([route], { fragment }));
      } else {
        this.ngZone.run(() => this.router.navigate([route]));
      }
    }

    // restore AuthContextService
    if (store.state.auth && store.state.auth.status) {
      this.ctx.setAuth(store.state.auth.status.user);
    }

    Object.keys(store).forEach((prop) => delete store[prop]);
  }

  public hmrOnDestroy(store) {
    // Save the whole store before the app is destroyed.
    // The saved store is restored in the `hmrOnInit()`
    this.store$.pipe(take(1)).subscribe((state) => (store.state = state));
  }

  onServer() {
    this.state.onSerialize(NGRX_STATE, () => {
      let state;
      this.store$
        .subscribe((s: any) => {
          state = s;
        })
        .unsubscribe();
      return state;
    });
  }

  onBrowser() {
    const payload = this.state.get<any>(NGRX_STATE, null);
    if (!payload) {
      return;
    }

    this.state.remove(NGRX_STATE);
    this.store$.dispatch({ type: 'REHYDRATE', payload });

    exposeStore(this.store$);
  }
}
