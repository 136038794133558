<form [formGroup]="form" (ngSubmit)="submit()" autocomplete="off">
  <div fxLayout="column" fxLayoutAlign="start stretch">
    <!-- Min. Price (aka Starting Bid) -->
    <mat-form-field fxFill class="with-hints">
      <input
        matInput
        #amount
        type="number"
        formControlName="minPrice"
        placeholder="Enter starting bid in EUR"
        i18n-placeholer
        required
      />
      <mat-hint align="start" i18n>
        My profit: <app-currency [amount]="amount.value | auctionProfit"></app-currency> (excl.
        <a routerLink="/terms" fragment="sell-at-auction">transaction fees</a>)
      </mat-hint>
      <mat-hint align="end"> &asymp;&nbsp;{{ (amount.value | auctionProfit) / 0.13417 | currency : 'DKK' }} </mat-hint>
    </mat-form-field>
    <!-- Auctioneer -->
    <mat-form-field fxFill *ngIf="form.contains('auctioneer')">
      <mat-select
        formControlName="auctioneer"
        placeholder="Auctioneer"
        [compareWith]="compare"
        i18n-placeholer
        required
      >
        <mat-option *ngFor="let auctioneer of auctioneers" [value]="auctioneer"
          >{{ auctioneer.name }}, {{ auctioneer.city }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <!-- Runtime -->
    <!-- <mat-form-field fxFill>
      <mat-select formControlName="runtime" placeholder="Runtime" i18n-placeholder required>
        <mat-option *ngFor="let runtime of runtimes" [value]="runtime.id">{{runtime.title}}</mat-option>
      </mat-select>
      <mat-hint align="start" i18n>At first valid bid, runtime will start to countdown</mat-hint>
    </mat-form-field> -->
    <div fxLayout="row" fxLayoutAlign="center center">
      <div fxFlex="90%" fxLayout="column" fxLayoutGap="8px">
        <div fxFlexFill fxLayout="row" fxLayoutAlign="center center">
          <mat-spinner fxFlex="none" diameter="36" strokeWidth="4" color="primary" *ngIf="isPending"></mat-spinner>
        </div>
        <button type="submit" mat-raised-button color="primary" i18n [disabled]="form.invalid" *ngIf="!isPending">
          {{ action }}
        </button>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</form>
