<div fxLayout="row" fxLayoutAlign="center center">
  <div fxFlex="none" *ngIf="(sent$ | async) === false">
    <app-forgot-pass-form [pending]="pending$ | async" [errorMessage]="error$ | async" (submitted)="onSubmit($event)">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" class="mb-2 ml-2">
        <a routerLink="/login" i18n>Login</a>
        <a routerLink="/signup" i18n>Signup</a>
      </div>
    </app-forgot-pass-form>
  </div>
  <div fxFlex="none" *ngIf="sent$ | async">
    <mat-card appearance="outlined">
      <mat-card-title i18n>Email Sent</mat-card-title>
      <mat-card-content>
        <p i18n>Please check your inbox and follow the instructions on how to reset your password.</p>
      </mat-card-content>
    </mat-card>
  </div>
</div>
