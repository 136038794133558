import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BottleStatePipe } from './bottle-state.pipe';

@NgModule({
  declarations: [BottleStatePipe],
  imports: [CommonModule],
  exports: [BottleStatePipe],
})
export class BottleStatePipeModule {}
