import { Pipe, PipeTransform } from '@angular/core';

import { Bottle } from '@whiskybazar/core';
import { BottleAttrPipe } from '../bottle-attr/bottle-attr.pipe';

@Pipe({
  name: 'bottleName',
})
export class BottleNamePipe extends BottleAttrPipe implements PipeTransform {
  transform(
    value: Bottle,
    attr = ['distillationYear', 'age', 'alcoholPercentage', 'volume', 'type'],
    separator = ' '
  ): string {
    if (!value || !value.metaBottle) {
      return '';
    }

    return value.metaBottle.title + separator + super.transform(value, attr, separator);
  }
}
