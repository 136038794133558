import { createAction, props } from '@ngrx/store';

import { Bottle, FacetOption, SearchResult, SortBy } from '@whiskybazar/core';

export const loadBottles = createAction('[Public] Load Bottles');

export const loadBottlesSuccess = createAction(
  '[Public] Load Bottles Success',
  props<{ result: SearchResult<Bottle> }>()
);

export const loadMoreBottles = createAction('[Public] Load More Bottles');

export const loadMoreBottlesSuccess = createAction(
  '[Public] Load More Bottles Success',
  props<{ result: SearchResult<Bottle> }>()
);

export const reloadBottles = createAction('[Public] Reload Bottles');

export const reloadBottlesSuccess = createAction(
  '[Public] Reload Bottles Success',
  props<{ result: SearchResult<Bottle> }>()
);

export const searchBottles = createAction(
  '[Public] Search Bottles',
  props<{
    query?: string;
    distilleries?: FacetOption[];
    bottlers?: FacetOption[];
    regions?: FacetOption[];
    types?: FacetOption[];
    price?: number[];
    sorting?: SortBy;
  }>()
);

export const searchBottlesError = createAction('[Public] Search Bottles Error', props<{ error: string }>());

export const searchBottlesSuccess = createAction(
  '[Public] Search Bottles Success',
  props<{ result: SearchResult<Bottle> }>()
);

export const resetFilters = createAction('[Public] Reset Filters');
