import { SellerAuctionInvoice } from '../models';

/**
 * Calculates and returns the total amount based on the given invoice
 *
 * @param invoice Seller auction invoice from which to calculate the total
 */
export function calcSellerInvoiceTotal(invoice: SellerAuctionInvoice): number {
  const details = invoice.details || {};

  return [
    details.hammerPrice ? details.hammerPrice : 0.0,
    details.commissionFee ? details.commissionFee * -1 : 0.0,
    details.handlingFee ? details.handlingFee * -1 : 0.0,
  ].reduce((sum: number, a: number) => sum + a);
}
