import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Article } from '@whiskybazar/pwa/categories/models';

export const loadArticles = createAction('[Categories/Article Page] Load Articles');

export const selectArticle = createAction('[Categories/Article Page] Select Article', props<{ articleId: string }>());

export const loadArticleContent = createAction(
  '[Categories/Article Page] Load Article Content',
  props<{ article: Article }>()
);

export const addArticles = createAction('[Categories/Article Page] Add Articles', props<{ articles: Article[] }>());

export const updateArticle = createAction(
  '[Categories/Article Page] Update Article',
  props<{ article: Update<Article> }>()
);
