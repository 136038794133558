import { ForgotPassActionType, ForgotPassAction } from './../actions';

export interface State {
  sent: boolean;
  error: string | null;
  pending: boolean;
}

export const initialState: State = {
  sent: false,
  error: null,
  pending: false,
};

export function reducer(state = initialState, action: ForgotPassAction): State {
  switch (action.type) {
    case ForgotPassActionType.SendPasswordResetEmail: {
      return {
        ...state,
        error: null,
        pending: true,
      };
    }

    case ForgotPassActionType.SendPasswordResetEmailSuccess: {
      return {
        ...state,
        error: null,
        pending: false,
        sent: true,
      };
    }

    case ForgotPassActionType.SendPasswordResetEmailFail: {
      return {
        ...state,
        error: action.error,
        pending: false,
      };
    }

    default: {
      return state;
    }
  }
}

export const getSent = (state: State) => state.sent;
export const getError = (state: State) => state.error;
export const getPending = (state: State) => state.pending;
