import { Auction, AuctionData } from '../models';
import { Mapper } from './mapper';

export class AuctionMapper implements Mapper<AuctionData, Auction> {
  fromDb(data: AuctionData | null): Auction | null {
    if (!data) {
      return null;
    }

    return {
      id: <string>data.id,
      minPrice: <number>data.min_price,
      state: <string>data.state,
      runtime: <string>data.runtime,
      bidIds: this.fromDbBids(data.bids),
      bids: [],
      auctioneerId: <string>data.auctioneer,
      auctionId: data.auction_id ? <string>data.auction_id : 'N/A',
      createdAt: data.created_at ? new Date(data.created_at) : new Date(),
      updatedAt: data.updated_at ? new Date(data.updated_at) : new Date(data.created_at),
      bottleId: <string>data.bottle,
      startedAt: data.started_at ? new Date(data.started_at) : null,
      endingAt: data.ending_at ? new Date(data.ending_at) : null,
      bestBidId: <string>data.best_bid,
      transactionId: <string>data.transaction,
    };
  }

  toDb(model: Auction | null): AuctionData | null {
    if (!model) {
      return null;
    }

    return {
      id: model.id,
      auctioneer: model.auctioneerId ? model.auctioneerId : model.auctioneer ? model.auctioneer.id : undefined,
      bottle: model.bottleId,
      min_price: model.minPrice,
      runtime: model.runtime,
      state: model.state,
    };
  }

  fromDbBids(data: { [id: string]: { id: string } }): string[] {
    if (!data) {
      return [];
    }

    return Object.keys(data);
  }
}

export const auctionMapper = new AuctionMapper();
