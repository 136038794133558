import { RouterStateSerializer } from '@ngrx/router-store';
import { RouterStateSnapshot, Params } from '@angular/router';
import { Injectable } from '@angular/core';

export interface RouterStateUrl {
  url: string;
  queryParams: Params;
}

@Injectable()
export class CustomRouterStateSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const { url } = routerState;
    const queryParams = routerState.root.queryParams;

    return { url: this.stripQueryParams(url), queryParams };
  }

  private stripQueryParams(url: string): string {
    const idx = url.indexOf('?');
    if (idx > -1) {
      return url.substring(0, idx);
    }

    return url;
  }
}
