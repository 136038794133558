import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Bottle, Image, EditImagePermissions } from '@whiskybazar/core';

@Component({
  selector: 'app-bottle-images-editor',
  templateUrl: './bottle-images-editor.component.html',
  styleUrls: ['./bottle-images-editor.component.scss'],
})
export class BottleImagesEditorComponent implements OnInit {
  @Input() bottle: Bottle;

  @Input() permissions: EditImagePermissions = {
    delete: true,
    rotate: true,
    promote: false,
  };

  @Output() readonly deleted: EventEmitter<Image>;

  @Output() readonly rotated: EventEmitter<Image>;

  @Output() readonly errored: EventEmitter<[Image, any]>;

  @Output() readonly promoted: EventEmitter<Image>;

  constructor() {
    this.deleted = new EventEmitter<Image>();
    this.rotated = new EventEmitter<Image>();
    this.errored = new EventEmitter<[Image, any]>();
    this.promoted = new EventEmitter<Image>();
  }

  ngOnInit() {}

  delete(img: Image) {
    this.deleted.emit(img);
  }

  rotate(img: Image) {
    this.rotated.emit(img);
  }

  error(img: Image, err: any) {
    this.errored.emit([img, err]);
  }

  promote(img: Image) {
    this.promoted.emit(img);
  }
}
