import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import { AuthService } from '@whiskybazar/pwa/core/services';
import {
  ForgotPassActionType,
  SendPasswordResetEmail,
  SendPasswordResetEmailFail,
  SendPasswordResetEmailSuccess,
} from '../actions';

@Injectable()
export class ForgotPassEffects {
  sendPasswordResetEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ForgotPassActionType.SendPasswordResetEmail),
      switchMap((action: SendPasswordResetEmail) =>
        this.authService.sendPasswordResetEmail(action.email).pipe(
          map(() => new SendPasswordResetEmailSuccess()),
          catchError((error) => of(new SendPasswordResetEmailFail(error)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private authService: AuthService) {}
}
