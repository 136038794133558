<div
  fxLayout="column"
  fxLayoutAlign="center stretch"
  (click)="fileInput.click()"
  appDragDropFiles
  (filesDropped)="uploadFiles($event)"
  (dragDropError)="onDragDropError($event)"
>
  <div fxFlex="nogrow" fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="8px">
    <div fxFlex="none" *ngFor="let upload of uploads" class="thumbnail">
      <div *ngIf="uploading || pending" class="progress-container">{{ upload.uploadPercent }}%</div>
      <img [src]="upload.servingUrl" class="thumbnail" />
    </div>
    <div fxFlex="none" *ngIf="pending" class="mt-4 mb-4">
      <mat-spinner diameter="36" strokeWidth="4" color="primary"></mat-spinner>
    </div>
  </div>

  <!-- Upload Button -->
  <div fxFlex="nogrow" fxLayout="row" fxLayoutAlign="center center" class="ml-4 mr-4">
    <div fxFlex="none">
      <input
        type="file"
        #fileInput
        (change)="uploadFiles($event.target.files)"
        accept=".png,.jpeg,.jpg"
        multiple="true"
        [disabled]="uploading || pending"
      />
      <div fxLayout="row" fxLayoutGap="16px" *ngIf="!(uploading || pending)">
        <div fxFlex="none" fxLayoutAlign="center center">
          <mat-icon>upload</mat-icon>
        </div>
        <div fxFlex="none">
          <div class="mat-h2">CLICK TO ADD PHOTOS</div>
          <div class="mat-caption text-center">(or Drag and Drop them here)</div>
        </div>
      </div>
      <span class="mat-caption text-center" *ngIf="uploading || pending">Uploading...</span>
    </div>
  </div>
</div>
