import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BottleLotPipe } from './bottle-lot.pipe';

@NgModule({
  declarations: [BottleLotPipe],
  imports: [CommonModule],
  exports: [BottleLotPipe],
})
export class BottleLotModule {}
