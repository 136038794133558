<div fxLayout="row">
  <div fxFlex="25" fxHide.xs></div>
  <div fxFlex="50" fxFlex.xs="100">
    <app-login-form [pending]="pending$ | async" [errorMessage]="error$ | async" (submitted)="onSubmit($event)">
      <div fxLayout="column" class="ml-2 mb-2">
        <div fxFlex="none">
          <a routerLink="/forgot-pass" i18n>Forgot password?</a>
        </div>
        <div fxFlex="none">
          <span i18n>Don't have an account?</span>&nbsp;
          <a routerLink="/signup" i18n>Signup</a>
        </div>
      </div>
    </app-login-form>
  </div>
  <div fxFlex="25" fxHide.xs></div>
</div>
