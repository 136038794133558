import { Action } from '@ngrx/store';

import { StaticPage } from '@whiskybazar/core';

export enum StaticPageActionType {
  LoadPage = '[Statics] Load Page',
  LoadPageSuccess = '[Statics] Load Page Success',
  LoadPageFail = '[Statics] Load Page Fail',
  SelectPage = '[Statics] Select Page',
}

export class LoadPage implements Action {
  readonly type = StaticPageActionType.LoadPage;

  constructor(public pageId: string) {}
}

export class LoadPageSuccess implements Action {
  readonly type = StaticPageActionType.LoadPageSuccess;

  constructor(public page: StaticPage) {}
}

export class LoadPageFail implements Action {
  readonly type = StaticPageActionType.LoadPageFail;

  constructor(public error: any) {}
}

export class SelectPage implements Action {
  readonly type = StaticPageActionType.SelectPage;

  constructor(public pageId: string) {}
}

export type StaticPageAction = LoadPage | LoadPageSuccess | LoadPageFail | SelectPage;
