export const AUCTION_STATES = {
  DRAFT: {
    id: 'DRAFT',
    name: 'Draft',
    transitions: ['PENDING_ACCEPTANCE'],
  },
  PENDING_ACCEPTANCE: {
    id: 'PENDING_ACCEPTANCE',
    name: 'Pending Acceptance',
    transitions: ['REJECTED', 'PENDING_START'],
  },
  REJECTED: {
    id: 'REJECTED',
    name: 'Rejected',
    transitions: ['PENDING_ACCEPTANCE'],
  },
  PENDING_START: {
    id: 'PENDING_START',
    name: 'Pending Start',
    transitions: ['RUNNING'],
  },
  RUNNING: {
    id: 'RUNNING',
    name: 'Running',
    transitions: ['COUNTING_DOWN'],
  },
  COUNTING_DOWN: {
    id: 'COUNTING_DOWN',
    name: 'Counting Down',
    transitions: ['ENDED'],
  },
  ENDED: {
    id: 'ENDED',
    name: 'Ended',
    transitions: [],
  },
};

export const OPEN_AUCTIONS = [AUCTION_STATES.RUNNING.id, AUCTION_STATES.COUNTING_DOWN.id];
