import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BottleAttrPipe } from './bottle-attr.pipe';

@NgModule({
  declarations: [BottleAttrPipe],
  imports: [CommonModule],
  exports: [BottleAttrPipe],
})
export class BottleAttrModule {}
