import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { Address, COUNTRIES } from '@whiskybazar/core';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressFormComponent implements OnInit {
  @Input()
  set value(val: Address | null) {
    if (val) {
      this.form.patchValue(val, { emitEvent: false });
    }
  }

  @Input() set disabled(val: boolean) {
    if (val) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Output() changes = new EventEmitter<Address>();

  form: UntypedFormGroup;

  countries: string[];

  changes$: Observable<Address>;

  constructor() {
    this.form = new UntypedFormGroup({
      id: new UntypedFormControl(null),
      name: new UntypedFormControl(null, [Validators.required]),
      address: new UntypedFormControl(null, [Validators.required]),
      zipCode: new UntypedFormControl(null, [Validators.required]),
      city: new UntypedFormControl(null, [Validators.required]),
      state: new UntypedFormControl(null),
      country: new UntypedFormControl(null, [Validators.required]),
    });

    this.countries = COUNTRIES;
  }

  ngOnInit() {
    this.changes$ = this.form.valueChanges.pipe(
      filter(() => this.form.valid),
      tap((v: Address) => this.changes.emit(v))
    );
  }
}
