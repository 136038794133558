import { Collector, CollectorData } from '../models';
import { Mapper } from './mapper';

export class CollectorMapper implements Mapper<CollectorData, Collector> {
  fromDb(data: CollectorData | null): Collector | null {
    if (!data) {
      return null;
    }

    return {
      id: data.id,
      type: 'collector',
      name: data.name,
      email: data.email,
      phone: data.phone_number,
      phoneVerified: data.phone_verified,
      address: data.address,
      zipCode: data.zip_code,
      city: data.city,
      country: data.country,
      profileImage: data.profile_image,
      yearOfBirth: +data.year_of_birth,
      createdAt: data.created_at ? new Date(data.created_at) : null,
      updatedAt: data.updated_at ? new Date(data.updated_at) : null,
      newsletter: data.newsletter,
    };
  }

  toDb(model: Collector | null): CollectorData | null {
    if (!model) {
      return null;
    }

    return {
      id: model.id,
      email: model.email,
      year_of_birth: model.yearOfBirth ? +model.yearOfBirth : undefined,
      name: model.name ? model.name : undefined,
      address: model.address ? model.address : undefined,
      city: model.city ? model.city : undefined,
      zip_code: model.zipCode ? model.zipCode : undefined,
      country: model.country ? model.country : undefined,
      phone_number: model.phone ? model.phone : undefined,
      phone_verified: model.phoneVerified ? model.phoneVerified : undefined,
      profile_image: model.profileImage ? model.profileImage : undefined,
      newsletter: model.newsletter ? model.newsletter : undefined,
    };
  }
}

export const collectorMapper = new CollectorMapper();
