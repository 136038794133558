export const BOTTLE_STATES = {
  DRAFT: {
    id: 'DRAFT',
    name: 'Draft',
    transitions: ['IN_PRIVATE_COLLECTION', 'IN_PUBLIC_COLLECTION', 'PENDING_AUCTIONEER_ACCEPTANCE'],
  },
  PENDING_AUCTIONEER_ACCEPTANCE: {
    id: 'PENDING_AUCTIONEER_ACCEPTANCE',
    name: 'Pending Auctioneer Acceptance',
    transitions: ['PENDING_DELIVERY_TO_AUCTIONEER'],
  },
  PENDING_DELIVERY_TO_AUCTIONEER: {
    id: 'PENDING_DELIVERY_TO_AUCTIONEER',
    name: 'Pending Delivery to Auctioneer',
    transitions: ['PENDING_AUCTION_START'],
  },
  PENDING_AUCTION_START: {
    id: 'PENDING_AUCTION_START',
    name: 'Pending Auction Start',
    transitions: ['IN_AUCTION'],
  },
  IN_AUCTION: {
    id: 'IN_AUCTION',
    name: 'In Auction',
    transitions: ['PENDING_AUCTION_END'],
  },
  PENDING_AUCTION_END: {
    id: 'PENDING_AUCTION_END',
    name: 'Pending Auction End',
    transitions: ['IN_TRANSACTION'],
  },
  IN_TRANSACTION: {
    id: 'IN_TRANSACTION',
    name: 'In Transaction',
    transitions: ['PENDING_SHIPPING', 'PENDING_PICKUP_AT_AUCTIONEER'],
  },
  PENDING_SHIPPING: {
    id: 'PENDING_SHIPPING',
    name: 'Pending Shipping',
    transitions: ['SHIPPED'],
  },
  SHIPPED: {
    id: 'SHIPPED',
    name: 'Shipped',
    transitions: ['PENDING_SETTLEMENT'],
  },
  PENDING_PICKUP_AT_AUCTIONEER: {
    id: 'PENDING_PICKUP_AT_AUCTIONEER',
    name: 'Pending Pickup',
    transitions: ['PENDING_SETTLEMENT'],
  },
  PENDING_SETTLEMENT: {
    id: 'PENDING_SETTLEMENT',
    name: 'Pending Settlement',
    transitions: ['IN_PRIVATE_COLLECTION', 'IN_PUBLIC_COLLECTION'],
  },
  IN_PRIVATE_COLLECTION: {
    id: 'IN_PRIVATE_COLLECTION',
    name: 'In Private Collection',
    transitions: ['IN_PUBLIC_COLLECTION', 'PENDING_AUCTIONEER_ACCEPTANCE', 'ARCHIVED'],
  },
  IN_PUBLIC_COLLECTION: {
    id: 'IN_PUBLIC_COLLECTION',
    name: 'In Public Collection',
    transitions: ['IN_PRIVATE_COLLECTION', 'PENDING_AUCTIONEER_ACCEPTANCE', 'ARCHIVED'],
  },
  ARCHIVED: {
    id: 'ARCHIVED',
    name: 'Archived',
    transitions: [],
  },
  IN_STOCK: {
    id: 'IN_STOCK',
    name: 'In Stock',
    transitions: [],
  },
  FOR_SALE: {
    id: 'FOR_SALE',
    name: 'For Sale',
    transitions: [],
  },
  ON_SALE: {
    id: 'ON_SALE',
    name: 'On Sale',
    transitions: [],
  },
};
