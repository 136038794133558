import { Bottle, BottleData, MetaBottle, MetaBottleData, Image, BottleImages } from '../models';

export class BottleImagesUtils {
  public static fromDbImages(data: BottleData | MetaBottleData): Image[] {
    const result: Image[] = [];
    for (const k in data.images) {
      if (k in data.images) {
        const img = data.images[k];
        result.push({ id: k, servingUrl: img.serving_url, promoted: !!img.promoted });
      }
    }

    return result;
  }

  public static toDbImages(model: Bottle | MetaBottle): BottleImages {
    const result: BottleImages = {};
    model.images.forEach(
      (img: Image) =>
        (result[img.id] = {
          serving_url: img.servingUrl,
          promoted: !!img.promoted,
        })
    );

    return result;
  }

  /**
   * Extracts the UID of the image creator from the serving URL of the provided image
   *
   * @param {Image} image The image to use for extraction
   * @returns {string|null} The UID of the creator of the image or `null` if could not extract the UID
   */
  public static extractUIDFromServingUrl(image: Image): string | null {
    // get storage path from the serving URL of the image
    const path = image.servingUrl.split('/').pop();
    if (!path) {
      return null;
    }

    // the first component of the storage path is the UID of the image creator
    const uid = decodeURIComponent(path).split('/').shift();

    return !!uid ? uid : null;
  }

  public static getPromotedImage(images: Image[]): Image | null {
    return images.find((img: Image) => img.promoted === true);
  }
}
