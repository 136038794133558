import { inject, Injectable } from '@angular/core';
import {
  ref,
  Storage,
  uploadBytesResumable,
  UploadMetadata,
  UploadTaskSnapshot,
  getDownloadURL,
  uploadString,
  UploadTask,
} from '@angular/fire/storage';
import { combineLatest, merge, Observable, from as fromPromise } from 'rxjs';
import { last, map, switchMap } from 'rxjs/operators';

import { AuthUser, Image, STORAGE, StorageUtils } from '@whiskybazar/core';

interface UploadWithProgress {
  snapshotChanges: () => Observable<UploadTaskSnapshot>;
  percentageChanges: () => Observable<number>;
}

@Injectable()
export class StorageService {
  private readonly BOTTLES_FOLDER = STORAGE.BOTTLES_FOLDER;
  private readonly META_BOTTLES_FOLDER = STORAGE.META_BOTTLES_FOLDER;

  private readonly storage: Storage = inject(Storage);

  uploadBottleImages(images: Image[], user: AuthUser): Observable<Image[]> {
    return combineLatest(images.map((img) => this.uploadBottleImage(img, user)));
  }

  uploadMetaBottleImages(images: Image[], metaBottleId: string): Observable<Image[]> {
    return combineLatest(images.map((img) => this.uploadMetaBottleImage(img, metaBottleId)));
  }

  uploadMetaBottleImage(image: Image, metaBottleId: string): Observable<Image> {
    const fileId = this.generateFileId(image.file);
    const filePath = this.getMetaBottleImagePath(metaBottleId, fileId);
    return this.uploadImage(image, filePath, fileId);
  }

  uploadBottleImage(image: Image, user: AuthUser): Observable<Image> {
    const fileId = this.generateFileId(image.file);
    const filePath = this.getFilePath(user.id, fileId);
    return this.uploadImage(image, filePath, fileId);
  }

  updateBottleImage(image: Image, user: AuthUser): Observable<Image> {
    const userId = image.creatorId ? image.creatorId : user.id;
    const filePath = this.getFilePath(userId, image.id);
    return this.updateImage(image, filePath);
  }

  updateMetaBottleImage(image: Image, metaBottleId: string): Observable<Image> {
    const filePath = this.getMetaBottleImagePath(metaBottleId, image.id);
    return this.updateImage(image, filePath);
  }

  uploadShipmentLabel(file: File, referenceId: string, shippingProductId: string): Observable<number | string> {
    const path = StorageUtils.buildShipmentLabelPath(referenceId, shippingProductId, file);
    const fileRef = ref(this.storage, path);
    const metadata: UploadMetadata = { contentType: file.type };
    const task = this.toUploadWithProgress(uploadBytesResumable(fileRef, file, metadata));

    const url$: Observable<string> = task.snapshotChanges().pipe(
      last(),
      switchMap(() => getDownloadURL(fileRef))
    );

    const progress$: Observable<number> = task
      .percentageChanges()
      .pipe(map((percentage: number) => Math.floor(percentage)));

    return merge(progress$, url$);
  }

  protected uploadImage(image: Image, path: string, id: string): Observable<Image> {
    const fileRef = ref(this.storage, path);
    const metadata: UploadMetadata = {
      contentType: image.file.type,
    };

    const uploadTask = uploadBytesResumable(fileRef, image.file, metadata);
    const task = this.toUploadWithProgress(uploadTask);

    const withUrl = task.snapshotChanges().pipe(
      last(),
      switchMap(() => getDownloadURL(fileRef)),
      map(
        (url: string) =>
          ({
            ...image,
            servingUrl: url,
            file: null,
            id,
          } as Image)
      )
    );
    const withProgress = task
      .percentageChanges()
      .pipe(map((percentage: number) => ({ ...image, uploadPercent: Math.floor(percentage) } as Image)));

    return merge(...[withProgress, withUrl]);
  }

  protected updateImage(image: Image, path: string): Observable<Image> {
    const fileRef = ref(this.storage, path);
    const task = uploadString(fileRef, image.base64Data, 'data_url');

    return fromPromise(task).pipe(
      switchMap(() => getDownloadURL(fileRef)),
      map((servingUrl: string) => ({ ...image, servingUrl, base64Data: null }))
    );
  }

  protected getFilePath(userId: string, fileId: string): string {
    return '{uid}/{folder}/{fileId}'
      .replace('{uid}', userId)
      .replace('{folder}', this.BOTTLES_FOLDER)
      .replace('{fileId}', fileId);
  }

  protected getMetaBottleImagePath(metaBottleId: string, imageId: string): string {
    return [this.META_BOTTLES_FOLDER, metaBottleId, 'images', imageId].join('/');
  }

  private generateFileId(file: File): string {
    return (file.name.replace(/[^A-Za-z0-9_]/gi, '') + '_' + Math.round(Math.random() * Date.now())).slice(-31);
  }

  private toUploadWithProgress(task: UploadTask): UploadWithProgress {
    return {
      snapshotChanges: () =>
        new Observable<UploadTaskSnapshot>((observer) => {
          task.on('state_changed', {
            next: (snapshot: UploadTaskSnapshot) => observer.next(snapshot),
            error: (error: Error) => observer.error(error),
            complete: () => observer.complete(),
          });
        }),
      percentageChanges: () =>
        new Observable<number>((observer) => {
          task.on('state_changed', {
            next: (snapshot: UploadTaskSnapshot) =>
              observer.next((snapshot.bytesTransferred / snapshot.totalBytes) * 100),
            error: (error: Error) => observer.error(error),
            complete: () => observer.complete(),
          });
        }),
    };
  }
}
