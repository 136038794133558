import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BottleAttrPrecedencePipe } from './bottle-attr-precedence.pipe';

@NgModule({
  declarations: [BottleAttrPrecedencePipe],
  imports: [CommonModule],
  exports: [BottleAttrPrecedencePipe],
})
export class BottleAttrPrecedenceModule {}
