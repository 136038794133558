<div
  *ngIf="bottle.auction"
  [class.winner]="ctx.hasBestBid(bottle)"
  [class.loser]="ctx.canBid(bottle) && !ctx.hasBestBid(bottle)"
>
  <mat-icon>gavel</mat-icon>
  <strong *ngIf="!bottle.auction.bestBid"> <app-currency [amount]="bottle.auction.minPrice"></app-currency> </strong>
  <div *ngIf="bottle.auction.bestBid" [class.stack]="!inline" matTooltip="Current bid" i18n-matTooltip>
    <strong><app-currency [amount]="bottle.auction.bestBid.amount"></app-currency></strong>
    <span><app-currency [amount]="bottle.auction.minPrice"></app-currency></span>
  </div>
  <ng-container *ngIf="!ctx.isOwner(bottle)">
    <mat-icon
      class="help"
      [matTooltip]="(bottle | bottlePrice : true | currency : 'EUR') + ' incl. fees, excl. shipping'"
      matTooltipPosition="above"
      >help_outline</mat-icon
    >
  </ng-container>
</div>

<div *ngIf="bottle.sale" matTooltip="Price" i18n-matTooltip>
  <mat-icon>payment</mat-icon>
  <strong *ngIf="!bottle.sale.discountActivated">
    <app-currency [amount]="bottle.sale.listPrice"></app-currency>
  </strong>
  <div *ngIf="bottle.sale.discountActivated" [class.stack]="!inline">
    <strong> <app-currency [amount]="bottle.sale.discountPrice"></app-currency> </strong>
    <span> <app-currency [amount]="bottle.sale.listPrice"></app-currency> </span>
  </div>
</div>
