<div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="16px">
  <!-- Details -->
  <div fxFlex="grow" fxLayout="column" fxLayoutGap="8px">
    <!-- Winning Bid (aka Hammer Price) -->
    <div fxFlex="none" fxLayout="row" fxLayoutGap="8px">
      <div fxFlex="80%" class="label">Winning Bid:</div>
      <div fxFlex="20%" class="amount">
        <app-currency [amount]="invoice?.details?.hammerPrice || 0"></app-currency>
      </div>
    </div>
    <!-- Auction Fee (aka variable fee) -->
    <div fxFlex="none" fxLayout="row" fxLayoutGap="8px">
      <div fxFlex="80%" class="label">
        Auction Fee:
        <small>({{ auctionVariableFee | percent }} of winning bid)</small>
      </div>
      <div fxFlex="20%" class="amount">
        <app-currency [amount]="invoice?.details?.auctionFee || 0"></app-currency>
      </div>
    </div>
    <!-- Packaging Fee (aka fixed fee) -->
    <div fxFlex="none" fxLayout="row" fxLayoutGap="8px">
      <div fxFlex="80%" class="label">Packaging Fee:</div>
      <div fxFlex="20%" class="amount">
        <app-currency [amount]="invoice?.details?.packagingFee || 0"></app-currency>
      </div>
    </div>
    <!-- Shipping -->
    <div fxFlex="none" fxLayout="row" fxLayoutGap="8px">
      <div fxFlex="80%" class="label">Shipping:</div>
      <div fxFlex="20%" class="amount">
        <app-currency [amount]="invoice?.details?.shippingCost || 0"></app-currency>
      </div>
    </div>
    <!-- Shipping Insurance -->
    <div fxFlex="none" fxLayout="row" fxLayoutGap="8px" *ngIf="invoice?.shippingInsuranceIncluded">
      <div fxFlex="80%" class="label">Shipping Insurance:</div>
      <div fxFlex="20%" class="amount">
        <app-currency [amount]="invoice?.details?.shippingInsuranceCost || 0"></app-currency>
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- Total Cost -->
    <div fxFlex="none" fxLayout="row" fxLayoutGap="8px" class="total">
      <div fxFlex="80%" class="label">Total Cost</div>
      <div fxFlex="20%" class="amount">
        <app-currency [amount]="calcTotal()"></app-currency>
      </div>
    </div>
  </div>

  <!-- Info -->
  <div fxFlex="grow" class="info">
    <p>
      All prices are incl. VAT. Customs fee and VAT for import can be added.
      <br />
      Profit margin scheme - collector's items and antiques - the buyer can not deduct VAT.
    </p>
  </div>
</div>
