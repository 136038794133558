import { Injectable } from '@angular/core';
import { Observable, fromEvent, merge, of } from 'rxjs';
import { mapTo, share } from 'rxjs/operators';

import { WindowRefService } from './window-ref.service';
import { PlatformService } from './platform.service';

@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  get isOnline(): boolean {
    return this.status === 'ONLINE';
  }

  get isOffline(): boolean {
    return this.status === 'OFFLINE';
  }

  private status: 'ONLINE' | 'OFFLINE' = 'ONLINE';

  constructor(private winRef: WindowRefService, private platform: PlatformService) {
    this.monitor().subscribe((result) => (this.status = result === true ? 'ONLINE' : 'OFFLINE'));
  }

  monitor(): Observable<boolean> {
    if (this.platform.onServer()) {
      return of(true);
    }

    const online$ = fromEvent(this.winRef.nativeWindow, 'online').pipe(mapTo(true));
    const offline$ = fromEvent(this.winRef.nativeWindow, 'offline').pipe(mapTo(false));

    return merge(online$, offline$).pipe(share());
  }
}
