<ng-container *ngFor="let nav of navService.defaultNav">
  <a
    mat-button
    *ngIf="!auth || auth.type === 'collector'"
    [routerLink]="nav.link"
    [fragment]="nav?.extras?.fragment"
    [attr.data-cy]="nav.label"
  >
    {{ nav.label }}
  </a>
</ng-container>

<span class="spacer"></span>

<app-search-button></app-search-button>

<div *ngIf="loggedIn; else signupOrLogin">
  <button mat-button class="dropdown" [matMenuTriggerFor]="profileMenu">
    {{ auth.name || auth.email }}
    <mat-icon>arrow_drop_down</mat-icon>
  </button>
  <mat-menu #profileMenu="matMenu">
    <ng-container [ngSwitch]="auth.type">
      <!-- Admin Actions -->
      <ng-container *ngSwitchCase="'admin'">
        <ng-container *ngFor="let nav of navService.adminNav">
          <a mat-menu-item [routerLink]="nav.link" [attr.data-cy]="nav.label">
            <mat-icon>{{ nav.icon }}</mat-icon>
            <span>{{ nav.label }}</span>
          </a>
        </ng-container>
      </ng-container>

      <!-- Collector Actions -->
      <ng-container *ngSwitchCase="'collector'">
        <ng-container *ngFor="let nav of navService.collectorNav">
          <a mat-menu-item [routerLink]="nav.link" [attr.data-cy]="nav.label">
            <mat-icon>{{ nav.icon }}</mat-icon>
            <span>{{ nav.label }}</span>
          </a>
        </ng-container>
      </ng-container>

      <!-- Dealer Actions -->
      <ng-container *ngSwitchCase="'dealer'">
        <ng-container *ngFor="let nav of navService.dealerNav">
          <a mat-menu-item [routerLink]="nav.link" [attr.data-cy]="nav.label">
            <mat-icon>{{ nav.icon }}</mat-icon>
            <span>{{ nav.label }}</span>
          </a>
        </ng-container>
      </ng-container>
    </ng-container>

    <mat-divider></mat-divider>

    <a mat-menu-item (click)="_logout()" data-cy="logout">
      <mat-icon>keyboard_backspace</mat-icon>
      <span i18n>Logout</span>
    </a>
  </mat-menu>
</div>

<ng-template #signupOrLogin>
  <a mat-button routerLink="signup" i18n data-cy="signup">Signup</a>
  <a mat-button routerLink="login" i18n data-cy="login">Login</a>
</ng-template>
