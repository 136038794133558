<form [formGroup]="form" (ngSubmit)="submit()">
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title i18n>Forgot Password?</mat-card-title>
      <mat-card-subtitle i18n
        >Enter your Email address and we will send you instructions on resetting your password.</mat-card-subtitle
      >
    </mat-card-header>
    <mat-card-content>
      <div fxLayout="column" class="mt-8">
        <mat-form-field fxFlex>
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" type="email" i18n-placeholder placeholder="Email" required />
          <mat-error *ngIf="form.get('email').invalid" i18n>Enter a valid Email address!</mat-error>
        </mat-form-field>

        <p *ngIf="errorMessage">
          <mat-error>{{ errorMessage }}</mat-error>
        </p>
      </div>
    </mat-card-content>
    <mat-card-actions align="end">
      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
        <mat-spinner diameter="36" strokeWidth="6" color="primary" *ngIf="isPending"></mat-spinner>
        <button mat-button color="primary" type="submit" [disabled]="!form.valid" i18n>Send Email</button>
      </div>
    </mat-card-actions>
    <mat-card-footer>
      <ng-content></ng-content>
    </mat-card-footer>
  </mat-card>
</form>
