{{ address?.name }} <br />
{{ address?.address }} <br />
{{ address?.zipCode }} {{ address?.city }} <br />
{{ address?.country }}
<ng-container *ngIf="full">
  <br />
  {{ address?.email }}
  <br />
  {{ address?.phone }}
</ng-container>
<ng-container *ngIf="crn">
  <br />
  CRN:&nbsp;{{ crn }}
</ng-container>
