import { Component, Input, OnInit } from '@angular/core';

import { Address, Bottle, calcSellerInvoiceTotal, SellerAuctionInvoice } from '@whiskybazar/core';
import { InvoiceView } from '@whiskybazar/pwa/shared/common';

@Component({
  selector: 'app-seller-invoice',
  templateUrl: './seller-invoice.component.html',
  styleUrls: ['./seller-invoice.component.scss'],
})
export class SellerInvoiceComponent extends InvoiceView implements OnInit {
  @Input() set bottle(val: Bottle) {
    this._bottle = val;
    this.init(this.bottle);
  }

  @Input() full = true;

  get bottle(): Bottle {
    return this._bottle;
  }
  private _bottle: Bottle;

  invoice: SellerAuctionInvoice;

  billing: Address;

  constructor() {
    super();
  }

  ngOnInit() {}

  calcTotal(): number {
    if (!this.invoice) {
      return 0.0;
    }

    return calcSellerInvoiceTotal(this.invoice);
  }

  protected init(bottle: Bottle) {
    if (!bottle) {
      return;
    }

    super.init(bottle);

    this.invoice = this.sellerInvoice;
    this.billing = {
      name: this.seller.name,
      address: this.seller.address,
      city: this.seller.city,
      zipCode: this.seller.zipCode,
      country: this.seller.country,
      email: this.seller.email,
      phone: this.seller.phone,
    } as Address;
  }
}
