import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { AuthContextService } from '@whiskybazar/pwa/core/services';
import { Bottle } from '@whiskybazar/core';

@Component({
  selector: 'app-bottle-edit-button',
  templateUrl: './bottle-edit-button.component.html',
  styleUrls: ['./bottle-edit-button.component.scss'],
})
export class BottleEditButtonComponent implements OnInit {
  @Input() bottle: Bottle;

  private readonly editRoutes = {
    collector: ['collector', 'collection', 'edit'],
    dealer: ['dealer', 'bottle', 'edit'],
  };

  constructor(private ctx: AuthContextService, private router: Router) {}

  ngOnInit() {}

  edit() {
    if (this.ctx.isCollector()) {
      this.router.navigate([...this.editRoutes.collector, this.bottle.id]);
    } else if (this.ctx.isDealer()) {
      this.router.navigate([...this.editRoutes.dealer, this.bottle.id]);
    }
  }
}
