import { add } from 'date-fns';

export const DEFAULT_CURRENCY = 'EUR';

export const PROD_PROJECT_ID = 'whisky-bazar';

export const STAGING_PROJECT_ID = 'whisky-bazar-f39cc';

export const STORAGE = {
  BOTTLES_FOLDER: 'bottles',
  META_BOTTLES_FOLDER: 'meta_bottles',
  STORAGE_HOST: 'https://storage.googleapis.com',
  SHIPPING_FOLDER: 'shipping',
};

export const AUCTION_RUNTIMES = {
  runtime_1: {
    id: 'runtime_1',
    title: '2 Days',
    durationInSeconds: 172800.0,
  },
  runtime_2: {
    id: 'runtime_2',
    title: '1 Week',
    durationInSeconds: 604800.0,
  },
  runtime_3: {
    id: 'runtime_3',
    title: '2 Weeks',
    durationInSeconds: 1209600.0,
  },
  runtime_4: {
    id: 'runtime_4',
    title: '1 Month',
    durationInSeconds: 2629743.83,
  },
};

export const BOTTLE_CONDITIONS = {
  condition_1: {
    id: 'condition_1',
    title: 'New',
  },
  condition_2: {
    id: 'condition_2',
    title: 'Excellent',
  },
  condition_3: {
    id: 'condition_3',
    title: 'Good',
  },
  condition_4: {
    id: 'condition_4',
    title: 'Fair',
  },
  condition_5: {
    id: 'condition_5',
    title: 'Damaged',
  },
  condition_6: {
    id: 'condition_6',
    title: 'Other',
  },
};

export const SUPPORTED_COUNTRIES = [
  {
    name: 'Australia',
    iso2: 'AU',
    iso3: 'AUS',
    eu_member: false,
    calling_code: '61',
  },
  {
    name: 'Austria',
    iso2: 'AT',
    iso3: 'AUT',
    eu_member: true,
    calling_code: '43',
  },
  {
    name: 'Belgium',
    iso2: 'BE',
    iso3: 'BEL',
    eu_member: true,
    calling_code: '32',
  },
  {
    name: 'Bulgaria',
    iso2: 'BG',
    iso3: 'BGR',
    eu_member: true,
    calling_code: '359',
  },
  {
    name: 'Canada',
    iso2: 'CA',
    iso3: 'CAN',
    eu_member: false,
    calling_code: '1',
  },
  {
    name: 'Croatia',
    iso2: 'HR',
    iso3: 'HRV',
    eu_member: true,
    calling_code: '385',
  },
  {
    name: 'Cyprus',
    iso2: 'CY',
    iso3: 'CYP',
    eu_member: true,
    calling_code: '357',
  },
  {
    name: 'Czech Republic',
    iso2: 'CZ',
    iso3: 'CZE',
    eu_member: true,
    calling_code: '420',
  },
  {
    name: 'Denmark',
    iso2: 'DK',
    iso3: 'DNK',
    eu_member: true,
    calling_code: '45',
  },
  {
    name: 'Estonia',
    iso2: 'EE',
    iso3: 'EST',
    eu_member: true,
    calling_code: '372',
  },
  {
    name: 'Finland',
    iso2: 'FI',
    iso3: 'FIN',
    eu_member: true,
    calling_code: '358',
  },
  {
    name: 'France',
    iso2: 'FR',
    iso3: 'FRA',
    eu_member: true,
    calling_code: '33',
  },
  {
    name: 'Germany',
    iso2: 'DE',
    iso3: 'DEU',
    eu_member: true,
    calling_code: '49',
  },
  {
    name: 'Greece',
    iso2: 'GR',
    iso3: 'GRC',
    eu_member: true,
    calling_code: '30',
  },
  {
    name: 'Hungary',
    iso2: 'HU',
    iso3: 'HUN',
    eu_member: true,
    calling_code: '36',
  },
  {
    name: 'Iceland',
    iso2: 'IS',
    iso3: 'ISL',
    eu_member: false,
    calling_code: '354',
  },
  {
    name: 'Ireland',
    iso2: 'IE',
    iso3: 'IRL',
    eu_member: true,
    calling_code: '353',
  },
  {
    name: 'Italy',
    iso2: 'IT',
    iso3: 'ITA',
    eu_member: true,
    calling_code: '39',
  },
  {
    name: 'Latvia',
    iso2: 'LV',
    iso3: 'LVA',
    eu_member: true,
    calling_code: '371',
  },
  {
    name: 'Liechtenstein',
    iso2: 'LI',
    iso3: 'LIE',
    eu_member: false,
    calling_code: '423',
  },
  {
    name: 'Lithuania',
    iso2: 'LT',
    iso3: 'LTU',
    eu_member: true,
    calling_code: '370',
  },
  {
    name: 'Luxembourg',
    iso2: 'LU',
    iso3: 'LUX',
    eu_member: true,
    calling_code: '352',
  },
  {
    name: 'Malta',
    iso2: 'MT',
    iso3: 'MLT',
    eu_member: true,
    calling_code: '356',
  },
  {
    name: 'Netherlands',
    iso2: 'NL',
    iso3: 'NLD',
    eu_member: true,
    calling_code: '31',
  },
  {
    name: 'New Zeland',
    iso2: 'NZ',
    iso3: 'NZL',
    eu_member: false,
    calling_code: '64',
  },
  {
    name: 'Poland',
    iso2: 'PL',
    iso3: 'POL',
    eu_member: true,
    calling_code: '48',
  },
  {
    name: 'Portugal',
    iso2: 'PT',
    iso3: 'PRT',
    eu_member: true,
    calling_code: '351',
  },
  {
    name: 'Romania',
    iso2: 'RO',
    iso3: 'ROU',
    eu_member: true,
    calling_code: '40',
  },
  {
    name: 'South Korea',
    iso2: 'KR',
    iso3: 'KOR',
    eu_member: false,
    calling_code: '82',
  },
  {
    name: 'Slovakia',
    iso2: 'SK',
    iso3: 'SVK',
    eu_member: true,
    calling_code: '421',
  },
  {
    name: 'Slovenia',
    iso2: 'SI',
    iso3: 'SVN',
    eu_member: true,
    calling_code: '386',
  },
  {
    name: 'Spain',
    iso2: 'ES',
    iso3: 'ESP',
    eu_member: true,
    calling_code: '34',
  },
  {
    name: 'Sweden',
    iso2: 'SE',
    iso3: 'SWE',
    eu_member: true,
    calling_code: '46',
  },
  {
    name: 'Switzerland',
    iso2: 'CH',
    iso3: 'CHE',
    eu_member: false,
    calling_code: '41',
  },
  {
    name: 'Taiwan',
    iso2: 'TW',
    iso3: 'TWN',
    eu_member: false,
    calling_code: '886',
  },
  {
    name: 'United Kingdom',
    iso2: 'GB',
    iso3: 'GBR',
    eu_member: true,
    calling_code: '44',
  },
  {
    name: 'United States of America',
    iso2: 'US',
    iso3: 'USA',
    eu_member: false,
    calling_code: '1',
  },
];
// List of names of all supported countries, e.g. 'Austria', 'Belgium', etc.
export const COUNTRIES = SUPPORTED_COUNTRIES.map((country) => country.name);
// Mapping from country names to ISO2 codes and vise versa, e.g. 'Austria' => 'AT', 'AT' => 'Austria'
export const COUNTRIES_DICT = SUPPORTED_COUNTRIES.reduce(
  (result, country) => Object.assign(result, { [country.name]: country.iso2, [country.iso2]: country.name }),
  {}
);
// Mapping from ISO3 codes to ISO2 codes and vise versa, e.g. 'AUT' => 'AT', 'AT' => 'AUT'
export const COUNTRIES_ISO_CODES = SUPPORTED_COUNTRIES.reduce(
  (result, country) => Object.assign(result, { [country.iso3]: country.iso2, [country.iso2]: country.iso3 }),
  {}
);
// Mapping from country names to country calling codes and vise versa, e.g. 'Denmark' => '45', '45' => 'Denmark'
export const COUNTRIES_CALLING_CODES = SUPPORTED_COUNTRIES.reduce(
  (result, country) =>
    Object.assign(result, { [country.name]: country.calling_code, [country.calling_code]: country.name }),
  {}
);

/**
 * This value conforms to the following JSON Schema (in YAML format):
 *
 * ```yml
 * definitions:
 *   shippingConstant:
 *     type: object
 *     properties:
 *       value: {type: number}
 *       isPercentage: {type: boolean}
 *       description: {type: string}
 *
 *   receiverCountry:
 *     type: object
 *     properties:
 *       code: {type: string}
 *       constants:
 *         type: array
 *         items: {'$ref': '#/definitions/shippingConstant'}
 *
 *   shippingGroup:
 *     type: object
 *     properties:
 *       id: {type: string}
 *       name: {type: string}
 *       constants:
 *         type: array
 *         items: {'$ref': '#/definitions/shippingConstant'}
 *       countries:
 *         type: array
 *         items: {'$ref': '#/definitions/receiverCountry'}
 *
 *   shippingProduct:
 *     type: object
 *     properties:
 *       id: {type: string}
 *       name: {type: string}
 *       description: {type: string}
 *       remarks: {type: string}
 *       insurable: {type: boolean}
 *       constants:
 *         type: array
 *         items: {'$ref': '#/definitions/shippingConstant'}
 *       groups:
 *         type: array
 *         items: {'$ref': '#/definitions/shippingGroup'}
 *
 * type: object
 * patternProperties:
 *   '^[A-Z]{2}$':
 *     type: object
 *     properties:
 *       products:
 *         type: array
 *         items: {'$ref': '#/definitions/shippingProduct'}
 * ```
 */
export const SHIPPING_PRICES = {
  DK: {
    products: [
      // DAO 365
      {
        id: 'dao365_dk',
        name: 'DAO 356',
        description:
          'Full tracking and fast delivery, best solution for safe and fast shipping. Packages are expected to arrive within 1 week (DK only)',
        remarks: 'Only for shipping in DK.',
        insurable: true,
        constants: [],
        groups: [
          {
            id: 'group_0',
            name: 'Group 0',
            constants: [{ value: 7.0 }],
            countries: [{ code: 'DK' }],
          },
        ],
      },

      // WhiskyBazar Defaults
      {
        id: 'wb_defaults_dk',
        name: 'Whisky Bazar Defaults from Denmark',
        description:
          'The default solution offered by Whisky Bazar for safe and fast shipping. Packages are expected to arrive within 2 weeks for most international countries.',
        remarks: '',
        insurable: true,
        constants: [],
        groups: [
          {
            id: 'group_1',
            name: 'Group 1',
            constants: [{ value: 12.0 }],
            countries: [
              { code: 'BE' },
              { code: 'DE' },
              { code: 'ES' },
              { code: 'GB' },
              { code: 'NL' },
              { code: 'PT' },
              { code: 'SE' },
            ],
          },
          {
            id: 'group_2',
            name: 'Group 2',
            constants: [{ value: 14.0 }],
            countries: [{ code: 'FI' }, { code: 'IT' }, { code: 'LU' }],
          },
          {
            id: 'group_3',
            name: 'Group 3',
            constants: [{ value: 17.0 }],
            countries: [
              { code: 'AT' },
              { code: 'CZ' },
              { code: 'EE' },
              { code: 'FR' },
              { code: 'GR' },
              { code: 'HU' },
              { code: 'IE' },
              { code: 'LT' },
              { code: 'LV' },
              { code: 'PL' },
              { code: 'RO' },
              { code: 'SI' },
              { code: 'SK' },
            ],
          },
          {
            id: 'group_4',
            name: 'Group 4',
            constants: [{ value: 35.0 }],
            countries: [{ code: 'BG' }, { code: 'HR' }, { code: 'KR' }],
          },
          {
            id: 'group_5',
            name: 'Group 5',
            constants: [{ value: 39.0 }],
            countries: [{ code: 'LI' }, { code: 'CH' }],
          },
          {
            id: 'group_6',
            name: 'Group 6',
            constants: [{ value: 60.0 }],
            countries: [{ code: 'IS' }],
          },
          {
            id: 'group_7',
            name: 'Group 7',
            constants: [{ value: 66.0 }],
            countries: [{ code: 'CA' }, { code: 'US' }],
          },
          {
            id: 'group_8',
            name: 'Group 8',
            constants: [{ value: 77.0 }],
            countries: [{ code: 'AU' }, { code: 'CY' }],
          },
          {
            id: 'group_9',
            name: 'Group 9',
            constants: [{ value: 220.0 }],
            countries: [{ code: 'TW' }],
          },
        ],
      }, // WhiskyBazar Defaults
    ], // DK products
  }, // DK
};

/**
 * Shipping product ID representing buyer picking the bottle at auctioneer by him/her self
 */
export const SHIPPING_SELF_PICKUP_AT_AUCTIONEER_PRODUCT_ID = 'self_pickup_at_auctioneer';

/**
 * Shipping product representing buyer picking the bottle at auctioneer by him/her self
 */
export const SHIPPING_SELF_PICKUP_AT_AUCTIONEER_PRODUCT = {
  id: SHIPPING_SELF_PICKUP_AT_AUCTIONEER_PRODUCT_ID,
  name: 'Pickup at Auctioneer',
  description: 'You can pickup at auctioneer by yourself.',
  remarks: '',
  insurable: false,
  cost: 0.0,
};

export const EXCHANGE_RATES = {
  DKK_EUR: 0.13417,
};

export const VAT_RATES = {
  DK: 0.25,
};

export const AUCTION_VARIABLE_FEE = 0.07; // 7% of hammer price

export const AUCTION_FIXED_FEE = 4.0; // EUR

export const AUCTIONEER_VARIABLE_PROFIT = 0.1; // 10% of hammer price

export const AUCTIONEER_FIXED_PROFIT = 10.0; // EUR

export const SHIPPING_COST_WITHIN_DK = 7.52; // EUR

export const SHIPPING_INSURANCE_VARIABLE_COST = 0.015; // 1.5% of hammer price

export const SHIPPING_INSURANCE_MIN_COST = 5.0; // EUR

export const sellerProfit = (amount: number): number =>
  amount ? amount * (1 - AUCTION_VARIABLE_FEE) - AUCTION_FIXED_FEE : 0;

export const auctioneerProfit = (amount: number): number =>
  amount ? amount * AUCTIONEER_VARIABLE_PROFIT + AUCTIONEER_FIXED_PROFIT : 0;

export const WHISKYBAZAR_INVOICING_ADDRESS = {
  id: 'whiskybazar-invoicing-address',
  name: 'Jutlander Group ApS',
  email: 'contact@WhiskyBazar.com',
  phone: '+4591718779',
  address: 'Messingvej 54A',
  city: 'Randers SV',
  zipCode: '8940',
  country: 'Denmark',
  crn: '38536990',
};

export const expectedAuctioneerSettlementDate = (now = new Date()) => add(now, { weeks: 6 });

export const auctionBuyerPaymentDueDate = (now = new Date()) => add(now, { days: 3 });

export const expectedAuctionSellerSettlementDate = (now = new Date()) => add(now, { weeks: 6 });

export const auctionShippingDueDate = (now = new Date()) => add(now, { days: 5 });

export const auctionPickupDueDate = (now = new Date()) => add(now, { weeks: 2 });

export const auctioneerMonthlyInvoicePaymentDueDate = (now = new Date()) => add(now, { weeks: 1 });
