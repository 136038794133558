import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuctionDetailsComponent } from './auction-details.component';
import { AuctionProfitModule, AuctionRuntimeModule, BottleModule, AuthContextModule } from '../../pipes';
import { CurrencyModule } from '../currency/currency.module';
import { UserModule } from '../user/user.module';
import { AuctionCountdownModule } from '../auction-countdown/auction-countdown.module';
import { MatIconModule } from '@angular/material/icon';
import { BottleDealerModule } from '../bottle-dealer/bottle-dealer.module';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [AuctionDetailsComponent],
  imports: [
    CommonModule,
    AuctionRuntimeModule,
    AuctionProfitModule,
    CurrencyModule,
    UserModule,
    AuctionCountdownModule,
    BottleDealerModule,
    BottleModule,
    MatIconModule,
    AuthContextModule,
    FlexLayoutModule,
  ],
  exports: [AuctionDetailsComponent],
})
export class AuctionDetailsModule {}
