import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, merge, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';

import { AuthUser } from '@whiskybazar/core';
import { AuthContextService, AuthService, DataLayerService } from '@whiskybazar/pwa/core/services';
import { AuthActionType, Authenticate, AuthenticateSuccess, Login, LoginFailure, LoginSuccess } from '../actions';

@Injectable()
export class AuthEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionType.Login),
      tap((action: Login) => this.setRedirectUrl(action.redirectUrl)),
      switchMap((action: Login) =>
        this.authService.login(action.email, action.password).pipe(
          map((user: AuthUser) => (user === null ? new LoginFailure('ERR_NULL_USER') : new LoginSuccess(user))),
          catchError((error) => of(new LoginFailure(error)))
        )
      )
    )
  );

  authenticate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionType.Authenticate),
      switchMap((action: Authenticate) =>
        this.authService.authenticate().pipe(
          filter((user: AuthUser) => user !== null),
          map((user: AuthUser) => new AuthenticateSuccess(user))
        )
      )
    )
  );

  success$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionType.LoginSuccess, AuthActionType.AuthenticateSuccess),
        switchMap((action: LoginSuccess | AuthenticateSuccess) =>
          merge(
            this.onSuccess(action.user),
            action.user.type !== 'collector' ? this.authService.setCustomUserClaims(action.user.id) : of(true)
          )
        )
      ),
    { dispatch: false }
  );

  loginRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionType.LoginRedirect),
        tap(() => this.router.navigate(['/login']))
      ),
    { dispatch: false }
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionType.Logout),
        tap(() => {
          this.authService.logout();
          this.router.navigate(['/']);
          this.ctxService.setAuth(null);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private ctxService: AuthContextService,
    private dataLayerService: DataLayerService
  ) {}

  protected onSuccess(user: AuthUser): Observable<boolean> {
    this.ctxService.setAuth(user);

    this.dataLayerService.setUserId(user.id);

    let navigation: Promise<boolean>;
    const redirect = this.authService.redirect;
    if (redirect) {
      navigation = this.router.navigateByUrl(redirect);
    } else {
      switch (user.type) {
        case 'admin':
          navigation = this.router.navigateByUrl('/admin');
          break;

        case 'collector':
          navigation = this.router.navigateByUrl('/collector');
          break;

        case 'dealer':
          navigation = this.router.navigateByUrl('/dealer');
          break;

        default:
          navigation = this.router.navigate(['/']);
      }
    }

    return from(navigation).pipe(
      switchMap((result) => (result === true ? of(true) : from(this.router.navigate(['/']))))
    );
  }

  protected setRedirectUrl(url: string) {
    if (!url) {
      return;
    }

    this.authService.redirect = url;
  }
}
