<div class="mat-elevation-z4" ngClass.lt-md="small">
  <!-- Bottle Details -->
  <div fxLayout.gt-xs="row" fxLayout.xs="column">
    <!-- Images -->
    <div fxFlex="50" class="images">
      <app-bottle-images [bottle]="bottle"></app-bottle-images>
    </div>
    <!-- Details -->
    <div fxFlex="50" class="details">
      <div fxLayout="column" fxLayoutGap="16px">
        <div fxFlex>
          <h1 ngClass.gt-md="mat-display-1">
            {{ bottle | bottleName }}
          </h1>
          <div class="subtitle">
            <ng-container *ngIf="bottle.auction; else noAuction">
              <ng-container>LOT: {{ bottle | bottleLot }}</ng-container>
              <ng-container *ngIf="(bottle | authContext : 'isAuctioneer') && bottle.owner"
                >, {{ bottle.owner?.name }} (seller), {{ bottle.owner?.email }}
              </ng-container>
              <ng-container *ngIf="!(bottle | authContext : 'isAuctioneer')"
                >, <app-bottle-dealer [bottle]="bottle"></app-bottle-dealer>
              </ng-container>
            </ng-container>
            <ng-template #noAuction> Barcode: {{ bottle?.metaBottle?.barcode || 'N/A' }} </ng-template>
          </div>
        </div>

        <mat-divider></mat-divider>

        <div fxFlex class="mat-h2 text-center">
          <app-bottle-state [bottle]="bottle" [elaborate]="true"></app-bottle-state>
        </div>

        <div fxFlex="none" class="body-container">
          <ng-content select="app-bottle-details-body"></ng-content>
        </div>

        <div fxFlex="none">
          <div class="mat-h1 text-center" i18n>Bottle details</div>
          <mat-divider></mat-divider>
        </div>

        <div fxFlex="none" fxLayout="column" fxLayoutGap="8px">
          <div fxFlex="none" class="attribute mat-h3" *ngIf="bottle | authContext : 'hasNumbering'">
            <span i18n>Item Number:</span> {{ bottle.itemNumber }}/{{ bottle.metaBottle.numberOfBottles }}
          </div>

          <div fxFlex="none" class="attribute mat-h3" *ngIf="bottle.batchNumber">
            <span i18n>Batch Number:</span> {{ bottle.batchNumber }}
          </div>

          <div fxFlex="none" class="attribute mat-h3" *ngIf="bottle.caskNumber">
            <span i18n>Cask Number:</span> {{ bottle.caskNumber }}
          </div>

          <div fxFlex="none" class="attribute mat-h3">
            <span i18n>Condition:</span> {{ bottle.condition | bottleCondition }}
          </div>

          <div fxFlex="none" class="attribute mat-h3">
            <span i18n>Original Packaging Included?</span> {{ bottle.originalPackagingIncluded ? 'Yes' : 'No' }}
          </div>

          <div fxFlex="none" class="attribute mat-h3" *ngIf="bottle.description">
            <span i18n>Comments:</span> {{ bottle.description }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Meta-bottle Details -->
  <div fxLayout="column" class="meta-bottle">
    <div fxFlex="none">
      <h2 class="mat-headline-4">About {{ bottle.metaBottle.title }}</h2>
    </div>

    <!-- Description -->
    <div fxFlex="none">
      <p>{{ bottle.metaBottle.description }}</p>
    </div>

    <!-- Attributes -->
    <div
      fxFlex="none"
      fxLayout="row"
      fxLayout.lt-sm="column"
      fxLayoutGap="64px"
      fxLayoutGap.lt-md="32px"
      fxLayoutGap.lt-sm="8px"
    >
      <!-- FIRST COLUMN -->
      <div fxFlex="50" fxFlex.lt-sm="none" fxLayout="column" class="attributes-column">
        <div fxFlex="nogrow" class="attribute mat-h3">
          <span i18n>Type:</span> {{ bottle.metaBottle.type || 'N/A' }}
        </div>

        <div fxFlex="nogrow" class="attribute mat-h3">
          <span i18n>Origin:</span> {{ bottle.metaBottle.origin || 'N/A' }}
        </div>

        <div fxFlex="nogrow" class="attribute mat-h3">
          <span i18n>Region:</span> {{ bottle.metaBottle.region || 'N/A' }}
        </div>

        <div fxFlex="nogrow" class="attribute mat-h3">
          <span i18n>Age:</span>&nbsp;<ng-container *ngIf="bottle.metaBottle.age; else noAge"
            >{{ bottle.metaBottle.age }} years</ng-container
          ><ng-template #noAge>N/A</ng-template>
        </div>

        <div fxFlex="nogrow" class="attribute mat-h3">
          <span i18n>Alcohol:</span>&nbsp;<ng-container *ngIf="bottle.metaBottle.alcoholPercentage; else noAlcohol"
            >{{ bottle.metaBottle.alcoholPercentage | round : 1 }}%</ng-container
          ><ng-template #noAlcohol>N/A</ng-template>
        </div>
      </div>

      <!-- SECOND COLUMN -->
      <div fxFlex="50" fxFlex.lt-sm="none" fxLayout="column" class="attributes-column">
        <div fxFlex="nogrow" class="attribute mat-h3">
          <span i18n>Distillery:</span> {{ bottle.metaBottle.distillery || 'N/A' }}
        </div>

        <div fxFlex="nogrow" class="attribute mat-h3">
          <span i18n>Bottler:</span> {{ bottle.metaBottle.bottler || 'N/A' }}
        </div>

        <div fxFlex="nogrow" class="attribute mat-h3">
          <span i18n>Distillation Year:</span> {{ bottle.metaBottle.distillationYear || 'N/A' }}
        </div>

        <div fxFlex="nogrow" class="attribute mat-h3">
          <span i18n>Bottling Year:</span> {{ bottle.metaBottle.bottlingYear || 'N/A' }}
        </div>

        <div fxFlex="nogrow" class="attribute mat-h3">
          <span i18n>Volume: </span>&nbsp;<ng-container *ngIf="bottle.metaBottle.volume; else noVolume"
            >{{ bottle.metaBottle.volume }}ml</ng-container
          ><ng-template #noVolume>N/A</ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
