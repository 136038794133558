import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { equalTo, limitToFirst, orderByChild, orderByKey } from '@angular/fire/database';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import {
  Auction,
  Bottle,
  Facet,
  Range,
  MetaBottle,
  OpenAuctionDocument,
  SearchQuery,
  SearchResult,
  SearchResultFacets,
  SortBy,
  Filter,
  isSingleValueFilter,
  isRangeFilter,
  isMultiValueFilter,
} from '@whiskybazar/core';
import { AuctionsService } from './auctions.service';
import { AuthContextService } from './auth-context.service';
import { BottlesService } from './bottles.service';
import { MetaBottlesService } from './meta-bottles.service';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { environment } from '@whiskybazar/pwa/environment';

const DEFAULT_AUCTIONS_FACETS: (keyof OpenAuctionDocument)[] = ['distillery', 'bottler', 'type', 'region', 'price'];

export interface MeilisearchRequest {
  q?: string;
  offset?: number;
  limit?: number;
  attributesToRetrieve?: string[];
  filter?: string;
  facets?: string[];
  sort?: string[];
}

export interface MeilisearchResponse {
  hits: Partial<OpenAuctionDocument>[];
  query: string;
  processingTimeMs: number;
  limit: number;
  offset: number;
  estimatedTotalHits: number;
  facetDistribution?: FacetDistribution;
  facetStats?: FacetStats;
}

export interface FacetDistribution {
  distillery?: Record<string, number>;
  bottler?: Record<string, number>;
  type?: Record<string, number>;
  region?: Record<string, number>;
}

export interface FacetStats {
  price?: FacetRange;
}

export interface FacetRange {
  min: number;
  max: number;
}

/**
 * @deprecated
 */
interface Index {
  [id: string]: string;
}

/**
 * @deprecated
 */
interface Search {
  id: string;
  hits: number;
}

@Injectable()
export class SearchService {
  private index$ = new ReplaySubject<Index>(1);

  constructor(
    private auctionsService: AuctionsService,
    private metaBottlesService: MetaBottlesService,
    private bottlesService: BottlesService,
    private ctx: AuthContextService,
    private http: HttpClient
  ) {}

  searchInOpenAuctions({
    q = '',
    limit = 20,
    offset = 0,
    sorting = [SortBy.PriceAsc],
    facets = DEFAULT_AUCTIONS_FACETS,
    attributesToRetrieve = ['bottle_id'],
    filters = [],
  }: SearchQuery<OpenAuctionDocument>): Observable<SearchResult<Bottle>> {
    return this.post({
      q,
      limit,
      offset,
      attributesToRetrieve,
      facets,
      sort: sorting,
      filter: filters?.length ? this.toMeilisearchFilter(filters) : undefined,
    }).pipe(
      switchMap(({ hits, estimatedTotalHits, facetDistribution, facetStats }) => {
        if (hits.length === 0) {
          return of({
            totalHits: estimatedTotalHits,
            results: [],
            facets: this.buildFacets(facetDistribution, facetStats),
          } as SearchResult<Bottle>);
        }

        return combineLatest(
          hits.map(({ bottle_id }) =>
            this.bottlesService.fetchById(bottle_id, {
              auction: { auctioneer: true, bestBid: true },
            })
          )
        ).pipe(
          map(
            (bottles) =>
              ({
                totalHits: estimatedTotalHits,
                results: bottles,
                facets: this.buildFacets(facetDistribution, facetStats),
              } as SearchResult<Bottle>)
          )
        );
      })
    );
  }

  private toMeilisearchFilter<T>(filters: Filter<T>[]): string {
    return filters
      .map((filter) => {
        if (isSingleValueFilter(filter)) {
          const { name, value } = filter;
          return value ? `${name} = "${value}"` : null;
        }

        if (isMultiValueFilter(filter)) {
          const { name, values } = filter;
          return values.length > 0 ? `${name} IN [${values.map((value) => `"${value}"`).join(', ')}]` : null;
        }

        if (isRangeFilter(filter)) {
          const { name, min, max } = filter;
          return `${name} ${min} TO ${max}`;
        }

        throw new Error('Unsupported filter type');
      })
      .filter(Boolean)
      .join(' AND ');
  }

  private buildFacets(
    { distillery, bottler, region, type }: FacetDistribution,
    { price }: FacetStats
  ): SearchResultFacets {
    const facet: Facet = {
      options: [],
      selection: [],
    };

    const range: Range = {
      min: 0,
      max: 0,
      step: 1,
      selection: [],
    };

    const toFacetOptions = (records: Record<string, number>) =>
      Object.entries(records).map(([name, count]) => ({
        name,
        count,
      }));

    return {
      distillery: distillery ? { ...facet, options: toFacetOptions(distillery) } : { ...facet },
      bottler: bottler ? { ...facet, options: toFacetOptions(bottler) } : { ...facet },
      region: region ? { ...facet, options: toFacetOptions(region) } : { ...facet },
      type: type ? { ...facet, options: toFacetOptions(type) } : { ...facet },
      price: price ? { ...range, min: price.min, max: price.max } : { ...range },
    };
  }

  private post(req: MeilisearchRequest): Observable<MeilisearchResponse> {
    const host = environment.meilisearchConfig.host;
    const apiKey = environment.meilisearchConfig.apiKey;
    const index = environment.meilisearchConfig.indexes.openAuctions;

    return this.http
      .post(`${host}/indexes/${index}/search`, JSON.stringify(req), {
        headers: {
          Authorization: `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
        },
      })
      .pipe(map((res) => res as MeilisearchResponse));
  }

  /**
   * @deprecated
   */
  init(): void {
    this.metaBottlesService
      .query(orderByKey())
      .pipe(map((result: MetaBottle[]) => this.createIndex(result)))
      .subscribe((index: Index) => this.index$.next(index));
  }

  /**
   * @deprecated
   */
  search(phrase: string): Observable<Bottle[]> {
    if (this.isLotNumber(phrase)) {
      return this.searchByLotNumber(phrase);
    }

    return this.index$.pipe(
      map((index: Index) => this.find(phrase, index)),
      switchMap((result: Search[]) =>
        result.length === 0
          ? of([])
          : combineLatest(result.map((s: Search) => this.bottlesService.fetchByMetaBottle(s.id, { auction: {} })))
      ),
      take(1),
      map((matrix: Bottle[][]) => matrix.reduce((prev: Bottle[], current: Bottle[]) => [...prev, ...current], [])),
      // Filter bottles based on the auth context
      map((bottles: Bottle[]) => this.filter(bottles))
    );
  }

  /**
   * @deprecated
   */
  searchByLotNumber(lotNumber: string): Observable<Bottle[]> {
    const lot = lotNumber.replace(/^(lot:|lot)/i, '').toUpperCase();

    return this.auctionsService.query(orderByChild('auction_id'), equalTo(lot), limitToFirst(1)).pipe(
      switchMap((auctions: Auction[]) =>
        auctions.length === 0
          ? of([])
          : combineLatest(
              auctions.map((a: Auction) =>
                this.bottlesService.fetchById(a.bottleId).pipe(map((b: Bottle) => ({ ...b, auction: a })))
              )
            )
      ),
      // Filter bottles based on the auth context
      map((bottles: Bottle[]) => this.filter(bottles))
    );
  }

  /**
   * @deprecated
   */
  protected filter(bottles: Bottle[]): Bottle[] {
    return bottles.filter((b: Bottle) => this.ctx.canView(b) || this.ctx.isPurchasable(b) || this.ctx.isAdmin());
  }

  /**
   * @deprecated
   */
  protected find(needle: string, haystack: Index): Search[] {
    const needles = (needle || '').toLowerCase().split(' ');
    // Also include the whole phrase without any space
    needles.push(needles.join(''));

    return Object.keys(haystack)
      .map((id: string) => {
        const value = haystack[id] || '';
        const hits = needles
          .map((n: string) => (value.indexOf(n) !== -1 ? 1 : 0))
          .reduce((sum: number, current: number) => sum + current, 0);
        return { id, hits };
      })
      .filter((s: Search) => s.hits > 0)
      .sort((a: Search, b: Search) => (a.hits >= b.hits ? 1 : -1));
  }

  /**
   * @deprecated
   */
  protected createIndex(metaBottles: MetaBottle[]): Index {
    if (!metaBottles) {
      return {};
    }

    return metaBottles
      .map((metaBottle: MetaBottle) => {
        // Construct a string (haystack) of all the searchable properties of the meta-bottle
        // this will be used for searching later on
        const values: string[] = [];
        values.push(metaBottle.title.toLowerCase());
        values.push(metaBottle.title.toLowerCase().replace(/[ ]+/g, ''));
        values.push((metaBottle.age || '') + '');
        values.push((metaBottle.distillationYear || '') + '');
        values.push((metaBottle.bottlingYear || '') + '');
        values.push((metaBottle.volume || '') + '');
        values.push((metaBottle.volume || '') + 'ml');
        values.push((metaBottle.alcoholPercentage || '') + '');
        values.push((metaBottle.alcoholPercentage || '') + '%');
        values.push((metaBottle.barcode || '').toLowerCase());
        values.push((metaBottle.type || '').toLowerCase());
        values.push((metaBottle.type || '').toLowerCase().replace(/[ ]+/g, ''));

        return { [metaBottle.id]: values.join('') };
      })
      .reduce((current, prev) => ({ ...current, ...prev }), {});
  }

  /**
   * @deprecated
   */
  protected isLotNumber(phrase: string): boolean {
    return phrase.match(/^(lot:|lot).*$/i) !== null;
  }
}
