import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { SearchButtonComponent } from '@whiskybazar/pwa-ui';
import { HorizontalNavComponent } from './horizontal-nav.component';

@NgModule({
  declarations: [HorizontalNavComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    RouterModule,
    MatDividerModule,
    FlexLayoutModule,
    MatButtonModule,
    MatToolbarModule,
    SearchButtonComponent,
  ],
  exports: [HorizontalNavComponent],
})
export class HorizontalNavModule {}
