import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { CallToActionModule } from '@whiskybazar/pwa-ui';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { CarouselModule } from '@whiskybazar/pwa/shared';
import { HeroSliderComponent } from './hero-slider.component';

@NgModule({
  declarations: [HeroSliderComponent],
  imports: [CommonModule, CarouselModule, CallToActionModule, FlexLayoutModule],
  exports: [HeroSliderComponent],
})
export class HeroSliderModule {}
