import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { delay, filter, map, tap } from 'rxjs/operators';

import { StaticPage } from '@whiskybazar/core';
import * as fromStore from '@whiskybazar/pwa/statics/store';

@Component({
  selector: 'app-static-page',
  templateUrl: './static-page.component.html',
  styleUrls: ['./static-page.component.scss'],
})
export class StaticPageComponent implements OnInit, AfterViewInit {
  @ViewChild('host', { static: true }) host: ElementRef;

  content$: Observable<SafeHtml>;

  navigation$: Observable<any>;

  navigateToFragment$: Observable<any>;

  activatedRouteFragment$: Observable<any>;

  private fragment$: Subject<string>;

  constructor(
    private store$: Store<fromStore.State>,
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private doc: Document
  ) {
    this.fragment$ = new Subject<string>();
  }

  ngOnInit() {
    this.content$ = this.store$.pipe(
      select(fromStore.selectCurrentPage),
      filter((page: StaticPage) => !!page),
      map((page: StaticPage) => this.sanitizer.bypassSecurityTrustHtml(page.content))
    );

    this.navigateToFragment$ = this.fragment$.pipe(tap((f: string) => this.scrollFragmentIntoView(f)));

    this.activatedRouteFragment$ = this.activatedRoute.fragment.pipe(
      delay(0),
      tap((v: string) => this.fragment$.next('#' + v))
    );
  }

  ngAfterViewInit() {
    this.navigation$ = this.content$.pipe(
      delay(0),
      tap(() => this.setupTocNavigation())
    );
  }

  protected setupTocNavigation(): void {
    const host = this.host.nativeElement as HTMLDivElement;
    const links = host.querySelectorAll('.toc a');
    for (let i = 0; i < links.length; i++) {
      const elem = links.item(i) as HTMLAnchorElement;
      elem.addEventListener('click', (evt) => {
        evt.preventDefault();
        this.fragment$.next(elem.hash);
      });
    }
  }

  protected scrollFragmentIntoView(fragment: string): void {
    if (!fragment) {
      return;
    }

    const element = this.doc.querySelector(fragment);
    if (!element) {
      return;
    }

    element.scrollIntoView();
  }
}
