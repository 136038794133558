import { Injectable } from '@angular/core';

import { environment } from '@whiskybazar/pwa/environment';

@Injectable()
export class ThumbnailGeneratorService {
  private readonly path = '/thumbnail';

  /**
   * Generates the URL for a thumbnail of the given size
   *
   * @param imageUrl The URL pointing to the original image
   * @param size The size of the resulting thumbnail, e.g. `128x128`
   */
  generate(imageUrl: string, size: string): string {
    if ((imageUrl || '').indexOf('assets/images') !== -1) {
      return imageUrl;
    }

    const uri = encodeURIComponent(imageUrl);
    const path = `${this.path}?uri=${uri}&size=${size}`;
    return environment.production === true ? path : environment.cloudFunctions + path;
  }
}
