import { auctioneerProfit, sellerProfit } from '../constants';
import { AuctionTransaction } from '../models';

/**
 * Calculates and returns the total revenue amount based on the given auction transaction
 *
 * @param transaction Auction transaction from which to calculate the total revenue amount
 */
export function calcAuctionTotalRevenue(transaction: AuctionTransaction): number {
  const { buyerInvoice } = transaction;
  const hammerPrice = buyerInvoice.details.hammerPrice ? buyerInvoice.details.hammerPrice : 0.0;

  return [
    hammerPrice,
    buyerInvoice.details.auctionFee ? buyerInvoice.details.auctionFee : 0.0,
    buyerInvoice.details.packagingFee ? buyerInvoice.details.packagingFee : 0.0,
    auctioneerProfit(hammerPrice) * -1,
    sellerProfit(hammerPrice) * -1,
  ].reduce((sum: number, a: number) => sum + a);
}
