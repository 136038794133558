import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { Store } from '@ngrx/store';
import { from, fromEvent, Observable, of } from 'rxjs';
import { delay, filter, map, share, switchMap, take, tap } from 'rxjs/operators';
import { User } from '@angular/fire/auth';

import { AuthUser } from '@whiskybazar/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as fromAuthStore from '@whiskybazar/pwa/auth/store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  AuthService,
  ConnectionService,
  DataLayerService,
  SearchService,
  SeoService,
  WindowRefService,
} from '@whiskybazar/pwa/core/services';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { environment } from '@whiskybazar/pwa/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  loggedIn$: Observable<boolean>;
  user$: Observable<AuthUser>;
  navigating$: Observable<boolean>;
  closing$: Observable<boolean>;
  searchBar = false;
  appVersion = environment.version;
  appRevisionId = environment.revisionId;

  constructor(
    private store: Store<fromAuthStore.AuthState>,
    private router: Router,
    private winRef: WindowRefService,
    private seoService: SeoService,
    @Inject(PLATFORM_ID) private platformId: string,
    private authService: AuthService,
    private dataLayerService: DataLayerService,
    private snackBar: MatSnackBar,
    private connection: ConnectionService,
    private searchService: SearchService,
    private swUpdates: SwUpdate,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  ngOnInit() {
    this.loggedIn$ = this.store.select(fromAuthStore.getLoggedIn);
    this.user$ = this.store.select(fromAuthStore.getUser);

    this.navigating$ = this.router.events.pipe(
      filter(
        (e) =>
          e instanceof NavigationStart ||
          e instanceof NavigationEnd ||
          e instanceof NavigationCancel ||
          e instanceof NavigationError
      ),
      delay(0),
      map((e) => e instanceof NavigationStart),
      tap((navigated: boolean) => this.resetMetaTags(navigated)),
      share()
    );

    if (isPlatformBrowser(this.platformId)) {
      this.searchService.init();
      this.dataLayerService.startTracking();

      this.closing$ = fromEvent(this.winRef.nativeWindow, 'beforeunload').pipe(
        tap(() => this.logout()),
        map(() => true)
      );

      // Logout the user whenever auth state changes to "signed out"
      this.loggedIn$
        .pipe(
          filter((loggedIn: boolean) => loggedIn === true),
          take(1),
          switchMap(() => this.authService.stateChange),
          filter((state: User | null) => state === null),
          tap(() => this.logout())
        )
        .subscribe(() => {
          this.store.dispatch(new fromAuthStore.LoginRedirect());
        });

      // Inform the user regarding connection loses
      let connectionSnackBarRef: MatSnackBarRef<SimpleSnackBar>;
      this.connection.monitor().subscribe((isOnline) => {
        if (isOnline && connectionSnackBarRef) {
          connectionSnackBarRef.dismiss();
        } else {
          connectionSnackBarRef = this.snackBar.open(
            'It seems that you are offline! Please check your Internet connection.',
            'Dismiss',
            {
              panelClass: 'connection-offline',
              horizontalPosition: 'right',
              verticalPosition: 'top',
            }
          );
        }
      });

      this.displayBrowserSupportWarning();

      this.promptUpdate();
    } else {
      this.closing$ = of(false);
    }
  }

  logout() {
    this.store.dispatch(new fromAuthStore.Logout());
  }

  toggleSearchBar() {
    this.searchBar = !this.searchBar;
  }

  protected resetMetaTags(navigated: boolean): void {
    if (navigated === true) {
      this.seoService.generateTags({});
    }
  }

  protected displayBrowserSupportWarning() {
    const UA = this.winRef.nativeWindow.navigator.userAgent;
    const isSupported = /Chrome\/.*|Safari\/.*/.test(UA);
    // Warn the user regarding browser support, i.e. any browser that is not Chrome or Safari
    if (!isSupported) {
      this.snackBar.open(
        'WhiskyBazar.com is only optimized for Chrome and Safari browsers. You might experience undesired behavior using WhiskyBazar.com on this browser.',
        'Dismiss',
        {
          panelClass: 'browser-warning',
          horizontalPosition: 'right',
          verticalPosition: 'top',
        }
      );
    }
  }

  protected promptUpdate() {
    if (!this.swUpdates.isEnabled) {
      return;
    }

    const promptUser = (event: VersionReadyEvent) =>
      this.snackBar
        .open('An update is available. Would you like to update now?', 'Update', {
          data: event,
          horizontalPosition: 'right',
          verticalPosition: 'bottom',
        })
        .onAction();

    const activateUpdate$ = from(this.swUpdates.activateUpdate());

    this.swUpdates.versionUpdates
      .pipe(
        filter((event): event is VersionReadyEvent => event.type === 'VERSION_READY'),
        switchMap((event: VersionReadyEvent) => promptUser(event)),
        switchMap(() => activateUpdate$)
      )
      .subscribe({
        next: (updated: boolean) => updated && this.doc.location.reload(),
        error: (err) => console.error(err),
      });
  }
}
