import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, concatMap, tap } from 'rxjs/operators';

import { CategoriesService } from '@whiskybazar/pwa/categories/services';
import * as CategoriesPageActions from '../actions/categories-page.actions';

@Injectable()
export class CategoriesPageEffects {
  loadCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoriesPageActions.loadCategories),
      concatMap(() =>
        this.categoriesService.fetchAll().pipe(
          map((categories) => CategoriesPageActions.loadCategoriesSuccess({ categories })),
          catchError((error) => of(CategoriesPageActions.loadCategoriesFailure({ error })))
        )
      )
    )
  );

  loadCategoriesSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CategoriesPageActions.loadCategoriesSuccess),
        tap(() => this.categoriesService.initNavigation())
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private categoriesService: CategoriesService) {}
}
