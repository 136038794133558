import { createFeatureSelector, createSelector } from '@ngrx/store';

// eslint-disable-next-line @nx/enforce-module-boundaries
import * as fromRoot from '@whiskybazar/pwa/store';
import * as fromLandingPage from './landing-page.reducers';

export interface PublicState {
  landingPage: fromLandingPage.LandingPageState;
}

export interface State extends fromRoot.State {
  public: PublicState;
}

export const reducers = {
  landingPage: fromLandingPage.reducer,
};

export const selectPublicState = createFeatureSelector<PublicState>('public');

// Landing Page selectors
export const selectLandingPageState = createSelector(selectPublicState, (state: PublicState) => state.landingPage);

export const getLoaded = createSelector(selectLandingPageState, fromLandingPage.getLoaded);
export const getLoading = createSelector(selectLandingPageState, fromLandingPage.getLoading);
export const getBottles = createSelector(selectLandingPageState, fromLandingPage.getBottles);
export const getError = createSelector(selectLandingPageState, fromLandingPage.getError);
export const getBottlesCount = createSelector(getBottles, (bottles) => (bottles ? bottles.length : 0));
export const getHasMore = createSelector(selectLandingPageState, fromLandingPage.getHasMore);
export const getQuery = createSelector(selectLandingPageState, fromLandingPage.getQuery);
export const getSearching = createSelector(selectLandingPageState, fromLandingPage.getSearching);
export const getTotalHits = createSelector(selectLandingPageState, fromLandingPage.getTotalHits);

// Facets
export const getFacets = createSelector(selectLandingPageState, fromLandingPage.getFacets);
export const getDistilleries = createSelector(getFacets, ({ distilleries }) => distilleries);
export const getBottlers = createSelector(getFacets, ({ bottlers }) => bottlers);
export const getRegions = createSelector(getFacets, ({ regions }) => regions);
export const getTypes = createSelector(getFacets, ({ types }) => types);
export const getPrice = createSelector(getFacets, ({ price }) => price);
