import { createReducer, on, Action } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { Article } from '@whiskybazar/pwa/categories/models';
import * as ArticlePageActions from '../actions/article-page.actions';

export interface State extends EntityState<Article> {
  selectedArticleId: string;
  loaded: boolean;
}

export const adapter: EntityAdapter<Article> = createEntityAdapter<Article>();

export const initialState: State = adapter.getInitialState({
  selectedArticleId: null,
  loaded: false,
});

const articlePageReducer = createReducer(
  initialState,
  on(ArticlePageActions.loadArticleContent, (state, action) => adapter.addOne(action.article, state)),
  on(ArticlePageActions.addArticles, (state, action) => adapter.addMany(action.articles, { ...state, loaded: true })),
  on(ArticlePageActions.updateArticle, (state, action) => adapter.updateOne(action.article, state)),
  on(ArticlePageActions.selectArticle, (state, action) => ({ ...state, selectedArticleId: action.articleId }))
);

export function reducer(state: State | undefined, action: Action) {
  return articlePageReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const getLoaded = (state: State) => state.loaded;

export const getSelected = (state: State) => state.entities[state.selectedArticleId];
