import { Sale, SaleData } from '../models';
import { Mapper } from './mapper';

export class SaleMapper implements Mapper<SaleData, Sale> {
  fromDb(data: SaleData | null): Sale | null {
    if (!data) {
      return null;
    }

    return {
      id: <string>data.id,
      saleId: <string>data.sale_id,
      listPrice: <number>data.list_price,
      discountPrice: data.discount_price ? <number>data.discount_price : 0,
      discountActivated: data.discount_activated ? <boolean>data.discount_activated : false,
      createdAt: data.created_at ? new Date(data.created_at) : new Date(),
      updatedAt: data.updated_at ? new Date(data.updated_at) : new Date(data.created_at),
      dealerId: <string>data.dealer,
    };
  }

  toDb(model: Sale | null): SaleData | null {
    return null;
    // TODO impl.
  }
}

export const saleMapper = new SaleMapper();
