import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AdminGuard, CollectorGuard, DealerGuard, SignupGuard } from '@whiskybazar/pwa/core/guards';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const router: Routes = [
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then((m) => m.SignupModule),
    canLoad: [SignupGuard],
  },

  // Collector Routing
  { path: 'latest', redirectTo: 'collector/latest' },
  { path: 'favorites', redirectTo: 'collector/favorites' },
  { path: 'bids', redirectTo: 'collector/bids' },
  { path: 'collection', redirectTo: 'collector/collection' },
  {
    path: 'collector',
    loadChildren: () => import('./collector/collector.module').then((m) => m.CollectorModule),
    canLoad: [CollectorGuard],
  },

  // Dealer Routing
  { path: 'auctions', redirectTo: 'dealer/auctions' },
  { path: 'stock', redirectTo: 'dealer/stock' },
  {
    path: 'dealer',
    loadChildren: () => import('./dealer/dealer.module').then((m) => m.DealerModule),
    canLoad: [DealerGuard],
  },

  // CMS Routing
  {
    path: 'admin',
    loadChildren: () => import('./cms/cms.module').then((m) => m.CmsModule),
    canLoad: [AdminGuard],
  },

  // Bottle (details) Routing
  {
    path: 'bottle',
    loadChildren: () => import('./bottle/bottle.module').then((m) => m.BottleModule),
  },

  // Dealers Routing
  {
    path: 'dealers',
    loadChildren: () => import('./dealers/dealers.module').then((m) => m.DealersModule),
  },

  {
    path: 'buying',
    loadChildren: () => import('@whiskybazar/pwa-buying').then((module) => module.PwaBuyingModule),
  },

  {
    path: 'selling',
    loadChildren: () => import('@whiskybazar/pwa-selling').then((module) => module.PwaSellingModule),
  },

  {
    path: 'delivery',
    loadChildren: () => import('@whiskybazar/pwa-delivery').then((module) => module.PwaDeliveryModule),
  },

  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(router, {
      scrollPositionRestoration: 'enabled',
      initialNavigation: 'enabledBlocking',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
