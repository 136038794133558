import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { SeoService } from '@whiskybazar/pwa/core/services';
import { Article } from '@whiskybazar/pwa/categories/models';
import { State, selectArticlePageSelected } from '@whiskybazar/pwa/categories/store/reducers';

@Component({
  selector: 'app-article-page',
  templateUrl: './article-page.component.html',
  styleUrls: ['./article-page.component.scss'],
})
export class ArticlePageComponent implements OnInit {
  article$: Observable<Article>;

  private readonly imagesFolder = '/assets/images/categories/';

  constructor(
    private store$: Store<State>,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private seoService: SeoService
  ) {}

  ngOnInit() {
    this.article$ = this.store$.pipe(
      select(selectArticlePageSelected),
      map((article: Article) => ({
        ...article,
        safeContent: this.domSanitizer.bypassSecurityTrustHtml(article.content),
      })),
      tap((article: Article) => this.seo(article))
    );
  }

  protected seo(article: Article) {
    this.seoService.generateTags(
      {
        title: article.title,
        description: article.summary,
        image: {
          url: this.imagesFolder + article.image,
          width: article.imageWidth,
          height: article.imageHeight,
        },
        url: this.router.url,
      },
      'article'
    );
  }
}
