import { Mapper } from './mapper';

export class AdminMapper implements Mapper<boolean, boolean> {
  fromDb(data: boolean): boolean {
    return data;
  }

  toDb(model: boolean): boolean {
    return model;
  }
}

export const adminMapper = new AdminMapper();
