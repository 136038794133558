<section [class.mt-2]="(isMobile$ | async) === false">
  <div class="page-container" fxLayout="row" fxLayoutAlign="center center">
    <div fxFlex="nogrow">
      <mat-drawer-container [hasBackdrop]="backdrop$ | async">
        <mat-drawer #drawer [opened]="opened$ | async" [mode]="mode$ | async">
          <app-categories-navigation [categories]="categories$ | async"></app-categories-navigation>
        </mat-drawer>
        <mat-drawer-content [class.is-mobile]="isMobile$ | async">
          <div class="mb-2" fxLayout="row" fxLayoutAlign="end start" *ngIf="isMobile$ | async">
            <button mat-button (click)="drawer.toggle()"><mat-icon>menu</mat-icon> Categories</button>
          </div>
          <router-outlet></router-outlet>
        </mat-drawer-content>
      </mat-drawer-container>
    </div>
  </div>
</section>
