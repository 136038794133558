<div fxLayout="column" fxLayoutAlign="center center" class="mb-4 enlarged-container">
  <img
    fxFlex="none"
    *ngIf="enlarged$ | async as enlarged"
    [appImageThumbnail]="enlarged.servingUrl"
    thumbnailSize="533x800"
    class="enlarged"
    [hidden]="loading"
    (load)="onLoaded()"
  />
  <mat-spinner fxFlex="none" *ngIf="loading"></mat-spinner>
</div>
<div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="8px" *ngIf="bottle.images.length > 1">
  <div fxFlex="none" *ngFor="let img of bottle.images" class="thumbnail" (click)="enlarge(img)">
    <img [appImageThumbnail]="img.servingUrl" class="thumbnail" />
  </div>
</div>
