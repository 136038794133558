import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, interval, Observable, of, Subject } from 'rxjs';
import { filter, map, share, startWith, switchMap, takeWhile, tap } from 'rxjs/operators';

import { Auction } from '@whiskybazar/core';
import { AUCTION_RUNTIMES, AUCTION_STATES } from '@whiskybazar/core';
import { PlatformService } from '@whiskybazar/pwa/core/services';
import { toDate } from '@whiskybazar/pwa/utils';

type UnitName = 'days' | 'hours' | 'minutes' | 'seconds';

type UnitSuffix = 'd' | 'h' | 'm' | 's';

interface TimeUnit {
  name: UnitName;
  suffix?: UnitSuffix;
}

interface Countdown {
  value: number;
  unit: TimeUnit;
  time?: Date;
}

@Component({
  selector: 'app-auction-countdown',
  templateUrl: './auction-countdown.component.html',
  styleUrls: ['./auction-countdown.component.scss'],
})
export class AuctionCountdownComponent implements OnInit {
  @Input()
  set auction(val: Auction | null) {
    if (val && val.state === AUCTION_STATES.COUNTING_DOWN.id) {
      const runtime = AUCTION_RUNTIMES[val.runtime];
      const endsAt = val.endingAt
        ? toDate(val.endingAt)
        : new Date(toDate(val.startedAt).getTime() + 1000 * runtime.durationInSeconds);

      this.endingAt$.next(endsAt);
    }
  }

  @Input() suffix: string;

  countdowns$: Observable<Countdown[]>;

  ended$: BehaviorSubject<boolean>;

  now = new Date();

  readonly unitNames: { [k: string]: UnitName } = {
    days: 'days',
    hours: 'hours',
    minutes: 'minutes',
    seconds: 'seconds',
  };

  private readonly milliseconds = {
    days: 1000 * 60 * 60 * 24,
    hours: 1000 * 60 * 60,
    minutes: 1000 * 60,
    seconds: 1000,
  };

  private endingAt$: Subject<Date>;

  constructor(private platform: PlatformService) {
    this.suffix = 'left';
    this.ended$ = new BehaviorSubject<boolean>(false);
    this.endingAt$ = new BehaviorSubject<Date>(null);
  }

  ngOnInit() {
    const countdown = (endingAt: Date) => {
      return this.getInterval(endingAt).pipe(
        startWith(0),
        tap(() => (this.now = new Date())),
        map(() => this.calcCountdowns(endingAt)),
        map((countdowns: Countdown[]) => (countdowns.length > 2 ? countdowns.slice(0, 2) : countdowns)),
        tap((countdowns: Countdown[]) => this.end(countdowns)),
        takeWhile((countdowns: Countdown[]) => countdowns.length > 0),
        share()
      );
    };

    this.countdowns$ = this.endingAt$.pipe(
      filter((endingAt: Date | null) => endingAt instanceof Date),
      switchMap(countdown)
    );
  }

  trackByFn(index: number, countdown: Countdown): number {
    return index;
  }

  protected calcCountdowns(ending: Date): Countdown[] {
    return Object.keys(this.milliseconds)
      .map(
        (k: UnitName) =>
          ({
            value: this.calcCountdownValueByUnit(ending, k),
            unit: { name: k },
          } as Countdown)
      )
      .filter((c: Countdown) => c.value > 0)
      .map((c: Countdown, _, all: Countdown[]) => this.reduceCountdown(c, all, ending));
  }

  protected reduceCountdown(countdown: Countdown, allCountdowns: Countdown[], ending: Date): Countdown {
    const reduceByUnit = (countdowns: Countdown[], unit: TimeUnit) =>
      countdowns
        .filter((c) => c.unit.name === unit.name)
        .reduce((acc, c) => acc + c.value * this.milliseconds[c.unit.name], 0);

    switch (countdown.unit.name) {
      case 'days':
        return {
          ...countdown,
          time: new Date(ending.getTime()),
          unit: { ...countdown.unit, suffix: 'd' },
        };
      case 'hours':
        return {
          ...countdown,
          time: new Date(ending.getTime() - reduceByUnit(allCountdowns, { name: 'days' })),
          unit: { ...countdown.unit, suffix: 'h' },
        };
      case 'minutes':
        return {
          ...countdown,
          time: new Date(ending.getTime() - reduceByUnit(allCountdowns, { name: 'hours' })),
          unit: { ...countdown.unit, suffix: 'm' },
        };
      case 'seconds':
        const result = {
          ...countdown,
          time: new Date(ending.getTime() - reduceByUnit(allCountdowns, { name: 'minutes' })),
          unit: { ...countdown.unit, suffix: 's' },
        } as Countdown;

        return allCountdowns.length === 1
          ? { ...result, time: new Date(this.now.getTime() + result.value * this.milliseconds.seconds) }
          : result;
    }
  }

  protected calcCountdownValueByUnit(ending: Date, unit: string): number {
    const diff = ending.getTime() - this.now.getTime();
    if (diff <= 0) {
      return 0;
    }

    return Math.floor(diff / this.milliseconds[unit]);
  }

  protected getInterval(ending: Date): Observable<number> {
    if (this.platform.onServer()) {
      return of(1);
    }

    if (this.calcCountdownValueByUnit(ending, 'days') > 0) {
      return interval(this.milliseconds.hours);
    } else if (this.calcCountdownValueByUnit(ending, 'hours') > 0) {
      return interval(this.milliseconds.minutes);
    }

    return interval(this.milliseconds.seconds);
  }

  protected end(countdowns: Countdown[]) {
    if (countdowns.length === 0) {
      this.ended$.next(true);
      this.ended$.complete();
    }
  }
}
