import { AdminsService } from './admins.service';
import { AuthService } from './auth.service';
import { CollectorsService } from './collectors.service';
import { DealersService } from './dealers.service';
import { MetaBottlesService } from './meta-bottles.service';
import { BottlesService } from './bottles.service';
import { AuctionsService } from './auctions.service';
import { SalesService } from './sales.service';
import { StorageService } from './storage.service';
import { OwnersService } from './owners.service';
import { BidsService } from './bids.service';
import { AuthContextService } from './auth-context.service';
import { AuctionTransactionsService } from './auction-transactions.service';
import { TransactionsService } from './transactions.service';
import { ShippingService } from './shipping.service';
import { PaymentService } from './payment.service';
import { DatabaseApiService } from './database-api.service';
import { WindowRefService } from './window-ref.service';
import { SearchService } from './search.service';
import { AddressesService } from './addresses.service';
import { SeoService } from './seo.service';
import { PlatformService } from './platform.service';
import { DialogService } from './dialog.service';
import { NavigationService } from './navigation.service';
import { ThumbnailGeneratorService } from './thumbnail-generator.service';
import { AuctioneerMonthlyInvoicesService } from './auctioneer-monthly-invoices.service';
import { ArchiveService } from './archive.service';

export const services = [
  AdminsService,
  ArchiveService,
  AuctioneerMonthlyInvoicesService,
  AuthService,
  CollectorsService,
  DealersService,
  MetaBottlesService,
  BottlesService,
  AuctionsService,
  SalesService,
  StorageService,
  OwnersService,
  BidsService,
  AuthContextService,
  AuctionTransactionsService,
  TransactionsService,
  ShippingService,
  PaymentService,
  DatabaseApiService,
  WindowRefService,
  SearchService,
  AddressesService,
  SeoService,
  PlatformService,
  DialogService,
  NavigationService,
  ThumbnailGeneratorService,
];

export * from './admins.service';
export * from './archive.service';
export * from './auctioneer-monthly-invoices.service';
export * from './auth.service';
export * from './collectors.service';
export * from './dealers.service';
export * from './meta-bottles.service';
export * from './bottles.service';
export * from './auctions.service';
export * from './sales.service';
export * from './storage.service';
export * from './owners.service';
export * from './bids.service';
export * from './auth-context.service';
export * from './auction-transactions.service';
export * from './transactions.service';
export * from './shipping.service';
export * from './payment.service';
export * from './database-api.service';
export * from './window-ref.service';
export * from './search.service';
export * from './addresses.service';
export * from './seo.service';
export * from './platform.service';
export * from './data-layer.service';
export * from './connection.service';
export * from './dialog.service';
export * from './navigation.service';
export * from './thumbnail-generator.service';
