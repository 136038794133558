import { createReducer, on } from '@ngrx/store';

import { PhoneNumberVerificationStep } from '@whiskybazar/core';
import * as PhoneVerificationActions from '../actions/phone-verification.actions';

export interface State {
  error: any;
  pending: boolean;
  phoneNumber: string;
  step: PhoneNumberVerificationStep;
  verified: boolean;
}

export const initialState: State = {
  error: null,
  pending: false,
  phoneNumber: null,
  step: 'verify' as PhoneNumberVerificationStep,
  verified: false,
};

export const reducer = createReducer(
  initialState,

  on(PhoneVerificationActions.initPhoneVerification, (state, action) => ({
    ...state,
    phoneNumber: action.phoneNumber,
    pending: true,
    error: null,
  })),

  on(PhoneVerificationActions.initPhoneVerificationSuccess, (state) => ({
    ...state,
    pending: false,
    error: null,
    step: 'confirm' as PhoneNumberVerificationStep,
  })),

  on(PhoneVerificationActions.initPhoneVerificationFailure, (state, action) => ({
    ...state,
    pending: false,
    error: action.error,
  })),

  on(PhoneVerificationActions.confirmVerificationCode, (state) => ({
    ...state,
    pending: true,
    error: null,
  })),

  on(PhoneVerificationActions.confirmVerificationCodeSuccess, (state) => ({
    ...state,
    pending: false,
    error: null,
    verified: true,
  })),

  on(PhoneVerificationActions.confirmVerificationCodeFailure, (state, action) => ({
    ...state,
    pending: false,
    error: action.error,
  })),

  on(PhoneVerificationActions.resetPhoneNumberVerification, (state) => ({
    ...initialState,
    phoneNumber: state.phoneNumber,
  }))
);

export const getError = (state: State) => state.error;
export const getPending = (state: State) => state.pending;
export const getPhoneNumber = (state: State) => state.phoneNumber;
export const getStep = (state: State) => state.step;
export const getVerified = (state: State) => state.verified;
