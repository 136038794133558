import { Component, Input, OnInit } from '@angular/core';

import {
  Address,
  AuctioneerAuctionInvoice,
  Bottle,
  calcAuctioneerInvoiceSubtotal,
  calcAuctioneerInvoiceTotal,
  WHISKYBAZAR_INVOICING_ADDRESS,
} from '@whiskybazar/core';
import { InvoiceView } from '@whiskybazar/pwa/shared/common';

@Component({
  selector: 'app-auctioneer-invoice',
  templateUrl: './auctioneer-invoice.component.html',
  styleUrls: ['./auctioneer-invoice.component.scss'],
})
export class AuctioneerInvoiceComponent extends InvoiceView implements OnInit {
  @Input() set bottle(val: Bottle) {
    this._bottle = val;
    this.init(this.bottle);
  }

  @Input() full = true;

  get bottle(): Bottle {
    return this._bottle;
  }
  private _bottle: Bottle;

  invoice: AuctioneerAuctionInvoice;

  billing: Address;

  readonly whiskyBazar = WHISKYBAZAR_INVOICING_ADDRESS;

  constructor() {
    super();
  }

  ngOnInit() {}

  protected init(bottle: Bottle) {
    if (!bottle) {
      return;
    }

    super.init(bottle);

    this.invoice = this.auctioneerInvoice;
    this.billing = {
      name: this.auctioneer.name,
      email: this.auctioneer.email,
      phone: this.auctioneer.phone,
      address: this.auctioneer.address,
      city: this.auctioneer.city,
      zipCode: this.auctioneer.zipCode,
      country: this.auctioneer.country,
    } as Address;
  }

  calcSubtotal(): number {
    return calcAuctioneerInvoiceSubtotal(this.invoice);
  }

  calcTotal(): number {
    return calcAuctioneerInvoiceTotal(this.invoice);
  }
}
