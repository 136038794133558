import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { NavigationService } from '@whiskybazar/pwa/core/services';
import { HorizontalNavComponent } from '../horizontal-nav/horizontal-nav.component';

@Component({
  selector: 'app-vertical-nav',
  templateUrl: './vertical-nav.component.html',
  styleUrls: ['./vertical-nav.component.scss'],
})
export class VerticalNavComponent extends HorizontalNavComponent implements OnInit, OnDestroy {
  @Input() drawer: MatSidenav;

  private subscriptions: Subscription[];

  constructor(public navService: NavigationService, private router: Router) {
    super(navService);

    this.subscriptions = [];
  }

  ngOnInit() {
    const subscription = this.router.events.pipe(filter((e) => e instanceof NavigationStart)).subscribe(() => {
      if (this.drawer) {
        this.drawer.close();
      }
    });

    this.subscriptions.push(subscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
