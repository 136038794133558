<ng-template #metaBottle let-value>
  <h4 class="title">{{ value?.title }}</h4>
  <img [appImageThumbnail]="value | bottlePromotedImage" thumbnailSize="54x54" />
  <span class="details">
    {{ value | bottleAttr : ['barcode', 'age', 'distillationYear', 'alcoholPercentage'] }}
  </span>
</ng-template>

<!--
  ACTUAL CARD
 -->
<mat-card appearance="outlined">
  <mat-card-content>
    <form [formGroup]="form" (ngSubmit)="submit()">
      <div fxLayout="column">
        <!-- Barcode -->
        <mat-form-field>
          <input
            matInput
            type="text"
            placeholder="Barcode"
            i18n-placeholer
            formControlName="barcode"
            [matAutocomplete]="barcode"
          />
          <mat-autocomplete
            #barcode="matAutocomplete"
            [displayWith]="barcodeDisplayFn"
            (optionSelected)="onSelected($event)"
          >
            <mat-option *ngFor="let option of results; trackBy: trackByMetaBottle" [value]="option">
              <ng-container *ngTemplateOutlet="metaBottle; context: { $implicit: option }"></ng-container>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <!-- Title -->
        <mat-form-field>
          <input
            matInput
            type="text"
            placeholder="Title (Distillery, Bottler, Batch/Cask No, Item X of Y)"
            i18n-placeholer
            formControlName="title"
            required
            [matAutocomplete]="title"
          />
          <mat-hint>e.g. Glenlivet Signatory Vintage The Un-chillfiltred Collection</mat-hint>
          <mat-autocomplete
            #title="matAutocomplete"
            [displayWith]="titleDisplayFn"
            (optionSelected)="onSelected($event)"
          >
            <mat-option *ngFor="let option of results; trackBy: trackByMetaBottle" [value]="option">
              <ng-container *ngTemplateOutlet="metaBottle; context: { $implicit: option }"></ng-container>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <!-- Actions -->
      <div
        class="mt-4"
        fxLayout.gt-xs="row"
        fxLayout.xs="column"
        fxLayoutAlign.gt-xs="end center"
        fxLayoutAlign.xs="start stretch"
        fxLayoutGap="8px"
      >
        <ng-content></ng-content>
      </div>
    </form>
  </mat-card-content>
</mat-card>

<!--
  The following is never rendered in the DOM.
  It is added to clean up the subscription.
-->
<ng-container *ngIf="query$ | async"></ng-container>
