<div class="mt-4 mr-4 mb-4 ml-4" fxLayout="column" fxLayoutAlign="center center">
  <div fxFlex="nogrow">
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title i18n>Page Not Found</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="mt-4">
          <p i18n>The page you are trying to access is either moved or does not exist.</p>

          <p i18n>Please use the below button to return to home page.</p>
        </div>
      </mat-card-content>
      <mat-card-actions align="end">
        <a mat-button routerLink="/" i18n>Return to Home Page</a>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
