import { BottlesState, Facet, SearchResultFacets, Range, SortBy } from '@whiskybazar/core';
import {
  loadBottles,
  loadMoreBottles,
  loadBottlesSuccess,
  loadMoreBottlesSuccess,
  reloadBottlesSuccess,
  searchBottles,
  searchBottlesSuccess,
  searchBottlesError,
  resetFilters,
} from './../actions';
import { createReducer, on } from '@ngrx/store';

interface FacetState {
  distilleries: Facet;
  bottlers: Facet;
  regions: Facet;
  types: Facet;
  price: Range;
}

export interface LandingPageState extends BottlesState {
  query: string;
  searching: boolean;
  totalHits: number;
  facets: FacetState;
  sorting: SortBy;
}

const emptyFacet: Facet = {
  options: [],
  selection: [],
};

const emptyRange: Range = {
  min: 0,
  max: 100,
  step: 1,
  selection: [],
};

export const initialState: LandingPageState = {
  loaded: false,
  loading: false,
  bottles: [],
  error: null,
  hasMore: false,
  query: null,
  searching: false,
  totalHits: null,
  facets: {
    distilleries: emptyFacet,
    bottlers: emptyFacet,
    regions: emptyFacet,
    types: emptyFacet,
    price: emptyRange,
  },
  sorting: SortBy.PriceAsc,
};

const reduceFacets = (facets: FacetState, { distillery, bottler, type, region, price }: SearchResultFacets) => ({
  ...facets,
  distilleries: {
    ...facets.distilleries,
    options: distillery.options,
  },
  bottlers: {
    ...facets.bottlers,
    options: bottler.options,
  },
  regions: {
    ...facets.regions,
    options: region.options,
  },
  types: {
    ...facets.types,
    options: type.options,
  },
  price: {
    ...facets.price,
    min: price.min,
    max: price.max,
  },
});

export const reducer = createReducer(
  initialState,
  on(loadBottles, loadMoreBottles, (state) => ({ ...state, loading: true })),
  on(loadBottlesSuccess, reloadBottlesSuccess, (state, { result: { results, totalHits, facets } }) => ({
    ...state,
    loading: false,
    loaded: true,
    bottles: results,
    hasMore: totalHits > results.length,
    totalHits: totalHits,
    facets: reduceFacets(state.facets, facets),
  })),
  on(searchBottles, (state, { query, distilleries, bottlers, regions, types, price, sorting }) => ({
    ...state,
    searching: true,
    query: query ?? state.query,
    facets: {
      ...state.facets,
      distilleries: {
        ...state.facets.distilleries,
        selection: distilleries ?? state.facets.distilleries.selection,
      },
      bottlers: {
        ...state.facets.bottlers,
        selection: bottlers ?? state.facets.bottlers.selection,
      },
      regions: {
        ...state.facets.regions,
        selection: regions ?? state.facets.regions.selection,
      },
      types: {
        ...state.facets.types,
        selection: types ?? state.facets.types.selection,
      },
      price: {
        ...state.facets.price,
        selection: price ?? state.facets.price.selection,
      },
    },
    sorting: sorting ?? state.sorting,
  })),
  on(loadMoreBottlesSuccess, searchBottlesSuccess, (state, { result: { results, totalHits } }) => ({
    ...state,
    searching: false,
    loading: false,
    bottles: results,
    totalHits,
    hasMore: totalHits > results.length,
  })),
  on(resetFilters, (state) => ({
    ...state,
    facets: {
      ...state.facets,
      distilleries: {
        ...state.facets.distilleries,
        selection: [],
      },
      bottlers: {
        ...state.facets.bottlers,
        selection: [],
      },
      regions: {
        ...state.facets.regions,
        selection: [],
      },
      types: {
        ...state.facets.types,
        selection: [],
      },
      price: {
        ...state.facets.price,
        selection: [],
      },
    },
    searching: true,
  })),
  on(searchBottlesError, (state, { error }) => ({
    ...state,
    searching: false,
    loading: false,
    error,
  }))
);

export const getLoaded = (state: LandingPageState) => state.loaded;
export const getLoading = (state: LandingPageState) => state.loading;
export const getBottles = (state: LandingPageState) => state.bottles;
export const getError = (state: LandingPageState) => state.error;
export const getHasMore = (state: LandingPageState) => state.hasMore;
export const getQuery = (state: LandingPageState) => state.query;
export const getSearching = (state: LandingPageState) => state.searching;
export const getTotalHits = (state: LandingPageState) => state.totalHits;
export const getFacets = (state: LandingPageState) => state.facets;
