import { SHIPPING_SELF_PICKUP_AT_AUCTIONEER_PRODUCT_ID } from '../constants';
import { Address, ShippingOption } from '../models';

/**
 * Returns `true` if the receiving party must pick the package up through a pickup location (aka package shop)
 *
 * @param from Sender address
 * @param to Receiver address
 * @returns true if pickup is required, false otherwise
 */
export function isPickupRequired(from: Address, to: Address, shippingOption: ShippingOption): boolean {
  return (
    shippingOption.id !== SHIPPING_SELF_PICKUP_AT_AUCTIONEER_PRODUCT_ID &&
    from.country === 'Denmark' &&
    to.country === 'Denmark'
  );
}

/**
 * Returns `true` if the shipments related to the given shipping product must be booked manually
 *
 * Manual booking is the case for those shipping products that do not have any integration with shipping provider(s)
 *
 * @param shippingProductId ID of the shipping product
 * @returns true if the shipment for the given shipping product must be booked manually, false otherwise
 */
export function isShipmentBookingManual(shippingProductId: string): boolean {
  return shippingProductId === 'wb_defaults_dk';
}
