import { Location } from '@angular/common';
import { Store, Action } from '@ngrx/store';
import { Observable } from 'rxjs';

import { DialogService } from '@whiskybazar/pwa/core/services';
import { Image, Bottle, MetaBottle } from '@whiskybazar/core';

export abstract class EditBottleBase {
  bottle$: Observable<Bottle>;

  uploads$: Observable<Image[]>;

  uploading$: Observable<boolean>;

  pending$: Observable<boolean>;

  error$: Observable<any>;

  bottle: Bottle;

  constructor(protected store$: Store<any>, protected dialogService: DialogService, protected location: Location) {}

  onUpload(images: Image[]) {
    if (images.length > 8) {
      return this.alert('Please upload at most 8 images at a time!');
    }

    this.store$.dispatch(this.createUploadImagesAction(images));
  }

  onImageDeleted(image: Image) {
    this.store$.dispatch(this.createDeleteImageAction(image));
  }

  onImageRotated(image: Image) {
    this.store$.dispatch(this.createUpdateImageAction(image));
  }

  onImageError(event: [Image, any]) {
    this.dialogService.openAlert('Failed to perform the action on image! Please try again.', 'Image Error');
    console.error(event);
  }

  onImagePromoted(image: Image) {
    this.store$.dispatch(this.createPromoteImageAction(image));
  }

  onBottleFormSubmit(changes: Bottle) {
    const bottle = {
      ...this.bottle,
      ...changes,
    } as Bottle;

    if (changes.metaBottle) {
      bottle.metaBottle = {
        ...changes.metaBottle,
        image: this.bottle.metaBottle.image,
        images: this.bottle.metaBottle.images,
      } as MetaBottle;
    } else {
      bottle.metaBottle = this.bottle.metaBottle;
    }

    this.store$.dispatch(this.createSaveBottleAction(bottle));
  }

  goBack() {
    this.location.back();
  }

  protected alert(msg: string | null): void {
    if (!msg) {
      return;
    }

    this.dialogService.openAlert(msg, 'Error');
  }

  protected parseError(error: any): string | null {
    if (!error) {
      return null;
    }

    if (typeof error === 'string') {
      return error as string;
    }

    // TODO parse error
    return 'An unexpected error occurred! Please try again.';
  }

  protected abstract createUploadImagesAction(images: Image[]): Action;
  protected abstract createDeleteImageAction(image: Image): Action;
  protected abstract createUpdateImageAction(image: Image): Action;
  protected abstract createPromoteImageAction(image: Image): Action;
  protected abstract createSaveBottleAction(bottle: Bottle): Action;
}
