import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { AuthUser } from '@whiskybazar/core';
import { NavigationService } from '@whiskybazar/pwa/core/services';

@Component({
  selector: 'app-horizontal-nav',
  templateUrl: './horizontal-nav.component.html',
  styleUrls: ['./horizontal-nav.component.scss'],
})
export class HorizontalNavComponent implements OnInit {
  @Input() auth: AuthUser;

  @Input() loggedIn: boolean;

  @Output() readonly logout = new EventEmitter<void>();

  @Output() readonly search = new EventEmitter<void>();

  constructor(public navService: NavigationService) {}

  ngOnInit() {}

  _logout() {
    this.logout.emit();
  }

  _toggleSearchBar() {
    this.search.emit();
  }
}
