import { Pipe, PipeTransform } from '@angular/core';

import { Bottle, MetaBottle } from '@whiskybazar/core';

@Pipe({
  name: 'bottleAttr',
})
export class BottleAttrPipe implements PipeTransform {
  transform(
    value: Bottle | MetaBottle,
    attr = ['distillationYear', 'age', 'alcoholPercentage', 'volume'],
    separator = ', '
  ): string {
    if (!value) {
      return '';
    }

    const metaBottle = !!value['metaBottle'] ? (value as Bottle).metaBottle : (value as MetaBottle);
    return attr
      .filter((a) => {
        switch (a) {
          case 'itemNumber_numberOfItems': {
            return !!(value as Bottle).itemNumber && !!(value as Bottle).metaBottle.numberOfBottles;
          }

          default: {
            return !!metaBottle[a];
          }
        }
      })
      .map((a) => {
        switch (a) {
          case 'volume':
            return `${metaBottle[a]}ml`;

          case 'age':
            return `${metaBottle[a]} years`;

          case 'alcoholPercentage':
            return `Vol ${metaBottle[a]}%`;

          case 'itemNumber_numberOfItems': {
            return `${(value as Bottle).itemNumber}/${metaBottle.numberOfBottles}`;
          }

          case 'distillationYear': {
            return `Distilled ${metaBottle.distillationYear}`;
          }

          case 'bottlingYear': {
            return `Bottled ${metaBottle.bottlingYear}`;
          }

          default:
            return metaBottle[a];
        }
      })
      .join(separator);
  }
}
