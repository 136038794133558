import { Injectable } from '@angular/core';
import { Route, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap, take, tap, catchError } from 'rxjs/operators';

import * as fromAuthStore from '@whiskybazar/pwa/auth/store';
import { AuthService } from '../services';

@Injectable()
export class AuthGuard {
  constructor(
    public store$: Store<fromAuthStore.State>,
    private authService: AuthService,
    private location: Location
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.isAuthenticated(state.url);
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(next, state);
  }

  canLoad(route: Route): Observable<boolean> {
    return this.isAuthenticated(this.location.path(true));
  }

  protected isAuthenticated(url: string): Observable<boolean> {
    if (!this.authService.redirect && url) {
      this.authService.redirect = url;
    }

    return this.store$.select(fromAuthStore.getLoggedIn).pipe(
      take(1),
      tap((loggedIn: boolean) => this.authenticate(loggedIn)),
      switchMap((loggedIn: boolean) => of(loggedIn)),
      catchError(() => of(false))
    );
  }

  protected authenticate(loggedIn: boolean) {
    if (loggedIn) {
      return;
    }

    this.store$.dispatch(new fromAuthStore.LoginRedirect());
  }
}
