import { Injectable, inject } from '@angular/core';
import { Database, equalTo, limitToLast, orderByChild } from '@angular/fire/database';

import { AbstractFirebaseList } from './abstract-firebase-list';
import {
  Mapper,
  auctioneerMonthlyInvoiceMapper,
  AuctioneerMonthlyInvoice,
  AuctioneerMonthlyInvoiceData,
} from '@whiskybazar/core';
import { Observable } from 'rxjs';

@Injectable()
export class AuctioneerMonthlyInvoicesService extends AbstractFirebaseList<
  AuctioneerMonthlyInvoice,
  AuctioneerMonthlyInvoiceData
> {
  private readonly path = 'auctioneer_monthly_invoices';

  private afDb: Database = inject(Database);

  constructor() {
    super();
  }

  getPath(): string {
    return this.path;
  }

  getAngularFireDatabase(): Database {
    return this.afDb;
  }

  getMapper(): Mapper<AuctioneerMonthlyInvoiceData, AuctioneerMonthlyInvoice> {
    return auctioneerMonthlyInvoiceMapper;
  }

  fetchByAuctioneer(auctioneerId: string, limit = 20): Observable<AuctioneerMonthlyInvoice[]> {
    return this.query(orderByChild('auctioneer'), equalTo(auctioneerId), limitToLast(limit));
  }
}
