import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { StaticPage } from '@whiskybazar/core';
import { StaticPageAction, StaticPageActionType } from './../actions';

export interface State extends EntityState<StaticPage> {
  selectedPageId: string | null;
  error: any;
  pending: boolean;
}

export const adapter: EntityAdapter<StaticPage> = createEntityAdapter<StaticPage>();

export const initialState: State = adapter.getInitialState({
  selectedPageId: null,
  error: null,
  pending: false,
});

export function reducer(state = initialState, action: StaticPageAction): State {
  switch (action.type) {
    case StaticPageActionType.SelectPage: {
      return { ...state, selectedPageId: action.pageId };
    }

    case StaticPageActionType.LoadPage: {
      return { ...state, pending: true };
    }

    case StaticPageActionType.LoadPageSuccess: {
      return adapter.addOne(action.page, { ...state, pending: false, error: null });
    }

    case StaticPageActionType.LoadPageFail: {
      return { ...state, pending: false, error: action.error };
    }

    default: {
      return state;
    }
  }
}

export const getSelectedPageId = (state: State) => state.selectedPageId;
export const getError = (state: State) => state.error;
export const getPending = (state: State) => state.pending;

export const { selectIds: getIds, selectEntities: getEntities } = adapter.getSelectors();
