<div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="32px">
  <div fxFlex="none" *ngIf="full">
    <h1 class="text-center">Buyer Invoice</h1>
  </div>

  <div fxFlex="none" fxLayout="row" fxLayoutGap="32px" *ngIf="full">
    <!-- Auctioneer (aka Dealer) -->
    <div fxFlex="50%" fxLayout="row" fxLayoutAlign="start start">
      <div fxFlex="none">
        <h2>Auctioneer</h2>
        <app-address [address]="auctioneer" [full]="true"></app-address>
      </div>
    </div>
    <!-- Buyer -->
    <div fxFlex="50%" fxLayout="row" fxLayoutAlign="end start">
      <div fxFlex="none">
        <h2>Buyer</h2>
        <app-address [address]="billing" [full]="true"></app-address>
      </div>
    </div>
  </div>

  <div fxFlex="none" fxLayout="column" fxLayoutGap="16px">
    <div fxFlex="none" fxLayout="column" fxLayoutAlign="start end">
      <!-- Invoice ID -->
      <div fxFlex="none"><strong>Invoice ID:</strong> {{ invoice?.invoiceId }}</div>
      <!-- Invoice Date -->
      <div fxFlex="none"><strong>Invoice Date:</strong> {{ transaction?.createdAt | date }}</div>
      <!-- Payment Date -->
      <div fxFlex="none" *ngIf="invoice.payment">
        <strong>Payment Date:</strong> {{ invoice?.payment?.authorizedAt | date }}
      </div>
      <!-- Payment Method -->
      <div fxFlex="none" *ngIf="invoice.payment"><strong>Payment Method:</strong> {{ invoice?.payment?.method }}</div>
    </div>

    <!-- Details -->
    <div fxFlex="none" fxLayout="row" fxLayoutGap="32px">
      <table border="0" cellpadding="0" cellspacing="0">
        <thead>
          <tr>
            <th style="width: 60%">Item</th>
            <th>LOT</th>
            <th style="min-width: 150px">Winning bid</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <!-- Bottle Details -->
            <td class="bb">
              <strong>{{ bottle?.metaBottle?.title }}</strong>
              <br />
              <small>{{ bottle | bottleAttr }}</small>
            </td>
            <!-- Auction LOT no. -->
            <td class="bb">{{ bottle?.auction?.auctionId }}</td>
            <!-- Hammer Price -->
            <td class="bb">
              <app-currency [amount]="invoice?.details?.hammerPrice"></app-currency>
            </td>
          </tr>
          <!-- Auction Fee -->
          <tr>
            <td colspan="2" class="text-right">
              <span class="text-bold">Auction Fee:&nbsp;</span>
              <small>({{ auctionVariableFee | percent }} of winning bid)</small>
            </td>
            <td>
              <app-currency [amount]="invoice?.details?.auctionFee"></app-currency>
            </td>
          </tr>
          <!-- Packaging Fee -->
          <tr>
            <td colspan="2" class="text-right text-bold">Packaging Fee:</td>
            <td>
              <app-currency [amount]="invoice?.details?.packagingFee"></app-currency>
            </td>
          </tr>
          <!-- Shipping Cost -->
          <tr>
            <td colspan="2" class="text-right text-bold">Shipping Cost:</td>
            <td>
              <app-currency [amount]="invoice?.details?.shippingCost || 0.0"></app-currency>
            </td>
          </tr>
          <!-- Shipping Insurance Cost -->
          <tr>
            <td colspan="2" class="text-right text-bold">Shipping Insurance Cost:</td>
            <td>
              <app-currency [amount]="invoice?.details?.shippingInsuranceCost || 0.0"></app-currency>
            </td>
          </tr>
          <!-- VAT -->
          <tr>
            <td colspan="2" class="text-right text-bold">VAT:</td>
            <td>
              <app-currency amount="0.00"></app-currency>
            </td>
          </tr>
          <!-- TOTAL -->
          <tr>
            <td colspan="2" class="text-right text-bold" style="border-top: 2px solid #000">TOTAL:</td>
            <td style="border-top: 2px solid #000">
              <app-currency [amount]="calcTotal()"></app-currency>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div fxFlex="none" fxLayout="row" fxLayoutGap="32px">
    <!-- Billing Address -->
    <div fxFlex="50%" fxLayout="row" fxLayoutAlign="start start">
      <div fxFlex="none">
        <h2>Billing Address</h2>
        <app-address [address]="billing" [full]="true"></app-address>
      </div>
    </div>
    <!-- Shipping Address -->
    <div fxFlex="50%" fxLayout="row" fxLayoutAlign="end start">
      <div fxFlex="none">
        <h2>Shipping Address</h2>
        <app-address [address]="shipping" [full]="true"></app-address>
      </div>
    </div>
  </div>
</div>
