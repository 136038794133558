import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageEditorComponent } from './image-editor.component';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ImageThumbnailModule } from '../../directives';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [ImageEditorComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    ImageThumbnailModule,
    FlexLayoutModule,
    MatButtonModule,
  ],
  exports: [ImageEditorComponent],
})
export class ImageEditorModule {}
