import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { Category } from '@whiskybazar/pwa/categories/models';
import * as CategoryPageActions from '../actions/category-page.actions';

export interface State extends EntityState<Category> {
  selectedCategoryId: string;
  loaded: boolean;
}

export const adapter: EntityAdapter<Category> = createEntityAdapter<Category>();

export const initialState: State = adapter.getInitialState({
  selectedCategoryId: null,
  loaded: false,
});

const categoryPageReducer = createReducer(
  initialState,
  on(CategoryPageActions.addCategories, (state, action) =>
    adapter.setAll(action.categories, { ...state, loaded: true })
  ),
  on(CategoryPageActions.clearCategories, (state) => adapter.removeAll(state)),
  on(CategoryPageActions.selectCategory, (state, action) => ({ ...state, selectedCategoryId: action.id }))
);

export function reducer(state: State | undefined, action: Action) {
  return categoryPageReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const getLoaded = (state: State) => state.loaded;

export const getSelected = (state: State) => state.entities[state.selectedCategoryId];
