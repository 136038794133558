import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BottleDetailsComponent, BottleDetailsBodyComponent } from './bottle-details.component';
import { MatDividerModule } from '@angular/material/divider';
import { BottleNameModule, BottleLotModule, AuthContextModule, BottleConditionModule, BottleModule } from '../../pipes';

import { NgPipesModule } from 'ngx-pipes';
import { BottleDealerModule } from '../bottle-dealer/bottle-dealer.module';
import { BottleImagesModule } from '../bottle-images/bottle-images.module';
import { BottleStateModule } from '../bottle-state/bottle-state.module';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [BottleDetailsComponent, BottleDetailsBodyComponent],
  imports: [
    CommonModule,
    MatDividerModule,
    BottleNameModule,
    BottleDealerModule,
    BottleImagesModule,
    BottleLotModule,
    BottleStateModule,
    AuthContextModule,
    BottleConditionModule,
    NgPipesModule,
    BottleModule,
    FlexLayoutModule,
  ],
  exports: [BottleDetailsComponent, BottleDetailsBodyComponent],
})
export class BottleDetailsModule {}
