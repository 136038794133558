import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromRoot from '@whiskybazar/pwa/store';
import * as fromCategoriesPage from './categories-page.reducer';
import * as fromCategoryPage from './category-page.reducer';
import * as fromArticlePage from './article-page.reducer';

export const categoriesFeatureKey = 'categories';

export interface CategoriesState {
  categoriesPage: fromCategoriesPage.State;
  categoryPage: fromCategoryPage.State;
  articlePage: fromArticlePage.State;
}

export interface State extends fromRoot.State {
  [categoriesFeatureKey]: CategoriesState;
}

export const reducers = {
  categoriesPage: fromCategoriesPage.reducer,
  categoryPage: fromCategoryPage.reducer,
  articlePage: fromArticlePage.reducer,
};

export const selectCategoriesState = createFeatureSelector<CategoriesState>(categoriesFeatureKey);

/*
 ### Categories Page selectors
 */
export const selectCategoriesPageState = createSelector(
  selectCategoriesState,
  (state: CategoriesState) => state.categoriesPage
);

export const selectCategoriesPageCategories = createSelector(
  selectCategoriesPageState,
  fromCategoriesPage.getCategories
);

export const selectCategoriesPageLoaded = createSelector(selectCategoriesPageState, fromCategoriesPage.getLoaded);

export const selectCategoriesPageLoading = createSelector(selectCategoriesPageState, fromCategoriesPage.getLoading);

export const selectCategoriesPageError = createSelector(selectCategoriesPageState, fromCategoriesPage.getError);

/*
 ### Category Page selectors
 */
export const selectCategoryPageState = createSelector(
  selectCategoriesState,
  (state: CategoriesState) => state.categoryPage
);

export const selectCategoryPageLoaded = createSelector(selectCategoryPageState, fromCategoryPage.getLoaded);

export const selectCategoryPageSelected = createSelector(selectCategoryPageState, fromCategoryPage.getSelected);

export const selectCategoryPageAll = createSelector(selectCategoryPageState, fromCategoryPage.selectAll);

/*
 ### Article Page selectors
 */
export const selectArticlePageState = createSelector(
  selectCategoriesState,
  (state: CategoriesState) => state.articlePage
);

export const selectArticlePageLoaded = createSelector(selectArticlePageState, fromArticlePage.getLoaded);

export const selectArticlePageSelected = createSelector(selectArticlePageState, fromArticlePage.getSelected);

export const selectArticlePageEntities = createSelector(selectArticlePageState, fromArticlePage.selectEntities);
