import { Component } from '@angular/core';

import { list, items } from '@whiskybazar/pwa/shared/animations';
import { BottlesListComponent } from '../bottles-list/bottles-list.component';

@Component({
  animations: [list, items],
  selector: 'app-bottles-grid',
  templateUrl: './bottles-grid.component.html',
  styleUrls: ['./bottles-grid.component.scss'],
})
export class BottlesGridComponent extends BottlesListComponent {}
