import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-call-to-action',
  templateUrl: './call-to-action.component.html',
  styleUrls: ['./call-to-action.component.scss'],
})
export class CallToActionComponent implements OnInit {
  @Input() arrow = true;

  @Input() size: 'default' | 'hero' = 'default';

  @Input() color: 'default' | 'positive' | 'negative' = 'default';

  constructor() {}

  ngOnInit() {}
}
