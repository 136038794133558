export const TRANSACTION_STATES = {
  PENDING_PAYMENT: {
    id: 'PENDING_PAYMENT',
    name: 'Pending Payment',
    transitions: ['PENDING_RECEIVAL_OF_BOTTLE_BY_BUYER', 'CANCELLED'],
  },
  PENDING_RECEIVAL_OF_BOTTLE_BY_BUYER: {
    id: 'PENDING_RECEIVAL_OF_BOTTLE_BY_BUYER',
    name: 'Pending receival of bottle by buyer',
    transitions: ['PENDING_EXPIRATION_OF_BUYERS_RIGHT_OF_WITHDRAWAL', 'PENDING_SETTLEMENT_WITH_SELLER'],
  },
  PENDING_EXPIRATION_OF_BUYERS_RIGHT_OF_WITHDRAWAL: {
    id: 'PENDING_EXPIRATION_OF_BUYERS_RIGHT_OF_WITHDRAWAL',
    name: "Pending expiration of buyer's right of withdrawal",
    transitions: ['PENDING_SETTLEMENT_WITH_SELLER', 'PENDING_RETURN_OF_BOTTLE_TO_AUCTIONEER_OR_SELLER'],
  },
  PENDING_RETURN_OF_BOTTLE_TO_AUCTIONEER_OR_SELLER: {
    id: 'PENDING_RETURN_OF_BOTTLE_TO_AUCTIONEER_OR_SELLER',
    name: 'Pending return of bottle to auctioneer/seller',
    transitions: ['CANCELLED'],
  },
  PENDING_SETTLEMENT_WITH_SELLER: {
    id: 'PENDING_SETTLEMENT_WITH_SELLER',
    name: 'Pending payment to seller',
    transitions: ['PENDING_SETTLEMENT_WITH_AUCTIONEER', 'COMPLETED'],
  },
  PENDING_SETTLEMENT_WITH_AUCTIONEER: {
    id: 'PENDING_SETTLEMENT_WITH_AUCTIONEER',
    name: 'Pending payment to auctioneer',
    transitions: ['PENDING_SETTLEMENT_WITH_SELLER', 'COMPLETED'],
  },
  CANCELLED: {
    id: 'CANCELLED',
    name: 'Cancelled',
    transitions: [],
  },
  COMPLETED: {
    id: 'COMPLETED',
    name: 'Completed',
    transitions: [],
  },
};
