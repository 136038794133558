import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { BottleModule } from '../../pipes';
import { BottleDeleteButtonComponent } from './bottle-delete-button.component';

@NgModule({
  declarations: [BottleDeleteButtonComponent],
  imports: [CommonModule, MatIconModule, BottleModule, MatButtonModule],
  exports: [BottleDeleteButtonComponent],
})
export class BottleDeleteButtonModule {}
