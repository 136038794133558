import { FacetOption, RangeFilter, MultiValueFilter, SingleValueFilter } from '../models';

export function buildMultiValueFilters<T>(records: Partial<Record<keyof T, FacetOption[]>>): MultiValueFilter<T>[] {
  return Object.entries(records).map(
    ([name, options]) =>
      ({
        name,
        values: (options as FacetOption[]).map(({ name }) => name),
      } as MultiValueFilter<T>)
  );
}

export function buildRangeFilters<T>(records: Partial<Record<keyof T, number[]>>): RangeFilter<T>[] {
  return Object.entries(records)
    .filter(([, range]) => (range as number[])?.length > 0)
    .map(
      ([name, range]) =>
        ({
          name,
          min: Math.min(...((range as number[]) ?? [])),
          max: Math.max(...((range as number[]) ?? [])),
        } as RangeFilter<T>)
    );
}

export function isSingleValueFilter<T>(filter: unknown): filter is SingleValueFilter<T> {
  return (filter as SingleValueFilter<T>)?.value !== undefined;
}

export function isMultiValueFilter<T>(filter: unknown): filter is MultiValueFilter<T> {
  return (filter as MultiValueFilter<T>)?.values !== undefined;
}

export function isRangeFilter<T>(filter: unknown): filter is RangeFilter<T> {
  return (filter as RangeFilter<T>)?.min !== undefined && (filter as RangeFilter<T>)?.max !== undefined;
}
