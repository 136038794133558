<ng-template #startingBid>
  <span>Starting bid:</span>&nbsp;
  <app-currency [amount]="bottle?.auction?.minPrice"></app-currency>
</ng-template>

<ng-template #runtime> <span>Runtime:</span>&nbsp;{{ bottle.auction.runtime | auctionRuntime }} </ng-template>

<ng-template #myProfit>
  <span>My profit:</span>&nbsp;<app-currency
    [amount]="bottle?.auction?.minPrice | auctionProfit : bottle"
  ></app-currency>
</ng-template>

<ng-template #myProfitWithTerms>
  <ng-container *ngTemplateOutlet="myProfit"></ng-container>
  <div class="mat-caption text-muted">
    &asymp;&nbsp;{{ (bottle.auction.minPrice | auctionProfit : bottle) / 0.13417 | currency : 'DKK' }} (excl.
    <a routerLink="/terms">transaction fees</a>)
  </div>
</ng-template>

<ng-template #sellerInfo>
  <div class="mat-caption text-muted">Seller's contact information:</div>
  <div>
    <app-user [user]="bottle.owner"></app-user>
  </div>
</ng-template>

<ng-template #auctioneerInfo>
  <app-user [user]="bottle.auction.auctioneer" [withAddress]="true"></app-user>
</ng-template>

<ng-template #startingBidWithBids>
  <div fxLayout="column">
    <div fxFlex="none" class="label">
      Starting bid <span class="mat-caption text-muted">({{ bottle.auction.bidIds.length }})</span>
    </div>
    <div fxFlex="none" class="mat-headline-3" data-cy="best-bid">
      <app-currency [amount]="bottle?.auction?.minPrice"></app-currency>
    </div>
  </div>
</ng-template>

<ng-template #runtimeLarge>
  <div fxLayout="column">
    <div fxFlex="none" class="label">Runtime</div>
    <div fxFlex="none" class="mat-headline-3" data-cy="runtime-countdown">
      {{ bottle.auction.runtime | auctionRuntime }}
    </div>
  </div>
</ng-template>

<ng-template #currentBidWithBids>
  <div fxLayout="column">
    <div fxFlex="none" class="label">
      Current bid
      <span class="mat-caption text-muted" matTooltip="Total bids count" matTooltipPosition="above"
        >({{ bottle.auction.bidIds.length }})</span
      >
    </div>
    <div fxFlex="none" class="mat-headline-3" data-cy="best-bid">
      <app-currency [amount]="bottle?.auction?.bestBid?.amount"></app-currency>
    </div>
  </div>
</ng-template>

<ng-template #timeRemainingLarge>
  <div fxLayout="column">
    <div fxFlex="none" class="label">Time remaining</div>
    <div fxFlex="none" class="mat-headline-3" data-cy="runtime-countdown">
      <app-auction-countdown [auction]="bottle.auction" [suffix]="''"></app-auction-countdown>
    </div>
  </div>
</ng-template>

<ng-template #myCurrentProfit>
  <span>My current profit:</span>&nbsp;<app-currency
    [amount]="bottle?.auction?.bestBid?.amount | auctionProfit : bottle"
  ></app-currency>
  <mat-icon matTooltip="Exchange and transfer fees may occur" matTooltipPosition="above">info_outline</mat-icon>
</ng-template>

<!--
  ACTUAL TEMPLATE
 -->
<div fxLayout="column" fxLayoutGap="16px">
  <div [ngSwitch]="bottle.auction.state" fxFlex="none">
    <ng-container *ngSwitchCase="auctionStates.PENDING_ACCEPTANCE">
      <div class="auction-detail mat-h3">
        <ng-container *ngTemplateOutlet="startingBid"></ng-container>
      </div>

      <!-- SELLER -->
      <ng-container *ngIf="bottle | authContext : 'isSeller'">
        <!-- Seller Profit -->
        <div fxLayout="row" class="mat-caption text-muted">
          <div fxFlex="grow">
            My profit:
            <app-currency [amount]="bottle?.auction?.minPrice | auctionProfit : bottle"></app-currency> (excl.
            <a routerLink="/terms" fragment="sell-at-auction">transaction fees</a>)
          </div>
          <div fxFlex="none">
            &asymp;&nbsp;{{ (bottle.auction.minPrice | auctionProfit : bottle) / 0.13417 | currency : 'DKK' }}
          </div>
        </div>

        <!-- Runtime -->
        <div class="auction-detail mat-h3">
          <ng-container *ngTemplateOutlet="runtime"></ng-container>
        </div>

        <!-- Auctioneer Info -->
        <div class="auction-detail mat-h3">
          <span>Auctioneer:</span>&nbsp;<app-bottle-dealer [bottle]="bottle"></app-bottle-dealer>
        </div>
      </ng-container>

      <!-- AUCTIONEER -->
      <ng-container *ngIf="bottle | authContext : 'isAuctioneer'">
        <!-- Runtime -->
        <div class="mat-caption text-muted">
          <ng-container *ngTemplateOutlet="runtime"></ng-container>
        </div>

        <!-- My Profit -->
        <div class="auction-detail mat-h3">
          <ng-container *ngTemplateOutlet="myProfitWithTerms"></ng-container>
        </div>

        <!-- Seller Info -->
        <div class="mt-4">
          <ng-container *ngTemplateOutlet="sellerInfo"></ng-container>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="auctionStates.REJECTED">
      <!-- Starting bid and Runtime -->
      <div class="auction-detail mat-h3">
        <ng-container *ngTemplateOutlet="startingBid"></ng-container>
      </div>
      <div class="mat-caption text-muted">
        <ng-container *ngTemplateOutlet="runtime"></ng-container>
      </div>

      <!-- My Profit -->
      <div class="auction-detail mat-h3">
        <ng-container *ngTemplateOutlet="myProfitWithTerms"></ng-container>
      </div>

      <!-- Seller Info -->
      <div class="mt-4">
        <ng-container *ngTemplateOutlet="sellerInfo"></ng-container>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="auctionStates.PENDING_START">
      <!-- AUCTIONEER/SELLER -->
      <ng-container *ngIf="bottle | authContext : 'isAuctioneerOrSeller'; else others">
        <!-- SELLER -->
        <ng-container *ngIf="bottle | authContext : 'isSeller'">
          <!-- Auctioneer Info -->
          <div class="mb-4">
            <ng-container *ngTemplateOutlet="auctioneerInfo"></ng-container>
          </div>

          <!--
              ### Auction Details ###
            -->
          <!-- Starting Bid -->
          <div class="auction-detail mat-caption">
            <ng-container *ngTemplateOutlet="startingBid"></ng-container>
          </div>
          <!-- Runtime -->
          <div class="auction-detail mat-caption">
            <ng-container *ngTemplateOutlet="runtime"></ng-container>
          </div>
        </ng-container>

        <!-- AUCTIONEER -->
        <ng-container *ngIf="bottle | authContext : 'isAuctioneer'">
          <!-- Seller Info -->
          <div class="mt-4 mb-8">
            <ng-container *ngTemplateOutlet="sellerInfo"></ng-container>
          </div>

          <!--
              ### Auction Details ###
            -->
          <!-- Starting Bid -->
          <div class="auction-detail mat-caption">
            <ng-container *ngTemplateOutlet="startingBid"></ng-container>
          </div>
          <!-- Runtime -->
          <div class="auction-detail mat-caption">
            <ng-container *ngTemplateOutlet="runtime"></ng-container>
          </div>
          <!-- My Profit -->
          <div class="auction-detail mat-caption">
            <ng-container *ngTemplateOutlet="myProfit"></ng-container>
          </div>
        </ng-container>
      </ng-container>

      <!-- OTHERS -->
      <ng-template #others>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start" fxLayoutGap="32px">
          <div fxFlex="50%" class="auction-detail">
            <ng-container *ngTemplateOutlet="startingBidWithBids"></ng-container>
          </div>
          <div fxFlex="50%" class="auction-detail">
            <ng-container *ngTemplateOutlet="runtimeLarge"></ng-container>
          </div>
        </div>
      </ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="auctionStates.RUNNING">
      <!-- AUCTIONEER/SELLER -->
      <ng-container *ngIf="bottle | authContext : 'isAuctioneerOrSeller'; else others">
        <!-- SELLER -->
        <ng-container *ngIf="bottle | authContext : 'isSeller'">
          <!-- Auctioneer Info -->
          <div class="mb-4">
            <ng-container *ngTemplateOutlet="auctioneerInfo"></ng-container>
          </div>

          <!--
            ### Auction Details ###
          -->
          <!-- Starting Bid -->
          <div class="auction-detail mat-caption">
            <ng-container *ngTemplateOutlet="startingBid"></ng-container>
          </div>
          <!-- Runtime -->
          <div class="auction-detail mat-caption">
            <ng-container *ngTemplateOutlet="runtime"></ng-container>
          </div>
        </ng-container>

        <!-- AUCTIONEER -->
        <ng-container *ngIf="bottle | authContext : 'isAuctioneer'">
          <!-- Seller Info -->
          <div class="mt-4 mb-8">
            <ng-container *ngTemplateOutlet="sellerInfo"></ng-container>
          </div>

          <!--
            ### Auction Details ###
          -->
          <!-- Starting Bid -->
          <div class="auction-detail mat-caption">
            <ng-container *ngTemplateOutlet="startingBid"></ng-container>
          </div>
          <!-- Runtime -->
          <div class="auction-detail mat-caption">
            <ng-container *ngTemplateOutlet="runtime"></ng-container>
          </div>
          <!-- My Profit -->
          <div class="auction-detail mat-caption">
            <ng-container *ngTemplateOutlet="myProfit"></ng-container>
          </div>
        </ng-container>
      </ng-container>

      <!-- OTHERS -->
      <ng-template #others>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start" fxLayoutGap="32px">
          <div fxFlex="50%" class="auction-detail">
            <ng-container *ngTemplateOutlet="startingBidWithBids"></ng-container>
          </div>
          <div fxFlex="50%" class="auction-detail">
            <ng-container *ngTemplateOutlet="runtimeLarge"></ng-container>
          </div>
        </div>
      </ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="auctionStates.COUNTING_DOWN">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start" fxLayoutGap="32px">
        <div fxFlex="50%" class="auction-detail">
          <ng-container *ngTemplateOutlet="currentBidWithBids"></ng-container>
        </div>
        <div fxFlex="50%" class="auction-detail">
          <ng-container *ngTemplateOutlet="timeRemainingLarge"></ng-container>
        </div>
      </div>

      <!-- AUCTIONEER/SELLER -->
      <ng-container *ngIf="bottle | authContext : 'isAuctioneerOrSeller'; else others">
        <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="32px" class="mt-4">
          <div fxFlex="none">
            <div class="auction-detail mat-h3">
              <ng-container *ngTemplateOutlet="myCurrentProfit"></ng-container>
            </div>
          </div>
          <div fxFlex="none">
            <div class="auction-detail">
              <ng-container *ngTemplateOutlet="startingBid"></ng-container>
            </div>
            <div class="auction-detail">
              <ng-container *ngTemplateOutlet="runtime"></ng-container>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- OTHERS -->
      <ng-template #others>
        <div class="auction-detail mat-caption" *ngIf="bottle | authContext : 'hasAnyBid'">
          <span>My max bid:</span>&nbsp;<span
            [class.positive]="bottle | authContext : 'hasBestBid'"
            [class.negative]="!(bottle | authContext : 'hasBestBid')"
            ><app-currency [amount]="bottle | authContext : 'getMaxBid'"></app-currency
          ></span>
        </div>
      </ng-template>
    </ng-container>
  </div>
</div>
