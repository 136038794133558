/* eslint-disable @nx/enforce-module-boundaries */
import { Component, OnInit, OnDestroy, AfterViewInit, TransferState, makeStateKey } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { Store } from '@ngrx/store';
import { Observable, Subject, of } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { Bottle, Facet, FacetOption, Sorting, Range, SortBy } from '@whiskybazar/core';
import * as fromPublicStore from '@whiskybazar/pwa/public/store';
import { PlatformService } from '@whiskybazar/pwa/core/services';
import { MatSnackBar } from '@angular/material/snack-bar';

const LANDING_PAGE = makeStateKey<boolean>('LANDING_PAGE');

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit, OnDestroy, AfterViewInit {
  bottles$: Observable<Bottle[]>;
  loading$: Observable<boolean>;
  searching$: Observable<boolean>;
  hasMore$: Observable<boolean>;
  query$: Observable<string>;
  distilleriesFilter$: Observable<Facet>;
  bottlersFilter$: Observable<Facet>;
  regionsFilter$: Observable<Facet>;
  typesFilter$: Observable<Facet>;
  priceFilter$: Observable<Range>;
  sorting$: Observable<Sorting>;
  total$: Observable<number>;
  error$: Observable<string>;

  private readonly destroyed$ = new Subject<boolean>();

  constructor(
    private store$: Store<fromPublicStore.PublicState>,
    private platform: PlatformService,
    private state: TransferState,
    private route: ActivatedRoute,
    private viewportScroller: ViewportScroller,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.bottles$ = this.store$.select(fromPublicStore.getBottles);
    this.loading$ = this.store$.select(fromPublicStore.getLoading);
    this.hasMore$ = this.store$.select(fromPublicStore.getHasMore);
    this.searching$ = this.store$.select(fromPublicStore.getSearching);
    this.query$ = this.store$.select(fromPublicStore.getQuery);
    this.distilleriesFilter$ = this.store$.select(fromPublicStore.getDistilleries);
    this.bottlersFilter$ = this.store$.select(fromPublicStore.getBottlers);
    this.regionsFilter$ = this.store$.select(fromPublicStore.getRegions);
    this.typesFilter$ = this.store$.select(fromPublicStore.getTypes);
    this.priceFilter$ = this.store$.select(fromPublicStore.getPrice);
    this.sorting$ = of({
      options: [
        { value: SortBy.PriceAsc, name: 'Price (low to high)' },
        { value: SortBy.PriceDesc, name: 'Price (high to low)' },
        { value: SortBy.AgeAsc, name: 'Age (low to high)' },
        { value: SortBy.AgeDesc, name: 'Age (high to low)' },
      ],
      selection: SortBy.PriceAsc,
    } as Sorting);
    this.total$ = this.store$.select(fromPublicStore.getTotalHits);
    this.error$ = this.store$.select(fromPublicStore.getError).pipe(tap((error) => error && this.openSnackBar(error)));

    if (this.platform.onServer()) {
      this.onServer();
    }

    if (this.platform.onBrowser()) {
      this.onBrowser();
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngAfterViewInit() {
    if (this.platform.onBrowser()) {
      this.route.fragment
        .pipe(takeUntil(this.destroyed$))
        .subscribe((fragment) => this.viewportScroller.scrollToAnchor(fragment));
    }
  }

  loadMore() {
    this.store$.dispatch(fromPublicStore.loadMoreBottles());
  }

  onQueryChange(query: string) {
    this.store$.dispatch(fromPublicStore.searchBottles({ query }));
  }

  onDistilleriesChange(distilleries: FacetOption[]) {
    this.store$.dispatch(fromPublicStore.searchBottles({ distilleries }));
  }

  onBottlersChange(bottlers: FacetOption[]) {
    this.store$.dispatch(fromPublicStore.searchBottles({ bottlers }));
  }

  onRegionsChange(regions: FacetOption[]) {
    this.store$.dispatch(fromPublicStore.searchBottles({ regions }));
  }

  onTypesChange(types: FacetOption[]) {
    this.store$.dispatch(fromPublicStore.searchBottles({ types }));
  }

  onPriceChange(price: number[]) {
    this.store$.dispatch(fromPublicStore.searchBottles({ price }));
  }

  onSortingChange(sorting: SortBy) {
    this.store$.dispatch(fromPublicStore.searchBottles({ sorting }));
  }

  onClearFilters() {
    this.store$.dispatch(fromPublicStore.resetFilters());
  }

  protected onServer() {
    this.state.set<boolean>(LANDING_PAGE, true);
  }

  protected onBrowser() {
    const exists = this.state.get<boolean>(LANDING_PAGE, false);
    if (!exists) {
      return;
    }

    this.state.remove(LANDING_PAGE);
    this.store$.dispatch(fromPublicStore.reloadBottles());
  }

  protected openSnackBar(msg: string) {
    this.snackBar.open(msg, null, { duration: 3000 });
  }
}
