import { Pipe, PipeTransform } from '@angular/core';

import { BottleAttrPipe } from '../bottle-attr/bottle-attr.pipe';
import { Bottle, MetaBottle } from '@whiskybazar/core';

export type BottleAttr = keyof Bottle | keyof MetaBottle;

export type SpecialAttr = 'age_onlyIfNotInTitle' | 'itemNumber_numberOfItems';

export type AttrType = BottleAttr | SpecialAttr;

export type PrecedenceDef = AttrType[];

export const defaultPrecedenceDef: PrecedenceDef[] = [
  // First Attribute Position
  [
    'age_onlyIfNotInTitle',
    'distillationYear',
    'bottlingYear',
    'type',
    'origin',
    'itemNumber_numberOfItems',
    'volume',
    'alcoholPercentage',
  ],

  // Second Attribute Position
  [
    'itemNumber_numberOfItems',
    'alcoholPercentage',
    'volume',
    'type',
    'origin',
    'distillationYear',
    'bottlingYear',
    'age_onlyIfNotInTitle',
  ],
];

@Pipe({
  name: 'bottleAttrPrecedence',
})
export class BottleAttrPrecedencePipe implements PipeTransform {
  private readonly bottleAttr = new BottleAttrPipe();

  transform(value: Bottle, precedenceDef: PrecedenceDef[] = defaultPrecedenceDef, separator = ', '): string | null {
    if (!value) {
      return null;
    }

    const attr: BottleAttr[] = [];
    for (let pos = 0; pos < precedenceDef.length; pos++) {
      const position = precedenceDef[pos];
      let result = this.resolvePrecedence(position, value);
      this.pruneFromOthers(result, pos, precedenceDef);
      result = this.toBottleAttr(result);

      attr[pos] = result;
    }

    return this.bottleAttr.transform(value, attr, separator);
  }

  protected resolvePrecedence(position: PrecedenceDef, bottle: Bottle): AttrType {
    return position.find((attr) => {
      switch (attr) {
        case 'age_onlyIfNotInTitle': {
          return !this.titleContainsAge(bottle.metaBottle.title);
        }

        case 'itemNumber_numberOfItems': {
          return !!bottle.itemNumber && !!bottle.metaBottle.numberOfBottles;
        }

        default: {
          return !!bottle[attr] || !!bottle.metaBottle[attr];
        }
      }
    });
  }

  protected pruneFromOthers(attr: AttrType, currentPos: number, precedenceDef: PrecedenceDef[]) {
    for (let i = currentPos + 1; i < precedenceDef.length; i++) {
      precedenceDef[i] = precedenceDef[i].filter((a) => a !== attr);
    }
  }

  protected titleContainsAge(title: string): boolean {
    return title.match(/\D+\d{1,2}\D+/i) !== null;
  }

  protected toBottleAttr(attr: AttrType): BottleAttr {
    switch (attr) {
      case 'age_onlyIfNotInTitle': {
        return 'age';
      }

      default: {
        return attr as BottleAttr;
      }
    }
  }
}
