import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselComponent, CarouselItemDirective } from './carousel.component';

@NgModule({
  declarations: [CarouselComponent, CarouselItemDirective],
  imports: [CommonModule],
  exports: [CarouselComponent, CarouselItemDirective],
})
export class CarouselModule {}
