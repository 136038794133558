import { Component, OnInit, Input } from '@angular/core';

import { MetaBottle } from '@whiskybazar/core';

@Component({
  selector: 'app-meta-bottle-preview',
  templateUrl: './meta-bottle-preview.component.html',
  styleUrls: ['./meta-bottle-preview.component.scss', '../bottle-preview/bottle-preview.component.scss'],
})
export class MetaBottlePreviewComponent implements OnInit {
  @Input() metaBottle: MetaBottle;

  constructor() {}

  ngOnInit() {}
}

@Component({
  selector: 'app-meta-bottle-preview-action',
  template: ` <ng-content></ng-content> `,
})
export class MetaBottlePreviewActionComponent {}
